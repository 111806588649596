import styled from "styled-components";
import ShareButton from "./Share/ShareButton";
import { PLACEHOLDER_IMAGE } from "../../utils";
import { TEXT } from "../../fonts/text";
import { COLOR } from "../../fonts/color";
import RButton from "./RButton";
import RModal from "./RModal";
import { RXIcon } from "rn-rx-icons";

interface Props {
  dealDetail: {
    img: string;
    title: string;
    dealId: string;
  };
  onClose: () => void;
}
const ShareModal = (props: Props) => {
  const { dealDetail, onClose } = props;
  const { img, title, dealId } = dealDetail;

  const shareUrl = `https://app.pickle.co.th/deals/${dealId}`;

  return (
    <RModal active={!!dealId} setActive={onClose}>
      <ShareModalContainer>
        <HeaderContainer>
          <BoldH5Text>แชร์แคมเปญ</BoldH5Text>
          <IconContainer onClick={onClose}>
            <RXIcon name="Close" />
          </IconContainer>
        </HeaderContainer>
        <DetailContainer>
          <ColumnContainer>
            <DealImage src={img || PLACEHOLDER_IMAGE} />
            <BoldH7Text style={{ width: "322px" }}>{title}</BoldH7Text>
          </ColumnContainer>
          <RowContainer style={{ gap: "31px" }}>
            <ShareButton shareUrl={shareUrl} type={"Line"} />
            <ShareButton shareUrl={shareUrl} type={"Messenger"} />
            <ShareButton shareUrl={shareUrl} type={"Facebook"} />
            <ShareButton shareUrl={shareUrl} type={"Instagram"} />
            <ShareButton shareUrl={shareUrl} type={"Twitter"} />
          </RowContainer>
        </DetailContainer>
        <ButtonsContainer>
          <RButton
            title="คัดลอกลิ้งก์"
            icon="Link"
            iconLocation="lefticon"
            textColor={COLOR.Blue_700}
            textStyle={{ marginLeft: "8px" }}
            backgroundColor={COLOR.WHITE}
            hoverColor={COLOR.Blue_50}
            onPress={() => navigator.clipboard.writeText(shareUrl)}
          />
          <RButton title="เสร็จสิ้น" onPress={onClose} />
        </ButtonsContainer>
      </ShareModalContainer>
    </RModal>
  );
};

export default ShareModal;

const ShareModalContainer = styled.div`
  gap: 24px;
  width: 608px;
  background: #ffffff;
  border-radius: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  height: 76px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const DetailContainer = styled.div`
  display: flex;
  padding: 24px 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 16px;
`;

const DealImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
`;

const ButtonsContainer = styled.div`
  display: flex;
  height: 96px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${COLOR.Gray_200};
  gap: 172px;
`;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const IconContainer = styled.div`
  position: absolute;
  margin-left: 530px;
  :hover {
    cursor: pointer;
  }
`;
