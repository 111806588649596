import { Slider } from "@mui/material";
import { styled as mstyled } from "@mui/material/styles";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";
import { ANDCOLOR } from "../../fonts/andColor";
import { useState } from "react";

interface Props {
  age: number[];
  setAge: (age: number[]) => void;
  color?: string;
  containerStyle?: React.CSSProperties;
  silderStyle?: React.CSSProperties;
}

interface ColorProps {
  customColor: string;
}

const marks = [
  {
    value: 15,
    label: 15,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 45,
    label: 45,
  },
  {
    value: 60,
    label: 60,
  },
];

function ANDAgeSlider(props: Props) {
  const {
    age,
    setAge,
    color = ANDCOLOR.BLUE,
    containerStyle,
    silderStyle,
  } = props;

  const [tempAge, setTempAge] = useState<number[]>(age);

  return (
    <AgeContainer style={containerStyle}>
      <AgeSlider
        style={silderStyle}
        value={tempAge}
        min={13}
        max={65}
        marks={marks}
        onChange={(event: Event, value: number | number[]) => {
          setTempAge(value as number[]);
        }}
        onChangeCommitted={(
          event: React.SyntheticEvent | Event,
          value: number | number[]
        ) => {
          setAge(value as number[]);
          setTempAge(value as number[]);
        }}
        valueLabelDisplay="auto"
        customColor={color}
      />
    </AgeContainer>
  );
}

export default ANDAgeSlider;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  align-items: center;
  margin: 11px 0px 24px 8px;
  padding-top: 10px;
  border-radius: 16px;
`;

const AgeSlider = mstyled(Slider)<ColorProps>`
  width: 448px;
  color: ${(props) => props.customColor};

  & .MuiSlider-thumb {
    width: 16px;
    height: 16px;
  }

  & .MuiSlider-mark {
    background-color: ${COLOR.Gray_200};
    height: 24px;
    width: 3px;
    border-radius: 12px;
    &.MuiSlider-markActive {
      opacity: 1;
      background-color: ${(props) => props.customColor};
    },
  },

  & .MuiSlider-rail {
    opacity: 0.5;
    background-color: ${COLOR.Gray_200};
  }
`;

const AgeText = styled(TEXT.body1BOLD)`
  margin-top: 8px;
  color: ${ANDCOLOR.BLUE};
`;
