import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ColumnContainer, RowContainer } from "../../../utils/styling/general";
import { CategoryType } from "../../organisms/CreateCampaign/Options";
import CategoryCard from "../RCategoryCard";

interface Props {
  interests: CategoryType[];
}

const CardCategory = (props: Props) => {
  const { interests } = props;
  const components: JSX.Element[] = [];
  interests.map((cat) =>
    components.push(
      <CategoryCard
        active={true}
        category={cat}
        onClick={() => {}}
        width={138}
        height={68}
        imageDimension={64}
        ActiveTextStyle={BoldBlue700Bu4Text}
      />
    )
  );
  return (
    <GapColumnContainer>
      <WhiteB3Text>ความสนใจ</WhiteB3Text>
      <GapRowContainer>{components}</GapRowContainer>
    </GapColumnContainer>
  );
};

export default CardCategory;

const CategoryContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.6px solid ${COLOR.Blue_700};
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(10px);
  width: 148px;
  height: 68px;
`;

const GapColumnContainer = styled(ColumnContainer)`
  gap: 8px;
  align-items: flex-start;
`;
const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
  opacity: 0.8;
`;

const BoldBlue700Bu4Text = styled(TEXT.Bu4_Bold)`
  color: ${COLOR.Blue_700};
  line-height: 10px;
`;
