import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../fonts/andColor';
import { ANDTEXT } from '../../../fonts/andText';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import { RXIcon } from 'rn-rx-icons';
import ANDButton from '../../atoms/ANDButton';
import { pageIndex } from '../../../pages/NewCreateCampaign/NewCreateCampaignPage';
import {
  calculatePercentage,
  numberWithCommas,
  toDecimalNumber
} from '../../../utils';
import { PieChart } from '@mui/x-charts/PieChart';
import { PlatformType } from '../../../types/deal';
import { ActiveProps } from '../../../types';
import AToolTip from '../../atoms/AToolTip';
import { COLOR } from '../../../fonts/color';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  page: pageIndex;
  nano: {
    value: number;
    avgEngagement: number;
    avgReachs: number;
    avgImpressions: number;
    avgViews?: number;
  };
  micro: {
    value: number;
    avgEngagement: number;
    avgReachs: number;
    avgImpressions: number;
    avgViews?: number;
  };
  mid: {
    value: number;
    avgEngagement: number;
    avgReachs: number;
    avgImpressions: number;
    avgViews?: number;
  };
  macro: {
    value: number;
    avgEngagement: number;
    avgReachs: number;
    avgImpressions: number;
    avgViews?: number;
  };
  platform: PlatformType;
  containerRef: React.RefObject<HTMLDivElement>;
  triggerHeightChange: boolean;
  navigation: NavigateFunction;
  setSamePageNavigate: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_SCROLL_Y = 110;
const DEFAULT_HEIGHT = 220;

function Summary(props: Props) {
  const {
    nano,
    micro,
    mid,
    macro,
    platform,
    containerRef,
    page,
    triggerHeightChange,
    navigation,
    setSamePageNavigate
  } = props;

  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState<number>(236);

  const avgEngagement =
    nano.avgEngagement * nano.value +
      micro.avgEngagement * micro.value +
      mid.avgEngagement * mid.value +
      macro.avgEngagement * macro.value || 0;
  const avgReachs =
    nano.avgReachs * nano.value +
      micro.avgReachs * micro.value +
      mid.avgReachs * mid.value +
      macro.avgReachs * macro.value || 0;
  const avgImpressions =
    nano.avgImpressions * nano.value +
      micro.avgImpressions * micro.value +
      mid.avgImpressions * mid.value +
      macro.avgImpressions * macro.value || 0;
  const avgViews =
    (nano.avgViews || 0) * nano.value +
      (micro.avgViews || 0) * micro.value +
      (mid.avgViews || 0) * mid.value +
      (macro.avgViews || 0) * macro.value || 0;

  const highestPercentage = () => {
    let impressions = platform === 'TIKTOK' ? avgViews : avgImpressions;
    const highestValue = Math.max(avgEngagement, avgReachs, impressions);
    const text =
      highestValue === avgEngagement
        ? 'Engagement'
        : highestValue === avgReachs
        ? 'Reach'
        : highestValue === impressions
        ? 'Impression'
        : '-';

    const percentText =
      calculatePercentage(
        highestValue,
        avgEngagement + avgReachs + impressions
      ) + '%';
    return { text, percent: percentText };
  };

  const impressionsDeterminer = () => {
    if (platform === 'TIKTOK') return avgViews;
    else return avgImpressions;
  };

  const pieSizing = {
    width: 220,
    height: 200,
    legend: { hidden: true }
  };

  const data = [
    {
      value: avgEngagement || 1,
      color: ANDCOLOR.BLUE600,
      label: 'Engagement'
    },
    {
      value: avgReachs || 1,
      color: ANDCOLOR.LIGHTPINK,
      label: 'Reach'
    },
    {
      value: impressionsDeterminer() || 1,
      color: ANDCOLOR.LIGHTYELLOW,
      label: 'Impression'
    }
  ];

  const onScroll = () => {
    const { scrollY } = window;
    const pageHeight = !!containerRef.current
      ? containerRef.current.clientHeight - 660
      : 2200;
    let newScrollY = MAX_SCROLL_Y;
    if (DEFAULT_HEIGHT - scrollY > 0) {
      newScrollY = DEFAULT_HEIGHT - scrollY;
    }
    if (scrollY > pageHeight - 80) {
      setScrollTop(pageHeight - scrollY);
    } else setScrollTop(newScrollY > MAX_SCROLL_Y ? newScrollY : MAX_SCROLL_Y);
  };

  const ContentComponent = () => {
    if (platform !== 'TIKTOK')
      return (
        <ContentContainer>
          <ColumnContainer>
            <BoldHeader7Text>
              {toDecimalNumber({ number: avgEngagement })}
            </BoldHeader7Text>
            <GrayHeader9Text>Avg. Engagement</GrayHeader9Text>
          </ColumnContainer>
          <ColumnContainer>
            <BoldHeader7Text>
              {toDecimalNumber({ number: avgReachs })}
            </BoldHeader7Text>
            <GrayHeader9Text>Avg. Reach</GrayHeader9Text>
          </ColumnContainer>
          <ColumnContainer>
            <BoldHeader7Text>
              {toDecimalNumber({ number: avgImpressions })}
            </BoldHeader7Text>
            <GrayHeader9Text>Avg. Impression</GrayHeader9Text>
          </ColumnContainer>
        </ContentContainer>
      );
    else
      return (
        <TiktokContentContainer>
          <ColumnContainer>
            <BoldHeader7Text>
              {toDecimalNumber({ number: avgEngagement })}
            </BoldHeader7Text>
            <GrayHeader9Text>Avg. Engagement</GrayHeader9Text>
          </ColumnContainer>
          <ColumnContainer>
            <BoldHeader7Text>
              {toDecimalNumber({ number: avgViews })}
            </BoldHeader7Text>
            <GrayHeader9Text>Avg. Impression</GrayHeader9Text>
          </ColumnContainer>
        </TiktokContentContainer>
      );
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [page]);

  useEffect(() => {
    if (!!containerRef.current) onScroll();
  }, [triggerHeightChange]);

  return (
    <Container
      style={{
        top: scrollTop
      }}
    >
      <HeaderContainer>
        <RowContainer>
          <BoldHeader6Text>คาดการณ์ผลลัพธ์แคมเปญ</BoldHeader6Text>
          <IconContainer
            onMouseEnter={() => {
              setTooltipActive(true);
            }}
            onMouseLeave={() => {
              setTooltipActive(false);
            }}
          >
            <RXIcon name="Info" />
          </IconContainer>
        </RowContainer>
        <TooltipContainer active={tooltipActive}>
          <AToolTip
            text={[
              'คำอธิบายข้อมูล',
              '1. Engagement คือค่าเฉลี่ยรวมของ Like, Comment, Share',
              '2. Reach คือค่าเฉลี่ยรวมเฉพาะผู้ใช้งานที่เห็นโพสต์ (Unique View)',
              '3. Impression คือค่าเฉลี่ยรวมทั้งหมดที่เข้าถึงโพสต์นั้น (Summary View)',
              'ข้อมูลที่แสดงเป็นข้อมูลที่คำนวณจากค่าเฉลี่ยในแอพพลิเคชัน ย้อนหลัง 30 วัน ยกเว้นช่องทาง Lemon8  จะไม่สามารถคำนวณข้อมูลข้อมูลล่วงหน้าได้'
            ]}
          />
        </TooltipContainer>
      </HeaderContainer>
      <BodyContainer>
        <ChartContainer>
          <PieChart
            series={[
              {
                data,
                innerRadius: 75,
                outerRadius: 100,
                cx: 98,
                valueFormatter: value =>
                  value.value === 1 ? '-' : value.value.toString()
              }
            ]}
            {...pieSizing}
          />
          <PercentContainer>
            <BlueBoldHeader4Text>
              {highestPercentage().percent}
            </BlueBoldHeader4Text>
            <BlueHeader9Text>{highestPercentage().text}</BlueHeader9Text>
          </PercentContainer>
        </ChartContainer>
        <ContentComponent />
      </BodyContainer>
      <FooterContainer>
        <ContentContainer>
          <BoldHeader7Text>Influencer ที่เลือก</BoldHeader7Text>
          <ANDButton
            title="เพิ่มจำนวน"
            onPress={() => {
              if (page === 1) setSamePageNavigate(true);
              else navigation('/createcampaign?page=1', { state: 'tier' });
            }}
          />
        </ContentContainer>
        <ContentContainer>
          <GrayBody3Text>Nano</GrayBody3Text>
          <Body2Text>{numberWithCommas(nano.value)}</Body2Text>
        </ContentContainer>
        <ContentContainer>
          <GrayBody3Text>Micro</GrayBody3Text>
          <Body2Text>{numberWithCommas(micro.value)}</Body2Text>
        </ContentContainer>
        <ContentContainer>
          <GrayBody3Text>Mid</GrayBody3Text>
          <Body2Text>{numberWithCommas(mid.value)}</Body2Text>
        </ContentContainer>
        <ContentContainer>
          <GrayBody3Text>Macro</GrayBody3Text>
          <Body2Text>{numberWithCommas(macro.value)}</Body2Text>
        </ContentContainer>
      </FooterContainer>
    </Container>
  );
}

export default Summary;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 352px;
  height: 554px;
  border-radius: 10px;
  border: 1px solid ${COLOR.Gray_300};
  background-color: ${ANDCOLOR.WHITE};
  right: 5%;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: center;
  width: 352px;
  border-bottom: 1px solid ${COLOR.Gray_300};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: center;
  justify-content: center;
  width: 352px;
  height: 310px;
  border-bottom: 1px solid ${COLOR.Gray_300};
`;

const ChartContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: center;
  width: 352px;
  height: 180px;
  gap: 4px;
`;

const ContentContainer = styled(SpaceBetweenRowContainer)`
  width: 352px;
  padding: 0px 20px;
`;

const TiktokContentContainer = styled(RowContainer)`
  width: 352px;
  padding: 0px 20px;
  justify-content: space-evenly;
`;

const PercentContainer = styled.div`
  position: absolute;
  top: 33%;
  left: 30%;
  right: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconContainer = styled.div``;

const TooltipContainer = styled.div<ActiveProps>`
  position: absolute;
  top: 50px;
  left: 50;
  display: flex;
  width: 307px;
  opacity: ${props => (props.active ? 1 : 0)};
  z-index: ${props => (props.active ? 99 : 0)};
`;

const BoldHeader6Text = styled(ANDTEXT.header6BOLD)`
  margin-right: 12px;
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)``;

const GrayHeader9Text = styled(ANDTEXT.header9)`
  color: ${COLOR.Gray_D};
`;

const BlueBoldHeader4Text = styled(ANDTEXT.header4BOLD)`
  color: ${ANDCOLOR.BLUE600};
`;

const BlueHeader9Text = styled(ANDTEXT.header9)`
  color: ${ANDCOLOR.BLUE};
`;
const GrayBody3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const Body2Text = styled(ANDTEXT.body2)``;
