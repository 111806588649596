import React from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";

interface Props {
  modalVisible: boolean;
  onClose: () => void;
  navigate: NavigateFunction;
}

function UnverifiedModal(props: Props) {
  const { modalVisible, onClose, navigate } = props;
  return (
    <RModal active={modalVisible} setActive={onClose}>
      <Container>
        <CloseContainer onClick={onClose}>
          <RXIcon name="Close" />
        </CloseContainer>
        <ImageContainer
          src={require("../../../assets/images/3DIcon/3DLightning.png")}
        />
        <BoldHeader5Text style={{ marginBottom: "8px" }}>
          ไม่สามารถเผยแพร่แคมเปญได้
        </BoldHeader5Text>
        <Body1Text style={{ marginBottom: "16px" }}>
          คุณยังไม่ได้อัปโหลดโลโก้แบรนด์
          <br />
          หรืออยู่ระหว่างการยืนยันบัญชี
          <br />
          หมายเหตุ : แคมเปญที่สร้างจะถูกบันทึกไว้
          <br />
          และเผยแพร่ทันที เมื่อบัญชีได้รับการยืนยัน
        </Body1Text>
        <RButton
          title="อัปโหลดโลโก้"
          onPress={() => navigate("/setting?page=profile")}
          containerStyle={{ width: "307px", marginBottom: "16px" }}
        />
        <RButton
          title="อัปโหลดแล้ว รอการยืนยันบัญชี"
          onPress={onClose}
          containerStyle={{ width: "307px" }}
          textColor={COLOR.DARKGREEN}
          backgroundColor={COLOR.WHITE}
          hoverColor={COLOR.LIGHTGREEN2}
        />
      </Container>
    </RModal>
  );
}

export default UnverifiedModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 394px;
  height: 492px;
  align-items: center;
  background: ${COLOR.WHITE};
  border-radius: 24px;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  width: 116px;
  height: 144px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const BoldHeader5Text = styled(TEXT.header5BOLD)``;

const Body1Text = styled(TEXT.body1)``;
