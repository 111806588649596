import { action, computed, makeAutoObservable, observable } from "mobx";
import { Account } from "../types/account";
import { setBrandName } from "../utils/Firebase";
import { GET, PATCH, uploadFileAsync } from "../utils/Networking";

export class AccountStore {
  @observable
  account: Account = {
    isVerified: false,
    brandId: "",
    name: "",
    img: "",
    authToken: "",
    email: "",
    contactName: "",
    contactPosition: "",
    contactTelephone: "",
    companyName: "",
    facebook: "",
    instagram: "",
    website: "",
    createdAt: "",
  };

  @observable
  brandSaved: string[] = [];

  @observable
  accountLoading: boolean = false;

  @observable
  accounts: Array<Account> = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  removeSavedInflu = (accountId: string) => {
    this.brandSaved = this.brandSaved.filter((saved) => saved !== accountId);
  };

  @action
  addSavedInflu = (accountId: string) => {
    this.brandSaved.push(accountId);
  };

  @action
  getAuthToken = () => {
    if (this.accounts.length === 0) this.loadAccounts();
    return localStorage.getItem("authToken");
  };

  @action
  setMyAccount = (account: Account) => {
    this.account = account;
  };

  @action
  getMyAccount = async () => {
    this.accountLoading = true;
    const brand = await GET("/brands/me");
    this.account = brand;
    this.brandSaved = brand.brandSaved || [];
    this.accountLoading = false;

    // set name for logging
    setBrandName(brand.name);

    const authToken = await localStorage.getItem("authToken");
    this.setAccounts(brand, authToken || "");
  };

  @observable
  changingContact = false;

  @observable
  changingContactAccount = {
    contactName: "",
    contactEmail: "",
    contactPosition: "",
    contactTelephone: "",
  };

  @action
  changeContactDetails = async () => {
    this.changingContact = true;
    const {
      contactName: name,
      contactEmail: email,
      contactPosition: position,
      contactTelephone: tel,
    } = this.changingContactAccount;
    const result = await PATCH(
      "/brands/setting/contact",
      {
        name,
        email,
        position,
        tel,
      },
      {}
    );
    this.account = {
      ...result,
      ...this.account,
    };
    this.changingContact = false;
  };

  @computed
  get isVerified() {
    return this.account.isVerified;
  }

  @action
  changeProfileImage = async (image: File) => {
    try {
      const result = await uploadFileAsync(image, undefined);
      const { imageUrl } = result;
      await PATCH(
        "/brands/setting/profileImg",
        {
          url: imageUrl,
        },
        {}
      );
      this.account.img = imageUrl;
    } catch (error) {
      console.log(error);
    }
  };

  @action
  setAccounts = async (account: Account, authToken: string) => {
    // get saved account
    const index = this.accounts.findIndex(({ brandId }) => {
      return brandId === account.brandId;
    });
    if (index < 0) {
      const addedAccounts = [...this.accounts, { ...account, authToken }];
      this.accounts = addedAccounts;
      localStorage.setItem("accounts", JSON.stringify(addedAccounts));
    } else {
      this.accounts[index] = { ...account, authToken };
      localStorage.setItem("accounts", JSON.stringify(this.accounts));
    }
  };

  @action
  loadAccounts = async () => {
    const accounts = await localStorage.getItem("accounts");
    this.accounts = JSON.parse(accounts || "") || [];
  };

  @action
  removeAccounts = (brandId?: string) => {
    // not specific brand -> remove all
    if (!brandId) {
      localStorage.removeItem("accounts");
      this.accounts = [];
      return;
    }

    let removedAccounts = this.accounts.filter(
      ({ brandId: _brandId }) => brandId !== _brandId
    );
    this.accounts = removedAccounts;
    localStorage.setItem("accounts", JSON.stringify(removedAccounts));
  };
}

export const accountStore = new AccountStore();
