import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ActiveProps } from "../../../types";
import { numberWithCommas } from "../../../utils";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  benefitsDetail: string;
  isValue: boolean;
  value: string | number;
  isCash: boolean;
  cash: string | number;
  onEdit?: () => void;
}

function DealCardReward(props: Props) {
  const { benefitsDetail, isValue, value, isCash, cash, onEdit } = props;

  const renderReward = () => {
    if (!isValue && !isCash) {
      return <RedableBody1Text active={true}>ยังไม่ระบุ</RedableBody1Text>;
    } else {
      return (
        <>
          {isValue && (
            <RewardContainer>
              <RedableBody1Text active={false}>
                {benefitsDetail || (
                  <RedableBody1Text active={true}>ไม่ได้ระบุ</RedableBody1Text>
                )}
              </RedableBody1Text>
              <RedableBody1Text active={value === 0}>
                มูลค่า {numberWithCommas(value)} บาท
              </RedableBody1Text>
            </RewardContainer>
          )}
          {isCash && (
            <RewardContainer>
              <RedableBody1Text active={false}>เงินค่าตอบแทน</RedableBody1Text>
              <RedableBody1Text active={cash === 0}>
                {numberWithCommas(cash)} บาท
              </RedableBody1Text>
            </RewardContainer>
          )}
        </>
      );
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <BoldButtonText>Reward</BoldButtonText>
        {!!onEdit && (
          <EditContainer onClick={onEdit}>
            <RXIcon name="Edit" color={COLOR.DARKGREEN2} size={20} />
            <Green2BoldBody1Text style={{ marginLeft: "4px" }}>
              แก้ไข
            </Green2BoldBody1Text>
          </EditContainer>
        )}
      </HeaderContainer>
      {renderReward()}
    </Container>
  );
}

export default DealCardReward;

const Container = styled.div`
  margin-top: 24px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${COLOR.Gray_300};
  margin-bottom: 12px;
`;

const RewardContainer = styled(RowContainer)`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  word-wrap: break-word;
`;

const BoldButtonText = styled(TEXT.buttonBOLD)``;

const RedableBody1Text = styled(TEXT.body1)<ActiveProps>`
  max-width: 510px;
  color: ${(props) => (props.active ? `${COLOR.PINK}` : `${COLOR.BLACK}`)};
  white-space: pre-wrap;
`;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const Green2BoldBody1Text = styled(BoldBody1Text)`
  color: ${COLOR.DARKGREEN2};
`;
