import { IconName } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import RButton from "../RButton";

interface Props {
  saveFavorite: () => void;
  isSaved: IconName;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function InviteButton(props: Props) {
  const { saveFavorite, isSaved, setActive, setSelected } = props;
  return (
    <Container>
      <RButton
        title="เพิ่มใน"
        icon={isSaved}
        iconLocation="righticon"
        textColor={COLOR.Blue_700}
        textStyle={{ marginRight: "8px" }}
        backgroundColor={COLOR.White}
        containerStyle={{
          width: 180,
          height: 48,
        }}
        hoverColor={COLOR.Blue_50}
        onPress={() => {
          saveFavorite();
        }}
      />
      <RButton
        title="ส่งคำเชิญ"
        icon="MailSend"
        iconLocation="righticon"
        textStyle={{ marginRight: "8px" }}
        containerStyle={{
          width: 180,
          height: 48,
        }}
        onPress={() => {
          setSelected([]);
          setActive(true);
        }}
      />
    </Container>
  );
}

export default InviteButton;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  width: 427px;
  height: 80px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;
