import styled from "styled-components";
import { TEXT } from "../../fonts/text";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SpaceBetweenRowContainer = styled(RowContainer)`
  justify-content: space-between;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexStartColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FlexEndColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PureContainer = styled.div``;

export const BlurredContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 36px;
  opacity: 0.6;
  pointer-events: none;
  background: var(--white-40, rgba(255, 255, 255, 0.4));
  filter: blur(6px);
  backdrop-filter: blur(8px);
`;

export const BoldH4Text = styled(TEXT.H4_Bold)``;
export const B4Text = styled(TEXT.B4_Reg)``;
