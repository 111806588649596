import React from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";

interface Props {
  iconName: IconName;
  text: string;
  right: boolean;
  top: boolean;
}

function RToolTip(props: Props) {
  const { iconName, text, right = false, top = false } = props;
  return (
    <Container>
      <Triangle right={right} top={top} />
      <RXIcon name={iconName} color={COLOR.ORANGE} />
      <OrangeBody2Text style={{ marginLeft: "8px" }}>{text}</OrangeBody2Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 48px;
  padding: 14px 12px;
  background: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Triangle = styled.div<{ right: boolean; top: boolean }>`
  position: absolute;
  top: ${(props) => (props.top ? "-22px" : "")};
  bottom: ${(props) => (props.top ? "" : "-12px")};
  right: ${(props) => (props.right ? "24px" : "")};
  left: ${(props) => (props.right ? "" : "24px")};
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-left-width: 6px;
  border-right-width: 6px;
  border-bottom-width: ${(props) => (props.top ? "12px" : "")};
  border-top-width: 12px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${(props) =>
    props.top ? `${COLOR.WHITE}` : "transparent"};
  border-top-color: ${(props) =>
    props.top ? "transparent" : `${COLOR.WHITE}`};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const OrangeBody2Text = styled(TEXT.body2)`
  color: ${COLOR.ORANGE};
`;

export default RToolTip;
