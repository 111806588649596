import { observer } from "mobx-react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { Deal } from "../../../types/deal";

interface Props {
  navigate: NavigateFunction;
  deal: Deal;
  drawerStatus: string;
}

function CampaignInfo(props: Props) {
  const { deal, navigate, drawerStatus } = props;
  const {
    pendingSize = 0,
    counterPendingSize = 0,
    ongoingSize = 0,
    checkSize = 0,
    doneSize = 0,
    notiCheck = 0,
    notiDone = 0,
    notiOngoing = 0,
    notiPending = 0,
  } = deal;

  return (
    <Container>
      <ContentContainer>
        <StatusContainer
          onClick={() => {
            localStorage.setItem("drawerStatus", drawerStatus);
            navigate(`/deal?dealId=${deal.dealId}&status=pending`);
          }}
        >
          <ValueText>
            {pendingSize + counterPendingSize}
            {notiPending ? <Notification /> : null}
          </ValueText>
          <DescriptionText>คำขอใหม่</DescriptionText>
        </StatusContainer>
        <StatusContainer
          onClick={() => {
            localStorage.setItem("drawerStatus", drawerStatus);
            navigate(`/deal?dealId=${deal.dealId}&status=ongoing`);
          }}
        >
          <ValueText>
            {ongoingSize}
            {notiOngoing ? <Notification /> : null}
          </ValueText>
          <DescriptionText>กำลังดำเนินการ</DescriptionText>
        </StatusContainer>
        <StatusContainer
          onClick={() => {
            localStorage.setItem("drawerStatus", drawerStatus);
            navigate(`/deal?dealId=${deal.dealId}&status=check`);
          }}
        >
          <ValueText>
            {checkSize}
            {notiCheck ? <Notification /> : null}
          </ValueText>
          <DescriptionText>ตรวจงาน</DescriptionText>
        </StatusContainer>
        <StatusContainer
          onClick={() => navigate(`/deal?dealId=${deal.dealId}&status=done`)}
        >
          <ValueText>
            {doneSize}
            {notiDone ? <Notification /> : null}
          </ValueText>
          <DescriptionText>เสร็จสิ้น</DescriptionText>
        </StatusContainer>
      </ContentContainer>
    </Container>
  );
}

export default observer(CampaignInfo);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 58px;
  padding: 9px 0px 9px 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 11px;

  :not(:first-child) {
    padding-left: 20px;
  }
`;

const ValueText = styled(TEXT.B2_Bold)`
  display: flex;
  flex-direction: row;
`;

const DescriptionText = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_M};
`;

const Notification = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${COLOR.PINK};
  border-radius: 50px;
`;
