import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { ANDCOLOR } from "../../../fonts/andColor";
import { useEffect, useState } from "react";
import { COLOR } from "../../../fonts/color";

export type TabStatus = "complete" | "ongoing" | "current";

type ColorType = {
  indexColor: string;
  textColor: string;
  borderColor: string;
  bgColor: string;
};

interface Props {
  index: number;
  header: string;
  detail: string;
  navigate: NavigateFunction;
  status: TabStatus;
  onClick: () => void;
}

interface TextProps {
  color: string;
}

interface ContainerProps {
  color: string;
  border: string;
}

function Tab(props: Props) {
  const { index, header, detail, status, onClick } = props;

  const [color, setColor] = useState<ColorType>({
    indexColor: COLOR.Gray_D,
    textColor: COLOR.Gray_D,
    borderColor: COLOR.Gray_D,
    bgColor: ANDCOLOR.WHITE,
  });

  useEffect(() => {
    let tempColor: ColorType = { ...color };
    switch (status) {
      case "complete":
        tempColor = {
          borderColor: ANDCOLOR.BLUE,
          bgColor: ANDCOLOR.BLUE,
          textColor: ANDCOLOR.BLUE,
          indexColor: ANDCOLOR.WHITE,
        };
        break;
      case "ongoing":
        tempColor = {
          indexColor: COLOR.Gray_D,
          textColor: COLOR.Gray_D,
          borderColor: COLOR.Gray_D,
          bgColor: ANDCOLOR.WHITE,
        };
        break;
      case "current":
        tempColor = {
          borderColor: ANDCOLOR.BLUE,
          bgColor: ANDCOLOR.LIGHTBLUE,
          textColor: ANDCOLOR.BLUE,
          indexColor: ANDCOLOR.BLUE,
        };
        break;
    }
    setColor(tempColor);
  }, [status]);

  return (
    <Container onClick={onClick}>
      <IndexContainer color={color.bgColor} border={color.borderColor}>
        <BoldBody1Text color={color.indexColor}>{index}</BoldBody1Text>
      </IndexContainer>
      <ColumnContainer>
        <BoldBody1Text color={color.textColor}>{header}</BoldBody1Text>
        <CaptionText color={color.textColor}>{detail}</CaptionText>
      </ColumnContainer>
    </Container>
  );
}

export default Tab;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 1280px;

  :hover {
    cursor: pointer;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const IndexContainer = styled.div<ContainerProps>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 70px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.color};
`;

const Body1Text = styled(TEXT.body1)<TextProps>`
  color: ${(props) => props.color};
`;

const BoldBody1Text = styled(TEXT.body1BOLD)<TextProps>`
  color: ${(props) => props.color};
`;

const CaptionText = styled(TEXT.caption1)<TextProps>`
  color: ${(props) => props.color};
`;
