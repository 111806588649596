import styled from "styled-components";
import RegisterCodeSentDetail from "../../molecules/RegisterCodeSent/CardDetail";

interface Props {
  onDone: () => void;
  email: string;
}

const RegisterCodeSentContainer = styled.div`
  width: 567px;
  height: 664px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  padding: 50px 88px;
`;

function RegisterCodeSent(props: Props) {
  return (
    <RegisterCodeSentContainer>
      <RegisterCodeSentDetail {...props} />
    </RegisterCodeSentContainer>
  );
}

export default RegisterCodeSent;
