import { useState } from 'react';
import {
  DealPerformancePost,
  DealPerformanceTableData,
} from '../../../types/deal';
import {
  PLACEHOLDER_ANOTHERDOT,
  postTypeName,
  toCapitalize,
  toDecimalNumber,
} from '../../../utils';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import {
  FlexStartColumnContainer,
  RowContainer,
} from '../../../utils/styling/general';
import PostTableValueRate from './PostTableValueRate';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import PostIcon from '../../../components/atoms/PostIcon';
import { TEXT } from '../../../fonts/text';
import { IconName, RXIcon } from 'rn-rx-icons';
import { tierType } from '../../../types/createDeal';
import { cellWidth } from './PostTable';

interface WidthProps {
  width: number;
}

interface WorkStatusProps {
  status: 'ongoing' | 'done';
}

const WorkStatusComponent = (props: WorkStatusProps) => {
  const { status } = props;
  if (status === 'done')
    return (
      <DoneContainer>
        <BoldGreenMB5Text>จบแคมเปญ</BoldGreenMB5Text>
      </DoneContainer>
    );
  else
    return (
      <OngoingContainer>
        <BoldLemonB5Text>กำลังดำเนินการ</BoldLemonB5Text>
      </OngoingContainer>
    );
};

interface TotalPostsProps {
  type: scopeOfWorkType;
  totalPosts: number;
}

const TotalPostsComponent = (props: TotalPostsProps) => {
  const { type, totalPosts } = props;
  const postTypeText = postTypeName[type as scopeOfWorkType];
  const totalPostsText: string =
    totalPosts > 1 ? `${totalPosts} โพสต์` : postTypeText;

  if (totalPosts > 1) return <GrayDB4Text>{totalPostsText}</GrayDB4Text>;
  return (
    <GapRowContainer>
      <IconContainer>
        <PostIcon type={type} active={false} size={16} />
      </IconContainer>
      <GrayDB4Text>{totalPostsText}</GrayDB4Text>
    </GapRowContainer>
  );
};

interface ProfileComponentProps {
  img: string;
  tier: tierType;
  username: string;
}

const ProfileComponent = (props: ProfileComponentProps) => {
  const { img, username, tier } = props;
  return (
    <GapRowContainer>
      <Image src={img || PLACEHOLDER_ANOTHERDOT} />
      <FlexStartColumnContainer>
        <B3Text>{username}</B3Text>
        <GrayMB4Text>{toCapitalize(tier)}</GrayMB4Text>
      </FlexStartColumnContainer>
    </GapRowContainer>
  );
};

interface PostViewComponentProps {
  isFromParent: boolean;
  viewPost?: boolean;
  setViewPost?: (value: boolean) => void;
  permalink?: string;
}

const PostViewComponent = (props: PostViewComponentProps) => {
  const { isFromParent, viewPost, setViewPost, permalink } = props;
  if (isFromParent && !!setViewPost) {
    const icon: IconName = viewPost ? 'ArrowUp' : 'ArrowDown';
    return (
      <ArrowIconContainer onClick={() => setViewPost(!viewPost)}>
        <RXIcon name={icon} color={COLOR.Gray_M} size={16} />
      </ArrowIconContainer>
    );
  }
  return (
    <PostViewContainer
      onClick={() => {
        if (permalink?.startsWith('https://')) window.open(permalink);
        else window.open('https://' + permalink);
      }}
    >
      <RXIcon name={'ExternalLink'} size={16} color={COLOR.Gray_D} />
      <GrayDB4Text>ดูโพสต์</GrayDB4Text>
    </PostViewContainer>
  );
};

interface PostListProps {
  posts: DealPerformancePost[];
  active: boolean;
}

const PostListComponent = (props: PostListProps) => {
  const { posts, active } = props;
  if (posts.length < 2 || !active) return <></>;
  const components: JSX.Element[] = [];
  posts.map((post) => {
    const { type, engagement, cpe, reach, cpr, impression, cpm, permalink } =
      post;
    components.push(
      <FullWidthRowContainer key={post.username + post.permalink}>
        <CellContainer width={cellWidth[0]}></CellContainer>
        <CellContainer width={cellWidth[1]}></CellContainer>
        <CellContainer width={cellWidth[2]}></CellContainer>
        <CellContainer width={cellWidth[3]}>
          <GapRowContainer>
            <PostIcon
              type={type}
              active={false}
              size={16}
              inactiveColor={COLOR.Gray_D}
            />
            <GrayDB4Text>{postTypeName[type as scopeOfWorkType]}</GrayDB4Text>
          </GapRowContainer>
        </CellContainer>
        <CellContainer width={cellWidth[4]}>
          <PostTableValueRate value={engagement} rate={cpe} />
        </CellContainer>
        <CellContainer width={cellWidth[5]}>
          <PostTableValueRate value={reach} rate={cpr} />
        </CellContainer>
        <CellContainer width={cellWidth[6]}>
          <PostTableValueRate value={impression} rate={cpm} />
        </CellContainer>
        <CellContainer width={cellWidth[7]}>
          <PostViewComponent isFromParent={false} permalink={permalink} />
        </CellContainer>
      </FullWidthRowContainer>
    );
  });
  return <PostListContainer>{components}</PostListContainer>;
};

interface TableRowContentProps {
  data: DealPerformanceTableData;
  isTikTok: boolean;
}

const PostTableRow = (props: TableRowContentProps) => {
  const { data, isTikTok } = props;
  const {
    status,
    budget,
    posts,
    username,
    profileImage,
    tier,
    totalCPE,
    totalCPM,
    totalCPR,
    totalEngagement,
    totalImpression,
    totalPosts,
    totalReach,
  } = data;

  const [viewPost, setViewPost] = useState<boolean>(false);

  return (
    <TableRowContainer>
      <FullWidthRowContainer>
        <CellContainer width={cellWidth[0]}>
          <ProfileComponent
            tier={tier}
            username={username}
            img={profileImage}
          />
        </CellContainer>
        <CellContainer width={cellWidth[1]}>
          <WorkStatusComponent status={status} />
        </CellContainer>
        <CellContainer width={cellWidth[2]}>
          <B3Text>{`${toDecimalNumber({ number: budget })} ฿`}</B3Text>
        </CellContainer>
        <CellContainer width={cellWidth[3]}>
          <TotalPostsComponent totalPosts={totalPosts} type={posts[0]?.type} />
        </CellContainer>
        <CellContainer width={cellWidth[4]}>
          <PostTableValueRate
            value={totalEngagement}
            rate={totalCPE.toFixed(1)}
          />
        </CellContainer>
        {!isTikTok && (
          <CellContainer width={cellWidth[5]}>
            <PostTableValueRate value={totalReach} rate={totalCPR.toFixed(1)} />
          </CellContainer>
        )}
        <CellContainer width={cellWidth[6]}>
          <PostTableValueRate
            value={totalImpression}
            rate={totalCPM.toFixed(1)}
          />
        </CellContainer>
        <CellContainer width={cellWidth[7]}>
          <PostViewComponent
            isFromParent={totalPosts > 1}
            viewPost={viewPost}
            setViewPost={setViewPost}
            permalink={posts[0].permalink}
          />
        </CellContainer>
      </FullWidthRowContainer>
      <PostListComponent posts={posts} active={viewPost} />
    </TableRowContainer>
  );
};

export default PostTableRow;

const FullWidthRowContainer = styled(RowContainer)`
  width: 100%;
`;

const GapRowContainer = styled(RowContainer)`
  align-items: center;
  gap: 4px;
`;

const TableRowContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 0.6px solid ${COLOR.Gray_400};
`;

const PostListContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 24px;
  margin-top: 24px;
`;

const ArrowIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid ${COLOR.Gray_M};
  :hover {
    cursor: pointer;
  }
`;

const PostViewContainer = styled(GapRowContainer)`
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const CellContainer = styled.div<WidthProps>`
  display: flex;
  width: ${(props) => props.width}%;
`;

const IconContainer = styled.div``;

const DoneContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: 50px;
  border: 0.6px solid ${COLOR.Herbal};
  background: ${COLOR.Green_L};
`;

const OngoingContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: 50px;
  border: 0.6px solid ${COLOR.Yellow_M};
  background: ${COLOR.Yellow_L};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50px;
`;

const B3Text = styled(TEXT.B3_Reg)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GrayDB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayMB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_M};
`;

const BoldGreenMB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Green_M};
`;

const BoldLemonB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Lemon};
`;
