import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

const registerLink = `/register`;

function LoginCardFooter() {
  return (
    <Container>
      <TextContainer>
        ยังไม่มีบัญชีใช่มั้ย?{" "}
        <LinkContainer to={registerLink}>
          <LinkText>สร้างบัญชีใหม่</LinkText>
        </LinkContainer>
      </TextContainer>
    </Container>
  );
}

export default LoginCardFooter;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled(TEXT.B2_Reg)`
  padding-top: 23px;
`;

const LinkContainer = styled(Link)`
  text-decoration: none;
`;

const LinkText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    color: ${COLOR.Blue_800};
  }
`;
