import React from "react";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";

interface Props {
  graphic?: JSX.Element;
  header?: string;
  descriptions?: string[];
  buttonText?: string;
  onClick?: () => void;
  buttonWidth?: number;
  containerStyle?: CSSProperties;
}

interface ButtonProps {
  width?: number;
}

const REmptyState = (props: Props) => {
  const {
    graphic,
    header,
    descriptions,
    buttonText,
    onClick,
    containerStyle,
    buttonWidth,
  } = props;
  return (
    <Container style={containerStyle}>
      {graphic}
      <BoldB1Text>{header}</BoldB1Text>
      {descriptions?.map((description) => {
        return <B2Text>{description}</B2Text>;
      })}
      {!!buttonText && (
        <ButtonContainer onClick={onClick} width={buttonWidth || 300}>
          <ButtonText>{buttonText}</ButtonText>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default REmptyState;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const BoldB1Text = styled(TEXT.B1_Bold)`
  margin-top: 16px;
`;

const B2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Gray_M};
`;

const ButtonContainer = styled.div<ButtonProps>`
  display: flex;
  width: ${(props) => props.width + "px"};
  height: 48px;
  margin-top: 21px;
  justify-content: center;
  align-items: center;
  background: ${COLOR.Blue_700};
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background: ${COLOR.Blue_900};
  }
`;

const ButtonText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.WHITE};
`;
