import styled, { CSSProperties } from "styled-components";

interface Props {
  zIndex?: number;
  gradient?: string;
  containerStyle?: CSSProperties;
}

function ImageOverlay(props: Props) {
  const {
    zIndex = 99,
    gradient = "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.5) 100%)",
    containerStyle,
  } = props;
  return (
    <ImageOverlayContainer
      zIndex={zIndex}
      gradient={gradient}
      style={containerStyle}
    />
  );
}

export default ImageOverlay;

const ImageOverlayContainer = styled.div<{ zIndex: number; gradient: string }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) => props.gradient};
  z-index: ${(props) => props.zIndex};
`;
