import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import { COLOR } from "../../../../fonts/color";
import { ActiveProps } from "../../../../types";
import { ITierType } from "../../../../types/createDeal";
import { limitText, numberWithCommas } from "../../../../utils";
import { RowContainer } from "../../../../utils/styling/general";
import ValueDetails from "../../../atoms/ValueDetails";

interface Props {
  name: string;
  photos: { url: string; file: File }[] | { url: string }[];
  category: string;
  value: number;
  tier: ITierType;
  checkDetail?: () => void;
  isDraft?: boolean;
  haveBack?: () => void;
  haveEdit?: boolean;
}

function DealCardHeader(props: Props) {
  const {
    name,
    photos,
    tier,
    category,
    value = "0",
    checkDetail,
    isDraft = false,
    haveBack,
    haveEdit = false,
  } = props;

  const displayValue = value ? `${value} ฿` : "-";
  const displayCash = numberWithCommas(
    tier.nano.cash + tier.micro.cash + tier.mid.cash + tier.macro.cash
  );
  const displayTier = () => {
    let text: string = "";
    if (tier.nano.value > 0) text += "Nano ";
    if (tier.micro.value > 0) text += "Micro ";
    if (tier.mid.value > 0) text += "Mid ";
    if (tier.macro.value > 0) text += "Macro";
    text = text.split(" ").join(" / ");
    text = text.slice(0, text.length - 3);
    return text;
  };

  const isActive = () => {
    if (haveEdit || !haveBack) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <PreviewImage src={photos[0]?.url} />
      {haveBack && (
        <BackContainer onClick={haveBack}>
          <RXIcon name="ArrowShortLeft" color={ANDCOLOR.WHITE} />
          <WhiteBoldBody1Text>กลับ</WhiteBoldBody1Text>
        </BackContainer>
      )}
      <PreviewTagContainer active={isDraft}>
        <WhiteBoldHeader6Text>ตัวอย่างแคมเปญที่จะเผยแพร่</WhiteBoldHeader6Text>
      </PreviewTagContainer>
      <DetailContainer>
        <WhiteBody2Text>{category}</WhiteBody2Text>
        <NameContainer>
          <WhiteBoldHeader4Text>{limitText(name, 60)}</WhiteBoldHeader4Text>
        </NameContainer>
        <RowContainer style={{ justifyContent: "space-between" }}>
          <ValueDetails
            icon="MoneyFill"
            value={displayValue}
            description="มูลค่าสินค้า"
          />
          <ValueDetails
            icon="BudgetFill"
            value={displayCash}
            description="เงินค่าตอบแทน"
          />
          <ValueDetails
            icon="Follower"
            value={displayTier()}
            description="ระดับ Influencer"
          />
          <WidthContainer>
            {!isDraft && (
              <DetailInfoContainer active={isActive()} onClick={checkDetail}>
                <DarkGrayBoldBody2Text>
                  {haveEdit ? "แก้ไขรายละเอียดแคมเปญ" : "รายละเอียดแคมเปญ"}
                </DarkGrayBoldBody2Text>
              </DetailInfoContainer>
            )}
          </WidthContainer>
        </RowContainer>
      </DetailContainer>
    </Container>
  );
}

export default DealCardHeader;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 688px;
  flex-direction: column;
  justify-content: space-between;
  height: 328px;
  padding: 24px 26px;
  border-radius: 12px;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.24) 24.03%,
    rgba(137, 137, 137, 0.16) 43.24%,
    rgba(255, 255, 255, 0.16) 52.18%
  );
`;

const PreviewImage = styled.img`
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  background: ${COLOR.Gray_200};
  border-radius: 12px;
  z-index: -1;
`;

const PreviewTagContainer = styled.div<ActiveProps>`
  display: flex;
  width: 634px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background: ${ANDCOLOR.BLUE};
  border-radius: 16px;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const BackContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 28px;
  left: 32px;
  z-index: 999;

  :hover {
    cursor: pointer;
  }
`;

const DetailContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  padding-bottom: 4px;
  margin-top: 6px;
  margin-bottom: 4px;
  max-height: 88px;
  /* white-space: nowrap; */
  word-wrap: break-word;
  color: ${ANDCOLOR.WHITE};
`;

const WidthContainer = styled.div`
  display: flex;
  width: 176px;
  justify-content: flex-end;
`;

const DetailInfoContainer = styled.div<ActiveProps>`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background: ${ANDCOLOR.WHITE};
  border-radius: 50px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? "auto" : "none")};

  transition: all 0.2s;
  :hover {
    background: ${COLOR.Gray_50};
  }
`;

const WhiteBoldBody1Text = styled(ANDTEXT.body1BOLD)`
  color: ${ANDCOLOR.WHITE};
`;

const WhiteBody2Text = styled(ANDTEXT.body2)`
  color: ${ANDCOLOR.WHITE};
`;

const DarkGrayBoldBody2Text = styled(ANDTEXT.body2BOLD)`
  color: ${COLOR.Gray_M};
`;

const WhiteBoldHeader4Text = styled(ANDTEXT.header4BOLD)`
  color: ${ANDCOLOR.WHITE};
  width: 634px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const WhiteBoldHeader6Text = styled(ANDTEXT.header6BOLD)`
  color: ${ANDCOLOR.WHITE};
`;
