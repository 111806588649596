import { useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { DealPerformance } from '../../../types/deal';
import {
  calculatePercentage,
  toCapitalize,
  toDecimalNumber,
} from '../../../utils';
import {
  FlexEndColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from '../../../utils/styling/general';
import REmptyState from '../REmptyState';

interface BackgroundProps {
  color: string;
}

type LegendType = 'nano' | 'micro' | 'mid' | 'macro';

const circleColors: Record<LegendType, string> = {
  nano: COLOR.Purple_700,
  micro: COLOR.Blue_500,
  mid: COLOR.Purple_L,
  macro: COLOR.Blue_900,
};

interface DetailProps {
  icon: IconName;
  detail: string;
  value: number;
  percentage: string;
}

const DetailComponent = (props: DetailProps) => {
  const { icon, detail, value, percentage } = props;
  return (
    <FullWidthRowContainer>
      <GapRowContainer>
        <RXIcon name={icon} size={16} />
        <GrayDH9Text>{detail}</GrayDH9Text>
      </GapRowContainer>
      <FlexEndColumnContainer>
        <BoldH7Text>
          {toDecimalNumber({ number: value, isZeroDash: false })}
        </BoldH7Text>
        <GrayDH9Text>{`(${percentage}%)`}</GrayDH9Text>
      </FlexEndColumnContainer>
    </FullWidthRowContainer>
  );
};

interface LegendClickProps {
  chartRef: React.MutableRefObject<ChartJSOrUndefined<'bar', number[], string>>;
  type: LegendType;
  legendActive: Record<
    LegendType,
    {
      index: number;
      active: boolean;
    }
  >;
  setLegendActive: React.Dispatch<
    React.SetStateAction<
      Record<
        LegendType,
        {
          index: number;
          active: boolean;
        }
      >
    >
  >;
}

const onLegendClick = (props: LegendClickProps) => {
  const { chartRef, setLegendActive, type, legendActive } = props;
  if (!!chartRef.current) {
    let tempLegendActive = { ...legendActive };
    const { active, index } = tempLegendActive[type];
    tempLegendActive[type].active = !active;
    setLegendActive(tempLegendActive);
    chartRef.current.getDatasetMeta(index).hidden = active;
    chartRef.current.update();
  }
};

const LegendComponent = (props: LegendClickProps) => {
  const { type, legendActive } = props;
  const colorActive = legendActive[type].active;
  return (
    <ChartSelectorContainer onClick={() => onLegendClick({ ...props })}>
      <CheckCircle color={colorActive ? COLOR.Blue_700 : COLOR.White}>
        <RXIcon
          name="Check"
          color={colorActive ? COLOR.White : COLOR.Gray_400}
          size={16}
        />
      </CheckCircle>
      <GrayB4Text>{toCapitalize(type)}</GrayB4Text>
      <Circle color={circleColors[type]} />
    </ChartSelectorContainer>
  );
};

interface Props {
  nanoBarData: number[];
  microBarData: number[];
  midBarData: number[];
  macroBarData: number[];
  dealPerformance: DealPerformance;
  labels: string[];
  dealPostTypes: scopeOfWorkType[];
  isInstagram: boolean;
  haveStory: boolean;
  suggestedMax: number;
}

const InsightBarChart = (props: Props) => {
  const {
    nanoBarData,
    microBarData,
    midBarData,
    macroBarData,
    dealPerformance,
    labels,
    isInstagram,
    haveStory,
    suggestedMax,
  } = props;

  const chartRef = useRef<
    ChartJSOrUndefined<'bar', number[], string> | undefined
  >();

  const [legendActive, setLegendActive] = useState<
    Record<LegendType, { index: number; active: boolean }>
  >({
    nano: { index: 0, active: true },
    micro: { index: 1, active: true },
    mid: { index: 2, active: true },
    macro: { index: 3, active: true },
  });

  const { engagement, like, comment, saved, share, replies } = dealPerformance;
  const isEmpty =
    nanoBarData.every((value) => value === 0) &&
    microBarData.every((value) => value === 0) &&
    midBarData.every((value) => value === 0) &&
    macroBarData.every((value) => value === 0);

  if (isEmpty) {
    const emptyStyle = {
      width: '100%',
      alignSelf: 'center',
      marginTop: 24,
      marginBottom: 24,
    };
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={emptyStyle}
      />
    );
  }

  const likePercentage = calculatePercentage(like, engagement);
  const commentPercentage = calculatePercentage(comment, engagement);
  const sharePercentage = calculatePercentage(share, engagement);
  const repliesPercentage = calculatePercentage(replies, engagement);
  const savedPercentage = calculatePercentage(saved, engagement);

  return (
    <Container>
      <FullWidthRowContainer>
        <HeaderContainer>
          <BoldH7Text>ภาพรวมทั้งหมด</BoldH7Text>
          <VerticalLine />
          <LegendComponent
            type="nano"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
          />
          <LegendComponent
            type="micro"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
          />
          <LegendComponent
            type="mid"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
          />
          <LegendComponent
            type="macro"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
          />
        </HeaderContainer>
      </FullWidthRowContainer>
      <ChartAndDetailContainer>
        <Bar
          ref={chartRef}
          data={{
            labels,
            datasets: [
              {
                label: 'Nano',
                data: nanoBarData,
                borderWidth: 2,
                borderRadius: 4,
                pointStyle: false,
                borderColor: circleColors.nano,
                backgroundColor: circleColors.nano,
                minBarLength: 2,
              },
              {
                label: 'Micro',
                data: microBarData,
                borderColor: circleColors.micro,
                backgroundColor: circleColors.micro,
                borderWidth: 1,
                borderRadius: 4,
                pointStyle: false,
                minBarLength: 2,
              },
              {
                label: 'Mid',
                data: midBarData,
                borderColor: circleColors.mid,
                backgroundColor: circleColors.mid,
                borderWidth: 1,
                borderRadius: 4,
                pointStyle: false,
                minBarLength: 2,
              },
              {
                label: 'Macro',
                data: macroBarData,
                borderColor: circleColors.macro,
                backgroundColor: circleColors.macro,
                borderWidth: 1,
                borderRadius: 4,
                pointStyle: false,
                minBarLength: 2,
              },
            ],
          }}
          style={{ height: 338, width: '66%', maxHeight: 338, maxWidth: '66%' }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                border: {
                  display: true,
                },
                grid: {
                  color: COLOR.Gray_50,
                  tickBorderDash: [20, 50],
                },
              },
              y: {
                suggestedMax,
                border: {
                  display: true,
                  dash: [20, 1],
                },
                grid: {
                  color: COLOR.Gray_50,
                  tickBorderDash: [20, 50],
                },
                ticks: {
                  maxTicksLimit: 6,
                  stepSize: 1,
                  callback: function (value, index, ticks) {
                    return toDecimalNumber({
                      number: value,
                      toFixed: 0,
                      round: false,
                      isZeroDash: false,
                    });
                  },
                },
              },
            },
          }}
        />
        <DetailContainer>
          <FullWidthRowContainer>
            <BoldH8Text>Total Engagement</BoldH8Text>
            <BoldH6Text>
              {toDecimalNumber({ number: engagement, isZeroDash: false })}
            </BoldH6Text>
          </FullWidthRowContainer>
          <DetailComponent
            icon="Heart"
            detail="Like"
            value={like}
            percentage={likePercentage}
          />
          <DetailComponent
            icon="Chat"
            detail="Comment"
            value={comment}
            percentage={commentPercentage}
          />
          <DetailComponent
            icon="Share"
            detail="Share"
            value={share}
            percentage={sharePercentage}
          />
          {haveStory && (
            <DetailComponent
              icon="PaperSend"
              detail="Reply"
              value={replies}
              percentage={repliesPercentage}
            />
          )}
          {isInstagram && (
            <DetailComponent
              icon="Save"
              detail="Save"
              value={saved}
              percentage={savedPercentage}
            />
          )}
        </DetailContainer>
      </ChartAndDetailContainer>
    </Container>
  );
};

export default InsightBarChart;

const Container = styled(FlexStartColumnContainer)`
  gap: 36px;
  width: 100%;
`;

const HeaderContainer = styled(RowContainer)`
  gap: 8px;
`;

const ChartSelectorContainer = styled(RowContainer)`
  background-color: ${COLOR.Gray_50};
  padding: 8px 12px;
  border-radius: 40px;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const ChartAndDetailContainer = styled(RowContainer)`
  gap: 24px;
  width: 100%;
`;

const DetailContainer = styled(FlexStartColumnContainer)`
  gap: 12px;
  width: 32%;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  border-right: 1px solid ${COLOR.Gray_300};
  margin: 0px 8px 0px 8px;
`;

const Circle = styled.div<BackgroundProps>`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
`;

const CheckCircle = styled.div<BackgroundProps>`
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: ${(props) => props.color};
`;

const GapRowContainer = styled(RowContainer)`
  gap: 10px;
`;
const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 100%;
`;
const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const BoldH8Text = styled(TEXT.H8_Bold)``;

const GrayDH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;
