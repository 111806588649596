import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import {
  useAccountStore,
  useAuthStore,
  useDealStore,
  useInfluencerStore,
  useNotificationStore,
} from "../../../stores/initialize/Context";
import { PLACEHOLDER_PROFILE, toCapitalize, useQuery } from "../../../utils";
import useComponentVisible from "../../../utils/hook/UseComponentVisible";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";
import { RowContainer } from "../../../utils/styling/general";
import HeaderDropdown from "./HeaderDropdown";
import NotificationDropdown from "./NotificationDropdown";
import React from "react";
import { featureAccessStore } from "../../../stores/featureAccessStore";
import FreeTier from "../../../assets/images/FreeTier.svg";

interface WidthProps {
  width: number;
}

const AnotherDotHeader = () => {
  const { account, accounts, accountLoading, removeAccounts } =
    useAccountStore();
  const {
    getNotifications,
    notificationsLoading,
    notifications,
    _readNotification,
    readUnreadNotification,
  } = useNotificationStore();
  const { logout, logoutAccounts, switchAccount, authLoading, setAuthLoading } =
    useAuthStore();
  const { influencer } = useInfluencerStore();
  const { deal } = useDealStore();
  const { width } = useWindowDimensions();

  const {
    ref: notificationRef,
    isComponentVisible: isNotiVisible,
    setIsComponentVisible: setIsNotiVisible,
  } = useComponentVisible(false);
  const {
    ref: accountRef,
    isComponentVisible: isAccountVisible,
    setIsComponentVisible: setIsAccountVisible,
  } = useComponentVisible(false);

  const navigate = useNavigate();
  const path = window.location.pathname;
  const query = useQuery();
  const influencerId = query.get("influencerId");
  const dealId = query.get("dealId");
  const dealContactId = query.get("dealContactId");
  const status = query.get("status");

  const StatusComponent = () => {
    const { tier } = featureAccessStore;
    if (!account.isVerified) {
      return (
        <ClockContainer>
          <RXIcon name="ClockFill" color={COLOR.ORANGE} size={12} />
        </ClockContainer>
      );
    }
    if (tier === "free") {
      return (
        <FreetierContainer>
          <FreeTier width={30} height={16} />
        </FreetierContainer>
      );
    } else {
      return (
        <CheckContainer active={account.isVerified}>
          <RXIcon name="Check" color={COLOR.White} size={12} />
        </CheckContainer>
      );
    }
  };

  const BrandLogoComponent = () => {
    if (authLoading || accountLoading) {
      return (
        <ImgContainer>
          <ImgPlaceHolder />
        </ImgContainer>
      );
    }
    return (
      <ImgContainer
        id="brand-profile-image"
        onClick={() => {
          setIsAccountVisible(!isAccountVisible);
        }}
      >
        <Img
          src={account.img || PLACEHOLDER_PROFILE}
          alt={PLACEHOLDER_PROFILE}
        />
        {StatusComponent()}
      </ImgContainer>
    );
  };

  const BreadCrumbComponent = () => {
    const components: JSX.Element[] = [];

    components.push(
      <GrayBu2Text key={"home/"} onClick={() => navigate("/")}>
        Home
      </GrayBu2Text>
    );
    const pathName = path.split("/");
    pathName.forEach((element, index) => {
      if (element === "") return;
      let name = toCapitalize(element);
      if (element === "directory") name = "Search & Select";
      if (index !== pathName.length - 1)
        components.push(
          <React.Fragment key={element + "/"}>
            <GraySlashText>/</GraySlashText>
            <GrayBu2Text
              key={element}
              onClick={() => {
                navigate(`/${element}`);
              }}
            >
              {name}
            </GrayBu2Text>
          </React.Fragment>
        );
      else if (!!influencer && !!influencerId) {
        const name = influencer?.account?.name || "";
        components.push(
          <React.Fragment key={"influencerd"}>
            <GraySlashText>/</GraySlashText>
            <GrayBu2Text
              key={"influencerd"}
              onClick={() => {
                navigate(`/directory`);
              }}
            >
              Search & Select
            </GrayBu2Text>
            <GraySlashText key={"influencerd/"}>/</GraySlashText>
            <Bu2Text key={name}>{name}</Bu2Text>
          </React.Fragment>
        );
      } else if (!!dealId) {
        if (!!dealContactId) {
          components.push(
            <React.Fragment key={"/deal"}>
              <GraySlashText>/</GraySlashText>
              <GrayBu2Text
                key={"influencerd"}
                onClick={() => {
                  navigate(
                    `/deal?dealId=${dealId}&status=${status || "pending"}`
                  );
                }}
              >
                {deal.name || "Campaign Name"}
              </GrayBu2Text>
              <GraySlashText key={"dealcontact/"}>/</GraySlashText>
              <Bu2Text key={influencer?.account.name + "dealcontact"}>
                {influencer?.account.name || "Influencer Username"}
              </Bu2Text>
            </React.Fragment>
          );
        } else {
          components.push(
            <React.Fragment key={"deal/"}>
              <GraySlashText>/</GraySlashText>
              <Bu2Text key={deal?.name}>
                {deal?.name || "Campaign Name"}
              </Bu2Text>
            </React.Fragment>
          );
        }
      } else {
        components.push(
          <React.Fragment key={element + "/"}>
            <GraySlashText>/</GraySlashText>
            <Bu2Text key={element}>{name}</Bu2Text>
          </React.Fragment>
        );
      }
    });

    return <BreadCrumbContainer>{components}</BreadCrumbContainer>;
  };

  const SettingComponent = () => {
    return (
      <SettingContainer>
        {/* <IconContainer>
          <RXIcon name="Chat" size={32} />
        </IconContainer> */}
        <IconContainer
          onClick={() => {
            setIsNotiVisible(!isNotiVisible);
          }}
        >
          <RXIcon name="Bell" size={32} />
        </IconContainer>
        <IconContainer
          id="favorite-influencer-link"
          onClick={() => navigate("/favorite")}
        >
          <RXIcon name="Save" size={32} />
        </IconContainer>
        {BrandLogoComponent()}
        <ReferenceContainer ref={accountRef}>
          <HeaderDropdown
            account={account}
            accounts={accounts}
            switchAccount={switchAccount}
            active={isAccountVisible}
            deactivate={() => {
              setIsAccountVisible(false);
            }}
            logout={logout}
            logoutAccounts={logoutAccounts}
            navigate={navigate}
            setAuthLoading={(status: boolean) => setAuthLoading(status)}
            offsetRight={-130}
            removeAccounts={removeAccounts}
          />
        </ReferenceContainer>
        <ReferenceContainer ref={notificationRef}>
          <NotificationDropdown
            active={isNotiVisible}
            notifications={notifications}
            onClose={() => {
              setIsNotiVisible(false);
            }}
            onBlur={() => {
              setIsNotiVisible(false);
            }}
            navigate={navigate}
            readUnreadNotification={readUnreadNotification}
            _readNotification={_readNotification}
            notificationsLoading={notificationsLoading}
            getNotifications={getNotifications}
            offsetRight={-30}
          />
        </ReferenceContainer>
      </SettingContainer>
    );
  };

  return (
    <>
      <PlaceholderContainer />
      <HeaderContainer width={width}>
        <BreadCrumbComponent />
        <SettingComponent />
      </HeaderContainer>
    </>
  );
};

export default observer(AnotherDotHeader);

const HeaderContainer = styled.div<WidthProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 28px 36px 24px 36px;
  width: ${(props) => props.width - 240}px;
  height: 88px;
  z-index: 99999;
  border-bottom: 0.6px solid ${COLOR.Gray_200};
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
`;

const BreadCrumbContainer = styled(RowContainer)`
  gap: 16px;
`;

const SettingContainer = styled(RowContainer)`
  gap: 4px;
  position: relative;
`;

const ReferenceContainer = styled.div`
  position: absolute;
`;

const ImgContainer = styled.div`
  position: relative;
`;

const ImgPlaceHolder = styled.img`
  width: 36px;
  height: 36px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;

const CheckContainer = styled.div<{ active: boolean }>`
  position: absolute;
  top: 24px;
  left: 23px;
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border: 2.5px solid white;
  border-radius: 50%;
  background: ${(props) => (props.active ? COLOR.Herbal : COLOR.ORANGE)};
  overflow: hidden;
  z-index: 99;
`;

const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
  position: absolute;
  top: 24px;
  left: 23px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
`;

const FreetierContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99;
`;

const IconContainer = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const PlaceholderContainer = styled.div`
  height: 104px;
  flex-shrink: 0;
`;

const Img = styled.img`
  width: 36px;
  height: 36px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;

const GrayBu2Text = styled(TEXT.Bu2_Reg)`
  color: ${COLOR.Gray_M};
  :hover {
    cursor: pointer;
  }
`;

const GraySlashText = styled(TEXT.Bu2_Reg)`
  color: ${COLOR.Gray_300};
`;

const Bu2Text = styled(TEXT.Bu2_Reg)``;
