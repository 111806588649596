import { StatusType } from "../types/deal";

export const determinePage = (status: StatusType) => {
  switch (status) {
    case "pending":
      return "profile";
    case "ongoing":
      return "chat";
    case "check":
    case "done":
      return "work";
  }
};
