import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ActiveProps } from "../../../types";
import { difTime, toDateDue } from "../../../utils";
import { RowContainer } from "../../../utils/styling/general";
import RButton from "../../atoms/RButton";
import RInput from "../../atoms/RInput";
import RModal from "../../atoms/RModal";
import RTextarea from "../../atoms/RTextarea";
import {
  useAccountStore,
  useInfluencerStore,
} from "../../../stores/initialize/Context";

interface Props {
  finishWorkModal: boolean;
  setFinishWorkModal: () => void;
  accountId: string;
  username?: string;
  img: string;
  submissionDate: Date;
  review: (star: string, comment: string, reviewTag: number[]) => void;
}

function FinishWorkModal(props: Props) {
  const {
    finishWorkModal,
    setFinishWorkModal,
    accountId,
    username,
    img,
    submissionDate,
    review,
  } = props;

  const { brandSaved, removeSavedInflu, addSavedInflu } = useAccountStore();
  const { triggerSaveInfluencer } = useInfluencerStore();

  const isSaved = brandSaved.includes(accountId);

  const [star, setStar] = useState<number>(0);
  const [reviewTag, setReviewTag] = useState<number[]>([0, 0, 0, 0, 0]);
  const [reviewComment, setReviewComment] = useState<string>("");

  // Same function used twice like the SaveIconComponent find way to export
  const saveInfluencer = () => {
    if (isSaved) {
      triggerSaveInfluencer({
        accountId,
        isSaved: true,
      });
    } else {
      triggerSaveInfluencer({
        accountId,
        isSaved: false,
      });
    }
  };

  const SaveIconComponent = () => {
    if (isSaved) return <RXIcon name={"SaveFill"} color={COLOR.Blue_700} />;
    return <RXIcon name={"Save"} color={COLOR.Blue_700} />;
  };

  const renderTag = (text: string, active: boolean) => {
    if (active) {
      return <BlueBoldBu2Text>{text}</BlueBoldBu2Text>;
    }
    return <Bu2Text>{text}</Bu2Text>;
  };

  const selectTag = (index: number) => {
    let newTags = reviewTag;
    newTags[index] = newTags[index] ^ 1;
    setReviewTag([...newTags]);
  };

  return (
    <RModal active={finishWorkModal} setActive={setFinishWorkModal}>
      <Container>
        <BoldH5Text>ยืนยันการจบงาน</BoldH5Text>
        <HeaderContainer>
          <DetailContainer>
            <ImageContainer src={img} />
            <NameContainer>
              <BoldH6Text>{username}</BoldH6Text>
              <RowContainer>
                <RXIcon name="File" size={20} />
                <B4Text style={{ marginLeft: "4px" }}>
                  ส่งงานได้ถึงวันที่ {toDateDue(submissionDate)}
                </B4Text>
              </RowContainer>
            </NameContainer>
          </DetailContainer>
          <SaveButton onClick={saveInfluencer}>
            <SaveIconComponent />
          </SaveButton>
        </HeaderContainer>
        <BoldH7Text>
          คุณจะแนะนำ Influencer คนนี้ให้กับเพื่อน หรือ คนที่รู้จักหรือไม่?
        </BoldH7Text>
        <ReviewContainer>
          <LabelContainer>
            <GrayMB4Text>ไม่แนะนำ</GrayMB4Text>
            <GrayMB4Text>แนะนำแน่นอน</GrayMB4Text>
          </LabelContainer>
          <StarsContainer>
            {new Array(5).fill("0").map((a, index) => {
              return (
                <StarContainer onClick={() => setStar(index + 1)}>
                  <RXIcon
                    key={`star${index}`}
                    name={index >= star ? "Star" : "StarFilled"}
                    color={COLOR.YELLOW}
                    size={36}
                  />
                </StarContainer>
              );
            })}
          </StarsContainer>
        </ReviewContainer>
        <TagsContainer>
          <TagContainer onClick={() => selectTag(0)} active={!!reviewTag[0]}>
            {renderTag("ทำตามบรีฟ", !!reviewTag[0])}
          </TagContainer>
          <TagContainer onClick={() => selectTag(1)} active={!!reviewTag[1]}>
            {renderTag("ถ่ายรูปสวย", !!reviewTag[1])}
          </TagContainer>
          <TagContainer onClick={() => selectTag(2)} active={!!reviewTag[2]}>
            {renderTag("ตอบแชทไว", !!reviewTag[2])}
          </TagContainer>
          <TagContainer onClick={() => selectTag(3)} active={!!reviewTag[3]}>
            {renderTag("ส่งงานตรงเวลา", !!reviewTag[3])}
          </TagContainer>
          <TagContainer onClick={() => selectTag(4)} active={!!reviewTag[4]}>
            {renderTag("ความคิดสร้างสรรค์", !!reviewTag[4])}
          </TagContainer>
        </TagsContainer>
        <RTextarea
          label="ความคิดเห็นเพิ่มเติม (ไม่บังคับ)"
          inputProp={{
            value: reviewComment,
            onChange: (e) => setReviewComment(e.target.value),
            placeholder: "ระบุความคิดเห็นเพิ่มเติม",
            style: { minHeight: "144px" },
          }}
          containerStyle={{
            marginTop: "24px",
          }}
        />
        <GrayMB3Text>
          ความเห็นของคุณจะช่วยให้เราพัฒนาแอปพลิเคชั่นให้ดียิ่งขึ้น
          <GrayMBoldB3Text>
            อินฟลูเอนเซอร์จะไม่เห็นความคิดเห็นนี้
          </GrayMBoldB3Text>
        </GrayMB3Text>
        <ButtonsContainer>
          <RButton
            title="ยกเลิก"
            onPress={setFinishWorkModal}
            containerStyle={{ width: "195px", marginRight: "40px" }}
            backgroundColor={COLOR.WHITE}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
          />
          <RButton
            title="ยืนยันการจบงาน"
            onPress={() => {
              review(star.toString(), reviewComment, reviewTag);
            }}
            active={star !== 0}
            containerStyle={{ width: "195px" }}
          />
        </ButtonsContainer>
      </Container>
    </RModal>
  );
}

export default observer(FinishWorkModal);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 746px;
  padding: 36px 32px;
  background: ${COLOR.WHITE};
  border-radius: 16px;
  text-align: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 24px 0px;
  padding-bottom: 30px;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const DetailContainer = styled.div`
  display: flex;
`;

const ImageContainer = styled.img`
  width: 50px;
  height: 50px;
  background: ${COLOR.Gray_200};
  border: 3px solid ${COLOR.Blue_700};
  border-radius: 50px;

  object-fit: cover;
`;

const NameContainer = styled.div`
  margin-left: 16px;
  text-align: start;
`;

const SaveButton = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 0px 10px 0px 8px;
  border-radius: 20px;
`;

const LabelContainer = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-left: 14px;
`;

const StarsContainer = styled.div`
  display: flex;
  width: 276px;
  justify-content: space-between;
  margin-top: 8px;
`;

const StarContainer = styled.div``;

const TagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const TagContainer = styled.div<ActiveProps>`
  display: flex;
  height: 60px;
  padding: 0px 16px;
  align-items: center;
  box-shadow: 0px 4px 10px 0px #0000000d;
  border-radius: 16px;
  border: 2px solid ${(props) => (props.active ? COLOR.Blue_700 : COLOR.White)};
  background: ${(props) => (props.active ? COLOR.Blue_50 : COLOR.White)};

  :hover {
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const B4Text = styled(TEXT.B4_Reg)``;

const GrayMBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Gray_M};
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;

const GrayMB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_M};
`;

const Bu2Text = styled(TEXT.Bu2_Reg)``;

const BlueBoldBu2Text = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
`;
