import { useCallback } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import { COLOR } from "../../../../fonts/color";
import { genderType } from "../../../../types/createDeal";
import { RowContainer } from "../../../../utils/styling/general";
import { IGenderOptions } from "../../../atoms/RGenderSelector";

interface Props {
  selectedGender: IGenderOptions[];
  setSelectedGender: (gender: IGenderOptions[]) => void;
  selectedAudience: IGenderOptions[];
  setSelectedAudience: (gender: IGenderOptions[]) => void;
}

interface ActiveProps {
  isActive: boolean;
}

const genderDetail = {
  M: {
    text: "เพศชาย",
    iconName: "Man",
  },
  W: {
    text: "เพศหญิง",
    iconName: "Woman",
  },
  L: {
    text: "LGBTQIAN+",
  },
};

function InfluencerGender(props: Props) {
  const {
    selectedGender,
    setSelectedGender,
    selectedAudience,
    setSelectedAudience,
  } = props;

  const IconComponent = ({
    gender,
    isActive,
  }: {
    gender: genderType;
    isActive: boolean;
  }) => {
    if (gender === "L")
      return (
        <Image
          src={require("../../../../assets/images/CreateCampaign/lgbtq.png")}
        />
      );
    return (
      <RXIcon
        name={genderDetail[gender].iconName as IconName}
        color={isActive ? ANDCOLOR.BLUE : ANDCOLOR.BLACK}
      />
    );
  };

  const onClickHandler = (
    gender: genderType,
    mode: "influencer" | "audience"
  ) => {
    if (mode === "influencer") {
      const tempGender = [...selectedGender];
      const index = tempGender.findIndex((value) => value.value === gender);
      tempGender[index].isSelected = !tempGender[index].isSelected;
      setSelectedGender(tempGender);
    } else {
      const tempGender = [...selectedAudience];
      const index = tempGender.findIndex((value) => value.value === gender);
      tempGender[index].isSelected = !tempGender[index].isSelected;
      setSelectedAudience(tempGender);
    }
  };

  const GenderCheckBox = useCallback(
    ({
      gender,
      mode,
    }: {
      gender: genderType;
      mode: "influencer" | "audience";
    }) => {
      const genderObject =
        mode === "influencer"
          ? selectedGender.find((value) => value.value === gender)
          : selectedAudience.find((value) => value.value === gender);
      if (!!genderObject)
        return (
          <GenderContainer
            isActive={genderObject.isSelected}
            onClick={() => onClickHandler(gender, mode)}
          >
            <GenderContentContainer>
              <IconComponent
                gender={gender}
                isActive={genderObject.isSelected}
              />
              <ButtonSmallText isActive={genderObject.isSelected}>
                {genderDetail[gender].text}
              </ButtonSmallText>
            </GenderContentContainer>
          </GenderContainer>
        );
      return <></>;
    },
    [selectedGender, selectedAudience]
  );

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>เพศ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <RXIcon name="PlaceholderFill" color={ANDCOLOR.BLUE} size={20} />
          <Body3Text>
            เลือกเพศของ <UnderlineBody3Text>Influencer </UnderlineBody3Text>
          </Body3Text>
        </GapRowContainer>
        <GapRowContainer>
          <GenderCheckBox gender="M" mode="influencer" />
          <GenderCheckBox gender="W" mode="influencer" />
          <GenderCheckBox gender="L" mode="influencer" />
        </GapRowContainer>
        <Body4Text style={{ marginBottom: 36 }}>
          Tip : เลือกได้มากกว่าหนึ่งตัวเลือก
        </Body4Text>
        <GapRowContainer>
          <Body3Text>
            เลือกเพศตาม{" "}
            <UnderlineBody3Text> กลุ่มลูกค้าเป้าหมาย</UnderlineBody3Text>
          </Body3Text>
        </GapRowContainer>
        <GapRowContainer>
          <GenderCheckBox gender="M" mode="audience" />
          <GenderCheckBox gender="W" mode="audience" />
          <GenderCheckBox gender="L" mode="audience" />
        </GapRowContainer>
        <Body4Text>Tip : เลือกได้มากกว่าหนึ่งตัวเลือก</Body4Text>
      </ContentContainer>
    </Container>
  );
}

export default InfluencerGender;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const GenderContainer = styled.div<ActiveProps>`
  display: flex;
  width: 136px;
  height: 86px;
  padding: 16px 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 0.6px solid
    ${(props) => (props.isActive ? ANDCOLOR.BLUE : COLOR.Gray_D)};
  background-color: ${(props) =>
    props.isActive ? ANDCOLOR.LIGHTBLUE : ANDCOLOR.WHITE};
`;

const GenderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;
const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(ANDTEXT.body3BOLD)`
  text-decoration: underline;
`;

const ButtonSmallText = styled(ANDTEXT.buttonSmall)<ActiveProps>`
  color: ${(props) => (props.isActive ? ANDCOLOR.BLUE : ANDCOLOR.BLACK)};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
`;

const Body4Text = styled(ANDTEXT.body4)`
  margin-top: 12px;
  color: ${COLOR.Gray_D};
`;
