import styled from "styled-components";
import {
  ColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import { TEXT } from "../../../fonts/text";
import { COLOR } from "../../../fonts/color";
import InsightChart from "../../atoms/Influencer/InsightChart";
import InsightStatistics from "../../atoms/Influencer/InsightStatistics";
import AgeGender from "../../atoms/Influencer/AgeGender";
import { PlatformType } from "../../../types/deal";
import ProvinceDemographic from "../../atoms/Influencer/ProvinceDemographic";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { PieValueType } from "@mui/x-charts/models/seriesType";
import {
  IInstagramInfluencer,
  ITiktokInfluencer,
  IFacebookInfluencer,
  SocialData,
} from "../../../types/api/getInfluencer";
import { observer } from "mobx-react";
import { useInfluencerStore } from "../../../stores/initialize/Context";
import { IAgeGenderDataObject } from "../../../types/api/listInfluencers";

interface AudienceProps {
  platformActive: PlatformType;
  ageGenderData:
    | {
        genders: [string, number][];
        ages: [string, number][];
        ageGender: IAgeGenderDataObject[];
      }
    | {
        genders: never[];
        ages: never[];
        ageGender?: undefined;
      };
}

const AudienceComponent = (props: AudienceProps) => {
  const { platformActive, ageGenderData } = props;

  const { influencer } = useInfluencerStore();

  if (!influencer || platformActive !== "INSTAGRAM") return <></>;

  const platform = platformActive.toLowerCase() as "instagram" | "facebook";

  if (!influencer[platform]?.ages && !influencer[platform]?.genders)
    return <></>;

  return (
    <AudienceInsightContainer>
      <BoldH6Text>Audience Insight</BoldH6Text>
      <FullWidthRowContainer>
        <AgeGender
          platformActive={platformActive}
          ageGenderData={ageGenderData}
        />
        <ProvinceDemographic platformActive={platformActive} />
      </FullWidthRowContainer>
    </AudienceInsightContainer>
  );
};

interface Props {
  platformActive: PlatformType;
  setPlatformActive: React.Dispatch<React.SetStateAction<PlatformType>>;
  instagram: IInstagramInfluencer;
  tiktok: ITiktokInfluencer;
  facebook: IFacebookInfluencer;
  dateFormat: "weekly" | "monthly";
  setDateFormat: React.Dispatch<React.SetStateAction<"weekly" | "monthly">>;
  statistic: {
    pieData: MakeOptional<PieValueType, "id">[];
    likeData: number;
    saveData: number;
    commentData: number;
    shareData: number;
    reachData: number;
    impressionsData: number;
    engagementData: number;
    viewData: number;
    postCount: number;
  };
  socialData: SocialData;
  loading: {
    influencers: {
      pending: boolean;
      ongoing: boolean;
      check: boolean;
      done: boolean;
    };
    influencer: boolean;
    reviews: boolean;
    social: boolean;
    list: boolean;
    attribute: boolean;
    posts: boolean;
    graph: boolean;
    similarList: boolean;
  };
  ageGenderData:
    | {
        genders: [string, number][];
        ages: [string, number][];
        ageGender: IAgeGenderDataObject[];
      }
    | {
        genders: never[];
        ages: never[];
        ageGender?: undefined;
      };
}

const InfluencerInsight = (props: Props) => {
  const {
    platformActive,
    instagram,
    tiktok,
    facebook,
    dateFormat,
    setDateFormat,
    statistic,
    socialData,
    loading,
    ageGenderData,
  } = props;
  // should never happen
  if (platformActive === "LEMON") return <></>;

  const dateAgoCount = dateFormat === "weekly" ? 7 : 30;

  const pieActive =
    statistic.likeData !== 0 ||
    statistic.shareData !== 0 ||
    statistic.saveData !== 0 ||
    statistic.commentData !== 0;

  return (
    <Container>
      <InsightChart
        data={socialData[platformActive].graphData}
        loading={loading.graph}
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        platformActive={platformActive}
      />
      <InsightStatistics
        pieData={statistic.pieData}
        like={statistic.likeData}
        comment={statistic.commentData}
        share={statistic.shareData}
        engagement={statistic.engagementData}
        reach={statistic.reachData}
        impressions={statistic.impressionsData}
        save={statistic.saveData}
        view={statistic.viewData}
        platformActive={platformActive}
        dateAgo={dateAgoCount}
        postCount={statistic.postCount}
        isPieActive={pieActive}
      />
      {/* <GapRowContainer> */}
      {/* <CategoryPerformance /> */}
      {/* <SentimentLifestyleContainer> */}
      {/* <Sentiment /> */}
      {/* <Lifestyle /> */}
      {/* </SentimentLifestyleContainer> */}
      {/* </GapRowContainer> */}
      <AudienceComponent
        platformActive={platformActive}
        ageGenderData={ageGenderData}
      />
    </Container>
  );
};

export default observer(InfluencerInsight);

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 36px;
  margin-top: 36px;
`;

const GapRowContainer = styled(RowContainer)`
  width: 100%;
  gap: 12px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const SentimentLifestyleContainer = styled(ColumnContainer)`
  width: 50%;
  gap: 12px;
`;

const AudienceInsightContainer = styled(ColumnContainer)`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 464px;
  border-radius: 16px;
  border: 0.6px solid ${COLOR.Gray_300};
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;
