import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { RowContainer } from "../../../utils/styling/general";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";

interface Props {
  active: string;
  setActive: () => void;
  username?: string;
  approveInflu: () => Promise<void>;
  rejectInflu: () => Promise<void>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 215px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 24px;
  background: ${COLOR.WHITE};
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;

const BoldB2Text = styled(TEXT.B2_Bold)``;

function AcceptInfluModal(props: Props) {
  const { active, setActive, username, approveInflu, rejectInflu } = props;
  const headerText = active === "accept" ? "ยืนยันตอบรับ" : "ปฏิเสธคำขอ";
  const bodyText =
    active === "accept"
      ? "คุณต้องการตอบรับคำขอเข้าร่วม\nแคมเปญจาก"
      : "คุณต้องการปฏิเสธคำขอ\nเข้าร่วมแคมเปญจาก";

  const confirm = async () => {
    if (active === "accept") {
      await approveInflu();
      setActive();
    } else {
      await rejectInflu();
      setActive();
    }
  };

  return (
    <RModal active={!!active} setActive={setActive}>
      <Container>
        <BoldH5Text style={{ marginBottom: "8px" }}>{headerText}</BoldH5Text>
        <B2Text>{bodyText}</B2Text>
        <BoldB2Text>{username}</BoldB2Text>
        <RowContainer style={{ marginTop: "16px" }}>
          <RButton
            title="ยกเลิก"
            onPress={() => {
              window.scrollTo(0, 0);
              setActive();
            }}
            backgroundColor={COLOR.White}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
          />
          <RButton
            title="ยืนยัน"
            onPress={() => {
              window.scrollTo(0, 0);
              confirm();
            }}
            containerStyle={{ marginLeft: "16px" }}
          />
        </RowContainer>
      </Container>
    </RModal>
  );
}

export default AcceptInfluModal;
