import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { Work } from "../../../types/work";
import {
  HTTP_PATTERN,
  PLACEHOLDER_IMAGE,
  PLACEHOLDER_REELS,
} from "../../../utils";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  title: string;
  workAmount: number;
  requirementCount: number;
  workData: Work[];
  isEditWork: Boolean;
  displayImage: (workList: Work[], index: number) => void;
  selectWorkList: Work[];
  setSelectWork: any;
}

function CheckWorkList(props: Props) {
  const {
    title,
    workAmount,
    requirementCount,
    workData,
    displayImage,
    setSelectWork,
    selectWorkList,
    isEditWork = false,
  } = props;

  let placeholderArray: Array<number> = [];
  if (workAmount !== requirementCount && requirementCount > workAmount) {
    placeholderArray = Array(requirementCount - workAmount).fill(0);
  }

  const getUri = (work: Work) => {
    if (work.media_type === "CAROUSEL_ALBUM") return work.media_url;
    if (work.media_type === "IMAGE") return work.media_url || PLACEHOLDER_IMAGE;
    if (!work.thumbnail_url || work.thumbnail_url === null)
      return PLACEHOLDER_REELS;
    return work.thumbnail_url || PLACEHOLDER_IMAGE;
  };

  const renderIcon = (): IconName => {
    switch (title) {
      case "Single-Post":
        return "Image";
      case "Multi Post":
        return "Multi";
      case "Story":
        return "StoryCircle";
      case "IGTV":
        return "TV";
      case "Reels":
        return "Reels";
      case "Tiktok Video":
        return "Tiktok";
      case "Lemon8 Article":
        return "Article";
      default:
        return "Image";
    }
  };

  const renderWork = () => {
    if (title === "Lemon8 Article") {
      if (workData.length === 0) {
        return (
          <NoLemonContainer>
            <RXIcon name="Link" color={COLOR.Gray_M} />
            <GrayMT1Text>อินฟลูเอนเซอร์ยังไม่ได้ส่งลิ้งก์บทความ</GrayMT1Text>
          </NoLemonContainer>
        );
      }
      return workData.map((workDetail, index) => {
        return (
          <LemonContainer
            onClick={() => {
              if (HTTP_PATTERN.test(workDetail.permalink)) {
                window.open(workDetail.permalink, "_blank", "noreferrer");
              } else {
                window.open(
                  "https://" + workDetail.permalink,
                  "_blank",
                  "noreferrer"
                );
              }
            }}
          >
            <RXIcon name="Link" />
            <T1Text>{workDetail.permalink}</T1Text>
          </LemonContainer>
        );
      });
    } else {
      return (
        <ImagesContainer>
          {workData.map((workDetail, index) => {
            const selected =
              selectWorkList.findIndex(
                (work) => work.workId === workDetail.workId
              ) >= 0;
            return (
              <div
                onClick={() =>
                  isEditWork ? "" : displayImage(workData, index)
                }
              >
                {isEditWork && (
                  <EditWorkCheckbox
                    onClick={() => setSelectWork(workDetail)}
                    style={
                      selected
                        ? {
                            background: COLOR.Blue_700,
                            border: `2px solid ${COLOR.White}`,
                          }
                        : {
                            background: COLOR.WHITE,
                            border: `2px solid ${COLOR.Gray_300}`,
                          }
                    }
                  >
                    <RXIcon
                      name="Check"
                      color={selected ? COLOR.WHITE : COLOR.Gray_300}
                    />
                  </EditWorkCheckbox>
                )}
                <ImageContainer src={getUri(workDetail)} />
              </div>
            );
          })}
          {placeholderArray.map(() => {
            return <PlaceholderContainer />;
          })}
        </ImagesContainer>
      );
    }
  };

  return (
    <Container>
      <RowContainer>
        <RXIcon name={renderIcon()} />
        <BoldH7Text style={{ marginLeft: "10px" }}>{title}</BoldH7Text>
        <H7Text style={{ marginLeft: "4px" }}>
          ({workAmount}/{requirementCount})
        </H7Text>
      </RowContainer>
      {renderWork()}
    </Container>
  );
}

export default CheckWorkList;

const Container = styled.div`
  margin-top: 24px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-top: 16px;
`;

const ImageContainer = styled.img`
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 16px;
  border-radius: 12px;
  background: ${COLOR.Gray_300};
  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;

const PlaceholderContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 16px;
  border-radius: 12px;
  background: ${COLOR.Gray_300};
`;

const NoLemonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 672px;
  height: 48px;
  padding: 15px 24px;
  margin-top: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background-color: ${COLOR.Gray_50};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`;

const LemonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 672px;
  height: 48px;
  padding: 15px 24px;
  margin-top: 16px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid ${COLOR.Gray_300};
  :hover {
    cursor: pointer;
  }
`;

const T1Text = styled(TEXT.T1_Reg)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const H7Text = styled(TEXT.H7_Reg)``;

const GrayMT1Text = styled(T1Text)`
  color: ${COLOR.Gray_M};
`;

const EditWorkCheckbox = styled.div`
  box-sizing: border-box;

  width: 31px;
  height: 31px;
  z-index: 1000;
  position: absolute;
  margin-left: 131px;
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  :hover {
    cursor: pointer;
  }
`;
