import styled from "styled-components";
import { TEXT } from "../../../fonts/text";

const FavoriteTab = () => {
  return (
    <Container>
      <BoldH4Text>Favorites</BoldH4Text>
      <B2Text>Influencer ที่คุณกดถูกใจ</B2Text>
    </Container>
  );
};

export default FavoriteTab;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 36px;
  width: 100%;
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;
