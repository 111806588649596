import { observer } from "mobx-react";
import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { DiscoverPage } from "../../../../pages/DiscoverPage";
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import FlatList from "../../../atoms/FlatList";
import ViewImageOverlay from "../../../atoms/ViewImageOverlay";

interface Props {
  discoveryWork: {
    data: {
      foodCat: any[];
      beautyCat: any[];
      fashionCat: any[];
    };
    metadata: { count: number; offset: number; limit: number };
  };
  feedImages:
    | {
        data: [];
        metadata: [];
        next: () => {};
      }
    | {
        data: [];
        metadata: [];
        next: () => void;
      };
  loading: boolean;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  page: DiscoverPage;
  navigate: NavigateFunction;
  clearFeedExpandWork: () => void;
}

function DiscoveryInfluencer(props: Props) {
  const {
    discoveryWork,
    feedImages,
    loading,
    setPage,
    navigate,
    clearFeedExpandWork,
  } = props;
  const { data, next } = feedImages;

  const category = [
    {
      title: "🍔 ดาวเด่นสายกิน",
      work: discoveryWork.data.foodCat,
      page: "Food & Beverage",
    },
    {
      title: "👗 ตัวตึงสายแฟ",
      work: discoveryWork.data.fashionCat,
      page: "Fashion & Accessory",
    },
    {
      title: "💄 ดาวรุ่งสายบิวตี้",
      work: discoveryWork.data.beautyCat,
      page: "Beauty & Cosmetics",
    },
  ];

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 350 && !loading) {
      next();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [loading]);

  useEffect(() => {
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const renderDetail = ({
    text,
    onClick,
    shouldRenderArrow,
  }: {
    text: string;
    onClick: () => void;
    shouldRenderArrow?: boolean;
  }) => {
    return (
      <DetailContainer>
        <BoldH5Text onClick={onClick}>{text}</BoldH5Text>
        {shouldRenderArrow && (
          <IconContainer onClick={onClick}>
            <RXIcon name="ArrowShortRight" size={32} />
          </IconContainer>
        )}
      </DetailContainer>
    );
  };

  return (
    <Container>
      {category.map((cat) => {
        return (
          <CategoryContainer>
            {renderDetail({
              text: cat.title,
              onClick: () => {
                if (
                  cat.page === "Food & Beverage" ||
                  cat.page === "Fashion & Accessory" ||
                  cat.page === "Beauty & Cosmetics"
                ) {
                  clearFeedExpandWork();
                  setPage(cat.page);
                }
              },
              shouldRenderArrow: true,
            })}
            <ImagesContainer>
              {cat.work.map((work) => {
                return (
                  <Image>
                    <ImageContainer
                      src={work.thumbnail_url || work.media_url}
                    />
                    <PlatformContainer>
                      {(work.postType?.includes("IG") ||
                        work.postType?.length === 0) && (
                        <InstagramContainer>
                          <RXIcon name="Instagram" color={COLOR.WHITE} />
                        </InstagramContainer>
                      )}
                      {work.postType?.includes("TK") && (
                        <TiktokIconContainer>
                          <RXIcon name="Tiktok" color={COLOR.WHITE} />
                        </TiktokIconContainer>
                      )}
                    </PlatformContainer>
                    <ViewImageOverlay
                      onClick={() => {
                        setPage("Work");
                        navigate(`/discover?page=work&workId=${work.workId}`);
                      }}
                      width={150}
                      height={150}
                    />
                  </Image>
                );
              })}
            </ImagesContainer>
          </CategoryContainer>
        );
      })}
      <CategoryContainer>
        {renderDetail({ text: "❤️ อินฟลูอื่นๆ ที่น่าสนใจ", onClick: () => {} })}
        <FlatList
          data={data}
          loading={loading}
          navigate={navigate}
          setPage={setPage}
        />
      </CategoryContainer>
    </Container>
  );
}
export default DiscoveryInfluencer;

const Container = styled(ColumnContainer)`
  width: 100%;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 25px;

  :hover {
    cursor: pointer;
    background: #f8f8f8;
  }
`;

const DetailContainer = styled(SpaceBetweenRowContainer)`
  padding: 24px 32px;
  width: inherit;
`;

const ImagesContainer = styled(RowContainer)`
  margin: 0px 40px 32px 32px;
  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 16px;
  border-radius: 16px;
  background: ${COLOR.Gray_300};
  object-fit: cover;
`;

const Image = styled.div`
  position: relative;
`;

const PlatformContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 8px;
  right: 24px;
`;

const TiktokIconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${COLOR.BLACK};
`;

const InstagramContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
`;
const BoldH5Text = styled(TEXT.H5_Bold)`
  :hover {
    cursor: pointer;
  }
`;
