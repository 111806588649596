import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ActiveProps } from "../../../types";
import { limitText, toDecimalNumber } from "../../../utils";
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import ValueDetails from "../../atoms/ValueDetails";
import { ANDCOLOR } from "../../../fonts/andColor";
import { ANDTEXT } from "../../../fonts/andText";
import { ITierType } from "../../../types/createDeal";

interface Props {
  name: string;
  photos: { url: string; file: File }[] | { url: string }[];
  category: string;
  influencersTier: ITierType;
  attribute: string[];
  gender: string;
  age: string;
}

function NewDealCardHeader(props: Props) {
  const { name, photos, category, influencersTier, attribute, gender, age } =
    props;

  const ageText = age.split("-").join(" - ") + " ปี";

  const genderText = () => {
    if (gender.includes("W") && gender.includes("M") && gender.includes("L"))
      return "ทุกเพศ";
    const replacedGender = gender
      .replace("W", "เพศหญิง")
      .replace("M", "เพศชาย")
      .replace("L", "LGBTQIA+");
    return replacedGender;
  };

  const ValueComponent = ({
    title,
    value,
    direction,
  }: {
    title: string;
    value: string | number;
    direction: "row" | "column";
  }) => {
    if (direction === "row")
      return (
        <HalfWidthContainer>
          <GrayHeader8Text>{title}</GrayHeader8Text>
          <BoldBody3Text>{value}</BoldBody3Text>
        </HalfWidthContainer>
      );
    return (
      <QuarterWidthContainer>
        <BoldBody3Text>{value}</BoldBody3Text>
        <GrayHeader8Text>{title}</GrayHeader8Text>
      </QuarterWidthContainer>
    );
  };

  const AttributesComponent = () => {
    const components: JSX.Element[] = [];
    attribute.map((att) =>
      components.push(
        <AttributeContainer>
          <Body4Text>{att}</Body4Text>
        </AttributeContainer>
      )
    );
    return <AttributesContainer>{components}</AttributesContainer>;
  };

  return (
    <Container>
      <PreviewImage src={photos[0]?.url} />
      <HeaderContainer>
        <LightPurpleBoldHeader8Text>{category}</LightPurpleBoldHeader8Text>
        <WightBoldHeader5Text>{name}</WightBoldHeader5Text>
      </HeaderContainer>
      <ContentContainer>
        <LeftContentContainer>
          <GrayHeader9Text>ข้อมูล Influencer</GrayHeader9Text>
          <GenderAgeContainer>
            <ValueComponent title="เพศ" value={genderText()} direction="row" />
            <ValueComponent title="อายุ" value={ageText} direction="row" />
          </GenderAgeContainer>
          <InfluencerContainer>
            <ValueComponent
              title="Nano"
              value={influencersTier?.nano?.value || "-"}
              direction="column"
            />
            <ValueComponent
              title="Micro"
              value={influencersTier?.micro?.value || "-"}
              direction="column"
            />
            <ValueComponent
              title="Mid"
              value={influencersTier?.mid?.value || "-"}
              direction="column"
            />
            <ValueComponent
              title="Macro"
              value={influencersTier?.macro?.value || "-"}
              direction="column"
            />
          </InfluencerContainer>
        </LeftContentContainer>
        <RightContentContainer>
          <GrayHeader9Text>Attribute</GrayHeader9Text>
          <AttributesComponent />
        </RightContentContainer>
      </ContentContainer>
    </Container>
  );
}

export default NewDealCardHeader;

const Container = styled.div`
  position: relative;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 78px;
  padding-left: 198px;
  padding-top: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${ANDCOLOR.BLUE};
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: 120px;
  padding: 8px;
  padding-left: 198px;
  border-width: 0.6px;
  border-style: solid;
  border-color: ${ANDCOLOR.BLUE};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const PreviewImage = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  width: 166px;
  height: 166px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  background: ${COLOR.Gray_300};
  z-index: 99;
  border-radius: 8px;
`;

const AttributesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

const AttributeContainer = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 41px;
  border: 0.6px solid ${ANDCOLOR.BLACK};
`;

const GenderAgeContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const InfluencerContainer = styled(GenderAgeContainer)`
  border-width: 0px;
  border-top-width: 0.6px;
  border-color: ${COLOR.Gray_300};
  border-style: solid;
  padding-top: 8px;
  margin-top: 8px;
`;

const HalfWidthContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const QuarterWidthContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const LeftContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 64%;
`;
const RightContentContainer = styled.div`
  margin-left: 8px;
  width: 33%;
`;
const LightPurpleBoldHeader8Text = styled(ANDTEXT.header8BOLD)`
  color: ${ANDCOLOR.LIGHTPURPLE};
`;

const WightBoldHeader5Text = styled(ANDTEXT.header5BOLD)`
  color: ${ANDCOLOR.WHITE};
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GrayHeader9Text = styled(ANDTEXT.header9)`
  color: ${COLOR.Gray_D};
`;
const GrayHeader8Text = styled(ANDTEXT.header8)`
  color: ${COLOR.Gray_D};
`;

const BoldBody3Text = styled(ANDTEXT.body3BOLD)``;

const Body4Text = styled(ANDTEXT.body4)``;
