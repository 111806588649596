import { observer } from "mobx-react";
import { SetStateAction, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import RLoading from "../components/atoms/RLoading";
import ScrollToTop from "../components/atoms/ScrollToTop";
import InviteConfirmModal from "../components/molecules/CampaignManagement/InviteConfirmModal";
import InviteModal from "../components/molecules/CampaignManagement/InviteModal";
import ProfileFullScreenModal from "../components/molecules/CampaignManagement/ProfileFullScreenModal";
import FavoritePageDetail from "../components/organisms/Favorite/FavoritePageDetail";
import FavoriteTab from "../components/organisms/Favorite/FavoriteTab";
import InfluencerPortfolio from "../components/organisms/Influencer/InfluencerPortfolio";
import {
  useDealStore,
  useInfluencerStore,
  useOfferStore,
} from "../stores/initialize/Context";
import { useQuery } from "../utils";
import { GET } from "../utils/Networking";
import {
  IGetInfluencerResponse,
  InfluencerPost,
} from "../types/api/getInfluencer";

interface FavoriteContentProps {
  id: string;
  influencerId: string;
  loading: boolean;
  influencer: IGetInfluencerResponse | undefined;
  navigate: NavigateFunction;
  brandSaved: {
    data: never[];
    metadata: {
      count: number;
      offset: number;
      limit: number;
    };
  };
  offerLoading: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStartIndex: React.Dispatch<React.SetStateAction<number>>;
  setImageList: React.Dispatch<React.SetStateAction<string[]>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const FavoriteDeterminer = (props: FavoriteContentProps) => {
  const {
    id,
    influencer,
    influencerId,
    loading,
    navigate,
    brandSaved,
    setActive,
    offerLoading,
  } = props;
  if (id !== "" || influencerId !== "") {
    if (loading) return <RLoading containerStyle={{ marginTop: 100 }} />;
    if (!!influencer)
      return (
        <InfluencerPortfolio influencer={influencer} setActive={setActive} />
      );
  }
  return (
    <>
      <FavoriteTab />
      <FavoritePageDetail
        influList={brandSaved}
        loading={offerLoading}
        navigate={navigate}
      />
    </>
  );
};

const FavoritePage = () => {
  const {
    getBrandSaveInfluencer,
    brandSaved,
    offerLoading,
    sendOfferInfluencer,
  } = useOfferStore();
  const { campaignList, getCampaignList } = useDealStore();
  const {
    influencer,
    getInfluencerById,
    getInfluencerBySocial,
    loading,
    socialData,
    clearInfluencerGraphPost,
  } = useInfluencerStore();

  const [startIndex, setStartIndex] = useState<number>(0);
  const [imageList, setImageList] = useState<string[]>([]);
  const [active, setActive] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [influencerid, setInfluencerid] = useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);

  const { ongoing: deals } = campaignList;
  const navigate = useNavigate();
  const url = window.location.href;

  const query = useQuery();

  const id = (query.get("id") as string) || "";
  const influencerId = (query.get("influencerId") as string) || "";
  const { influencer: influencerLoading } = loading;

  const inviteConfirmHandler = () => {
    if (deals.length === 0) navigate("/createcampaign");
    if (selected.length === 0) {
      console.warn("no invite list");
      return;
    }
    selected.forEach((dealId) => {
      //send offer to influ without invite limit increase on deal

      sendOfferInfluencer(influencerid, influencerid, dealId, true);
    });

    //close bottom sheet
    setActive(false);

    //open invitedCard modal
    setShowModal(true);
  };

  useEffect(() => {
    getCampaignList();
    clearInfluencerGraphPost();
    if (id !== "" && id !== undefined) {
      setInfluencerid(id || "");
      getInfluencerById({ id, isForSocial: false });
    }
    if (influencerId !== "" && influencerId !== undefined) {
      setInfluencerid(influencerId || "");
      getInfluencerBySocial({ id: influencerId });
      //TODO: doesn't have load more function. Please come back to do it
    } else getBrandSaveInfluencer(999, 0);
  }, [url]);

  return (
    <Container>
      <ScrollToTop />
      <ContentContainer>
        <FavoriteDeterminer
          id={id}
          influencerId={influencerId}
          loading={influencerLoading}
          influencer={influencer}
          navigate={navigate}
          brandSaved={brandSaved}
          offerLoading={offerLoading}
          setActive={setActive}
          setStartIndex={setStartIndex}
          setImageList={setImageList}
          setSelected={setSelected}
        />
      </ContentContainer>
      <ProfileFullScreenModal
        imageList={imageList}
        resetImage={() => setImageList([])}
        startIndex={startIndex}
      />
      <InviteModal
        active={active}
        setActive={setActive}
        onConfirm={inviteConfirmHandler}
        selected={selected}
        setSelected={setSelected}
        deals={deals}
      />
      <InviteConfirmModal active={showModal} setActive={setShowModal} />
    </Container>
  );
};

export default observer(FavoritePage);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  width: 100%;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px;
`;

const NavigationBack = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  left: -42px;
  top: 148px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
