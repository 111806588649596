import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import { COLOR } from "../../../fonts/color";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { RowContainer } from "../../../utils/styling/general";

interface NavigationProps {
  navigation: NavigateFunction;
}

const BackButton = (props: NavigationProps) => {
  const { navigation } = props;
  return (
    <BackButtonContainer onClick={() => navigation(-1)}>
      <RXIcon name="ArrowShortLeft" size={18} color={COLOR.Gray_M} />
      <Bu3Text>ย้อนกลับ</Bu3Text>
    </BackButtonContainer>
  );
};

export default BackButton;

const BackButtonContainer = styled(RowContainer)`
  margin-top: 20px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 12px;
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;
const Bu3Text = styled(TEXT.Bu3_Reg)``;
