const device = window.navigator.userAgent;

export const getDevice = () => {
  if (/iPhone/.test(device)) {
    return "iphone";
  } else if (/Android/.test(device)) {
    return "android";
  } else if (/iPad/.test(device)) {
    return "ipad";
  } else {
    return "pc";
  }
};
