import { Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { DiscoverPage } from "../../../../pages/DiscoverPage";
import { PLACEHOLDER_BRAND, toDecimalNumber } from "../../../../utils";
import { RowContainer } from "../../../../utils/styling/general";
import ImageOverlay from "../../../atoms/ImageOverlay";
import ValueDetails from "../../../atoms/ValueDetails";

interface Props {
  width: number;
  name: string;
  category: string;
  value: number;
  cash: number;
  minFollower: string;
  tiktokMinFollower: string;
  coverImg: string;
  navigate: NavigateFunction;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
}

interface SizeProps {
  width: number;
  img: string;
}

function DiscoverCampaignHeader(props: Props) {
  const {
    width,
    name,
    category,
    value,
    cash,
    minFollower,
    tiktokMinFollower,
    coverImg,
    navigate,
    setPage,
  } = props;

  const displayValue = value ? `${value} ฿` : "-";
  const displayCash = cash ? `${cash} ฿` : "-";
  const nameRef = useRef<HTMLDivElement | null>(null);
  const [nameHeight, setNameHeight] = useState<number>(36);

  useEffect(() => {
    if (nameRef.current?.clientHeight) {
      setNameHeight(nameRef.current?.clientHeight);
    }
  }, [nameRef]);

  const displayFollower = () => {
    let igFollower = "-";
    let tkFollower = "-";

    if (minFollower) {
      igFollower = toDecimalNumber({
        number: parseInt(minFollower),
        toFixed: 0,
      });
    }
    if (tiktokMinFollower) {
      tkFollower = toDecimalNumber({
        number: parseInt(tiktokMinFollower),
        toFixed: 0,
      });
    }
    return `${igFollower} / ${tkFollower}`;
  };

  return (
    <Container img={coverImg} width={width}>
      <ContentContainer>
        <BackButton
          onClick={() => {
            setPage("Campaign");
            navigate("/discover?page=campaign");
          }}
          nameHeight={nameHeight}
        >
          <IconContainer>
            <RXIcon name="ArrowShortLeft" color={COLOR.WHITE} />
          </IconContainer>
          <Body1Text>กลับ</Body1Text>
        </BackButton>
        <Body2Text>{category}</Body2Text>
        <BoldH4Text ref={nameRef}>{name}</BoldH4Text>
        <ValueRowContainer>
          <ValueDetails
            icon="MoneyFill"
            value={displayValue}
            description="มูลค่าสินค้า"
          />
          <ValueDetails
            icon="BudgetFill"
            value={displayCash}
            description="เงินค่าตอบแทน"
          />
          <ValueDetails
            icon="Follower"
            value={displayFollower()}
            description="Followers IG / TikTok"
          />
        </ValueRowContainer>
      </ContentContainer>
    </Container>
  );
}

export default DiscoverCampaignHeader;

const Container = styled.div<SizeProps>`
  margin-top: 96px;
  width: ${(props) => props.width + "px"};
  height: 520px;
  object-fit: cover;
  background-image: linear-gradient(
      180deg,
      rgba(35, 31, 32, 0.36) -10.14%,
      rgba(0, 0, 0, 0.0612) 19.81%,
      rgba(0, 0, 0, 0) 62.41%,
      rgba(0, 0, 0, 0.24) 93.11%
    ),
    url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: 722px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
`;

const IconContainer = styled.div``;

const ValueRowContainer = styled(RowContainer)`
  gap: 156px;
  margin-bottom: 48px;
`;

const BoldH4Text = styled(TEXT.header4BOLD)`
  color: ${COLOR.WHITE};
  width: 722px;
`;

const Body2Text = styled(TEXT.body2)`
  color: ${COLOR.WHITE};
`;

const Body1Text = styled(TEXT.body1)`
  color: ${COLOR.WHITE};
  margin-bottom: 4px;
`;

const BackButton = styled.div<{ nameHeight: number }>`
  left: 0px;
  top: ${(props) => -366 + props.nameHeight + "px"};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 71px;
  height: 32px;
  :hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 25px;
  }
`;
