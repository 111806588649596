import { SetStateAction, useState } from "react";
import { RXIcon } from "rn-rx-icons";
import shortid from "shortid";
import styled from "styled-components";
import { PostMedia } from "../../../../class/ScopeOfWork";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { TEXT } from "../../../../fonts/text";
import { ActiveProps } from "../../../../types";
import { RowContainer } from "../../../../utils/styling/general";
import ANDButton from "../../../atoms/ANDButton";
import ANDCheckbox from "../../../atoms/ANDCheckbox";
import { COLOR } from "../../../../fonts/color";

interface Props {
  setTkPostMedias: (postMedia: PostMedia) => void;
  setAddActive: React.Dispatch<SetStateAction<boolean>>;
}

function TKScopeOfWorkSelector(props: Props) {
  const { setTkPostMedias, setAddActive } = props;

  const [tkActive, setTkActive] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(0);

  const handleSave = () => {
    const media: PostMedia = {
      id: shortid.generate(),
      image: 0,
      video: 1,
      article: 0,
      postType: "TK_video",
      duration: duration,
    };
    setTkPostMedias(media);
  };

  return (
    <Container>
      <HeaderContainer>
        <Body2Text>
          เลือกรูปแบบของโพสต์ <RedText>*</RedText>
        </Body2Text>
      </HeaderContainer>
      {tkActive ? (
        <ActivePostContainer>
          <ActivePostHeaderContainer>
            <RowContainer>
              <RXIcon name="Tiktok" color={ANDCOLOR.BLUE} />
              <BlueBody1Text>TikTok Video</BlueBody1Text>
            </RowContainer>
            <ANDCheckbox active={true} containerStyle={{ borderRadius: 24 }} />
          </ActivePostHeaderContainer>
          <ChoiceContainer onClick={() => setDuration(30)}>
            <ANDCheckbox
              active={duration === 30}
              containerStyle={{ borderRadius: 24 }}
            />
            <Body1Text>30 วินาที</Body1Text>
          </ChoiceContainer>
          <ChoiceContainer onClick={() => setDuration(60)}>
            <ANDCheckbox
              active={duration === 60}
              containerStyle={{ borderRadius: 24 }}
            />
            <Body1Text>1 นาที</Body1Text>
          </ChoiceContainer>
          <ChoiceContainer onClick={() => setDuration(180)}>
            <ANDCheckbox
              active={duration === 180}
              containerStyle={{ borderRadius: 24 }}
            />
            <Body1Text>3 นาที</Body1Text>
          </ChoiceContainer>
          <ChoiceContainer onClick={() => setDuration(300)}>
            <ANDCheckbox
              active={duration === 300}
              containerStyle={{ borderRadius: 24 }}
            />
            <Body1Text>5 นาที</Body1Text>
          </ChoiceContainer>
        </ActivePostContainer>
      ) : (
        <PostContainer active={false} onClick={() => setTkActive(true)}>
          <RowContainer>
            <RXIcon name="Tiktok" color={COLOR.Gray_D} />
            <GrayBody1Text>TikTok Video</GrayBody1Text>
          </RowContainer>
          <ANDCheckbox active={false} containerStyle={{ borderRadius: 24 }} />
        </PostContainer>
      )}
      {!!tkActive && (
        <RowContainer
          style={{
            justifyContent: "space-around",
            marginTop: "16px",
          }}
        >
          <ANDButton
            title="ยกเลิก"
            backgroundColor={ANDCOLOR.WHITE}
            onPress={() => {
              setAddActive(false);
            }}
            containerStyle={{
              minWidth: "0px",
              width: "99px",
              height: "40px",
            }}
            textColor={ANDCOLOR.BLUE}
          />
          <ANDButton
            active={duration !== 0}
            title="ยืนยัน"
            onPress={handleSave}
            containerStyle={{
              minWidth: "0px",
              width: "99px",
              height: "40px",
            }}
          />
        </RowContainer>
      )}
    </Container>
  );
}

export default TKScopeOfWorkSelector;

const Container = styled.div`
  position: absolute;
  right: 0;
  width: 320px;
  padding: 24px;
  background-color: ${ANDCOLOR.WHITE};
  border: 1px solid ${COLOR.Gray_400};
  z-index: 999;
  border-radius: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostContainer = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  margin-top: 16px;
  background: ${(props) =>
    props.active ? ANDCOLOR.LIGHTBLUE : ANDCOLOR.WHITE};
  border-radius: 24px;
  border: ${(props) => (props.active ? "none" : `1px solid ${COLOR.Gray_200}`)};

  :hover {
    cursor: pointer;
  }
`;

const ActivePostContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ANDCOLOR.LIGHTBLUE};
  padding: 16px;
  margin-top: 16px;
  border-radius: 24px;
`;

const ActivePostHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ChoiceContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
`;

const Body1Text = styled(TEXT.body1)`
  margin-left: 8px;
`;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const GrayBody1Text = styled(TEXT.body1)`
  color: ${COLOR.Gray_D};
  margin-left: 8px;
`;

const BlueBody1Text = styled(TEXT.body1)`
  color: ${ANDCOLOR.BLUE};
  margin-left: 4px;
`;

const Body2Text = styled(TEXT.H8_Bold)``;

const RedText = styled(TEXT.body2)`
  color: ${ANDCOLOR.PINK};
`;
