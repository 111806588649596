import { useState } from "react";
import { RXIcon } from "rn-rx-icons";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { isHaveEveryElement } from "../../../utils";
import { changwat } from "../../organisms/CreateCampaign/Options";
import RCheckbox from "../RCheckbox";
import "./Dropdown.css";
import useComponentVisible from "../../../utils/hook/UseComponentVisible";

interface ContainerProp {
  active: boolean;
}

interface DropdownProps {
  defaultValue?: string[];
  placeholder: string;
  options: Record<string, { label: string; value: string }[]>;
  onChange: (option: string[]) => void;
  containerStyle?: CSSProperties;
  nonChildElement?: string[];
  labelAsValue?: boolean;
  totalText: string;
}

interface ParentClickFunctionProps {
  value: string[];
  parentActive: boolean;
  selected: string[];
  setSelected: (arr: string[]) => void;
  onChange: (option: string[]) => void;
  totalText: string;
}

const onParentClick = (props: ParentClickFunctionProps) => {
  const { value, parentActive, selected, setSelected, totalText, onChange } =
    props;
  let tempArray = [...selected];
  if (value[0] === "all" || value[0] === totalText) {
    if (tempArray[0] === "all") tempArray = [];
    else tempArray = ["all"];
  } else {
    tempArray.splice(tempArray.indexOf("all", 1));
    if (parentActive) {
      value.map((val) => tempArray.splice(tempArray.indexOf(val), 1));
    } else {
      tempArray = Array.from(new Set([...tempArray, ...value]));
    }
  }
  setSelected(tempArray);
  onChange(tempArray);
};

interface ChildClickFunctionProps {
  value: string;
  childActive: boolean;
  selected: string[];
  setSelected: (arr: string[]) => void;
  onChange: (option: string[]) => void;
  totalText: string;
}

const onChildClick = (props: ChildClickFunctionProps) => {
  const { value, childActive, selected, setSelected, totalText, onChange } =
    props;
  let tempArray = [...selected];
  if (tempArray[0] === "all" || tempArray[0] === totalText) {
    tempArray.splice(tempArray.indexOf("all", 1));
  }
  if (childActive) {
    tempArray.splice(tempArray.indexOf(value), 1);
  } else {
    tempArray.push(value);
  }
  setSelected(tempArray);
  onChange(tempArray);
};

interface ComponentProps {
  value: string;
  onSelect: () => void;
  itemActive: boolean;
  allActive: boolean;
}

const Parent = (props: ComponentProps) => {
  const { value, onSelect, itemActive, allActive } = props;
  return (
    <ParentContainer onClick={onSelect}>
      <RCheckbox active={itemActive || allActive} />
      <Bu2Text>{value}</Bu2Text>
    </ParentContainer>
  );
};

const Child = (props: ComponentProps) => {
  const { value, onSelect, itemActive, allActive } = props;
  return (
    <ChildContainer onClick={onSelect}>
      <RCheckbox active={itemActive || allActive} />
      <Bu3Text>{value}</Bu3Text>
    </ChildContainer>
  );
};

interface ItemListProps {
  active: boolean;
  options: Record<
    string,
    {
      label: string;
      value: string;
    }[]
  >;
  labelAsValue: boolean;
  selected: string[];
  setSelected: (arr: string[]) => void;
  allActive: boolean;
  nonChildElement: string[] | undefined;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (option: string[]) => void;
  totalText: string;
}

const ItemsListComponent = (props: ItemListProps) => {
  const {
    active,
    options,
    labelAsValue,
    selected,
    allActive,
    nonChildElement,
    setSelected,
    setIsComponentVisible,
    onChange,
    totalText,
  } = props;

  if (!active) return <></>;
  const components: JSX.Element[] = [];
  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const element = options[key];
      const childInParent = element.map((el) =>
        labelAsValue ? el.label : el.value
      );
      const parentActive = isHaveEveryElement(childInParent, selected);
      components.push(
        <Parent
          key={key}
          value={key}
          onSelect={() =>
            onParentClick({
              value: childInParent,
              parentActive,
              selected,
              setSelected,
              totalText,
              onChange,
            })
          }
          itemActive={parentActive}
          allActive={allActive}
        />
      );
      element.map((child) => {
        if (!nonChildElement || !nonChildElement.includes(child.label)) {
          const childActive = selected.includes(
            labelAsValue ? child.label : child.value
          );
          components.push(
            <Child
              key={child.label}
              value={child.label}
              onSelect={() =>
                onChildClick({
                  value: labelAsValue ? child.label : child.value,
                  childActive,
                  selected,
                  setSelected,
                  totalText,
                  onChange,
                })
              }
              itemActive={childActive}
              allActive={allActive}
            />
          );
        }
      });
    }
  }

  return <DropdownContainer active={active}>{components}</DropdownContainer>;
};

interface SelectedProps {
  selected: string[];
  placeholder: string;
  labelAsValue: boolean;
  totalText: string;
}

const SelectedTextComponent = (props: SelectedProps) => {
  const { selected, placeholder, labelAsValue, totalText } = props;
  let selectedText: string = "";
  if (selected.length === 0)
    return <T1Text style={{ color: COLOR.Gray_300 }}>{placeholder}</T1Text>;
  selected.map((text) => {
    const label =
      changwat.find((cw) =>
        labelAsValue ? cw.label === text : cw.value === text
      )?.label || "";
    if (text === "all") selectedText += totalText;
    else if (selectedText !== "") {
      selectedText += `, ${label}`;
    } else {
      selectedText = label;
    }
  });
  return <T1Text>{selectedText}</T1Text>;
};

interface FooterProps {
  selected: string[];
  setSelected: (arr: string[]) => void;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (option: string[]) => void;
}

const HierarchyDropdown = (props: DropdownProps) => {
  const {
    defaultValue,
    placeholder,
    options,
    onChange,
    containerStyle,
    nonChildElement,
    labelAsValue = false,
    totalText,
  } = props;
  const [selected, setSelected] = useState<string[]>([...(defaultValue || [])]);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const allActive = selected[0] === "all" || selected[0] === totalText;

  return (
    <Container active={isComponentVisible} ref={ref}>
      <BoxContainer
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        active={isComponentVisible}
        onBlur={() => setIsComponentVisible(false)}
        style={containerStyle}
      >
        <TextContainer>
          <SelectedTextComponent
            {...props}
            labelAsValue={labelAsValue}
            selected={selected}
          />
          <IconContainer>
            <RXIcon name="ArrowShortRight" color={COLOR.Gray_300} />
          </IconContainer>
        </TextContainer>
      </BoxContainer>
      <ItemsListComponent
        {...props}
        nonChildElement={nonChildElement}
        labelAsValue={labelAsValue}
        selected={selected}
        setSelected={setSelected}
        active={isComponentVisible}
        setIsComponentVisible={setIsComponentVisible}
        allActive={allActive}
      />
    </Container>
  );
};

export default HierarchyDropdown;

const Container = styled.div<ContainerProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${COLOR.WHITE};
  align-items: center;
  z-index: 100;
  gap: 12px;
  :hover {
    cursor: pointer;
  }
`;

const BoxContainer = styled.div<ContainerProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${COLOR.WHITE};
  border: ${(props) =>
    props.active ? `1px solid ${COLOR.Gray_M}` : `1px solid ${COLOR.Gray_300}`};
  border-radius: 25px;
  align-items: center;
  z-index: 100;
  :hover {
    cursor: pointer;
  }
`;

const DropdownContainer = styled.div<ContainerProp>`
  width: 100%;
  height: 336px;
  background-color: ${COLOR.WHITE};
  z-index: 100;
  border-radius: 0px 0px 25px 25px;
  overflow-y: scroll;
  border: ${(props) =>
    props.active ? `1px solid ${COLOR.Gray_M}` : `1px solid ${COLOR.Gray_300}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;

  &::-webkit-scrollbar {
    position: absolute;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_200};
    border-radius: 18px;
    border: solid 4px transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: solid 4px transparent;
    background: ${COLOR.Gray_300};
    background-clip: padding-box;
  }

  :hover {
    overflow-y: overlay;
  }
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
`;

const FooterContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  padding: 16px 24px;
  align-items: center;
  width: 100%;
  height: 70px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid ${COLOR.Gray_M};
  border-top: 0.6px solid ${COLOR.Gray_400};
  background: ${COLOR.White};
  z-index: 101;
`;

const ParentContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 16px 24px;
  gap: 8px;
  background-color: ${COLOR.WHITE};
  align-items: center;

  :hover {
    background-color: ${COLOR.Gray_50};
  }
`;

const ChildContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 24px 12px 52px;
  gap: 8px;
  background-color: ${COLOR.WHITE};
  align-items: center;

  :hover {
    background-color: ${COLOR.Gray_50};
  }
`;

const IconContainer = styled.div`
  transform: rotate(90deg);
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 38px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${COLOR.Blue_700};
  :hover {
    cursor: pointer;
  }
`;

const T1Text = styled(TEXT.T1_Reg)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`;

const Bu2Text = styled(TEXT.Bu2_Reg)``;

const Bu3Text = styled(TEXT.Bu3_Reg)``;

const GrayBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_M};
  :hover {
    cursor: pointer;
  }
`;

const WhiteBoldBu3Text = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.White};
`;
