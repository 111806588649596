import { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { RowContainer } from "../../../utils/styling/general";
import RButton from "../../atoms/RButton";
import RCheckbox from "../../atoms/RCheckbox";

interface Props {
  navigate: NavigateFunction;
}

function DeleteAccount(props: Props) {
  const { navigate } = props;
  const [active, setActive] = useState<boolean>(false);
  return (
    <Container>
      <HeaderContainer>
        <IconContainer onClick={() => navigate("/setting?page=profile")}>
          <RXIcon name="ArrowShortLeft" size={32} />
        </IconContainer>
        <BoldH5Text style={{ marginLeft: "8px" }}>Delete Account</BoldH5Text>
      </HeaderContainer>
      <TextContainer>
        <BoldH7Text>
          โปรดตรวจสอบให้แน่ใจว่า
          <br />
          <br />
        </BoldH7Text>
        <B3Text>
          <li>คุณไม่มีแคมเปญที่เปิดไว้ และยังไม่หมดอายุ</li>
          <li>
            คุณไม่มีอินฟลูเอนเซอร์ที่ตกลงร่วมงานกับคุณ
            และกำลังอยู่ในขั้นตอนการดำเนินงาน
          </li>
          <br />
          หากคุณตรวจสอบแล้ว ยังต้องการลบบัญชีผู้ใช้นี้ เราขอแจ้งว่า
          ข้อมูลทั้งหมด รวมถึงประวัติการทำงาน ข้อความ และรูปภาพ ต่างๆ
          ที่คุณดำเนินการบนแอพลิเคชั่น จะถูกลบโดยถาวร
          <br />
          <br />
        </B3Text>
      </TextContainer>
      <ButtonContainer>
        <RowContainer
          style={{ marginLeft: "64px", gap: "8px" }}
          onClick={() => setActive(!active)}
        >
          <RCheckbox active={active} />
          <B3Text>ฉันรับทราบและยินยอมให้ลบบัญชีของฉัน</B3Text>
        </RowContainer>
      </ButtonContainer>
      <RButton
        title="ยืนยันลบบัญชี"
        onPress={() => {
          window.open("https://lin.ee/WHP5sdH", "_blank", "noreferrer");
          navigate("/");
        }}
        active={active}
        containerStyle={{
          margin: "24px 0px 32px 56px",
        }}
      />
    </Container>
  );
}

export default DeleteAccount;

const Container = styled.div`
  height: 414px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 24px 56px 13px 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;

const TextContainer = styled.div`
  padding: 0px 44px 0px 56px;
`;

const ButtonContainer = styled.label`
  :hover {
    cursor: pointer;
  }
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;
const BoldH7Text = styled(TEXT.H7_Bold)``;

const B3Text = styled(TEXT.B3_Reg)``;
