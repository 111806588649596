import { observer } from "mobx-react";
import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { PageStatus } from "../../../pages/CampaignManagement/CampaignManagement";
import {
  useChatStore,
  useDealStore,
  useInfluencerStore,
} from "../../../stores/initialize/Context";
import { IInfluencerList } from "../../../types/api/listInfluencers";
import { Deal, StatusType } from "../../../types/deal";
import { RowContainer } from "../../../utils/styling/general";
import BarNavigator from "../../molecules/CampaignManagement/BarNavigator";
import InfluencerList from "../../molecules/CampaignManagement/InfluencerList";
import NewDealCardHeader from "../../molecules/Deal/NewDealCardHeader";
import EditDealDetails from "./EditDealDetails";
import InfluencerRecommend from "./InfluencerRecommend";
import NewDealDetail from "./NewDealDetail";

interface Props {
  navigate: NavigateFunction;
  deal: Deal;
  influencers: Record<StatusType, IInfluencerList>;
  selectedBar: PageStatus;
  setDealContactId: React.Dispatch<React.SetStateAction<string>>;
  setRejectActive: React.Dispatch<React.SetStateAction<boolean>>;
  brandId: string;
  setDealDetailActive: React.Dispatch<React.SetStateAction<boolean>>;
  shouldUpdate: boolean;
  toggleShouldUpdate: () => void;
  chatBadge: Record<string, number>;
  loadingInfluencers: {
    pending: boolean;
    ongoing: boolean;
    check: boolean;
    done: boolean;
  };
}

function CampaignManagementBoard(props: Props) {
  const {
    navigate,
    deal,
    influencers,
    selectedBar,
    setRejectActive,
    setDealContactId,
    brandId,
    shouldUpdate,
    toggleShouldUpdate,
    chatBadge,
    loadingInfluencers,
  } = props;

  const { pendingContacts, ongoingContacts, checkContacts, doneContacts } =
    deal;

  const {
    pending: pendingInfluencers,
    ongoing: ongoingInfluencers,
    check: checkInfluencers,
    done: doneInfluencers,
  } = influencers;

  const { loading, triggerSaveInfluencer, changeInfluDeliverStatus } =
    useInfluencerStore();

  const { changeDeliverStatus } = useDealStore();

  const { subscribeToLastMessage, lastMessages } = useChatStore();
  const {
    dueDate,
    dealId,
    name,
    photos,
    category,
    influencersTier,
    attribute,
    gender,
    age,
  } = deal;

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (
      selectedBar !== "detail" &&
      selectedBar !== "edit" &&
      selectedBar !== "recommend"
    ) {
      if (scrollTop + clientHeight >= scrollHeight - 400) {
        if (
          influencers[selectedBar].metadata.count &&
          influencers[selectedBar].metadata.count >
            influencers[selectedBar].data.length &&
          !loading.influencers[selectedBar]
        ) {
          influencers[selectedBar].next?.();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const isViewDetail =
    selectedBar === "detail" ||
    selectedBar === "edit" ||
    selectedBar === "recommend";
  const onBack = () => {
    if (isViewDetail) {
      return () => navigate(-1);
    }
    return undefined;
  };

  const {
    notiCheck = 0,
    notiDone = 0,
    notiOngoing = 0,
    notiPending = 0,
  } = deal;

  const pendingBar = () => {
    return (
      <BarRowContainer
        onClick={() => navigate(`/deal?dealId=${dealId}&status=pending`)}
      >
        <BoldBu2Text>คำขอใหม่</BoldBu2Text>
        {pendingContacts.length !== 0 && (
          <NotificationContainer>
            <BoldBu2Text>({pendingContacts.length})</BoldBu2Text>
            {notiPending > 0 && <Notification />}
          </NotificationContainer>
        )}
        {selectedBar === "pending" && <ActiveBar style={{ width: "120px" }} />}
      </BarRowContainer>
    );
  };

  const ongoingBar = () => {
    return (
      <BarRowContainer
        onClick={() => navigate(`/deal?dealId=${deal.dealId}&status=ongoing`)}
      >
        <BoldBu2Text>กำลังดำเนินการ</BoldBu2Text>
        {ongoingContacts.length !== 0 && (
          <NotificationContainer>
            <BoldBu2Text>({ongoingContacts.length})</BoldBu2Text>
            {notiOngoing > 0 && <Notification />}
          </NotificationContainer>
        )}
        {selectedBar === "ongoing" && <ActiveBar style={{ width: "169px" }} />}
      </BarRowContainer>
    );
  };

  const workBar = () => {
    return (
      <BarRowContainer
        onClick={() => navigate(`/deal?dealId=${deal.dealId}&status=check`)}
      >
        <BoldBu2Text>ตรวจงาน</BoldBu2Text>
        {checkContacts.length !== 0 && (
          <NotificationContainer>
            <BoldBu2Text>({checkContacts.length})</BoldBu2Text>
            {notiCheck > 0 && <Notification />}
          </NotificationContainer>
        )}
        {selectedBar === "check" && <ActiveBar style={{ width: "120px" }} />}
      </BarRowContainer>
    );
  };

  const doneBar = () => {
    return (
      <BarRowContainer
        onClick={() => navigate(`/deal?dealId=${deal.dealId}&status=done`)}
      >
        <BoldBu2Text>เสร็จสิ้น</BoldBu2Text>
        {doneInfluencers.data.length !== 0 && (
          <NotificationContainer>
            <BoldBu2Text>({doneInfluencers.metadata.count})</BoldBu2Text>
            {notiDone > 0 && <Notification />}
          </NotificationContainer>
        )}
        {selectedBar === "done" && <ActiveBar style={{ width: "110px" }} />}
      </BarRowContainer>
    );
  };

  const CampaignComponent = () => {
    if (selectedBar === "pending") {
      return (
        <InfluencerList
          status={selectedBar}
          influencers={pendingInfluencers.data}
          dealId={dealId}
          dueDate={dueDate}
          setRejectActive={setRejectActive}
          setDealContactId={setDealContactId}
          brandId={brandId}
          changeDeliverStatus={changeDeliverStatus}
          changeInfluDeliverStatus={changeInfluDeliverStatus}
          shouldUpdate={shouldUpdate}
          toggleShouldUpdate={toggleShouldUpdate}
          chatBadge={chatBadge}
          loading={loadingInfluencers.pending}
        />
      );
    } else if (selectedBar === "ongoing") {
      return (
        <InfluencerList
          status={selectedBar}
          influencers={ongoingInfluencers.data}
          getLastMessage={subscribeToLastMessage}
          lastMessage={lastMessages}
          dealId={dealId}
          setRejectActive={setRejectActive}
          setDealContactId={setDealContactId}
          brandId={brandId}
          changeDeliverStatus={changeDeliverStatus}
          changeInfluDeliverStatus={changeInfluDeliverStatus}
          shouldUpdate={shouldUpdate}
          toggleShouldUpdate={toggleShouldUpdate}
          chatBadge={chatBadge}
          loading={loadingInfluencers.ongoing}
        />
      );
    } else if (selectedBar === "check") {
      return (
        <InfluencerList
          status={selectedBar}
          influencers={checkInfluencers.data}
          getLastMessage={subscribeToLastMessage}
          lastMessage={lastMessages}
          dealId={dealId}
          setRejectActive={setRejectActive}
          setDealContactId={setDealContactId}
          brandId={brandId}
          changeDeliverStatus={changeDeliverStatus}
          changeInfluDeliverStatus={changeInfluDeliverStatus}
          shouldUpdate={shouldUpdate}
          toggleShouldUpdate={toggleShouldUpdate}
          chatBadge={chatBadge}
          loading={loadingInfluencers.check}
        />
      );
    } else if (selectedBar === "done") {
      return (
        <InfluencerList
          status={selectedBar}
          influencers={doneInfluencers.data}
          getLastMessage={subscribeToLastMessage}
          lastMessage={lastMessages}
          dealId={dealId}
          setRejectActive={setRejectActive}
          setDealContactId={setDealContactId}
          brandId={brandId}
          changeDeliverStatus={changeDeliverStatus}
          changeInfluDeliverStatus={changeInfluDeliverStatus}
          shouldUpdate={shouldUpdate}
          toggleShouldUpdate={toggleShouldUpdate}
          chatBadge={chatBadge}
          loading={loadingInfluencers.done}
        />
      );
    } else if (selectedBar === "detail") {
      return <NewDealDetail deal={deal} detailWidth={850} />;
    } else if (selectedBar === "recommend") {
      return <InfluencerRecommend deal={deal} />;
    } else if (selectedBar === "edit") {
      return <EditDealDetails deal={deal} back={() => navigate(-1)} />;
    } else {
      return <></>;
    }
  };

  return (
    <Container>
      <NewDealCardHeader
        name={name}
        photos={photos.map((photo) => {
          return { url: photo };
        })}
        category={category}
        influencersTier={influencersTier}
        gender={gender}
        age={age}
        attribute={attribute}
      />
      {!isViewDetail && (
        <BarNavigator
          bars={[pendingBar(), ongoingBar(), workBar(), doneBar()]}
        />
      )}
      <CampaignComponent />
    </Container>
  );
}

export default observer(CampaignManagementBoard);

const Container = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 20px 36px 36px 36px;
`;

const NotificationContainer = styled.div`
  margin-left: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const BarRowContainer = styled(RowContainer)`
  width: 180px;
  overflow: visible;
  position: relative;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const ActiveBar = styled.div`
  position: absolute;
  bottom: 0;
  height: 4px;
  background: ${COLOR.Blue_700};
  border-radius: 2px;
`;

const Notification = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${COLOR.PINK};
  border-radius: 50px;
  margin-left: 2px;
  margin-bottom: 12px;
`;

const BoldBu2Text = styled(TEXT.Bu2_Bold)`
  white-space: nowrap;
`;
