import { observer } from "mobx-react";
import { SetStateAction, useState } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { PostMedia, scopeOfWorkType } from "../../../../class/ScopeOfWork";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import { COLOR } from "../../../../fonts/color";
import { PlatformType } from "../../../../types/deal";
import {
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import ANDButton from "../../../atoms/ANDButton";
import IGScopeOfWorkSelector from "./IGScopeOfWorkSelector";
import LMScopeOfWorkSelector from "./LMScopeOfWorkSelector";
import TKScopeOfWorkSelector from "./TKScopeOfWorkSelector";
import { PlatformModalType } from "../../CreateCampaign/AddPlatformModal";
import FBScopeOfWorkSelector from "./FBScopeOfWorkSelector";

interface Props {
  platform: PlatformType;
  postMedias: Array<{
    duration?: number;
    image: number;
    id: string;
    postType: scopeOfWorkType;
    video: number;
    article: number;
  }>;
  setPostMedias: (postMedia: PostMedia) => void;
  deletePostMedias: (id: string) => void;
}

const scopeOfWorkDetail: Record<
  scopeOfWorkType,
  { text: string; icon: IconName }
> = {
  IG_individual_post: {
    text: "Single Post",
    icon: "Image",
  },
  IG_album_post: {
    text: "Multi Post",
    icon: "Multi",
  },
  IG_story: {
    text: "Story",
    icon: "StoryCircle",
  },
  IG_tv: {
    text: "",
    icon: "Alert",
  },
  IG_reels: {
    text: "Reels",
    icon: "Reels",
  },
  TK_video: {
    text: "TikTok Video",
    icon: "Tiktok",
  },
  LM_article: {
    text: "Lemon Article",
    icon: "Article",
  },
  FB_individual_post: {
    text: "Single Post",
    icon: "Image",
  },
  FB_album_post: {
    text: "Multi Post",
    icon: "Multi",
  },
  FB_reels: {
    text: "Reels",
    icon: "Reels",
  },
};

function PostSelector(props: Props) {
  const { postMedias, setPostMedias, deletePostMedias, platform } = props;

  const [addActive, setAddActive] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const postDetail = (media: PostMedia) => {
    let text: string = "";
    if (media.postType) text = scopeOfWorkDetail[media.postType].text;
    if (media.image > 0 || media.video > 0) {
      text += " ( ";
      if (media.image > 0) {
        text += media.image + " รูปภาพ";
      }
      if (media.video > 0) {
        if (media.image > 0) text += " ";
        if (!!media.duration && media.duration !== 0) {
          const minute = Math.floor(media.duration / 60);
          const second = media.duration % 60;
          if (minute > 0) {
            text += `${minute} นาที `;
          }
          if (second > 0) {
            text += `${second} วินาที`;
          }
        } else text += `${media.video} วีดิโอ`;
      }
      text += " )";
    }
    return text;
  };

  const DeleteMediaComponent = ({ id }: { id: string }) => {
    if (id === deleteId) {
      return (
        <DeleteContainer onClick={() => deletePostMedias(id)}>
          <Body3Text style={{ marginBottom: 0 }}>ลบ</Body3Text>
        </DeleteContainer>
      );
    }
    return <></>;
  };

  const AddMediaComponent = () => {
    if (addActive) {
      switch (platform) {
        case "INSTAGRAM":
          return (
            <IGScopeOfWorkSelector
              setIgPostMedias={(postMedia: PostMedia) => {
                setPostMedias(postMedia);
              }}
              setInactive={() => setAddActive(false)}
            />
          );
        case "FACEBOOK":
          return (
            <FBScopeOfWorkSelector
              setFbPostMedias={(postMedia: PostMedia) => {
                setPostMedias(postMedia);
              }}
              setInactive={() => setAddActive(false)}
            />
          );
        case "TIKTOK":
          return (
            <TKScopeOfWorkSelector
              setAddActive={setAddActive}
              setTkPostMedias={(postMedia: PostMedia) => {
                setPostMedias(postMedia);
              }}
            />
          );
        case "LEMON":
          return (
            <LMScopeOfWorkSelector
              setAddActive={setAddActive}
              setLmPostMedias={(postMedia: PostMedia) => {
                setPostMedias(postMedia);
              }}
            />
          );
      }
    }
    return <></>;
  };

  const MediaDetail = ({ media }: { media: PostMedia }) => {
    return (
      <MediaContainer>
        <GapRowContainer style={{ marginBottom: 0 }}>
          <RXIcon
            name={scopeOfWorkDetail[media.postType].icon}
            color={ANDCOLOR.BLUE}
          ></RXIcon>
          <BoldButtonText>{postDetail(media)}</BoldButtonText>
        </GapRowContainer>
        <IconContainer
          onClick={() => {
            deletePostMedias(media.id);
          }}
        >
          <RXIcon name="Close" color={ANDCOLOR.BLUE} size={20} />
        </IconContainer>
        <DeleteMediaComponent id={media.id} />
      </MediaContainer>
    );
  };

  const PostMediaComponent = () => {
    const components: JSX.Element[] = [];
    postMedias.map((media) => {
      components.push(<MediaDetail media={media} />);
    });
    return <MediasContainer>{components}</MediasContainer>;
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>รูปแบบโพสต์</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <SpaceBetweenRowContainer style={{ width: 568 }}>
          <ContentContainer>
            <GapRowContainer>
              <Body3Text>
                เลือกประเภทชิ้นงานที่ต้องการ (ได้มากกว่า 1 ประเภท)
              </Body3Text>
            </GapRowContainer>
          </ContentContainer>
          <AddContainer>
            <ANDButton
              title="เพิ่ม"
              icon="Plus"
              iconLocation="lefticon"
              onPress={() => setAddActive(!addActive)}
            />
            <AddMediaComponent />
          </AddContainer>
        </SpaceBetweenRowContainer>
        <PostMediaComponent />
      </ContentContainer>
    </Container>
  );
}

export default observer(PostSelector);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
  margin-top: 44px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const AddContainer = styled.div`
  position: relative;
`;

const MediasContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 568px;
  margin-top: 16px;
  gap: 8px;
`;

const MediaContainer = styled.div`
  position: relative;
  display: flex;
  width: 278px;
  padding: 10px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${ANDCOLOR.BLUE};
  background: ${ANDCOLOR.LIGHTBLUE};
`;

const IconContainer = styled.div`
  rotate: 90deg;
  width: 20px;
  height: 20px;
`;

const DeleteContainer = styled.div`
  position: absolute;
  display: flex;
  padding: 8px 12px;
  width: 88px;
  border-radius: 16px;
  justify-content: center;
  border: 1px solid ${COLOR.Gray_400};
  background-color: ${ANDCOLOR.WHITE};
  right: 0px;
  top: 30px;
  :hover {
    cursor: pointer;
  }
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const BoldButtonText = styled(ANDTEXT.buttonSmallBOLD)`
  color: ${ANDCOLOR.BLUE};
  white-space: nowrap;
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
