import styled from "styled-components";
import StepPicture from "../../../assets/images/Banner/AnotherDot.svg";
import { COLOR } from "../../../fonts/color";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";

const Banner = () => {
  const { width } = useWindowDimensions();

  const navigateToDestination = () => {
    window.open("https://anotherdot.co/");
  };

  const bannerWidth = width - 312 < 1128 ? 1128 : width - 312;
  const bannerHeight = bannerWidth / 5.64;

  return (
    <Container>
      <StepPicture
        width={bannerWidth}
        height={bannerHeight}
        onClick={navigateToDestination}
      />
      {/* <ImageContainer src={StepPicture} onClick={navigateToDestination} /> */}
      <CarouselIndicator />
    </Container>
  );
};

export default Banner;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }
`;

const CarouselIndicator = styled.div`
  height: 6px;
  width: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${COLOR.Gray_300};
  border-radius: 50px;
`;
