import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { RateCard } from "../../../types/api/getInfluencer";
import { PlatformType } from "../../../types/deal";
import { calculatePercentage, toDecimalNumber } from "../../../utils";
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import { CategoryType } from "../../organisms/CreateCampaign/Options";
import InfluencerCardCategory from "./InfluencerCardCategory";

interface GetRateCardRangeProps {
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
  platform: PlatformType;
}

type RateCardPlatformType = "instagram" | "tiktok" | "lemon" | "facebook";

const getRateCardRange = (props: GetRateCardRangeProps) => {
  const { rateCard, platform } = props;

  const rateCardPlatform = platform.toLowerCase() as RateCardPlatformType;

  if (!rateCard || rateCard?.[rateCardPlatform]?.length === 0)
    return "ไม่มีข้อมูล Ratecard";

  let minRate = Infinity;
  let maxRate = -Infinity;

  Object.values(rateCard).forEach((platformRates) => {
    platformRates.forEach(({ rate }) => {
      if (rate < minRate) {
        minRate = rate;
      }
      if (rate > maxRate) {
        maxRate = rate;
      }
    });
  });
  if (minRate === maxRate) return `${toDecimalNumber({ number: minRate })} ฿`;
  return `${toDecimalNumber({ number: minRate })} - ${toDecimalNumber({
    number: maxRate,
  })} ฿`;
};

interface OnClickLemonProps {
  lemonUrl: string;
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
}

const onClickLemon = (props: OnClickLemonProps) => {
  const { e, lemonUrl } = props;
  e.preventDefault();
  e.stopPropagation();
  if (lemonUrl?.startsWith("https://")) window.open(lemonUrl);
  else window.open("https://" + lemonUrl);
};

interface RateCardComponentProps {
  haveRateCard: boolean;
  rateCard: string;
}

const RateCardComponent = (props: RateCardComponentProps) => {
  const { rateCard, haveRateCard } = props;
  if (!haveRateCard)
    return (
      <FullWidthRowContainer>
        <Gray300B5Text>ราคาต่อโพสต์</Gray300B5Text>
        <WhiteH9Text>{rateCard}</WhiteH9Text>
      </FullWidthRowContainer>
    );
  return (
    <FullWidthRowContainer>
      <Gray300B5Text>ราคาต่อโพสต์</Gray300B5Text>
      <WhiteBoldH9Text>{rateCard}</WhiteBoldH9Text>
    </FullWidthRowContainer>
  );
};

interface BodyComponentProps {
  followers: number;
  influencerEngagement: number;
  platform: PlatformType;
  attributes: string[];
  activeAttributes: string[];
  category: string[];
  activeCategory?: CategoryType;
  lemonUrl?: string;
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
}

const InfluencerCardBody = (props: BodyComponentProps) => {
  const {
    followers,
    influencerEngagement,
    platform,
    lemonUrl,
    rateCard,
    category,
    activeCategory,
  } = props;

  const followersValue = toDecimalNumber({ number: followers });
  const influencerEngagementValue = toDecimalNumber({
    number: influencerEngagement,
    isZeroDash: false,
  });
  const engagementPercentage = calculatePercentage(
    influencerEngagement,
    followers
  );
  const rateCardRange = getRateCardRange({ rateCard, platform });
  const haveRateCard = rateCardRange !== "ไม่มีข้อมูล Ratecard";

  if (platform !== "LEMON")
    return (
      <BodyContainer>
        <FullWidthRowContainer>
          <HalfColumnContainer>
            <WhiteBoldH7Text>{followersValue}</WhiteBoldH7Text>
            <Gray200B4Text>Followers</Gray200B4Text>
          </HalfColumnContainer>
          <HalfColumnContainer>
            <GapRowContainer>
              <WhiteBoldH7Text>{influencerEngagementValue}</WhiteBoldH7Text>
              <WhiteBoldH9Text>{`(${engagementPercentage}%)`}</WhiteBoldH9Text>
            </GapRowContainer>
            <Gray200B4Text>Avg.Engagement</Gray200B4Text>
          </HalfColumnContainer>
        </FullWidthRowContainer>
        <Dashline />
        <FullWidthColumnContainer>
          <RateCardComponent
            haveRateCard={haveRateCard}
            rateCard={rateCardRange}
          />
          {/* <InfluencerCardAttributes
            activeAttributes={activeAttributes}
            attributes={attributes}
          /> */}
          <InfluencerCardCategory
            activeCategory={activeCategory}
            category={category}
          />
        </FullWidthColumnContainer>
      </BodyContainer>
    );
  else if (!!lemonUrl)
    return (
      <LemonBodyContainer>
        <LemonContainer onClick={(e) => onClickLemon({ e, lemonUrl })}>
          <GrayDT2Text>{lemonUrl}</GrayDT2Text>
          <svg
            //TODO: Add icon to RXIcon
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M6.66666 4.00008H4C3.26362 4.00008 2.66666 4.59703 2.66666 5.33341V12.0001C2.66666 12.7365 3.26362 13.3334 4 13.3334H10.6667C11.4031 13.3334 12 12.7365 12 12.0001V9.33341M9.33333 2.66675H13.3333M13.3333 2.66675V6.66675M13.3333 2.66675L6.66666 9.33341"
              stroke="#010101"
              stroke-width="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </LemonContainer>
        <FullWidthRowContainer>
          <Gray300B5Text>ราคาต่อโพสต์</Gray300B5Text>
          <WhiteBoldH9Text>ไม่มีข้อมูล Ratecard</WhiteBoldH9Text>
        </FullWidthRowContainer>
      </LemonBodyContainer>
    );
  return <></>;
};

export default InfluencerCardBody;

const BodyContainer = styled(ColumnContainer)`
  margin: 0px 12px 12px 12px;
  padding: 16px;
  border-radius: 12px;
  background: rgba(1, 1, 1, 0.5);
  backdrop-filter: blur(18px);
  z-index: 3;
  gap: 12px;
`;

const LemonBodyContainer = styled(ColumnContainer)`
  margin: 0px 12px 12px 12px;
  padding: 16px;
  min-width: 249px;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(1, 1, 1, 0.5);
  backdrop-filter: blur(18px);
  z-index: 3;
  gap: 12px;
`;

const HalfColumnContainer = styled(ColumnContainer)`
  width: 50%;
  gap: 2px;
`;

const FullWidthColumnContainer = styled(ColumnContainer)`
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 4px;
`;

const LemonContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${COLOR.White};
  :hover {
    cursor: pointer;
  }
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  position: relative;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 0.6px solid ${COLOR.Gray_M};
`;

const WhiteBoldH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;

const WhiteBoldH9Text = styled(TEXT.H9_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const Gray200B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_200};
`;

const Gray300B5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_300};
`;

const GrayDT2Text = styled(TEXT.T2_Reg)`
  color: ${COLOR.Gray_D};
  width: 177px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
