import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";
import { DiscoverPage } from "../../pages/DiscoverPage";
import { ColumnContainer, RowContainer } from "../../utils/styling/general";
import RLoading from "./RLoading";
import ViewImageOverlay from "./ViewImageOverlay";

interface FlatListWork {
  id: string;
  image: string;
  type: string;
  video: string;
  weight: string | number;
  workId: string;
  like_count: number;
  comments_count: number;
  postType: string;
}

interface Props {
  data: FlatListWork[][];
  loading: boolean;
  navigate: NavigateFunction;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
}

function Flatlist(props: Props) {
  const { data, loading, navigate, setPage } = props;

  if (data.length === 0) {
    return <></>;
  }

  const renderIcon = (type: string) => {
    switch (type) {
      case "IG_reels":
        return (
          <IconContainer>
            <RXIcon name="Reels" color={COLOR.WHITE} size={32} />
          </IconContainer>
        );
      case "IG_album_post":
        return (
          <IconContainer>
            <RXIcon name="Album" color={COLOR.WHITE} size={32} />
          </IconContainer>
        );
      case "TK_video":
        return (
          <IconContainer>
            <RXIcon name="Tiktok" color={COLOR.WHITE} size={32} />
          </IconContainer>
        );
      case "TK_story":
        return (
          <IconContainer>
            <RXIcon name="TiktokStories" color={COLOR.WHITE} size={32} />
          </IconContainer>
        );
      default:
        return <></>;
    }
  };

  const children = (work: FlatListWork) => {
    return (
      <ViewOverlay>
        <RXIcon name="HeartFill" color={COLOR.WHITE} />
        <WhiteButtonText style={{ marginRight: 12 }}>
          {work.like_count || 0}
        </WhiteButtonText>
        <RXIcon name="Comment" color={COLOR.WHITE} />
        <WhiteButtonText>{work.comments_count || 0}</WhiteButtonText>
      </ViewOverlay>
    );
  };

  return (
    <Container>
      {data.map((row) => {
        if (row.length === 0) return;
        return (
          <RowContainers>
            {row.map((column) => {
              return (
                <ColumnContainers>
                  <Image
                    onClick={() => {
                      setPage("Work");
                    }}
                  >
                    {renderIcon(column.postType)}
                    <ImageContainer src={column.image} />
                    <ViewImageOverlay
                      onClick={() => {
                        setPage("Work");
                        navigate(`/discover?page=work&workId=${column.workId}`);
                      }}
                      width={250}
                      height={250}
                      borderRadius={0}
                      children={children(column)}
                    />
                  </Image>
                </ColumnContainers>
              );
            })}
          </RowContainers>
        );
      })}
      {loading && <RLoading containerStyle={{ alignSelf: "center" }} />}
    </Container>
  );
}

export default Flatlist;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 999;
`;

const RowContainers = styled(RowContainer)`
  align-items: flex-start;
`;

const ColumnContainers = styled(ColumnContainer)`
  align-items: flex-start;
`;

const ImageContainer = styled.img`
  position: relative;
  width: 250px;
  height: 250px;
  background: ${COLOR.Gray_300};
  object-fit: cover;
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.div`
  position: relative;
  margin: 16px;
`;

const ViewOverlay = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${COLOR.BLACK};
  border-radius: 24px;
  border: none;
  cursor: pointer;
`;

const WhiteButtonText = styled(TEXT.buttonBOLD)`
  margin-left: 5px;
  color: ${COLOR.WHITE};
`;
