import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { useAuthStore } from "../../../../stores/initialize/Context";
import { clearArray } from "../../../../utils";
import RButton from "../../../atoms/RButton";
import RInput from "../../../atoms/RInput";

interface Props {
  navigate: NavigateFunction;
  setPage: Dispatch<SetStateAction<number>>;
}

function LoginComponent(props: Props) {
  const { navigate, setPage } = props;

  const {
    setAuthLogin,
    login,
    authLoginError,
    isLoginActive,
    loadIsLoggedIn,
    authLoading,
    setAuthLoading,
  } = useAuthStore();

  const emailError =
    authLoginError.includes("ไม่พบอีเมลในระบบ") ||
    authLoginError.includes(
      "กรุณายืนยันอีเมลก่อนเข้าสู่ระบบ หากไม่ได้รับอีเมลกรุณาติดต่อ @picklethailand"
    );

  const errorMessage = authLoginError.includes("ไม่พบอีเมลในระบบ")
    ? "ไม่พบอีเมลในระบบ โปรดตรวจสอบอีกครั้ง"
    : authLoginError[0];

  const passwordError = authLoginError.includes("กรอกรหัสผ่านไม่ถูกต้อง");

  useEffect(() => {
    clearArray(authLoginError);
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        LoginPress();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const LoginPress = async () => {
    if (authLoading || !isLoginActive) return;
    setAuthLoading(true);
    const { isRegistered, loggedIn } = await login();
    setAuthLoading(false);
    if (loggedIn) {
      setPage(0);
      if (!isRegistered) {
        navigate("/createprofile", { state: "fromLogin" });
      } else {
        navigate("/");
      }
    }
    if (isRegistered) {
    }
  };

  return (
    <Container>
      <TopContainer>
        <BoldH4Text>เข้าสู่ระบบสำหรับแบรนด์</BoldH4Text>
        <RInput
          icon="Email"
          label="อีเมลสำหรับเข้าใช้งาน"
          type="text"
          state={emailError ? "error" : "normal"}
          containerStyle={{
            marginTop: "16px",
          }}
          inputProp={{
            onChange: (e) =>
              setAuthLogin({ value: e.target.value, property: "email" }),
            placeholder: "youremail@email.com",
          }}
          message={emailError ? errorMessage : " "}
        />
        <BoxContainer>
          <RInput
            icon="Lock"
            label="รหัสผ่าน"
            type="password"
            state={passwordError ? "error" : "normal"}
            inputProp={{
              onChange: (e) =>
                setAuthLogin({ value: e.target.value, property: "password" }),
              placeholder: "password",
            }}
            password={true}
            message={passwordError ? "รหัสผ่านไม่ถูกต้อง" : " "}
          />
        </BoxContainer>
        <ShadowTextConatiner onClick={() => setPage(3)}>
          <GrayMBu2Text>ลืมรหัสผ่าน?</GrayMBu2Text>
        </ShadowTextConatiner>
        <ButtonContainer>
          <RButton
            title="เข้าสู่ระบบ"
            onPress={LoginPress}
            active={!authLoading && isLoginActive}
          />
        </ButtonContainer>
      </TopContainer>
      <BottomContainer>
        <B2Text>
          ยังไม่มีบัญชีใช่มั้ย?{" "}
          <LinkContainer onClick={() => setPage(2)}>
            <BottomLinkText>สร้างบัญชีใหม่</BottomLinkText>
          </LinkContainer>
        </B2Text>
      </BottomContainer>
    </Container>
  );
}

export default observer(LoginComponent);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 567px;
  height: 664px;
  padding: 50px 88px;
  justify-content: space-between;
`;

const TopContainer = styled.div``;

const BottomContainer = styled.div``;

const BoxContainer = styled.div`
  margin-top: 14px;
`;

const ShadowTextConatiner = styled.a`
  color: ${COLOR.Gray_300};
  text-decoration: none;
  width: 90px;
  margin-top: 14px;
`;

const GrayMBu2Text = styled(TEXT.Bu2_Reg)`
  color: ${COLOR.Gray_M};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: row;
  justify-content: flex-end;
  margin-top: 44px;
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const B2Text = styled(TEXT.B2_Reg)`
  padding-top: 23px;
`;

const LinkContainer = styled.a`
  color: ${COLOR.BLACK};
  text-decoration: none;
`;

const BottomLinkText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    color: ${COLOR.Blue_800};
  }
`;
