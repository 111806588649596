import { useEffect, useState } from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { ColumnContainer } from "../../../utils/styling/general";
import RModal from "../../atoms/RModal";
import { ActiveProps } from "../../../types";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TEXT } from "../../../fonts/text";

interface Props {
  imageList: string[];
  resetImage: () => void;
  startIndex: number;
}

type AnimationTimeType =
  | "easeOut"
  | "linear"
  | "easeInQuad"
  | "easeOutQuad"
  | "easeInOutQuad"
  | "easeInCubic"
  | "easeOutCubic"
  | "easeInOutCubic"
  | "easeInQuart"
  | "easeOutQuart"
  | "easeInOutQuart"
  | "easeInQuint"
  | "easeOutQuint"
  | "easeInOutQuint"
  | undefined;

type ResetFunctionType = (
  animationTime?: number | undefined,
  animationType?: AnimationTimeType
) => void;

function ProfileFullScreenModal(props: Props) {
  const { imageList, resetImage, startIndex } = props;

  const [index, setIndex] = useState<number>(0);
  const [resetTransformFunction, setResetTransfromFunction] =
    useState<ResetFunctionType>();

  const ZoomControls = ({
    zoomIn,
    zoomOut,
    resetTransform,
  }: {
    zoomIn: (
      step?: number | undefined,
      animationTime?: number | undefined,
      animationType?: AnimationTimeType
    ) => void;
    zoomOut: (
      step?: number | undefined,
      animationTime?: number | undefined,
      animationType?: AnimationTimeType
    ) => void;
    resetTransform: ResetFunctionType;
  }) => {
    setResetTransfromFunction(resetTransform);
    return (
      <>
        <ZoomButtonsContainer onClick={() => zoomIn()}>
          <RXIcon name="Plus" />
        </ZoomButtonsContainer>
        <ZoomButtonsContainer onClick={() => zoomOut()} style={{ left: 560 }}>
          <RXIcon name="Minus" />
        </ZoomButtonsContainer>
      </>
    );
  };

  useEffect(() => {
    setIndex(startIndex);
  }, [startIndex]);

  const renderContent = () => {
    if (imageList.length === 0) {
      return <></>;
    } else {
      return (
        <ContentContainer>
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <ZoomControls
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  resetTransform={resetTransform}
                />
                <TransformComponent>
                  <ImageContainer src={imageList[index]} />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </ContentContainer>
      );
    }
  };

  const changeImage = (direction: 1 | -1) => {
    if (resetTransformFunction) {
      resetTransformFunction();
    }
    if (direction === 1) {
      if (index + 1 > imageList.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      if (index - 1 < 0) {
        setIndex(imageList.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  return (
    <RModal active={imageList.length !== 0} setActive={resetImage}>
      <ColumnContainer style={{ position: "relative", alignItems: "center" }}>
        <CloseButtonsContainer onClick={resetImage}>
          <RXIcon name="Close" size={32} color={COLOR.WHITE} />
        </CloseButtonsContainer>
        <ImagesContainer>
          {index !== 0 ? (
            <SideButtonsContainer active={true} onClick={() => changeImage(-1)}>
              <RXIcon name="ArrowShortLeft" size={32} />
            </SideButtonsContainer>
          ) : (
            <EmptyButtonsContainer active={true} onClick={resetImage} />
          )}
          {renderContent()}
          {index !== imageList.length - 1 ? (
            <SideButtonsContainer active={false} onClick={() => changeImage(1)}>
              <RXIcon name="ArrowShortRight" size={32} />
            </SideButtonsContainer>
          ) : (
            <EmptyButtonsContainer active={false} onClick={resetImage} />
          )}
        </ImagesContainer>
        <TextContainer>
          <B3Text>
            {index + 1} of {imageList.length}
          </B3Text>
        </TextContainer>
      </ColumnContainer>
    </RModal>
  );
}

export default ProfileFullScreenModal;

const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 1032px;
  height: 600px;
  border-radius: 16px;
`;

const ImageContainer = styled.img`
  width: 1034px;
  height: 600px;
  background: ${COLOR.BLACK};
  border-radius: 16px;
  object-fit: contain;
`;

const SideButtonsContainer = styled.div<ActiveProps>`
  position: absolute;
  left: ${(props) => (props.active ? "-90px" : "auto")};
  right: ${(props) => (props.active ? "auto" : "-90px")};
  display: flex;
  width: 58px;
  height: 58px;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }
`;

const ZoomButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: -56px;
  left: 440px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
  border-radius: 50%;
  z-index: 999;

  :hover {
    cursor: pointer;
  }
`;
const EmptyButtonsContainer = styled.div<ActiveProps>`
  position: absolute;
  left: ${(props) => (props.active ? "-90px" : "auto")};
  right: ${(props) => (props.active ? "auto" : "-90px")};
  display: flex;
  width: 58px;
  height: 58px;
  justify-content: center;
  align-items: center;
`;

const CloseButtonsContainer = styled.div`
  position: absolute;
  top: -67px;
  right: -84px;
  display: flex;
  width: 58px;
  height: 58px;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  :hover {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 4px;
  margin-top: 24px;
  height: 32px;
  background: ${COLOR.WHITE};
  border-radius: 16px;
`;

const B3Text = styled(TEXT.B3_Reg)``;
