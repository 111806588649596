import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { PostMedia, ScopeOfWork } from "../../../class/ScopeOfWork";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { PostTypes } from "../../../types/work";
import { RowContainer } from "../../../utils/styling/general";
import { ImageProp } from "../../organisms/CreateCampaign/ImageSelector";
import ImageList from "../ImageList";
import PostMediaList from "./PostMediaList";

interface Props {
  platforms?: ("Instagram" | "Tiktok" | "Lemon" | "Facebook")[];
  posts: PostTypes & {
    exampleDetail: string;
    examples: string[] | ImageProp[];
    postMedias: PostMedia[];
    tiktokExamples?: string[] | ImageProp[];
    facebookExamples?: string[] | ImageProp[];
  };
  hashtags: string[];
  tiktokHashtags: string[];
  facebookHashtags: string[];
  caption: string;
  tiktokCaptionGuide: string;
  facebookCaptionGuide: string;
  onEdit?: () => void;
}

function DealCardScopeOfWork(props: Props) {
  const {
    platforms = [],
    posts,
    hashtags,
    tiktokHashtags,
    caption,
    tiktokCaptionGuide,
    facebookCaptionGuide,
    facebookHashtags,
    onEdit,
  } = props;

  const { examples, tiktokExamples, facebookExamples } = posts;
  let scopeOfWork = new ScopeOfWork(posts.postMedias);

  const InstagramComponent = () => {
    const components: JSX.Element[] = [];
    if (!!onEdit || platforms.includes("Instagram")) {
      components.push(
        <>
          <RowContainer>
            <BoldBody1Text>ช่องทาง</BoldBody1Text>
            <IGContainer>
              <RXIcon name="Instagram" size={21} color={COLOR.WHITE} />
              <WhiteBoldBody1Text>Instagram</WhiteBoldBody1Text>
            </IGContainer>
          </RowContainer>
          <PostMediaList platform={"INSTAGRAM"} scopeOfWork={scopeOfWork} />
        </>
      );
      if (hashtags?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "8px" }}>
              <BoldBody1Text>#Hashtag</BoldBody1Text>
            </RowContainer>
            <RowContainer
              style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}
            >
              {hashtags.map((hashtag, index) => {
                return (
                  <HashtagContainer key={hashtag + index}>
                    <BlueBody1Text>#{hashtag}</BlueBody1Text>
                  </HashtagContainer>
                );
              })}
            </RowContainer>
          </>
        );
      }
      if (examples?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px" }}>
              <BoldBody1Text>ตัวอย่างภาพ</BoldBody1Text>
            </RowContainer>
            <ImageList
              images={
                posts.examples?.map((example) => {
                  if (typeof example === "string") {
                    return { url: example };
                  } else {
                    return { url: example.url };
                  }
                }) || []
              }
            />
          </>
        );
      }

      if (!!caption) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "4px" }}>
              <BoldBody1Text>รายละเอียดงานเพิ่มเติม</BoldBody1Text>
            </RowContainer>
            <Body1Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {caption}
            </Body1Text>
          </>
        );
      }
      return <>{components}</>;
    } else {
      return <></>;
    }
  };

  const TiktokComponent = () => {
    const components: JSX.Element[] = [];
    if (!!onEdit || platforms.includes("Tiktok")) {
      components.push(
        <>
          <RowContainer style={{ marginTop: "16px" }}>
            <BoldBody1Text>ช่องทาง</BoldBody1Text>
            <TKContainer>
              <RXIcon name="Tiktok" size={21} color={COLOR.WHITE} />
              <WhiteBoldBody1Text>TikTok</WhiteBoldBody1Text>
            </TKContainer>
          </RowContainer>
          <PostMediaList platform={"TIKTOK"} scopeOfWork={scopeOfWork} />
        </>
      );
      if (tiktokHashtags?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "8px" }}>
              <BoldBody1Text>#Hashtag</BoldBody1Text>
            </RowContainer>
            <RowContainer
              style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}
            >
              {tiktokHashtags.map((hashtag, index) => {
                return (
                  <HashtagContainer key={hashtag + index}>
                    <BlueBody1Text>#{hashtag}</BlueBody1Text>
                  </HashtagContainer>
                );
              })}
            </RowContainer>
          </>
        );
      }
      if (tiktokExamples?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px" }}>
              <BoldBody1Text>ตัวอย่างภาพ</BoldBody1Text>
            </RowContainer>
            <ImageList
              images={
                posts.tiktokExamples?.map((example) => {
                  if (typeof example === "string") {
                    return { url: example };
                  } else {
                    return { url: example.url };
                  }
                }) || []
              }
            />
          </>
        );
      }
      if (!!tiktokCaptionGuide) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "4px" }}>
              <BoldBody1Text>รายละเอียดงานเพิ่มเติม</BoldBody1Text>
            </RowContainer>
            <Body1Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {tiktokCaptionGuide}
            </Body1Text>
          </>
        );
      }
      return <>{components}</>;
    } else {
      return <></>;
    }
  };

  const FacebookComponent = () => {
    const components: JSX.Element[] = [];
    if (!!onEdit || platforms.includes("Facebook")) {
      components.push(
        <>
          <RowContainer style={{ marginTop: "16px" }}>
            <BoldBody1Text>ช่องทาง</BoldBody1Text>
            <FBContainer>
              <RXIcon name="FacebookCircle" size={21} color={COLOR.WHITE} />
              <WhiteBoldBody1Text>Facebook</WhiteBoldBody1Text>
            </FBContainer>
          </RowContainer>
          <PostMediaList platform={"FACEBOOK"} scopeOfWork={scopeOfWork} />
        </>
      );
      if (facebookHashtags?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "8px" }}>
              <BoldBody1Text>#Hashtag</BoldBody1Text>
            </RowContainer>
            <RowContainer
              style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}
            >
              {facebookHashtags.map((hashtag, index) => {
                return (
                  <HashtagContainer key={hashtag + index}>
                    <BlueBody1Text>#{hashtag}</BlueBody1Text>
                  </HashtagContainer>
                );
              })}
            </RowContainer>
          </>
        );
      }
      if (facebookExamples?.length !== 0) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px" }}>
              <BoldBody1Text>ตัวอย่างภาพ</BoldBody1Text>
            </RowContainer>
            <ImageList
              images={
                posts.facebookExamples?.map((example) => {
                  if (typeof example === "string") {
                    return { url: example };
                  } else {
                    return { url: example.url };
                  }
                }) || []
              }
            />
          </>
        );
      }
      if (!!facebookCaptionGuide) {
        components.push(
          <>
            <RowContainer style={{ marginTop: "16px", marginBottom: "4px" }}>
              <BoldBody1Text>รายละเอียดงานเพิ่มเติม</BoldBody1Text>
            </RowContainer>
            <Body1Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {facebookCaptionGuide}
            </Body1Text>
          </>
        );
      }
      return <>{components}</>;
    } else {
      return <></>;
    }
  };

  const LemonComponent = () => {
    if (!!onEdit || platforms.includes("Lemon")) {
      return (
        <>
          <RowContainer style={{ marginTop: "16px" }}>
            <BoldBody1Text>ช่องทาง</BoldBody1Text>
            <LMContainer>
              <ImageContainer
                src={require("../../../assets/images/PlatformLogos/Lemon.png")}
              />
              <BoldBody1Text style={{ marginLeft: 4 }}>Lemon8</BoldBody1Text>
            </LMContainer>
          </RowContainer>
          <PostMediaList platform={"LEMON"} scopeOfWork={scopeOfWork} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const EditComponent = () => {
    if (!!onEdit) {
      return (
        <EditContainer onClick={onEdit}>
          <RXIcon name="Edit" color={COLOR.DARKGREEN2} size={20} />
          <Green2BoldBody1Text style={{ marginLeft: "4px" }}>
            แก้ไข
          </Green2BoldBody1Text>
        </EditContainer>
      );
    }
    return <></>;
  };
  return (
    <Container>
      <HeaderContainer>
        <BoldButtonText>Scope of Work</BoldButtonText>
        <EditComponent />
      </HeaderContainer>
      <InstagramComponent />
      <TiktokComponent />
      <FacebookComponent />
      <LemonComponent />
    </Container>
  );
}

export default DealCardScopeOfWork;

const Container = styled.div`
  margin-top: 24px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${COLOR.Gray_300};
  margin-bottom: 12px;
`;

const IGContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
  border-radius: 16px;
`;

const TKContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.BLACK};
  border-radius: 16px;
`;

const FBContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.Blue_700};
  border-radius: 16px;
`;

const LMContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.LEMON};
  border-radius: 16px;
`;

const HashtagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 6px 8px;
  border: 1px solid ${COLOR.LIGHTBLUE};
  border-radius: 16px;
`;

const ImageContainer = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  background: ${COLOR.LEMON};
`;

const BoldButtonText = styled(TEXT.buttonBOLD)``;

const Body1Text = styled(TEXT.body1)``;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const WhiteBoldBody1Text = styled(BoldBody1Text)`
  color: ${COLOR.WHITE};
  margin-left: 4px;
`;

const BlueBody1Text = styled(Body1Text)`
  color: ${COLOR.LIGHTBLUE};
`;

const Green2BoldBody1Text = styled(BoldBody1Text)`
  color: ${COLOR.DARKGREEN2};
`;
