import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { RowContainer } from "../../../../utils/styling/general";
import ANDImageSelector from "../../../organisms/CreateCampaign/ANDImageSelector";
import { ImageProp } from "../../../organisms/CreateCampaign/ImageSelector";
import { TEXT } from "../../../../fonts/text";

interface Props {
  examples: ImageProp[];
  setExamples: (examples: ImageProp[]) => void;
}

const Example = (props: Props) => {
  const { examples, setExamples } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ตัวอย่างรูป</BoldHeader7Text>
        <BoldHeader7Text style={{ marginTop: 8 }}>
          {examples.length + " / 4"}
        </BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <Body3Text>
            สไตล์รูปหรือวิดีโอที่ชอบ เพื่อให้ Influencer ทำงาน{" "}
            <UnderlineBody3Text>ตรงบรีฟ</UnderlineBody3Text> มากขึ้น
          </Body3Text>
        </GapRowContainer>
        <GapRowContainer>
          <ANDImageSelector
            images={examples}
            smallImage={[1, 2, 3, 4]}
            onSelect={(photoArray: ImageProp[]) => {
              setExamples(photoArray);
            }}
          />
        </GapRowContainer>
      </ContentContainer>
    </Container>
  );
};

export default Example;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
  display: flex;
  flex-direction: column;
`;

const BoldHeader7Text = styled(TEXT.H7_Bold)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(TEXT.B3_Bold)`
  text-decoration: underline;
`;
