import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { useAuthStore } from "../../../../stores/initialize/Context";
import RegisterCard from "../../../organisms/Register/RegisterCard";
import RegisterCodeSent from "../../../organisms/Register/VerifiedCard";

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
}

function RegisterComponent(props: Props) {
  const { setPage } = props;

  const {
    authSignUp,
    setAuthSignUp,
    register,
    authSignUpError,
    validateTeamEmail,
    validatePassword,
    validateRePassword,
  } = useAuthStore();

  const [registerPage, setRegisterPage] = useState<number>(1);

  const renderComponent = () => {
    switch (registerPage) {
      case 1:
        return (
          <RegisterCard
            changePage={() => setRegisterPage(2)}
            authSignUp={authSignUp}
            setAuthSignUp={setAuthSignUp}
            register={register}
            authSignUpError={authSignUpError}
            validateTeamEmail={validateTeamEmail}
            validatePassword={validatePassword}
            validateRePassword={validateRePassword}
          />
        );
      case 2:
        return (
          <RegisterCodeSent
            onDone={() => setPage(1)}
            email={authSignUp.teamEmail}
          />
        );
    }
  };

  return <Container>{renderComponent()}</Container>;
}

export default RegisterComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 567px;
  height: 664px;
`;
