import { useEffect, useState } from "react";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { clearArray } from "../../../utils";
import RButton from "../../atoms/RButton";
import RInput from "../../atoms/RInput";

interface Props {
  changePage: () => void;
  setEmail: ({ value }: { value: string }) => void;
  sendForgetPasswordEmail: () => Promise<void>;
  emailError: string[];
  isEmailSendActive: boolean;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LargeText = styled(TEXT.H4_Bold)``;

const ButtonContainer = styled.div`
  margin-top: 35px;
  align-self: flex-end;
`;

function ForgotPasswordCardDetail(props: Props) {
  const {
    changePage,
    setEmail,
    sendForgetPasswordEmail,
    emailError,
    isEmailSendActive,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const EmailPress = async () => {
    try {
      setLoading(true);
      await sendForgetPasswordEmail();
      setLoading(false);
      if (emailError.length === 0) {
        changePage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const message = emailError[0];

  const renderErrorMessage = (message: string) => {
    if (
      message === "You made a request earlier, please wait and try again later."
    ) {
      return "คุณได้ส่งคำขอเมื่อไม่นานมานี้ อีเมลจะใช้เวลาในการส่ง 3-5 นาที";
    } else {
      return "ไม่พบอีเมลนี้ในระบบ หรืออีเมลไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง";
    }
  };

  useEffect(() => {
    clearArray(emailError);
  }, []);

  return (
    <Container>
      <LargeText>ลืมรหัสผ่าน</LargeText>
      <RInput
        label="อีเมลสำหรับเข้าใช้งาน"
        type="text"
        state={message ? "error" : "normal"}
        icon="Email"
        inputProp={{
          onChange: (e) => setEmail({ value: e.target.value }),
          placeholder: "youremail@email.com",
        }}
        containerStyle={{ marginTop: 18 }}
        message={
          message
            ? renderErrorMessage(message)
            : "โปรดกรอกอีเมลที่ใช้ลงทะเบียน เราจะส่งโค้ดถึงคุณเพื่อรีเซ็ตรหัสผ่านใหม่"
        }
      />
      <ButtonContainer>
        <RButton
          title="ยืนยัน"
          onPress={EmailPress}
          active={!loading && isEmailSendActive}
          textStyle={{ padding: "0px 24px" }}
        />
      </ButtonContainer>
    </Container>
  );
}

export default ForgotPasswordCardDetail;
