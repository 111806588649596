import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ANDCOLOR } from "../../../fonts/andColor";
import { ANDTEXT } from "../../../fonts/andText";
import PlatformSelector from "../../molecules/NewCreateCampaign/TargetInfluencer/PlatformSelector";
import InfluencerType from "../../molecules/NewCreateCampaign/TargetInfluencer/InfluencerLevel";
import InfluencerGender from "../../molecules/NewCreateCampaign/TargetInfluencer/InfluencerGender";
import InfluencerAge from "../../molecules/NewCreateCampaign/TargetInfluencer/InfluencerAge";
import LocationSelector from "../../molecules/NewCreateCampaign/TargetInfluencer/LocationSelector";
import AttributeSelector from "../../molecules/NewCreateCampaign/TargetInfluencer/AttributeSelector";
import ReviewTag from "../../molecules/NewCreateCampaign/TargetInfluencer/ReviewTag";
import { Deal, PlatformType } from "../../../types/deal";
import {
  ITierType,
  dealKeys,
  genderType,
  newDealKeys,
} from "../../../types/createDeal";
import { IGenderOptions } from "../../atoms/RGenderSelector";
import ScrollToTop from "../../atoms/ScrollToTop";

interface Props {
  deal: newDealKeys;
  setDealData: (deal: newDealKeys) => void;
  tierRef: React.RefObject<HTMLDivElement>;
}

function TargetInfluencer(props: Props) {
  const { deal, setDealData, tierRef } = props;

  const {
    platforms,
    influencersTier,
    criteria,
    location,
    reviewTag,
    attribute,
  } = deal;

  const { location: audienceLocation } = criteria;

  const [selectedGender, setSelectedGender] = useState<IGenderOptions[]>([
    {
      isSelected: deal.gender.includes("W") || false,
      value: "W",
    },
    {
      isSelected: deal.gender.includes("M") || false,
      value: "M",
    },
    {
      isSelected: deal.gender.includes("L") || false,
      value: "L",
    },
  ]);
  const [selectedAudience, setSelectedAudience] = useState<IGenderOptions[]>([
    {
      isSelected: deal.criteria.gender.includes("W") || false,
      value: "W",
    },
    {
      isSelected: deal.criteria.gender.includes("M") || false,
      value: "M",
    },
    {
      isSelected: deal.criteria.gender.includes("L") || false,
      value: "L",
    },
  ]);
  const [influencerAge, setInfluencerAge] = useState<number[]>(
    deal.age.split("-").map((x) => parseInt(x)) || [13, 65]
  );
  const [audienceAge, setAudienceAge] = useState<number[]>(
    deal.criteria.ages.split("-").map((x) => parseInt(x)) || [13, 65]
  );

  const adjustGenderData = (gender: IGenderOptions[]) => {
    let gen: string[] = [];
    gender.forEach((t) => {
      if (t.isSelected) {
        gen.push(t.value);
      }
    });
    return gen.join(",");
  };

  const setDealPlatform = (platform: PlatformType) => {
    deal.posts.postMedias = [];
    setDealData({ ...deal, platforms: platform });
  };

  const setDealInfluLevel = (level: ITierType) => {
    setDealData({ ...deal, influencersTier: level });
  };

  // I from Influencer
  const setDealIGender = (gender: IGenderOptions[]) => {
    setDealData({ ...deal, gender: adjustGenderData(gender) });
  };

  // A from Audience
  const setDealAGender = (gender: IGenderOptions[]) => {
    setDealData({
      ...deal,
      criteria: { ...deal.criteria, gender: adjustGenderData(gender) },
    });
  };

  const setDealIAge = (age: number[]) => {
    setDealData({ ...deal, age: `${age[0]}-${age[1]}` });
  };

  const setDealAAge = (age: number[]) => {
    setDealData({
      ...deal,
      criteria: { ...deal.criteria, ages: `${age[0]}-${age[1]}` },
    });
  };

  const setDealILocation = (location: string) => {
    setDealData({ ...deal, location });
  };

  const setDealALocation = (location: string) => {
    setDealData({ ...deal, criteria: { ...deal.criteria, location } });
  };

  const setDealTag = (tag: string[]) => {
    setDealData({ ...deal, reviewTag: tag });
  };

  const setDealAttribute = (attribute: string[]) => {
    setDealData({ ...deal, attribute });
  };

  return (
    <Container>
      <BoldHeader7Text>Create Campaign</BoldHeader7Text>
      <BoldHeader4Text>Influencer Criteria</BoldHeader4Text>
      <Body2Text>ระบุรายละเอียดของ Influencer ที่คุณต้องการ</Body2Text>
      <ContentContainer>
        <PlatformSelector platform={platforms} setPlatform={setDealPlatform} />
        <TierRefContainer ref={tierRef} />
        <InfluencerType
          influencersTier={influencersTier}
          setinfluencersTier={setDealInfluLevel}
        />
        <InfluencerGender
          selectedGender={selectedGender}
          setSelectedGender={setDealIGender}
          selectedAudience={selectedAudience}
          setSelectedAudience={setDealAGender}
        />
        <InfluencerAge
          influencerAge={influencerAge}
          setInfluencerAge={setDealIAge}
          audienceAge={audienceAge}
          setAudienceAge={setDealAAge}
        />
        <LocationSelector
          influencerLocation={location}
          audienceLocation={audienceLocation}
          setInfluencerLocation={setDealILocation}
          setAudienceLocation={setDealALocation}
        />
        {/* Remove it for now. Waiting for revamp */}
        {/* <AttributeSelector
          attribute={attribute}
          setAttribute={setDealAttribute}
        /> */}
        {/* <ReviewTag selectedTag={reviewTag} setSelectedTag={setDealTag} /> */}
      </ContentContainer>
    </Container>
  );
}

export default TargetInfluencer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  min-width: 688px;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 688px;
  gap: 64px;
`;

const TierRefContainer = styled.div`
  position: absolute;
  top: 330px;
  height: 100px;
  width: 100%;
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const BoldHeader4Text = styled(ANDTEXT.header4BOLD)`
  margin-top: 20px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  margin-top: 12px;
`;
