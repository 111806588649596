import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ANDCOLOR } from "../../../fonts/andColor";
import { SpaceBetweenRowContainer } from "../../../utils/styling/general";
import ANDButton from "../../atoms/ANDButton";
import { pageIndex } from "../../../pages/NewCreateCampaign/NewCreateCampaignPage";
import { NavigateFunction } from "react-router-dom";
import { observer } from "mobx-react";

interface Props {
  page: pageIndex;
  setPage: React.Dispatch<React.SetStateAction<pageIndex>>;
  saveDraft: () => void;
  navigate: NavigateFunction;
  createDeal: () => Promise<void>;
  indexSuccess: number[];
}

const CampaignFooter = (props: Props) => {
  const { page, indexSuccess, setPage, saveDraft, navigate, createDeal } =
    props;

  const NextButtonComponent = () => {
    if (page !== 4) {
      const nextPage = (page + 1) as pageIndex;
      return (
        <ANDButton
          active={indexSuccess.includes(page - 1)}
          title="ถัดไป"
          onPress={() => setPage(nextPage)}
          containerStyle={{
            marginLeft: 8,
            padding: "20px 32px",
          }}
        />
      );
    }
    const buttonActive =
      indexSuccess.includes(0) &&
      indexSuccess.includes(1) &&
      indexSuccess.includes(2);
    return (
      <ANDButton
        active={buttonActive}
        title="ยืนยัน"
        onPress={() => createDeal()}
        containerStyle={{
          marginLeft: 8,
          padding: "20px 32px",
        }}
      />
    );
  };

  return (
    <Container>
      <GapRowContainer>
        <ANDButton
          title="ดูตัวอย่าง"
          icon="EyeOn"
          textColor={ANDCOLOR.BLUE}
          onPress={() => setPage(4)}
          containerStyle={{
            backgroundColor: ANDCOLOR.WHITE,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: ANDCOLOR.BLUE,
            padding: "20px 32px",
          }}
        />
        <ANDButton
          title=""
          icon="Disk"
          textColor={ANDCOLOR.BLUE}
          iconSize={24}
          onPress={() => saveDraft()}
          containerStyle={{
            backgroundColor: ANDCOLOR.WHITE,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: ANDCOLOR.BLUE,
            width: 40,
            minWidth: 40,
            height: 40,
            paddingLeft: 8,
            marginLeft: 24,
          }}
        />
      </GapRowContainer>
      <GapRowContainer>
        <ANDButton
          title="ยกเลิก"
          textColor={ANDCOLOR.BLACK}
          onPress={() => navigate("/")}
          containerStyle={{
            padding: "20px 32px",
            backgroundColor: ANDCOLOR.WHITE,
          }}
        />
        <NextButtonComponent />
      </GapRowContainer>
    </Container>
  );
};

export default observer(CampaignFooter);

const Container = styled(SpaceBetweenRowContainer)`
  display: flex;
  position: fixed;
  bottom: 0;
  width: calc(100% - 240px);
  padding: 12px 136px;
  align-items: center;
  z-index: 999;
  gap: 24px;
  background: ${ANDCOLOR.WHITE};
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
`;

const GapRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8;
`;
