import { PieChart } from "@mui/x-charts/PieChart";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { useInfluencerStore } from "../../../stores/initialize/Context";
import { ActiveProps } from "../../../types";
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer,
} from "../../../types/api/getInfluencer";
import { IAgeGenderDataObject } from "../../../types/api/listInfluencers";
import { PlatformType } from "../../../types/deal";
import { toDecimalNumber } from "../../../utils";
import useComponentVisible from "../../../utils/hook/UseComponentVisible";
import {
  ColumnContainer,
  FlexEndColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import REmptyState from "../REmptyState";

const pieSizing = {
  width: 160,
  height: 120,
  legend: { hidden: true },
};

interface HeightProps {
  height: number;
}

interface GetAgeRangeProps {}

const getAgeRangeText = () => {};

interface HoverProps {
  isComponentVisible: boolean;
  age: string;
  man: number;
  women: number;
}

const HoverComponent = (props: HoverProps) => {
  const { isComponentVisible, age, man, women } = props;
  return (
    <HoverContainer active={isComponentVisible}>
      <BoldH8Text>{age}</BoldH8Text>
      <FullWidthRowContainer>
        <GapRowContainer>
          <BlueCircle />
          <GrayDB4Text>เพศชาย</GrayDB4Text>
        </GapRowContainer>
        <BoldBlue700B4Text>{`${toDecimalNumber({
          number: man,
        })} คน`}</BoldBlue700B4Text>
      </FullWidthRowContainer>
      <FullWidthRowContainer>
        <GapRowContainer>
          <PinkCircle />
          <GrayDB4Text>เพศหญิง</GrayDB4Text>
        </GapRowContainer>
        <BoldRaspberryB4Text>{`${toDecimalNumber({
          number: women,
        })} คน`}</BoldRaspberryB4Text>
      </FullWidthRowContainer>
    </HoverContainer>
  );
};
interface BarProps {
  max: number;
  man: number;
  women: number;
  // other: number;
  age: string;
  percent: number;
}

const Bar = (props: BarProps) => {
  const { max, man, women, age, percent } = props;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const MAX_BAR_HEIGHT = 110;
  const blueBarHeight = (man / max) * MAX_BAR_HEIGHT;
  const pinkBarHeight = (women / max) * MAX_BAR_HEIGHT;
  // const blackBarHeight = (other / max) * MAX_BAR_HEIGHT;
  return (
    <BarContainer
      ref={ref}
      onMouseEnter={() => {
        setIsComponentVisible(true);
      }}
      onMouseLeave={() => {
        setIsComponentVisible(false);
      }}
    >
      <BarsContainer>
        {/* <GradientBar height={blueBarHeight} /> */}
        <BlueBar height={blueBarHeight} />
        <PinkBar height={pinkBarHeight} />
        {/* <BlackBar height={blackBarHeight} /> */}
      </BarsContainer>
      <ColumnContainer>
        <GrayDB4Text>{age}</GrayDB4Text>
        <GrayMB5Text>{toDecimalNumber({ number: man + women })}</GrayMB5Text>
        <GrayMB5Text>{"(" + percent.toFixed(1) + "%)"}</GrayMB5Text>
      </ColumnContainer>
      <HoverComponent
        age={age}
        man={man}
        women={women}
        isComponentVisible={isComponentVisible}
      />
    </BarContainer>
  );
};

interface GetAgeGenderProps {
  instagram: IInstagramInfluencer;
  facebook: IFacebookInfluencer;
  tiktok: ITiktokInfluencer;
  platformActive: PlatformType;
}

const getAgeGenderData = (props: GetAgeGenderProps) => {
  const { instagram, facebook, tiktok, platformActive } = props;
  switch (platformActive) {
    case "INSTAGRAM":
      return {
        genders: instagram?.demographicGenders || [],
        ages: instagram?.demographicAges || [],
        ageGender: instagram?.demographicAgesGenders || [],
      };
    case "FACEBOOK":
      return {
        genders: facebook?.demographicGenders || [],
        ages: facebook?.demographicAges || [],
        ageGender: facebook?.demographicAgesGenders || [],
      };
    default:
      return { genders: [], ages: [] };
  }
};

interface AgesComponentProps {
  ageGender?: IAgeGenderDataObject[];
}

const AgesComponent = (props: AgesComponentProps): JSX.Element => {
  const { ageGender } = props;

  if (!ageGender || ageGender.length === 0) return <></>;

  const initialMaxData = ageGender.find((ag) => ag.gender !== "U") || {
    value: -Infinity,
  };

  const calculateMaxSum = ageGender.reduce(
    (acc, current) => {
      if (current.gender !== "U") {
        acc.sumData += current.value;
        if (current.value > acc.maxData.value) {
          acc.maxData = current;
        }
      }
      return acc;
    },
    { maxData: initialMaxData, sumData: 0 }
  );

  const maxData = calculateMaxSum.maxData;
  const sumData = calculateMaxSum.sumData;
  const dataByAgeRange = new Map<string, { M: number; F: number; U: number }>();

  ageGender.map(({ age, gender, value }) => {
    const genderType = gender as "M" | "F" | "U";
    if (!dataByAgeRange.has(age)) {
      dataByAgeRange.set(age, { M: 0, F: 0, U: 0 });
    }
    const genderData = dataByAgeRange.get(age);
    if (!!genderData) {
      genderData[genderType] += value;
    }
  });

  const agesTitle: { title: string; range: string[] }[] = [
    { title: "< 18", range: ["13-17"] },
    { title: "18-24", range: ["18-24"] },
    { title: "25-34", range: ["25-34"] },
    { title: "35-44", range: ["35-44"] },
    { title: "> 45", range: ["45-54", "55-64", "65+"] },
  ];

  const components = agesTitle.map(({ title, range }) => {
    let man = 0,
      woman = 0;
    // other = 0;

    range.forEach((ageRange) => {
      const genderData = dataByAgeRange.get(ageRange);
      if (genderData) {
        man += genderData.M;
        woman += genderData.F;
        // other += genderData.U;
      }
    });

    const sum = man + woman;

    return (
      <Bar
        key={title}
        max={maxData.value}
        man={man}
        women={woman}
        // other={other}
        age={title}
        percent={(sum / sumData) * 100}
      />
    );
  });

  return <GapRowContainer>{components}</GapRowContainer>;
};
interface Props {
  platformActive: PlatformType;
  ageGenderData:
    | {
        genders: [string, number][];
        ages: [string, number][];
        ageGender: IAgeGenderDataObject[];
      }
    | {
        genders: never[];
        ages: never[];
        ageGender?: undefined;
      };
}

const AgeGender = (props: Props) => {
  const { ageGenderData } = props;

  const { influencer } = useInfluencerStore();

  if (!influencer) return <></>;

  const { genders, ages, ageGender } = ageGenderData;

  const womanCount = genders.find((gender) => gender[0] === "F")?.[1] || 0;
  const manCount = genders.find((gender) => gender[0] === "M")?.[1] || 0;
  const lgbtCount = genders.find((gender) => gender[0] === "U")?.[1] || 0;

  const totalCount = manCount + womanCount + lgbtCount;

  const manPercentage = (manCount / totalCount) * 100;
  const womanPercentage = (womanCount / totalCount) * 100;
  const lgbtPercentage = (lgbtCount / totalCount) * 100;

  const pieData = [
    {
      value: lgbtCount,
      color: COLOR.Black,
      label: "อื่นๆ",
    },
    {
      value: manCount,
      color: COLOR.Blue_300,
      label: "เพศชาย",
    },
    {
      value: womanCount,
      color: COLOR.Rubicund,
      label: "เพศหญิง",
    },
  ];

  console.log(
    manCount,
    womanCount,
    ageGenderData,
    genders.find((gender) => gender[0] === "F")?.[1]
  );
  if (manCount === 0 && womanCount === 0 && lgbtCount === 0)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require("../../../assets/images/EmptyData.png")}
          />
        }
        header="กำลังรวบรวมข้อมูล"
        containerStyle={{ alignSelf: "center", flex: 1 }}
      />
    );

  return (
    <Container>
      <BoldH6Text>เพศและอายุ</BoldH6Text>
      <PieContainer>
        <PieChartContainer>
          <PieChart
            series={[
              {
                data: pieData,
                innerRadius: 25,
                outerRadius: 50,
                cx: 80,
                cornerRadius: 4,
                paddingAngle: 1,
                valueFormatter: (value) =>
                  value.value === 1 ? "-" : value.value.toString(),
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 20,
                  additionalRadius: -10,
                  color: "gray",
                },
              },
            ]}
            {...pieSizing}
          />
        </PieChartContainer>
        <PieLegendContainer>
          <GenderRowContainer>
            <GapRowContainer>
              <BlueCircle />
              <GrayDB4Text>เพศชาย</GrayDB4Text>
            </GapRowContainer>
            <DetailContainer>
              <ValueContainer>
                <BoldH8Text>{`${toDecimalNumber({
                  number: manCount,
                })}`}</BoldH8Text>
              </ValueContainer>
              <PercentContainer>
                <GrayMB5Text>{`(${manPercentage.toFixed(1)}%)`}</GrayMB5Text>
              </PercentContainer>
            </DetailContainer>
          </GenderRowContainer>
          <GenderRowContainer>
            <GapRowContainer>
              <PinkCircle />
              <GrayDB4Text>เพศหญิง</GrayDB4Text>
            </GapRowContainer>
            <DetailContainer>
              <ValueContainer>
                <BoldH8Text>{`${toDecimalNumber({
                  number: womanCount,
                })}`}</BoldH8Text>
              </ValueContainer>
              <PercentContainer>
                <GrayMB5Text>{`(${womanPercentage.toFixed(1)}%)`}</GrayMB5Text>
              </PercentContainer>
            </DetailContainer>
          </GenderRowContainer>
          <GenderRowContainer>
            <GapRowContainer>
              <BlackCircle />
              <GrayDB4Text>อื่นๆ</GrayDB4Text>
            </GapRowContainer>
            <DetailContainer>
              <ValueContainer>
                <BoldH8Text>{`${toDecimalNumber({
                  number: lgbtCount,
                })}`}</BoldH8Text>
              </ValueContainer>
              <PercentContainer>
                <GrayMB5Text>{`(${lgbtPercentage.toFixed(1)}%)`}</GrayMB5Text>
              </PercentContainer>
            </DetailContainer>
          </GenderRowContainer>
        </PieLegendContainer>
      </PieContainer>
      <AgesComponent ageGender={ageGender} />
    </Container>
  );
};

export default AgeGender;

const Container = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 24px;
`;

const PieContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const PieChartContainer = styled.div`
  padding: 0px 40px;
`;

const PieLegendContainer = styled(FlexStartColumnContainer)`
  gap: 16px;
  padding: 0px 8px;
  width: 190px;
`;

const GenderRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const DetailContainer = styled(RowContainer)`
  gap: 4px;
  width: 50%;
`;

const BarsContainer = styled(RowContainer)`
  align-items: flex-end;
  height: 70%;
  gap: 8px;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const GrayMB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_M};
`;

const GrayDB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;

const BarContainer = styled(ColumnContainer)`
  position: relative;
  border-radius: 8px;
  height: 180px;
  width: 19%;
  gap: 8px;
  :hover {
    background-color: ${COLOR.Gray_50};
  }
  &:hover ${GrayDB4Text} {
    font-weight: 700;
    color: ${COLOR.Black};
  }
  &:hover ${GrayMB5Text} {
    font-weight: 700;
    color: ${COLOR.Gray_D};
  }
`;

const HoverContainer = styled.div<ActiveProps>`
  position: absolute;
  display: ${(props) => (props.active ? "flex" : "none")};
  padding: 12px;
  width: 152px;
  flex-direction: column;
  align-items: flex-start;
  top: 40px;
  left: 90px;
  gap: 8px;
  border-radius: 12px;
  opacity: 0.9;
  background: ${COLOR.White};
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  z-index: 99;
  pointer-events: none;
`;

const ValueContainer = styled(FlexEndColumnContainer)`
  width: 50%;
`;

const PercentContainer = styled(FlexStartColumnContainer)`
  width: 50%;
`;

const GradientBar = styled.div<HeightProps>`
  width: 20px;
  height: ${(prop) => prop.height}px;
  border-radius: 8px;
  background: linear-gradient(180deg, #90b6ff 0%, #f0857d 100%);
`;

const BlueBar = styled.div<HeightProps>`
  width: 20px;
  height: ${(prop) => prop.height}px;
  background-color: ${COLOR.Blue_300};
  border-radius: 8px;
`;

const PinkBar = styled.div<HeightProps>`
  width: 20px;
  height: ${(prop) => prop.height}px;
  background-color: ${COLOR.Rubicund};
  border-radius: 8px;
`;

const BlackBar = styled.div<HeightProps>`
  width: 20px;
  height: ${(prop) => prop.height}px;
  background-color: ${COLOR.Black};
  border-radius: 8px;
`;

const BlueCircle = styled.div`
  background-color: ${COLOR.Blue_300};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const PinkCircle = styled.div`
  background-color: ${COLOR.Rubicund};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BlackCircle = styled.div`
  background-color: ${COLOR.Black};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldH8Text = styled(TEXT.H8_Bold)``;

const BoldBlue700B4Text = styled(TEXT.B4_Bold)`
  color: ${COLOR.Blue_700};
`;

const BoldRaspberryB4Text = styled(TEXT.B4_Bold)`
  color: ${COLOR.Raspberry};
`;
