import { observer } from "mobx-react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { DealsData } from "../../../stores/dealStore";
import { ChannelMap } from "../../../stores/webSocketStore";
import REmptyState from "../../atoms/REmptyState";
import Banner from "../../molecules/Campaign/Banner";
import CampaignCardCreator from "../../molecules/Campaign/CampaignCardCreator";
import CampaignList from "../../molecules/Campaign/CampaignList";
import PendingCampaignList from "../../molecules/Campaign/Ongoing/PendingCampaignList";

interface Props {
  navigate: NavigateFunction;
  deals: {
    pending: DealsData;
    ongoing: DealsData;
    done: DealsData;
  };
  page: number;
  setPage: (page: number) => void;
  initializePending: () => void;
  loading: {
    pending: boolean;
    ongoing: boolean;
    done: boolean;
    deal: boolean;
    reviewing: boolean;
    influWorks: boolean;
  };
  drawerStatus: string;
  activateShareModal: (img: string, title: string, dealId: string) => void;
  isDraftAvailable: boolean;
  setDraftModalActive: React.Dispatch<React.SetStateAction<string>>;
  connectWebsocket: ({ channel }: { channel: string }) => void;
  socket: ChannelMap;
  increment: ({
    dealId,
    status,
    key,
    isIncrease,
  }: {
    dealId: string;
    status: "pending" | "ongoing" | "done";
    key: "pendingSize" | "ongoingSize" | "checkSize" | "doneSize";
    isIncrease: boolean;
  }) => void;
  deleteCampaign: ({ dealId }: { dealId: string }) => Promise<void>;
  dealNotification: ({
    dealId,
    status,
    key,
  }: {
    dealId: string;
    status: "pending" | "ongoing" | "done";
    key: "notiPending" | "notiOngoing" | "notiCheck" | "notiDone";
  }) => void;
}

function CampaignCard(props: Props) {
  const {
    navigate,
    page,
    deals,
    initializePending,
    loading,
    drawerStatus,
    activateShareModal,
    isDraftAvailable,
    setDraftModalActive,
    connectWebsocket,
    socket,
    increment,
    deleteCampaign,
    dealNotification,
  } = props;

  const renderPage = () => {
    switch (page) {
      case 1:
        return (
          <ListContainer>
            <Banner />
            <HeaderContainer>
              <BoldH5Text>แคมเปญที่กำลังเผยแพร่</BoldH5Text>
            </HeaderContainer>
            <CampaignList
              navigate={navigate}
              deals={deals["ongoing"].data}
              status="ongoing"
              nextDeals={deals["ongoing"].next}
              metadata={deals["ongoing"].metadata}
              drawerStatus={drawerStatus}
              activateShareModal={activateShareModal}
              isLoading={loading.ongoing}
              connectWebsocket={connectWebsocket}
              socket={socket}
              increment={increment}
              dealNotification={dealNotification}
              emptyStateHeader="ไม่มีข้อมูล"
              emptyStateDescription={[
                "คุณยังไม่สร้างแคมเปญ หรือ",
                "ยังไม่มีแคมเปญที่กำลังเผยแพร่อยู่ในตอนนี้",
              ]}
            />
          </ListContainer>
        );
      case 2:
        return (
          <>
            <HeaderContainer>
              <BoldH5Text>แคมเปญที่ยังไม่เผยแพร่</BoldH5Text>
            </HeaderContainer>
            <PendingCampaignList
              navigate={navigate}
              deals={deals["pending"]?.data}
              nextDeals={deals["pending"].next}
              metadata={deals["pending"].metadata}
              initialize={initializePending}
              drawerStatus={drawerStatus}
              deleteCampaign={deleteCampaign}
              emptyStateHeader="ไม่พบข้อมูล"
              emptyStateDescription={[
                "ไม่พบข้อมูลแคมเปญที่ยังไม่เผยแพร่",
                "สร้างแคมเปญเพื่อโปรโมทสินค้าของคุณตอนนี้เลย",
              ]}
            />
          </>
        );
      case 3:
        return (
          <>
            <HeaderContainer>
              <BoldH5Text>แคมเปญที่เสร็จสิ้นแล้ว</BoldH5Text>
            </HeaderContainer>
            <CampaignList
              navigate={navigate}
              deals={deals["done"].data}
              status="done"
              nextDeals={deals["done"].next}
              metadata={deals["done"].metadata}
              drawerStatus={drawerStatus}
              activateShareModal={activateShareModal}
              isLoading={loading.done}
              connectWebsocket={connectWebsocket}
              socket={socket}
              increment={increment}
              dealNotification={dealNotification}
              emptyStateHeader="ไม่พบข้อมูล"
              emptyStateDescription={[
                "ไม่พบข้อมูลแคมเปญที่เสร็จสิ้นแล้ว",
                "สร้างแคมเปญเพื่อโปรโมทสินค้าของคุณตอนนี้เลย",
              ]}
            />
          </>
        );
    }
  };

  return <Container>{renderPage()}</Container>;
}

export default observer(CampaignCard);

const Container = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  height: 64px;
  padding: 0px 32px;
  margin-bottom: 24px;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;
