import styled from "styled-components";
import Lightning from "../../../assets/images/3DIcon/3DLightning.png";
import { TEXT } from "../../../fonts/text";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";

interface Props {
  active: boolean;
}
const OfflineModal = (props: Props) => {
  const { active } = props;
  return (
    <RModal active={active} setActive={() => {}}>
      <Container>
        <ImageContainer src={Lightning} />
        <BoldHeader5Text>ไม่พบสัญญาณอินเทอร์เน็ต</BoldHeader5Text>
        <Body1Text>โปรดตรวจสอบการเชื่อมต่อเครือข่าย</Body1Text>
        <Body1Text>ข้อมูลจะแสดงขึ้นเมื่อได้รับ</Body1Text>
        <Body1Text>การเชื่อมต่ออีกครั้ง</Body1Text>
        <RButton
          title="เข้าใจแล้ว"
          onPress={() => window.location.reload()}
          containerStyle={{ marginTop: 28 }}
        />
      </Container>
    </RModal>
  );
};

export default OfflineModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 32px 0px;
  width: 394px;
  background: #ffffff;
  border-radius: 24px;
`;

const ImageContainer = styled.img`
  width: 144px;
  height: 144px;
`;

const Body1Text = styled(TEXT.body1)``;

const BoldHeader5Text = styled(TEXT.header5BOLD)`
  margin: 16px 0px 8px 0px;
`;
