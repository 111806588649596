import { CSSProperties } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { TEXT } from "../../fonts/text";

interface Props {
  value: string | number;
  description: string;
  style?: CSSProperties;
  iconName?: IconName;
}

function RInfoDescription(props: Props) {
  const { value, description, style, iconName } = props;
  return (
    <Container>
      {iconName && <RXIcon name={iconName} size={20} />}
      <InformationContainer style={style}>
        <BoldH7Text>{value}</BoldH7Text>
        <B2Text>{description}</B2Text>
      </InformationContainer>
    </Container>
  );
}

export default RInfoDescription;

const Container = styled.div`
  display: flex;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const B2Text = styled(TEXT.B2_Reg)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;
