import React from "react";
import styled from "styled-components";
import { ANDCOLOR } from "../../../fonts/andColor";
import { ANDTEXT } from "../../../fonts/andText";
import { newDealKeys } from "../../../types/createDeal";
import CampaignImage from "../../molecules/NewCreateCampaign/Detail/CampaignImage";
import CampaignName from "../../molecules/NewCreateCampaign/Detail/CampaignName";
import CampaignDetail from "../../molecules/NewCreateCampaign/Detail/CampaignDetail";
import Category from "../../molecules/NewCreateCampaign/Detail/Category";
import Public from "../../molecules/NewCreateCampaign/Detail/Public";
import PinnedCampaign from "../../molecules/NewCreateCampaign/Detail/PinnedCampaign";
import Timeline from "../../molecules/NewCreateCampaign/Detail/Timeline";
import { ImageProp } from "../CreateCampaign/ImageSelector";
import { adsDuration } from "../CreateCampaign/Options";
import ScrollToTop from "../../atoms/ScrollToTop";
import { COLOR } from "../../../fonts/color";

interface Props {
  deal: newDealKeys;
  setDealData: (deal: newDealKeys) => void;
}

export type dealDateType =
  | "startDate"
  | "endDate"
  | "postingDate"
  | "submissionDate";

function Detail(props: Props) {
  const { deal, setDealData } = props;

  const {
    photos,
    name,
    detail,
    category,
    secret,
    ads,
    startDate,
    endDate,
    postingDate,
    submissionDate,
  } = deal;

  const setDealPhotos = (photos: ImageProp[]) => {
    setDealData({ ...deal, photos });
  };

  const setDealName = (name: string) => {
    setDealData({ ...deal, name });
  };

  const setDealDetail = (detail: string) => {
    setDealData({ ...deal, detail });
  };

  const setDealCategory = (category: string) => {
    setDealData({ ...deal, category });
  };

  const setDealIsSecret = (secret: boolean) => {
    setDealData({ ...deal, secret });
  };

  const setDealAds = (ads: {
    value: adsDuration;
    startDate: string | null;
    endDate: string | null;
  }) => {
    setDealData({ ...deal, ads });
  };

  const setDealDate = (date: string, type: dealDateType) => {
    setDealData({ ...deal, [type]: date });
  };
  return (
    <Container>
      <BoldHeader7Text>Create Campaign</BoldHeader7Text>
      <BoldHeader4Text>Campaign Details</BoldHeader4Text>
      <Body2Text>
        ใส่รายละเอียดเพื่อดึงดูดให้ Influencer อยากร่วมแคมเปญของคุณ
      </Body2Text>
      <ContentContainer>
        <CampaignImage photos={photos} setPhotos={setDealPhotos} />
        <CampaignName name={name} setName={setDealName} />
        <CampaignDetail detail={detail} setDetail={setDealDetail} />
        <Category category={category} setCategory={setDealCategory} />
        <Public isSecret={secret} setIsSecret={setDealIsSecret} />
        {/* <DashedLine />
        <PinnedCampaign ads={ads} setAds={setDealAds} /> */}
        <Timeline
          startDate={startDate}
          endDate={endDate}
          postingDate={postingDate}
          submissionDate={submissionDate}
          setDealDate={setDealDate}
        />
      </ContentContainer>
    </Container>
  );
}

export default Detail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 688px;
  padding-bottom: 72px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 688px;
  gap: 64px;
`;

const DashedLine = styled.div`
  width: 688px;
  height: 1px;
  border-top: 1px solid ${COLOR.Gray_300};
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const BoldHeader4Text = styled(ANDTEXT.header4BOLD)`
  margin-top: 20px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  margin-top: 12px;
`;
