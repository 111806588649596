import styled from "styled-components";
import SurveyCardDetail from "../../molecules/Survey/SurveyCardDetail";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 512px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  padding: 40px 88px 0px 88px;
  border-radius: 24px;
  margin-top: 176px;
`;

function SurveyCard() {
  return (
    <Container>
      <SurveyCardDetail />
    </Container>
  );
}

export default SurveyCard;
