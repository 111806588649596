import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import { COLOR } from "../../../../fonts/color";
import {
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import { dealDateType } from "../../../organisms/NewCreateCampaign/Detail";

interface Props {
  startDate: string | null;
  endDate: string | null;
  postingDate: string | null;
  submissionDate: string | null;
  setDealDate: (date: string, type: dealDateType) => void;
}

function Timeline(props: Props) {
  const { startDate, endDate, postingDate, submissionDate, setDealDate } =
    props;
  const [openCalendar, setOpenCalendar] = useState<number>(0);

  const switchCalendar = (calendarNum: number, disabled: boolean) => {
    if (disabled) {
      return;
    } else {
      setOpenCalendar(calendarNum);
    }
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>กำหนดเวลา</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <BoldHeader8Text>กำหนดรับสมัคร</BoldHeader8Text>
        </GapRowContainer>
        <GrayBody3Text>
          ระยะเวลาของแคมเปญในการเปิดปิดรับสมัคร Influencer
        </GrayBody3Text>
        <SpaceBetweenRowContainer>
          <Body3Text>
            วันที่เปิดรับสมัคร Influencer<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={openCalendar === 1}
              onOpen={() => switchCalendar(1, false)}
              onClose={() => switchCalendar(0, false)}
              value={startDate}
              onChange={(newValue) => {
                setDealDate(
                  new Date(newValue || "").toISOString(),
                  "startDate"
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  onClick={() => switchCalendar(1, false)}
                  sx={{
                    "& .MuiInputBase-input": {
                      "&:hover": {
                        cursor: "pointer",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        border: "0.5px solid gray",
                      },
                    },
                  }}
                />
              )}
              inputFormat="DD/MM/YYYY"
              minDate={new Date().toISOString()}
              maxDate={endDate}
              // Customize by looking at class name through the browser
              InputProps={{
                sx: {
                  width: "180px",
                  height: "49px",
                  marginTop: "8px",
                  borderRadius: "24px",
                },
              }}
              disableHighlightToday
            />
          </LocalizationProvider>
        </SpaceBetweenRowContainer>
        <SpaceBetweenRowContainer style={{ marginTop: 36 }}>
          <Body3Text>
            วันที่ปิดรับสมัคร Influencer<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={openCalendar === 2}
              onOpen={() => switchCalendar(2, false)}
              onClose={() => switchCalendar(0, false)}
              value={endDate}
              onChange={(newValue) => {
                setDealDate(new Date(newValue || "").toISOString(), "endDate");
              }}
              minDate={startDate || new Date().toISOString()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  onClick={() => switchCalendar(2, false)}
                  sx={{
                    "& .MuiInputBase-input": {
                      "&:hover": {
                        cursor: "pointer",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        border: "0.5px solid gray",
                      },
                    },
                  }}
                />
              )}
              inputFormat="DD/MM/YYYY"
              InputProps={{
                sx: {
                  width: "180px",
                  height: "49px",
                  marginTop: "8px",
                  borderRadius: "24px",
                },
              }}
              disableHighlightToday
            />
          </LocalizationProvider>
        </SpaceBetweenRowContainer>
        <DashedLine />
        <GapRowContainer>
          <BoldHeader8Text>กำหนดโพสต์งาน</BoldHeader8Text>
        </GapRowContainer>
        <GrayBody3Text>ระยะเวลาที่ต้องการให้ Influencer ส่งงาน</GrayBody3Text>
        <SpaceBetweenRowContainer>
          <Body3Text>
            วันที่เริ่มโพสต์งาน<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={openCalendar === 3}
              onOpen={() => switchCalendar(3, !startDate)}
              onClose={() => switchCalendar(0, !startDate)}
              disabled={!startDate}
              value={postingDate}
              onChange={(newValue) => {
                setDealDate(
                  new Date(newValue || "").toISOString(),
                  "postingDate"
                );
              }}
              minDate={startDate || new Date().toISOString()}
              maxDate={submissionDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  onClick={() => switchCalendar(3, !startDate)}
                  sx={{
                    "& .MuiInputBase-input": {
                      "&:hover": {
                        cursor: "pointer",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        border: "0.5px solid gray",
                      },
                    },
                  }}
                />
              )}
              inputFormat="DD/MM/YYYY"
              InputProps={{
                sx: {
                  width: "180px",
                  height: "49px",
                  marginTop: "8px",
                  borderRadius: "24px",
                },
              }}
              disableHighlightToday
            />
          </LocalizationProvider>
        </SpaceBetweenRowContainer>
        <SpaceBetweenRowContainer style={{ marginTop: 36 }}>
          <Body3Text>
            วันที่โพสต์งานวันสุดท้าย<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={openCalendar === 4}
              onOpen={() => switchCalendar(4, !startDate)}
              onClose={() => switchCalendar(0, !startDate)}
              disabled={!startDate}
              value={submissionDate}
              onChange={(newValue) => {
                setDealDate(
                  new Date(newValue || "").toISOString(),
                  "submissionDate"
                );
              }}
              minDate={postingDate || new Date().toISOString()}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    onClick={() => switchCalendar(4, !startDate)}
                    sx={{
                      "& .MuiInputBase-input": {
                        "&:hover": {
                          cursor: "pointer",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          border: "0.5px solid gray",
                        },
                      },
                    }}
                  />
                );
              }}
              inputFormat="DD/MM/YYYY"
              InputProps={{
                sx: {
                  width: "180px",
                  height: "49px",
                  marginTop: "8px",
                  borderRadius: "24px",
                },
              }}
              disableHighlightToday
            />
          </LocalizationProvider>
        </SpaceBetweenRowContainer>
      </ContentContainer>
    </Container>
  );
}

export default Timeline;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const DashedLine = styled.div`
  width: 568px;
  height: 1;
  border-top: 1px solid ${COLOR.Gray_400};
  margin: 36px 0px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const GrayBody3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
  margin-bottom: 24px;
`;

const Body3Text = styled(ANDTEXT.body3)``;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
