import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

interface Props {
  changePage: () => void;
}

function ForgotPasswordCardFooter(props: Props) {
  const { changePage } = props;
  return (
    <Container>
      <TextContainer>
        คุณส่งคำขอรีเซ็ตรหัสผ่านเมื่อไม่นานมานี้?{" "}
        <LinkText onClick={changePage}>ไปหน้าใส่รหัส</LinkText>
      </TextContainer>
    </Container>
  );
}

export default ForgotPasswordCardFooter;

const Container = styled.div`
  position: relative;
`;

const TextContainer = styled(TEXT.B2_Reg)`
  flex-direction: row;
  padding-top: 23px;
`;

const LinkText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    color: ${COLOR.Blue_800};
  }
`;
