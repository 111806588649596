import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../fonts/color";
import { ActiveProps } from "../../types";
import { ANDCOLOR } from "../../fonts/andColor";

interface Props {
  active: boolean;
  onClick?: () => void;
  containerStyle?: CSSProperties;
  disable?: boolean;
}

function ANDCheckbox(props: Props) {
  const { active, onClick = () => {}, containerStyle, disable = false } = props;
  return (
    <Container
      active={active}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
      style={containerStyle}
    >
      <Checked active={active} />
    </Container>
  );
}

export default ANDCheckbox;

const Container = styled.div<ActiveProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: ${ANDCOLOR.WHITE};
  border: ${(props) =>
    props.active
      ? `1px solid ${ANDCOLOR.BLUE}`
      : `1px solid ${COLOR.Gray_200}`};

  :hover {
    cursor: pointer;
  }
`;

const Checked = styled.div<ActiveProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: ${(props) => (props.active ? ANDCOLOR.BLUE : COLOR.WHITE)};
`;
