import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  bars: Array<JSX.Element>;
}

const Container = styled.div`
  display: flex;
  height: 56px;
  flex-direction: row;
  justify-content: space-evenly;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

function BarNavigator(props: Props) {
  const { bars } = props;

  return <Container>{bars}</Container>;
}

export default BarNavigator;
