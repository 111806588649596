import { RXIcon } from "rn-rx-icons";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../fonts/color";
import { ActiveProps } from "../../types";
import { observer } from "mobx-react";

interface Props {
  active: boolean;
  onClick?: () => void;
  containerStyle?: CSSProperties;
  disable?: boolean;
  activeColor?: string;
}

interface ActiveColorProps extends ActiveProps {
  activeColor: string;
}

const Container = styled.div<ActiveColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: ${(props) => (props.active ? props.activeColor : COLOR.WHITE)};
  border: ${(props) => (props.active ? "none" : `1px solid ${COLOR.Gray_300}`)};
`;

function RCheckbox(props: Props) {
  const {
    active,
    onClick = () => {},
    containerStyle,
    disable = false,
    activeColor = COLOR.Blue_700,
  } = props;
  return (
    <Container
      active={active}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
      style={containerStyle}
      activeColor={activeColor}
    >
      <RXIcon name="Check" color={COLOR.White} size={20} />
    </Container>
  );
}

export default observer(RCheckbox);
