import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { SpaceBetweenRowContainer } from "../../../utils/styling/general";
import AdvanceFilter from "./AdvanceFilter";
import AudienceFilter from "./Audience/AudienceFilter";
import FilterFooter from "./FilterFooter";
import InfluencerFilter from "./Influencer/InfluencerFilter";
import Search from "../Search";
import RModal from "../../../components/atoms/RModal";
import { useInfluencerListFilterContext } from "../InfluencerListContext";

interface Props {
  closeFilter: () => void;
  onSubmit: () => void;
}

function SidebarFilter(props: Props) {
  const { closeFilter, onSubmit } = props;
  return (
    <Container>
      <SpaceBetweenRowContainer>
        <BoldB6Text>ตัวกรอง</BoldB6Text>
        <IconContainer onClick={closeFilter}>
          <RXIcon name="Close" color={COLOR.Gray_D} />
        </IconContainer>
      </SpaceBetweenRowContainer>
      <Search containerWidth={304} />
      <InfluencerFilter />
      <SolidLine />
      <AdvanceFilter />
      <SolidLine />
      <AudienceFilter />
      <FilterFooter onSubmit={onSubmit} />
    </Container>
  );
}

const SidebarFilterModal = ({
  getNewInfluencerList,
}: {
  getNewInfluencerList: () => void;
}) => {
  const { isOpenFilterPanel, toggleFilterPanel, closeFilterPanel } =
    useInfluencerListFilterContext();
  return (
    <RModal active={isOpenFilterPanel} setActive={toggleFilterPanel}>
      <FilterContainer>
        <SidebarFilter
          closeFilter={closeFilterPanel}
          onSubmit={() => {
            getNewInfluencerList();
            closeFilterPanel();
          }}
        />
      </FilterContainer>
    </RModal>
  );
};

export default SidebarFilterModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 352px;
  height: ${window.innerHeight}px;
  background-color: ${COLOR.White};
  z-index: 9;
  padding: 20px 24px;
  padding-bottom: 100px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border: 0.6px solid ${COLOR.Gray_300};
  overflow-y: scroll;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const SolidLine = styled.div`
  width: 352px;
  height: 0.6px;
  background-color: ${COLOR.Gray_300};
  margin: 4px 0px 4px 0px;
`;

const BoldB6Text = styled(TEXT.H6_Bold)``;

const FilterContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
`;
