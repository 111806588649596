import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import logo from "../../../assets/images/AnotherDotLogoWhite.png";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import {
  useAccountStore,
  useAuthStore,
  useNotificationStore,
  useWebSocketStore,
} from "../../../stores/initialize/Context";
import { PLACEHOLDER_PROFILE } from "../../../utils";
import RButton from "../../atoms/RButton";
import HeaderDropdown from "./HeaderDropdown";
import NotificationDropdown from "./NotificationDropdown";

interface HeaderProps {
  isFocus?: boolean;
}

function Header() {
  const { account, accounts, getMyAccount, accountLoading, getAuthToken } =
    useAccountStore();
  const {
    getNotifications,
    notificationsLoading,
    notifications,
    _readNotification,
    readUnreadNotification,
    getUnreadNotis,
    unreadNotis,
    setUnreadNotis,
    readSeenNotification,
  } = useNotificationStore();
  const { logout, logoutAccounts, switchAccount, authLoading, setAuthLoading } =
    useAuthStore();
  const { connectWebsocket, socket } = useWebSocketStore();
  const navigate = useNavigate();
  const [notiActive, setNotiActive] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [focus, setFocus] = useState<string>("default");
  const [reconnect, setReconnect] = useState<boolean>(true);
  const [favHover, setFavHover] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);
  const { brandId } = account;
  const url = window.location.pathname;

  const channelName = brandId ? "brand-" + brandId : "";

  const loginPageUrl = [
    "/createprofile",
    "/login",
    "/register",
    "/forgotpassword",
    "/privacy",
  ];

  useEffect(() => {
    setIsLoginPage(loginPageUrl.includes(url));
    if (!getAuthToken()) {
      if (loginPageUrl.includes(url)) return;
      navigate("/login");
    }
  }, [url]);

  //TODO: Want to change WebSocket Logic
  useEffect(() => {
    getNotifications({ offset: 0, limit: 10 });
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      setReconnect(false);
      socket[channelName]?.close();
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    connectWebsocket({ channel: channelName });
  }, [channelName]);

  useEffect(() => {
    getUnreadNotis();
  }, [notificationsLoading]);

  useEffect(() => {
    const urlSplit = url.split("/");
    const page = urlSplit[urlSplit.length - 1];
    const nonQueryPage = page.includes("?") ? page.split("?")[0] : page;

    if (window.location.href.includes("favorite?influencerId"))
      setFocus("directory");
    else if (nonQueryPage === "directory") setFocus("directory");
    else if (nonQueryPage === "discover" || nonQueryPage === "performance")
      setFocus(nonQueryPage);
    else if (nonQueryPage === "setting") setFocus("");
    else setFocus("default");
  }, [url]);

  useEffect(() => {
    if (socket[channelName]) {
      socket[channelName].onmessage = (event) => {
        if (event.data === "verified") {
          getMyAccount();
        }
        getNotifications({ offset: 0, limit: 10 });
      };
      socket[channelName].onclose = () => {
        if (reconnect) {
          connectWebsocket({ channel: channelName });
          return () => {
            setReconnect(false);
            socket[channelName]?.close();
          };
        }
      };
    }
  }, [socket[channelName]]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const renderButtons = () => {
    if (isLoginPage) {
      return (
        <RButton
          title="ฉันเป็นอินฟลูเอนเซอร์"
          onPress={() => window.open("https://anotherdot.co")}
          backgroundColor="linear-gradient(71deg, #2032FF 27.55%, #648BFF 83.35%)"
        />
      );
    } else {
      const renderCheck = () => {
        if (account.isVerified) {
          return <RXIcon name="Check" color={COLOR.WHITE} size={18} />;
        }
        return (
          <ClockContainer>
            <RXIcon name="ClockFill" color={COLOR.ORANGE} size={18} />
          </ClockContainer>
        );
      };

      const renderBrandLogo = () => {
        if (authLoading || accountLoading) {
          return (
            <ImgContainer>
              <ImgPlaceHolder />
            </ImgContainer>
          );
        }
        return (
          <ImgContainer>
            <Img
              src={account.img || PLACEHOLDER_PROFILE}
              alt={PLACEHOLDER_PROFILE}
            />
            <CheckContainer active={account.isVerified}>
              {renderCheck()}
            </CheckContainer>
          </ImgContainer>
        );
      };

      return (
        <ProfileContainer>
          <IconContainer
            onClick={() => {
              setNotiActive(!notiActive);
              setActive(false);
              setUnreadNotis(0);
              readSeenNotification();
            }}
          >
            {notiActive ? (
              <RXIcon name="BellFill" size={32} color={COLOR.WHITE} />
            ) : (
              <RXIcon name="Bell" size={32} color={COLOR.WHITE} />
            )}
            {unreadNotis !== 0 && (
              <NotificationContainer>
                <WarningB5Text>{unreadNotis}</WarningB5Text>
              </NotificationContainer>
            )}
          </IconContainer>
          <IconContainer
            onMouseOver={() => setFavHover(true)}
            onMouseLeave={() => setFavHover(false)}
            onClick={() => navigate("/favorite")}
          >
            {favHover || focus === "favorite" ? (
              <RXIcon name="SaveFill" size={32} color={COLOR.WHITE} />
            ) : (
              <RXIcon name="Save" size={32} color={COLOR.WHITE} />
            )}
          </IconContainer>
          <SettingContainer
            onClick={() => {
              setActive(!active);
              setNotiActive(false);
            }}
          >
            <ImgContainer>{renderBrandLogo()}</ImgContainer>
            <ArrowContainer>
              <RXIcon name="ArrowShortRight" size={32} color={COLOR.WHITE} />
            </ArrowContainer>
          </SettingContainer>
          {/* <HeaderDropdown
            account={account}
            accounts={accounts}
            switchAccount={switchAccount}
            active={active}
            deactivate={() => {
              setActive(false);
              setNotiActive(false);
            }}
            logout={logout}
            logoutAccounts={logoutAccounts}
            navigate={navigate}
            setAuthLoading={(status: boolean) => setAuthLoading(status)}
          /> */}
          {/* <NotificationDropdown
            active={notiActive}
            notifications={notifications}
            onClose={() => {
              setNotiActive(false);
            }}
            navigate={navigate}
            readUnreadNotification={readUnreadNotification}
            _readNotification={_readNotification}
            notificationsLoading={notificationsLoading}
            getNotifications={getNotifications}
          /> */}
        </ProfileContainer>
      );
    }
  };

  return (
    <HeaderContainer>
      <ContentContainer>
        <ImageContainer to={"/"}>
          <Image src={logo} alt="logo" />
        </ImageContainer>

        <TextContainer>
          {!isLoginPage && (
            <>
              <WhiteBoldBu2Text
                isFocus={focus === "default"}
                onClick={() => navigate("/")}
              >
                Campaign
              </WhiteBoldBu2Text>
              <WhiteBoldBu2Text
                isFocus={focus === "discover"}
                onClick={() => {
                  navigate("/discover?page=influencer");
                }}
              >
                Discover
              </WhiteBoldBu2Text>
              <WhiteBoldBu2Text
                isFocus={focus === "directory"}
                onClick={() => {
                  navigate("/directory");
                }}
              >
                Directory
              </WhiteBoldBu2Text>
              <WhiteBoldBu2Text
                isFocus={focus === "performance"}
                onClick={() => navigate("/performance")}
              >
                Performance
              </WhiteBoldBu2Text>
            </>
          )}
        </TextContainer>
        {renderButtons()}
      </ContentContainer>
    </HeaderContainer>
  );
}

export default observer(Header);

const HeaderContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  min-width: 1280px;
  background-color: ${COLOR.BLACK};
  z-index: 9999;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 96px;
  width: 1220px;
`;

const TextContainer = styled.div`
  display: flex;
  width: 454px;
  flex-direction: row;
  align-items: center;
  margin-right: 310px;
`;

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
`;

const ImageContainer = styled(Link)`
  margin-right: 120px;
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 144px;
  height: 27px;
`;

const SettingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  :hover {
    cursor: pointer;
  }
`;

const ImgContainer = styled.div`
  position: relative;
`;

const ImgPlaceHolder = styled.img`
  width: 47.5px;
  height: 47.5px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;

const Img = styled.img`
  width: 47.5px;
  height: 47.5px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;

const CheckContainer = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: 4px;
  right: 0px;
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  border-radius: 50%;
  background: ${(props) => (props.active ? COLOR.Blue_700 : COLOR.ORANGE)};
  overflow: hidden;
  z-index: 99;
`;

const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
`;

const ArrowContainer = styled.div`
  transform: rotate(90deg);
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const NotificationContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 0px;
  left: 16px;
  width: 24px;
`;

const WhiteBoldBu2Text = styled(TEXT.Bu2_Bold)<HeaderProps>`
  color: ${COLOR.White};
  font-size: 18px;
  margin-right: 60px;
  line-height: 24px;
  border-bottom: ${(props) =>
    props.isFocus ? `1px solid ${COLOR.White}` : `1px solid ${COLOR.BLACK}`};

  :hover {
    cursor: pointer;
    border-bottom: 1px solid ${COLOR.White};
  }
`;

const WarningB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.White};
  background-color: ${COLOR.Warning};
  display: flex;
  align-items: center;
  border-radius: 100px;
  height: 19px;
  padding: 0px 6px;
`;
