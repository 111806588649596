import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { Deal, PlatformWithTotalType } from "../../../types/deal";
import RecommendList from "../../../reusables/InfluencerList/RecommendList";
import ATag from "../../atoms/ATag";
import { genderType, tierType } from "../../../types/createDeal";
import { useEffect, useState } from "react";
import { SortByType, SortOrderType } from "../../../types/sort";
import { useInfluencerStore } from "../../../stores/initialize/Context";
import { CategoryType } from "../CreateCampaign/Options";
import { InfluencerListContextProvider } from "../../../reusables/InfluencerList/InfluencerListContext";
import FreeTrialBanner from "../../../reusables/FreeTrialBanner";
import {
  Feature,
  featureAccessStore,
} from "../../../stores/featureAccessStore";

interface Props {
  deal: Deal;
}

function InfluencerRecommend(props: Props) {
  const { deal } = props;

  const { clearInfluencerList, getInfluencerList } = useInfluencerStore();
  const [sortBy, setSortBy] = useState<SortByType>("default");
  const [sortOrder, setSortOrder] = useState<SortOrderType>("none");
  const [finishInitialize, setFinishInitialize] = useState<boolean>(false);
  const canAccessFeature = featureAccessStore.canAccess(
    Feature.INFLUENCER_DIRECTORY
  );

  const {
    gender,
    influencersTier,
    platforms,
    attribute,
    age,
    criteria,
    location,
    category,
  } = deal;

  const { ages, gender: Agender } = criteria;

  const socials = platforms?.map(
    (platform) => platform.toUpperCase() as PlatformWithTotalType
  ) || ["ALL"];

  const tiers = () => {
    const returnArr: tierType[] = [];
    if (influencersTier.nano.value > 0) returnArr.push("nano");
    if (influencersTier.micro.value > 0) returnArr.push("micro");
    if (influencersTier.mid.value > 0) returnArr.push("mid");
    if (influencersTier.macro.value > 0) returnArr.push("macro");
    return returnArr;
  };

  useEffect(() => {
    clearInfluencerList();
    getInfluencerList({
      filter: {
        username: "",
        gender: gender.split(",") as genderType[],
        tier: tiers(),
        social: socials[0],
        attribute,
        engagement: [0, 10000000],
        follower: [],
        rating: [],
        age: [15, 60],
        location: ["all"],
        audience: {
          age: ages.split("-").map((age) => parseInt(age)),
          gender: Agender.split(",") as genderType[],
          location: [location],
        },
        category: category as CategoryType,
      },
      withUnfiltered: false,
      limit: 12,
      offset: 0,
      sortBy,
      sortOrder,
      sortByMatch: true,
      firstInitialize: true,
    });
  }, []);

  return (
    <Container>
      {canAccessFeature ? null : <FreeTrialBanner />}
      <GapRowContainer>
        <BoldH4Text>Recommend</BoldH4Text>
        <ATag text="AI" />
      </GapRowContainer>
      <B2Text>AnotherDot ช่วยคุณหา Influencer ที่เหมาะสมที่สุด</B2Text>
      <InfluencerListContextProvider>
        <RecommendList
          titleList={[{ title: "แนะนำ", haveTotal: false }]}
          fromDeal={true}
          filterActive={false}
          finishInitialize={finishInitialize}
          setFinishInitialize={setFinishInitialize}
        />
      </InfluencerListContextProvider>
    </Container>
  );
}

export default InfluencerRecommend;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

const GapRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;
