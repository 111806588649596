import {
  IGetInfluencerResponse,
  IInstagramInfluencer,
} from "../../types/api/getInfluencer";
import {
  IListInfluencerResponse,
  IListInfluencersParams,
} from "../../types/api/listInfluencers";
import { objectToQuerystring } from "../../utils";
import { GET, _GET } from "../../utils/Networking";

export const listInfluencers = async ({
  dealId,
  status,
  offset = 0,
  limit = 10,
  sortBy = "createdAt",
  sortOrder = "desc",
  filter = "none",
}: IListInfluencersParams) => {
  const queries = { status, offset, limit, sortBy, sortOrder, filter };

  const qs = objectToQuerystring(queries);

  const { metadata, data } = await _GET<IListInfluencerResponse>(
    `/deals/influencers/${dealId}${qs}`
  );

  return {
    metadata: metadata.length > 0 ? metadata[0] : { count: 0, limit, offset },
    data,
    dealId,
  };
};

// old version -- deprecated with tiktok update
// export const getInfluencer = async ({ dealContactId }) => {
//   const qs = objectToQuerystring({ dealContacts: `["${dealContactId}"]` });
//   const influencer = await _GET<IGetInfluencerResponse>(
//     `/brands/deals/influencers${qs}`,
//   );
//   // return influencers.length > 0 ? influencers[0] : undefined;
//   return influencer;
// };

export const getInfluencer = async ({
  dealContactId,
  id,
}: {
  dealContactId?: string;
  id?: string;
}) => {
  if (id) {
    const qs = objectToQuerystring({ id: `${id}` });
    const influencer = await _GET<IGetInfluencerResponse>(
      `/brands/deals/influencer${qs}`
    );
    return influencer;
  } else {
    const qs = objectToQuerystring({ dealContacts: `["${dealContactId}"]` });
    const influencer = await _GET<IGetInfluencerResponse>(
      `/brands/deals/influencer${qs}`
    );
    return influencer;
  }
};

export const getInfluencerById = async ({ id }: { id: string }) => {
  const influencer = await _GET<IGetInfluencerResponse>(
    `/brands/influencerinfo/${id}`
  );
  return influencer;
};

export const getInfluencerBySocialId = async ({ id }: { id: string }) => {
  const influencer = await _GET<IGetInfluencerResponse>(
    `/brands/influencer/social/${id}`
  );
  return influencer;
};

export const getOfferInfluencer = async ({
  dealId,
  limit,
  offset,
}: {
  dealId: string;
  limit: number;
  offset: number;
}) => {
  const qs = objectToQuerystring({ dealId, limit, offset });
  const { metadata, data, invite } = await GET(
    `/brands/offer/influencers${qs}`
  );
  return {
    metadata: metadata.length > 0 ? metadata[0] : { count: 0, limit, offset },
    data,
    invite: invite && invite.length > 0 ? invite[0] : { invited: 0, limit: 0 },
  };
};

export const getSavedInfluencer = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) => {
  const qs = objectToQuerystring({ limit, offset });
  const { metadata, data, invite } = await GET(
    `/brands/offer/saveInfluencers${qs}`
  );
  return {
    metadata: metadata.length > 0 ? metadata[0] : { count: 0, limit, offset },
    data,
    invite: invite && invite.length > 0 ? invite[0] : { invited: 0, limit: 0 },
  };
};

export const getReviews = async ({
  accountId,
  offset = 0,
  limit = 10,
  sortOrder = "desc",
  filter = 0,
}: {
  accountId: string;
  offset: number;
  limit: number;
  sortOrder: string;
  filter: number;
}) => {
  const queries = { limit, offset, sortOrder, filter };

  const qs = objectToQuerystring(queries);

  const { metadata, data, starCount, ratingLength } = await GET(
    `/influencers/getReviews/${accountId}${qs}`
  );

  return {
    metadata: metadata.length > 0 ? metadata[0] : { count: 0, limit, offset },
    data,
    accountId,
    starCount,
    ratingLength,
  };
};
