import { useState } from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { SpaceBetweenRowContainer } from "../../../utils/styling/general";
import FilterEngagement from "./Influencer/FilterEngagement";
import FilterFollower from "./Influencer/FilterFollower";
import FilterRating from "./Influencer/FilterRating";

interface ActiveFilterProps {
  active: boolean;
}

const ArrowComponent = (props: ActiveFilterProps) => {
  const { active } = props;
  if (active) return <RXIcon name="ArrowDown" />;
  return <RXIcon name="ArrowUp" />;
};

const ContentComponent = (props: ActiveFilterProps) => {
  const { active } = props;
  if (!active) return <></>;
  return (
    <>
      <FilterEngagement />
      <FilterFollower />
      <FilterRating />
    </>
  );
};

const AdvanceFilter = () => {
  const [active, setActive] = useState<boolean>(true);

  return (
    <Container>
      <RowContainer onClick={() => setActive(!active)}>
        <BoldH8Text>Advanced setting</BoldH8Text>
        <ArrowComponent active={active} />
      </RowContainer>
      <ContentComponent active={active} />
    </Container>
  );
};

export default AdvanceFilter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RowContainer = styled(SpaceBetweenRowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const BoldH8Text = styled(TEXT.H8_Bold)``;
