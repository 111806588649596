import { Alert, Slide, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useEffect, useState } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import {
  useAccountStore,
  useInfluencerStore,
} from "../../../stores/initialize/Context";
import { IGetInfluencerResponse } from "../../../types/api/getInfluencer";
import { Work } from "../../../types/work";
import {
  PLACEHOLDER_PROFILE,
  numberWithCommas,
  toDecimalNumber,
} from "../../../utils";
import { ColumnContainer, RowContainer } from "../../../utils/styling/general";
import { Information } from "../../molecules/CampaignManagement/WorkModal";
import DiscoverButton from "./DiscoverButton";

interface Props {
  work: Work;
  influencer: IGetInfluencerResponse | undefined;
  influLoading: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function DiscoverInfluProfile(props: Props) {
  const { work, influencer, influLoading, setActive, setSelected } = props;
  const {
    accountId,
    username,
    postType,
    comments_count,
    engagement,
    like_count,
    share_count,
    view_count,
    reach,
    impressions,
    followers_count,
  } = work;

  const { brandSaved } = useAccountStore();
  const { triggerSaveInfluencer } = useInfluencerStore();

  const isSaved = brandSaved.includes(accountId);
  const snackMessage = isSaved
    ? ["บันทึกไว้ที่", "เรียบร้อยแล้ว"]
    : ["นำรายชื่อออกจาก", "แล้ว"];

  const [activeSnackbar, setActiveSnackbar] = useState<boolean>(false);

  const [iconName, setIconName] = useState<IconName>(
    isSaved ? "SaveFill" : "Save"
  );

  useEffect(() => {
    setIconName(isSaved ? "SaveFill" : "Save");
  }, [isSaved]);

  const transitionProp: React.ComponentType<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    }
  > = Slide;

  const saveInfluencer = () => {
    if (isSaved) {
      triggerSaveInfluencer({
        accountId,
        isSaved: true,
      });
    } else {
      triggerSaveInfluencer({
        accountId,
        isSaved: false,
      });
    }
    setActiveSnackbar(true);
  };

  const SaveIconComponent = () => {
    if (isSaved) return <RXIcon name={"SaveFill"} color={COLOR.Blue_700} />;
    return <RXIcon name={"Save"} color={COLOR.Blue_700} />;
  };

  const renderInsight = () => {
    if (postType === "TK_video") {
      return (
        <InsightContainer>
          <InsightColumnContainer>
            <RowTextContainer>
              <RXIcon name="Tiktok" />
              <BoldH6Text style={{ marginLeft: 4, marginRight: 4 }}>
                Post{" "}
              </BoldH6Text>
              <B3Text>: คำนวณเฉลี่ยจาก 3 โพสต์ล่าสุด</B3Text>
            </RowTextContainer>
            <SpaceAroundRowContainer>
              <ColumnContainer
                style={{
                  alignItems: "flex-start",
                  margin: `0px 16px`,
                  gap: 32,
                }}
              >
                <Information
                  value={toDecimalNumber({
                    number: view_count || 0,
                    toFixed: 0,
                    round: true,
                  })}
                  description="Video Views"
                  style={{ marginLeft: "10px" }}
                  containerStyle={{ marginTop: "14px" }}
                  iconName="EyeOnFill"
                />
                <Information
                  value={toDecimalNumber({
                    number: like_count || 0,
                    toFixed: 0,
                    round: true,
                  })}
                  description="Like"
                  style={{ marginLeft: "10px" }}
                  containerStyle={{ marginTop: "14px" }}
                  iconName="HeartFill"
                />
              </ColumnContainer>
              <ColumnContainer
                style={{
                  alignItems: "flex-start",
                  margin: `0px 16px`,
                  gap: 32,
                }}
              >
                <Information
                  value={toDecimalNumber({
                    number: comments_count || 0,
                    toFixed: 0,
                    round: true,
                  })}
                  description="Comments"
                  style={{ marginLeft: "10px" }}
                  containerStyle={{ marginTop: "14px" }}
                  iconName="Comment"
                />
                <Information
                  value={toDecimalNumber({
                    number: share_count || 0,
                    toFixed: 0,
                    round: true,
                  })}
                  description="Share"
                  style={{ marginLeft: "10px" }}
                  containerStyle={{ marginTop: "14px" }}
                  iconName="ShareFill"
                />
              </ColumnContainer>
            </SpaceAroundRowContainer>
          </InsightColumnContainer>
        </InsightContainer>
      );
    } else {
      return (
        <InsightContainer>
          <InsightColumnContainer>
            <RowTextContainer>
              <RXIcon name="Profile" />
              <BoldH6Text style={{ marginLeft: 4, marginRight: 4 }}>
                Accounts{" "}
              </BoldH6Text>
              <B3Text> : คำนวณเฉลี่ย 30 วันล่าสุด</B3Text>
            </RowTextContainer>
            <SpaceAroundRowContainer>
              <Information
                value={toDecimalNumber({
                  number: reach || 0,
                  toFixed: 0,
                  round: true,
                })}
                description="Reach"
                style={{ marginLeft: "10px" }}
                containerStyle={{ marginTop: "14px" }}
                iconName="Signal"
              />
              <Information
                value={toDecimalNumber({
                  number: impressions || 0,
                  toFixed: 0,
                  round: true,
                })}
                description="Impression"
                style={{ marginLeft: "10px" }}
                containerStyle={{ marginTop: "14px" }}
                iconName="ImpressionFill"
              />
            </SpaceAroundRowContainer>
          </InsightColumnContainer>
          <InsightColumnContainer>
            <RowTextContainer>
              <RXIcon name="Image" />
              <BoldH6Text style={{ marginLeft: 4, marginRight: 4 }}>
                Post
              </BoldH6Text>
              <B3Text> : คำนวณเฉลี่ยจาก 3 โพสต์ล่าสุด</B3Text>
            </RowTextContainer>
            <SpaceAroundRowContainer>
              <Information
                value={toDecimalNumber({
                  number: like_count || 0,
                  toFixed: 0,
                  round: true,
                })}
                description="Like"
                style={{ marginLeft: "10px" }}
                containerStyle={{ marginTop: "14px" }}
                iconName="HeartFill"
              />
              <Information
                value={toDecimalNumber({
                  number: engagement || 0,
                  toFixed: 0,
                  round: true,
                })}
                description="Engagement"
                style={{ marginLeft: "10px" }}
                containerStyle={{ marginTop: "14px" }}
                iconName="Engagement"
              />
            </SpaceAroundRowContainer>
          </InsightColumnContainer>
        </InsightContainer>
      );
    }
  };

  return (
    <Container>
      <UsernameContainer>
        <ProfileContainer
          // @ts-ignore
          src={influencer?.img || PLACEHOLDER_PROFILE}
          onError={(e) => {
            e.currentTarget.src = PLACEHOLDER_PROFILE;
          }}
        />
        {postType === "TK_video" ? (
          <BoldH6Text>{username}</BoldH6Text>
        ) : (
          <ColumnTextContainer>
            <BoldH6Text>{username}</BoldH6Text>
            <RowTextContainer>
              <RXIcon name="Follower" size={20} />
              <B3Text style={{ marginLeft: 8 }}>
                {numberWithCommas(followers_count || 0)} followers
              </B3Text>
            </RowTextContainer>
          </ColumnTextContainer>
        )}
      </UsernameContainer>
      {renderInsight()}
      <DiscoverButton
        saveFavorite={saveInfluencer}
        isSaved={iconName}
        setActive={setActive}
        setSelected={setSelected}
      />
      <Snackbar
        open={activeSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setActiveSnackbar(false)}
        TransitionComponent={transitionProp}
        key={"saveSnackbar"}
      >
        <Alert
          icon={<RXIcon name="CheckCircle" color={COLOR.WHITE} />}
          variant="filled"
          onClose={() => setActiveSnackbar(false)}
          sx={{
            height: "48px",
            width: "344px",
            bgcolor: COLOR.BLACK,
            alignItems: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <WhiteB3Text>
            {snackMessage[0]} <Blue300BoldB3Text>Favorites</Blue300BoldB3Text>{" "}
            {snackMessage[1]}
          </WhiteB3Text>
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default DiscoverInfluProfile;

const Container = styled(ColumnContainer)`
  width: 427px;
  gap: 8px;
`;

const ProfileContainer = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: ${COLOR.Gray_300};
  margin-right: 8px;
  object-fit: cover;
`;

const InsightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 10px;
  width: 427px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;

  width: 427px;
  height: 80px;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;

const ColumnTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InsightColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin: 12px 0px 12px 0px;
`;

const RowTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ReviewContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  margin-left: 16px;
  padding: 0px 10px 0px 8px;
  border-radius: 20px;
  background: ${COLOR.Gray_50};
`;

const SpaceAroundRowContainer = styled(RowContainer)`
  justify-content: space-around;
`;
const BoldH6Text = styled(TEXT.H6_Bold)``;

const B3Text = styled(TEXT.B3_Reg)``;

const BoldH9Text = styled(TEXT.H9_Bold)`
  margin-top: 4px;
  margin-left: 2px;
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
`;

const Blue300BoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Blue_300};
`;
