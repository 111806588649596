import styled, { StyledComponent } from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";
import { ActiveProps } from "../../types";
import {
  CategoryType,
  categoryOptions,
} from "../organisms/CreateCampaign/Options";

interface CardContainerProps extends ActiveProps {
  width: number;
  height: number;
}

interface ImageProps {
  width: number;
  height: number;
}

interface CardTextProps {
  active: boolean;
  text: string;
  ActiveTextStyle: StyledComponent<"span", any, {}, never>;
  InactiveTextStyle: StyledComponent<"span", any, {}, never>;
}

const CardTextComponent = (props: CardTextProps) => {
  const { active, text, ActiveTextStyle, InactiveTextStyle } = props;
  if (active) return <ActiveTextStyle>{text}</ActiveTextStyle>;
  else return <InactiveTextStyle>{text}</InactiveTextStyle>;
};

interface ImageComponentProps {
  category: CategoryType;
  imageDimension: number;
}

const ImageComponent = (props: ImageComponentProps) => {
  const { category, imageDimension } = props;
  const categoryImage =
    categoryOptions.find((cat) => cat.value === category)?.icon ?? "Other";

  return (
    <Image
      src={require("../../assets/images/Category/" + categoryImage + ".png")}
      width={imageDimension}
      height={imageDimension}
    />
  );
};

interface Props {
  active: boolean;
  category: CategoryType;
  onClick: () => void;
  width?: number;
  height?: number;
  imageDimension?: number;
  ActiveTextStyle?: StyledComponent<"span", any, {}, never>;
  InactiveTextStyle?: StyledComponent<"span", any, {}, never>;
}

const RCategoryCard = (props: Props) => {
  const {
    category,
    active,
    onClick,
    width = 164,
    height = 96,
    imageDimension = 100,
    ActiveTextStyle = Blue700BoldB3Text,
    InactiveTextStyle = GrayMB3Text,
  } = props;

  return (
    <CardContainer
      active={active}
      onClick={onClick}
      width={width}
      height={height}
    >
      <TextContainer>
        <CardTextComponent
          active={active}
          text={category}
          ActiveTextStyle={ActiveTextStyle}
          InactiveTextStyle={InactiveTextStyle}
        />
      </TextContainer>
      <ImageComponent category={category} imageDimension={imageDimension} />
    </CardContainer>
  );
};

export default RCategoryCard;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 8px;
  border: 0.6px solid ${COLOR.Blue_700};
  border-color: ${(props) => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
  padding: 8px;
  background: ${(props) => (props.active ? COLOR.Blue_50 : COLOR.White)};
  :hover {
    cursor: pointer;
    border: ${(props) =>
      props.active
        ? `0.6px solid ${COLOR.Blue_700}`
        : `0.6px solid ${COLOR.Black}`};
  }
  &:hover * {
    color: ${(props) => (props.active ? COLOR.Blue_700 : COLOR.Black)};
  }
`;

const TextContainer = styled.div`
  width: 100px;
`;

const Image = styled.img<ImageProps>`
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: 75px;
  top: 9px;
`;

const Blue700BoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Blue_700};
`;
