import styled from "styled-components";
import ForgotPasswordCardDetail from "../../molecules/ForgotPassword/ForgotPasswordCardDetail";
import ForgotPasswordCardFooter from "../../molecules/ForgotPassword/ForgotPasswordCardFooter";

interface Props {
  changePage: () => void;
  email: {
    value: string;
  };
  setEmail: ({ value }: { value: string }) => void;
  sendForgetPasswordEmail: () => Promise<void>;
  emailError: string[];
  isEmailSendActive: boolean;
}

const RegisterCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 567px;
  height: 664px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  padding: 50px 88px;
`;

function ForgotPasswordCard(props: Props) {
  return (
    <RegisterCardContainer>
      <ForgotPasswordCardDetail {...props} />
      <ForgotPasswordCardFooter changePage={props.changePage} />
    </RegisterCardContainer>
  );
}

export default ForgotPasswordCard;
