import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

const EmptyRateCard = () => {
  return (
    <Container>
      <WhiteH7Text>ไม่มีข้อมูล Ratecard</WhiteH7Text>
    </Container>
  );
};

export default EmptyRateCard;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
`;

const WhiteH7Text = styled(TEXT.H7_Reg)`
  color: ${COLOR.White};
`;
