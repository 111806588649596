import React from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";
import { ANDTEXT } from "../../fonts/andText";
import { ANDCOLOR } from "../../fonts/andColor";

interface Props {
  text: string[];
}

function AToolTip(props: Props) {
  const { text } = props;
  const TextComponent = () => {
    const textArray: JSX.Element[] = [];
    text.map((t) =>
      textArray.push(
        <Body2Text key={t} style={{ marginLeft: "8px" }}>
          {t}
        </Body2Text>
      )
    );
    return <TextContainer>{textArray}</TextContainer>;
  };
  return (
    <Container>
      <TextComponent />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 14px 12px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body2Text = styled(ANDTEXT.body3)`
  color: ${ANDCOLOR.WHITE};
`;

export default AToolTip;
