import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const Space = styled.div`
  margin-top: 50px;
`;

const DownloadOnlyContainer = styled.div`
  width: 240px;
  height: 180px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #c4c4c4;
  border-radius: 12px;

  flex: none;
  order: 1;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :hover {
    cursor: pointer;
  }
`;

const DownloadOnlyText = styled(TEXT.caption1)`
  padding-top: 4px;
  color: ${COLOR.WHITE};
`;

function CustomMessageVideo(props: any) {
  const video = props.currentMessage.video;

  if (video) {
    const urlSplit = video.split(".");
    const videoType = "video/" + String(urlSplit[urlSplit.length - 1]);
    let type: string = videoType;
    if (type === "video/MOV") {
      return (
        <DownloadOnlyContainer onClick={() => window.location.assign(video)}>
          <div
            style={{
              backgroundColor: COLOR.BLACK,
              opacity: "0.6",
              borderRadius: "20px",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RXIcon name="Download" color={COLOR.WHITE} />
          </div>
          <DownloadOnlyText>ดาวน์โหลดวิดีโอ</DownloadOnlyText>
        </DownloadOnlyContainer>
      );
    }

    return (
      <video controls width={200} height={200} style={{ borderRadius: "25px" }}>
        <source src={video} type={"video/mp4"} />
      </video>
    );
  }

  return <Container></Container>;
}

export default CustomMessageVideo;
