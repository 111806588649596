import Constants from "expo-constants";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { PKNotification } from "../types/notification";
import { objectToQuerystring } from "../utils";
import { GET, POST } from "../utils/Networking";

const env = process.env.REACT_APP_ENVIRONMENT;
export interface PKNotifications {
  data: Array<PKNotification>;
  metadata: Array<{ count: number; limit: number; offset: number }>;
}

export type ChannelMap = {
  [channel: string]: WebSocket;
};

export class WebSocketStore {
  @observable
  socket: ChannelMap = {};

  constructor() {
    makeAutoObservable(this);
  }

  @action
  connectWebsocket = ({ channel }: { channel: string }) => {
    const WebSocket_URL =
      env === "production"
        ? process.env.REACT_APP_PRODUCTION_WS_URL
        : env === "local"
        ? process.env.REACT_APP_LOCAL_WS_URL
        : process.env.REACT_APP_STAGING_WS_URL;
    try {
      const channelName = channel;
      if (channelName !== "") {
        this.socket[channel] = new WebSocket(
          `${WebSocket_URL}?channel=${channelName}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export const webSocketStore = new WebSocketStore();
