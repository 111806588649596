import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ActiveProps } from "../../../types";
import { toDateDue } from "../../../utils";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  startDate: string | Date;
  endDate: string | Date;
  postingDate: string | Date;
  submissionDate: string | Date;
  brandApprove: boolean;
  onEdit?: () => void;
}

const Container = styled.div`
  margin-top: 24px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${COLOR.Gray_300};
  margin-bottom: 12px;
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const WarningContainer = styled.div`
  display: flex;
  width: 240px;
  height: 32px;
  align-items: center;
  padding: 6px 10px;
  margin-top: 16px;
  background: ${COLOR.LIGHTYELLOW2};
  border-radius: 20px;
`;

const BoldButtonText = styled(TEXT.buttonBOLD)``;

const Body1Text = styled(TEXT.body1)``;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const OrangeBody1Text = styled(TEXT.body1)`
  color: ${COLOR.DARKYELLOW};
  margin-left: 6px;
`;

const Green2BoldBody1Text = styled(BoldBody1Text)`
  color: ${COLOR.DARKGREEN2};
`;

const ActivableBody1Text = styled(TEXT.body1)<ActiveProps>`
  margin-left: 8px;
  color: ${(props) => (props.active ? COLOR.BLACK : COLOR.PINK)};
`;

function DealCardTimeline(props: Props) {
  const {
    startDate,
    endDate,
    postingDate,
    submissionDate,
    brandApprove,
    onEdit,
  } = props;

  const renderFirstRow = () => {
    if (
      startDate === endDate &&
      new Date(startDate).toLocaleDateString() ===
        new Date().toLocaleDateString()
    ) {
      return (
        <TimelineContainer>
          <BoldBody1Text style={{ marginBottom: "8px" }}>
            ช่วงเวลาที่เปิด - ปิดรับสมัคร influencer
          </BoldBody1Text>
          <RowContainer style={{ alignItems: "center" }}>
            <RXIcon name="Calendar" color={COLOR.Gray_300} size={18} />
            <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
          </RowContainer>
        </TimelineContainer>
      );
    } else {
      return (
        <TimelineContainer>
          <BoldBody1Text style={{ marginBottom: "8px" }}>
            ช่วงเวลาที่เปิด - ปิดรับสมัคร influencer
          </BoldBody1Text>
          <RowContainer>
            <RXIcon name="Calendar" color={COLOR.GREEN} size={18} />
            <Body1Text style={{ marginLeft: "8px" }}>
              {`${toDateDue(startDate)} - ${toDateDue(endDate)}`}
            </Body1Text>
          </RowContainer>
        </TimelineContainer>
      );
    }
  };

  const renderSecondRow = () => {
    if (
      postingDate === submissionDate &&
      new Date(postingDate).toLocaleDateString() ===
        new Date().toLocaleDateString()
    ) {
      return (
        <TimelineContainer>
          <BoldBody1Text style={{ marginBottom: "8px" }}>
            ช่วงเวลาที่ Influencer โพสต์งานและส่งผลงาน
          </BoldBody1Text>
          <RowContainer style={{ alignItems: "center" }}>
            <RXIcon name="Calendar" color={COLOR.Gray_300} size={18} />
            <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
          </RowContainer>
        </TimelineContainer>
      );
    } else {
      return (
        <TimelineContainer>
          <BoldBody1Text style={{ marginBottom: "8px" }}>
            ช่วงเวลาที่ Influencer โพสต์งานและส่งผลงาน
          </BoldBody1Text>
          <RowContainer>
            <RXIcon name="Calendar" color={COLOR.GREEN} size={18} />
            <Body1Text style={{ marginLeft: "8px" }}>
              {`${toDateDue(postingDate)} - ${toDateDue(submissionDate)}`}
            </Body1Text>
          </RowContainer>
        </TimelineContainer>
      );
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <BoldButtonText>Timeline</BoldButtonText>
        {!!onEdit && (
          <EditContainer onClick={onEdit}>
            <RXIcon name="Edit" color={COLOR.DARKGREEN2} size={20} />
            <Green2BoldBody1Text style={{ marginLeft: "4px" }}>
              แก้ไข
            </Green2BoldBody1Text>
          </EditContainer>
        )}
      </HeaderContainer>
      {renderFirstRow()}
      {renderSecondRow()}
      {brandApprove && (
        <WarningContainer>
          <RXIcon name="Alert" size={24} color={COLOR.DARKYELLOW} />
          <OrangeBody1Text>ส่งให้แบรนด์ตรวจก่อนโพสต์</OrangeBody1Text>
        </WarningContainer>
      )}
    </Container>
  );
}

export default DealCardTimeline;
