import { useState } from "react";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { PasswordCodeProp } from "../../../types/resetpassword";
import RButton from "../../atoms/RButton";
import RInput, { InputStateProps } from "../../atoms/RInput";

interface Props {
  code: PasswordCodeProp;
  resetPassword: () => Promise<void>;
  isRepasswordSendActive: boolean;
  onDone: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LargeTextContainer = styled(TEXT.H4_Bold)``;

const BoxContainer = styled.div`
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  margin-top: 86px;
  align-self: flex-end;
`;

function ForgotPasswordCardDetail(props: Props) {
  const { code, resetPassword, isRepasswordSendActive, onDone } = props;
  const [passwordError, setPasswordError] = useState<InputStateProps>("normal");
  const [loading, setLoading] = useState<boolean>(false);
  const [rePasswordError, setRePasswordError] =
    useState<InputStateProps>("normal");

  const NewPasswordPress = async () => {
    try {
      setLoading(true);
      await resetPassword();
      setLoading(false);
      onDone();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <LargeTextContainer>ตั้งรหัสผ่านใหม่</LargeTextContainer>
      <BoxContainer>
        <RInput
          icon="Lock"
          type="password"
          label="รหัสผ่านใหม่"
          state={passwordError}
          inputProp={{
            onChange: (e) => (code.password = e.target.value),
            onBlur: (e) => {
              if (code.password.length < 8) setPasswordError("error");
              else setPasswordError("check");
            },
            placeholder: "New Password",
          }}
          password={true}
          message={
            passwordError === "error"
              ? "โปรดใช้รหัสผ่านที่มี 8 ตัวอักษรขึ้นไป"
              : " "
          }
        />
      </BoxContainer>
      <BoxContainer>
        <RInput
          icon="LockCheck"
          type="password"
          label="ยืนยันรหัสผ่านใหม่"
          state={rePasswordError}
          inputProp={{
            onChange: (e) => (code.rePassword = e.target.value),
            onBlur: (e) => {
              if (code.rePassword !== code.password)
                setRePasswordError("error");
              else setRePasswordError("check");
            },
            placeholder: "Confirm New Password",
          }}
          password={true}
          message={
            rePasswordError === "error"
              ? "รหัสผ่านไม่ตรงกัน โปรดใส่รหัสผ่านให้ถูกต้อง"
              : " "
          }
        />
      </BoxContainer>
      <ButtonContainer>
        <RButton
          title="ยืนยัน"
          onPress={NewPasswordPress}
          active={!loading && isRepasswordSendActive}
          textStyle={{ padding: "0px 20px" }}
        />
      </ButtonContainer>
    </Container>
  );
}

export default ForgotPasswordCardDetail;
