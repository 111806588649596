import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";

interface Props {
  active: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function EditDealModal(props: Props) {
  const { active, onClose, onConfirm } = props;
  return (
    <RModal active={active} setActive={onClose}>
      <Container>
        <BoldH5Text style={{ marginBottom: "8px" }}>ยืนยันการแก้ไข</BoldH5Text>
        <B2Text>ยืนยันการแก้ไขรายละเอียดแคมเปญ</B2Text>
        <ButtonContainer>
          <RButton
            title="ยกเลิก"
            onPress={onClose}
            backgroundColor={COLOR.WHITE}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
          />
          <RButton title="ยืนยัน" onPress={onConfirm} />
        </ButtonContainer>
      </Container>
    </RModal>
  );
}

export default EditDealModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 394px;
  height: 192px;
  border-radius: 24px;
  background: ${COLOR.WHITE};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 16px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;
