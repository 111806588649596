// import * as Notifications from 'expo-notifications';
import { GET } from "./Networking";

// export const getCurrentBadges = async () => {
//   const currentBadge = await Notifications.getBadgeCountAsync();
//   return currentBadge;
// };

// export const setBadges = async (numberOfBadge: number) => {
//   const isSupportBadge = await Notifications.setBadgeCountAsync(numberOfBadge);
//   return isSupportBadge;
// };

// export const decrementBadges = async (decrease: number) => {
//   const currentBadge = await getCurrentBadges();
//   setBadges(currentBadge - decrease);
//   return currentBadge - decrease;
// };

// export const resetBadge = async () => {
//   setBadges(0);
// };

export const getNumberOfBadge = async () => {
  const numberOfBadge = await GET("/brands/notifications/badges");
  return numberOfBadge;
};

export const getNumberOfNotiBadge = async () => {
  const numberOfBadge = await GET("/brands/notifications/notibadges");
  return numberOfBadge;
};

export const getNumberOfBadges = async (ids: string[]) => {
  const numberOfBadge = await GET(
    `/brands/notifications/badges?ids=${ids.join(",")}`
  );
  return numberOfBadge;
};

// export const handleBadge = async (accounts: string[]) => {
//   const badges = await getNumberOfBadges(accounts);
//   if (badges) setBadges(parseInt(badges, 10));
// };
