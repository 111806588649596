import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import {
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import ANDButton from "../../../atoms/ANDButton";
import ANDImageSelector from "../../../organisms/CreateCampaign/ANDImageSelector";
import { ImageProp } from "../../../organisms/CreateCampaign/ImageSelector";
import { COLOR } from "../../../../fonts/color";

interface Props {
  photos: ImageProp[];
  setPhotos: (photos: ImageProp[]) => void;
}

function CampaignImage(props: Props) {
  const { photos, setPhotos } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>รูปแคมเปญ</BoldHeader7Text>
        <BoldHeader7Text style={{ marginTop: 8 }}>
          {photos.length + " / 4"}
        </BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <Body3Text>
            ใส่รูปผลิตภัณฑ์หรือบริการที่สวยงาม<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
        </GapRowContainer>
        <ANDImageSelector
          images={photos}
          haveLarge={true}
          smallImage={[1, 2, 3]}
          onSelect={(photoArray: ImageProp[]) => {
            setPhotos(photoArray);
          }}
        />
      </ContentContainer>
    </Container>
  );
}

export default CampaignImage;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
  margin-top: 72px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 88px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
