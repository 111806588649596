import { IconName, RXIcon } from "rn-rx-icons";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { ChatMessage } from "../../../types/chatMessage";
interface Props {
  message: string;
  styles?: CSSProperties;
  userType: "current" | "partner" | "bot" | "system";
}

const MarkdownRules = ({
  message,
  userType,
}: {
  message: string;
  userType: string;
}) => {
  const markdownMessage = message.split("~~");
  const returnMessage: Array<JSX.Element> = [];

  const isHeader = (message: string) => {
    if (
      message.includes("สิ่งที่ได้รับ") ||
      message.includes("สิ่งที่ต้องทำ (scope of work)") ||
      message.includes("ระยะเวลาส่งงาน")
    ) {
      return true;
    }
    return false;
  };

  markdownMessage.map((content) => {
    if (content.includes("RXHashtag")) {
      const properties = content.split(":");
      const hashtagText = properties[1];

      returnMessage.push(
        <>
          <span
            key={content}
            style={{
              backgroundColor: COLOR.WHITE,
              border: `1px solid ${COLOR.LIGHTBLUE}`,
              borderRadius: "50px",
              lineHeight: "40px",
              padding: "6px 8px",
            }}
          >
            <Body1Text
              style={{
                color: COLOR.LIGHTBLUE,
              }}
            >
              #{hashtagText}
            </Body1Text>
          </span>
        </>
      );
    } else if (content.match("RxVerifyBeforePost")) {
      returnMessage.push(
        <div
          key={content}
          style={{
            height: 28,
            width: 212,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 6,
            paddingBottom: 6,
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFEFCC",
            borderRadius: 20,
          }}
        >
          <RXIcon name="Alert" size={20} color={COLOR.DARKYELLOW} />
          <Body2Text style={{ color: COLOR.DARKYELLOW, marginLeft: 6 }}>
            ส่งให้แบรนด์ตรวจก่อนโพสต์
          </Body2Text>
        </div>
      );
    } else if (content.match("RxHasExpImg")) {
      returnMessage.push(
        <div style={{ paddingBottom: 6 }} key={content}>
          <div
            style={{
              height: 28,
              padding: "0px 10px 0px 10px",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: COLOR.Blue_50,
              borderRadius: 20,
            }}
          >
            <RXIcon name="CheckCircle" size={20} color={COLOR.Blue_700} />
            <Body2Text style={{ color: COLOR.Blue_700, marginLeft: 6 }}>
              มีตัวอย่างรูป
            </Body2Text>
          </div>
        </div>
      );
    } else if (content.match("Box")) {
      const properties = content.split(":");
      const title: string = properties[1];
      const icon: string = properties[2];
      const theme: string = properties[3];
      const value: string = properties[4];
      const greenTheme = theme === "Green";
      const validateIcon: IconName = icon as IconName;
      let bgColor = greenTheme ? COLOR.Blue_50 : COLOR.Gray_50;
      let titleColor = greenTheme ? COLOR.Blue_700 : COLOR.Gray_M;
      let valueColor = greenTheme ? COLOR.Blue_700 : COLOR.Gray_M;
      returnMessage.push(
        <div
          key={content}
          style={{
            backgroundColor: bgColor,
            flexDirection: "row",
            padding: "15px 13px 15px 13px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderRadius: 10,
            width: "208px",
            height: "56px",
          }}
        >
          <Body2Text style={{ color: titleColor }}>{title} : </Body2Text>
          <RXIcon name={validateIcon} size={20} color={valueColor} />
          <BoldCaption1Text style={{ color: valueColor, marginLeft: 5 }}>
            {value}
          </BoldCaption1Text>
        </div>
      );
    } else if (content.match("COLOR")) {
      const [, color, text]: string[] = content.split(":");
      returnMessage.push(
        <Body2Text key={content} style={{ color: color }}>
          {text}
        </Body2Text>
      );
    } else if (content.match("RXIcon")) {
      const [, iconName, iconColor]: string[] = content.split(":");
      const validateIcon: IconName = (iconName as IconName) || "";
      returnMessage.push(
        userType === "system" ? (
          <IconContainer>
            <RXIcon
              name={validateIcon}
              color={iconColor}
              size={16}
              key={content}
            />
          </IconContainer>
        ) : (
          <RXIcon
            name={validateIcon}
            color={iconColor}
            size={16}
            key={content}
          />
        )
      );
    } else if (content.match("RXBadge")) {
      const [, badgeName]: string[] = content.split(":");
      if (badgeName === "Instagram") {
        returnMessage.push(
          <div
            key={content}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Body2Text style={{ marginRight: 5 }}>ช่องทาง</Body2Text>
            <div
              style={{
                background: `linear-gradient(to left bottom, #4F5BD5, #962FBF, #D62976, #FA7E1E, #FEDA75)`,
                flexDirection: "row",
                width: 100,
                height: 24,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "5px",
              }}
            >
              <RXIcon name="Instagram" size={18} color={COLOR.WHITE} />
              <Body2Text
                style={{
                  marginLeft: 5,
                  color: COLOR.WHITE,
                }}
              >
                Instagram
              </Body2Text>
            </div>
            {/* </LinearGradient> */}
          </div>
        );
      } else if (badgeName === "Tiktok") {
        returnMessage.push(
          <div
            key={content}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Body2Text style={{ marginRight: 5 }}>ช่องทาง</Body2Text>
            <div
              style={{
                backgroundColor: COLOR.BLACK,
                flexDirection: "row",
                width: 100,
                height: 24,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "5px",
              }}
            >
              <RXIcon name="Tiktok" size={18} color={COLOR.WHITE} />
              <Body2Text
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: COLOR.WHITE,
                }}
              >
                TikTok
              </Body2Text>
            </div>
          </div>
        );
      } else if (badgeName === "Lemon") {
        returnMessage.push(
          <div
            key={content}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Body2Text style={{ marginRight: 5 }}>ช่องทาง</Body2Text>
            <div
              style={{
                backgroundColor: COLOR.LEMON,
                flexDirection: "row",
                width: 100,
                height: 24,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "5px",
              }}
            >
              <ImageContainer
                src={require("../../../assets/images/PlatformLogos/Lemon.png")}
              />
              <Body2Text
                style={{
                  marginLeft: 4,
                  marginRight: 10,
                  color: COLOR.BLACK,
                }}
              >
                Lemon8
              </Body2Text>
            </div>
          </div>
        );
      }
    } else if (content.trim().length === 1 && content.includes(",")) {
    } else {
      const messageSplit = content.split("**");
      messageSplit.forEach((element, index) => {
        if (index % 2 == 1) {
          if (element.includes(":")) {
            const [colorMessage, color] = element.split(":");
            returnMessage.push(
              isHeader(colorMessage) ? (
                <BoldBody1Text style={{ color: color }}>
                  {colorMessage}
                </BoldBody1Text>
              ) : (
                <BoldBody2Text style={{ color: color }}>
                  {colorMessage}
                </BoldBody2Text>
              )
            );
          } else {
            if (element === "#Hashtag") {
              returnMessage.push(<br />);
            }
            returnMessage.push(
              isHeader(element) ? (
                <BoldBody1Text key={element}>{element}</BoldBody1Text>
              ) : (
                <BoldBody2Text key={element}>{element}</BoldBody2Text>
              )
            );
          }
        } else if (element.match("\n")) {
          const newLine = element.split("\n");
          newLine.map((text) => {
            if (
              !text.replace(/\s+/g, "") &&
              returnMessage[returnMessage.length - 1].type !== "br"
            ) {
              returnMessage.push(<br />);
            } else {
              if (
                text.includes("[") &&
                text.includes("]") &&
                text.includes("(") &&
                text.includes(")")
              ) {
                if (
                  text.indexOf("[") < text.indexOf("]") &&
                  text.indexOf("]") === text.indexOf("(") - 1 &&
                  text.indexOf("(") < text.indexOf(")")
                ) {
                  const link = text.split("[")[1].split("]")[0];
                  if (
                    returnMessage[returnMessage.length - 1].key ===
                    "รายละเอียดงานเพิ่มเติม"
                  ) {
                    returnMessage.push(
                      <>
                        <br />
                        <Hyperlink onClick={() => window.open(link, "_blank")}>
                          {link}
                        </Hyperlink>
                      </>
                    );
                  } else {
                    returnMessage.push(
                      <Hyperlink onClick={() => window.open(link, "_blank")}>
                        {link}
                      </Hyperlink>
                    );
                  }
                }
              } else {
                if (text.includes("เงินค่าตอบแทน")) returnMessage.push(<br />);
                returnMessage.push(
                  <Body2Text
                    key={text}
                    style={{
                      color: userType === "system" ? COLOR.Gray_M : COLOR.BLACK,
                    }}
                  >
                    {text}
                  </Body2Text>
                );
              }
            }
          });
        } else {
          if (element.includes(":#"))
            returnMessage.push(
              <Body2Text
                key={element}
                style={{
                  color: "#" + element.split(":#")[1],
                  textDecoration: element.includes("true")
                    ? "line-through"
                    : "none",
                }}
              >
                {element.split(":#")[0]}
              </Body2Text>
            );
          else
            returnMessage.push(
              userType === "system" ? (
                <Caption1Text
                  key={element}
                  style={{
                    color: COLOR.Gray_M,
                  }}
                >
                  {element}
                </Caption1Text>
              ) : (
                <Body2Text key={element}>{element}</Body2Text>
              )
            );
        }
      });
    }
  });
  return returnMessage;
};

const CustomMarkdown = (props: Props) => {
  const { message, styles, userType } = props;

  if (userType === "current") {
    return <CurrentUserText style={styles || {}}>{message}</CurrentUserText>;
  }
  if (userType === "partner") {
    return <PartnerUserText style={styles || {}}>{message}</PartnerUserText>;
  }
  if (userType === "bot") {
    return <BotUserText>{MarkdownRules({ message, userType })}</BotUserText>;
  }
  if (userType === "system") {
    return <SystemText>{MarkdownRules({ message, userType })}</SystemText>;
  }
  return <></>;
};
export default CustomMarkdown;

const ImageContainer = styled.img`
  width: 18px;
  height: 18px;
`;
const CurrentUserText = styled(TEXT.body2)`
  color: ${COLOR.WHITE};
  word-wrap: break-word;
`;

const PartnerUserText = styled(TEXT.body2)`
  word-wrap: break-word;
`;

const BotUserText = styled(TEXT.body2)``;

const SystemText = styled(TEXT.caption1)`
  display: flex;
  gap: 2px;
  color: ${COLOR.Gray_M};
`;

const Caption1Text = styled(TEXT.caption1)``;

const BoldCaption1Text = styled(TEXT.caption1BOLD)``;

const Body2Text = styled(TEXT.body2)``;

const Body1Text = styled(TEXT.body1)``;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const BoldBody2Text = styled(TEXT.body2BOLD)``;

const Hyperlink = styled(TEXT.body2)`
  color: ${COLOR.Blue_700};
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div``;
