import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { IInfluAddressAndAccount } from "../../../types/chatMessage";

interface Props {
  detail: IInfluAddressAndAccount;
  cash: number;
  isChatConfirm: boolean;
}
const ChatInfluencerDetail = (props: Props) => {
  const { detail, cash, isChatConfirm } = props;
  const {
    addressRecipient,
    deliveryPhone,
    address,
    bank,
    bankNumber,
    bankOwnerName,
  } = detail;
  return (
    <Container>
      <ColumnContainer
        style={{
          width: "266px",
          borderRight: `1px dotted ${COLOR.Blue_50}`,
        }}
      >
        <BoldH6Text>
          <IconContainer>
            <RXIcon name="Parcel" color={COLOR.Blue_700} />
          </IconContainer>
          ข้อมูลที่อยู่
        </BoldH6Text>
        {isChatConfirm ? (
          <>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>ชื่อผู้รับ</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{addressRecipient}</Body2Text>
            </RowContainer>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>ที่อยู่จัดส่ง</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{address}</Body2Text>
            </RowContainer>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>เบอร์ติดต่อ</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{deliveryPhone}</Body2Text>
            </RowContainer>
          </>
        ) : (
          <ConfirmContainer>
            <GreenBoldBody2Text>รอการยืนยันจากอินฟลูเซอร์</GreenBoldBody2Text>
          </ConfirmContainer>
        )}
      </ColumnContainer>
      <VerticalDashedLine />
      <ColumnContainer style={{ width: "266px" }}>
        <BoldH6Text>
          <IconContainer>
            <RXIcon name="Card" color={COLOR.Blue_700} />
          </IconContainer>
          ข้อมูลธุรกรรม
        </BoldH6Text>
        {isChatConfirm ? (
          <>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>ธนาคาร</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{bank}</Body2Text>
            </RowContainer>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>เลขที่บัญชี</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{bankNumber}</Body2Text>
            </RowContainer>
            <RowContainer>
              <BoldBody2Text style={{ flex: 3 }}>ชื่อบัญชี</BoldBody2Text>
              <BoldBody2Text style={{ flex: 1 }}>:</BoldBody2Text>
              <Body2Text style={{ flex: 6 }}>{bankOwnerName}</Body2Text>
            </RowContainer>
          </>
        ) : (
          <ConfirmContainer
            style={{
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            <GreenBoldBody2Text>รอการยืนยันจากอินฟลูเซอร์</GreenBoldBody2Text>
          </ConfirmContainer>
        )}
        <BoxContainer>
          <Caption1Text
            style={{
              width: "50%",
              borderRight: `1px dotted ${COLOR.Blue_700}`,
              height: "100%",
            }}
          >
            ค่าตอบแทนที่ต้อง <br />
            ให้อินฟลูเอนเซอร์
          </Caption1Text>
          <ColumnContainer
            style={{ alignItems: "center", width: "50%", paddingTop: "0px" }}
          >
            <Caption1Text>จำนวนเงิน</Caption1Text>
            <BoldBody1Text>
              <IconContainer style={{ paddingRight: "2px" }}>
                <RXIcon name="BudgetFill" color={COLOR.Blue_700} />
              </IconContainer>
              {cash} ฿
            </BoldBody1Text>
          </ColumnContainer>
        </BoxContainer>
      </ColumnContainer>
    </Container>
  );
};

export default ChatInfluencerDetail;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 608px;
  height: 232px;
  margin-top: 32px;
  background: ${COLOR.Blue_50};
  border-radius: 12px;
  justify-content: space-evenly;
`;

const ConfirmContainer = styled.div`
  margin: 32px 36px;
`;

const BoldH6Text = styled(TEXT.header6BOLD)`
  color: ${COLOR.Blue_700};
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 5px;
`;

const BoldBody2Text = styled(TEXT.body2BOLD)`
  padding-top: 5px;
`;

const BoldBody1Text = styled(TEXT.body1BOLD)`
  display: flex;
  color: ${COLOR.Blue_700};
`;
const Body2Text = styled(TEXT.body2)`
  padding-top: 5px;
`;
const GreenBoldBody2Text = styled(TEXT.body2BOLD)`
  padding-top: 5px;
  color: ${COLOR.Blue_700};
`;

const Caption1Text = styled(TEXT.caption1)`
  color: ${COLOR.Blue_700};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoxContainer = styled(RowContainer)`
  width: 264px;
  height: 48px;
  background: ${COLOR.WHITE};
  border-radius: 10px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerticalDashedLine = styled.div`
  border-left: 1px dashed ${COLOR.Blue_700};
  height: 232px;
  width: 0;
`;

const IconContainer = styled.div``;
