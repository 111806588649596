import { observer } from "mobx-react";
import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { ChannelMap } from "../../../stores/webSocketStore";
import { Deal } from "../../../types/deal";
import { PLACEHOLDER_IMAGE } from "../../../utils";
import RLoading from "../../atoms/RLoading";
import CampaignCardDetail from "./CampaignCardDetail";
import REmptyState from "../../atoms/REmptyState";

interface Props {
  navigate: NavigateFunction;
  deals: Deal[] | undefined;
  status: "ongoing" | "pending" | "done";
  nextDeals: (() => void) | undefined;
  metadata: {
    count: number;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
  };
  drawerStatus: string;
  activateShareModal: (img: string, title: string, dealId: string) => void;
  isLoading: boolean;
  connectWebsocket: ({ channel }: { channel: string }) => void;
  socket: ChannelMap;
  increment: ({
    dealId,
    status,
    key,
    isIncrease,
  }: {
    dealId: string;
    status: "pending" | "ongoing" | "done";
    key: "pendingSize" | "ongoingSize" | "checkSize" | "doneSize";
    isIncrease: boolean;
  }) => void;
  dealNotification: ({
    dealId,
    status,
    key,
  }: {
    dealId: string;
    status: "pending" | "ongoing" | "done";
    key: "notiPending" | "notiOngoing" | "notiCheck" | "notiDone";
  }) => void;
  emptyStateHeader: string;
  emptyStateDescription: string[];
}

function CampaignList(props: Props) {
  const {
    navigate,
    deals = [],
    status,
    nextDeals = () => {},
    metadata,
    drawerStatus,
    activateShareModal,
    isLoading,
    connectWebsocket,
    socket,
    increment,
    dealNotification,
    emptyStateHeader,
    emptyStateDescription,
  } = props;

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 674) {
      if (metadata.count && metadata.count > deals.length) nextDeals();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isLoading]);

  if (deals.length === 0)
    return (
      <EmptyContainer>
        <REmptyState
          graphic={
            <EmptyStateImage
              src={require("../../../assets/images/state/NothingHere.png")}
            />
          }
          header={emptyStateHeader}
          descriptions={emptyStateDescription}
        />
      </EmptyContainer>
    );

  return (
    <Container>
      <ListContainer>
        {deals?.map((deal, index) => {
          return (
            <CampaignCardDetail
              navigate={navigate}
              key={deal.dealId + index}
              deal={deal}
              status={status}
              drawerStatus={drawerStatus}
              activateShareModal={() => {
                activateShareModal(
                  deal.coverImg || PLACEHOLDER_IMAGE,
                  deal.name,
                  deal.dealId
                );
              }}
              connectWebsocket={connectWebsocket}
              socket={socket}
              increment={increment}
              dealNotification={dealNotification}
            />
          );
        })}
      </ListContainer>
      {isLoading && (
        <RLoading containerStyle={{ marginTop: "0px", marginBottom: "0px" }} />
      )}
    </Container>
  );
}

export default observer(CampaignList);

const Container = styled.div``;

const ListContainer = styled.div`
  display: flex;
  gap: 24px 84px;
  margin-bottom: 36px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;
