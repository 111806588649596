import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { useEditDealStore } from "../../../stores/initialize/Context";
import { Deal } from "../../../types/deal";
import RButton from "../../atoms/RButton";
import RCounter from "../../atoms/RCounter";
import RInput from "../../atoms/RInput";
import EditDealModal from "../../molecules/Campaign/EditDealModal";
import ImageSelector, { ImageProp } from "../CreateCampaign/ImageSelector";

interface Props {
  deal: Deal;
  back: () => void;
}

function EditDealDetails(props: Props) {
  const { deal, back } = props;
  const { dealId, name, photos, postingDate, active } = deal;

  const { editDeal } = useEditDealStore();

  const [limit, setLimit] = useState<number>(deal.limit);
  const [newName, setNewName] = useState<string>(name);
  const [newDates, setNewDates] = useState<{
    startDate: Date;
    dueDate: Date;
    postingDate: Date;
    submissionDate: Date;
  }>({
    startDate: deal.startDate,
    dueDate: deal.dueDate,
    postingDate: deal.postingDate,
    submissionDate: deal.submissionDate,
  });
  const photoHolder = photos.map((photo) => {
    return { url: photo };
  });
  const [openCalendar, setOpenCalendar] = useState<number>(0);
  const [newPhotos, setNewPhotos] = useState<ImageProp[]>(photoHolder);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const isPastToday = (date: Date) => {
    return new Date(date).getTime() < new Date().getTime();
  };

  return (
    <Container>
      <EditCampaignContainer>
        <BoldH6Text>ภาพแคมเปญ</BoldH6Text>
        <ImageSelector
          // @ts-ignore
          images={photoHolder}
          haveLarge={true}
          smallImage={[1, 2, 3]}
          containerStyle={{ marginTop: "13px", marginBottom: "16px" }}
          onSelect={(photoArray: ImageProp[]) => {
            setNewPhotos(photoArray);
          }}
        />
        <WarningB2Text>
          *ไม่สามารถลบรูปได้ทั้งหมด ต้องมีรูปแคมเปญอย่างน้อย 1 รูป
        </WarningB2Text>
        <RInput
          label="ชื่อแคมเปญ"
          type="text"
          inputProp={{
            defaultValue: name,
            onChange: (e) => {
              setNewName(e.target.value);
            },
            maxLength: 80,
          }}
          containerStyle={{ marginTop: "24px" }}
          state={!!newName.trim() ? "normal" : "error"}
          message={!!newName.trim() ? "" : "*ไม่สามารถลบชื่อแคมเปญได้ "}
        />
        <LimitContainer>
          <BoldH6Text>
            จำนวน Influencer ที่ต้องการ <WarningBoldH6Text>*</WarningBoldH6Text>
          </BoldH6Text>
          <RCounter
            value={limit}
            limit={30}
            lowerLimit={1}
            changeValue={(newValue) => setLimit(newValue)}
          />
        </LimitContainer>
        <PeriodContainer>
          <CalendarContainer>
            <B3Text>
              วันที่เปิดรับสมัคร Influencer <WarningB3Text>*</WarningB3Text>
            </B3Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openCalendar === 1}
                onOpen={() => setOpenCalendar(1)}
                onClose={() => setOpenCalendar(0)}
                value={newDates.startDate}
                onChange={(newValue) => {
                  setNewDates({
                    ...newDates,
                    startDate: new Date(newValue || ""),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    onClick={() => setOpenCalendar(1)}
                  />
                )}
                inputFormat="DD/MM/YYYY"
                minDate={new Date()}
                maxDate={newDates.dueDate}
                // Customize by looking at class name through the browser
                InputProps={{
                  sx: {
                    width: "180px",
                    height: "49px",
                    marginTop: "8px",
                    borderRadius: "24px",
                  },
                }}
                disableHighlightToday
                disabled={active}
              />
            </LocalizationProvider>
          </CalendarContainer>
          <ArrowContainer>
            <RXIcon name="ArrowRight" />
          </ArrowContainer>
          <CalendarContainer style={{ marginLeft: "16px" }}>
            <B3Text>
              วันที่ปิดรับสมัคร Influencer <WarningB3Text>*</WarningB3Text>
            </B3Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openCalendar === 2}
                onOpen={() => setOpenCalendar(2)}
                onClose={() => setOpenCalendar(0)}
                value={newDates.dueDate}
                onChange={(newValue) => {
                  setNewDates({
                    ...newDates,
                    dueDate: new Date(newValue || ""),
                  });
                }}
                minDate={
                  isPastToday(deal.startDate) ? new Date() : deal.startDate
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    onClick={() => setOpenCalendar(2)}
                  />
                )}
                inputFormat="DD/MM/YYYY"
                InputProps={{
                  sx: {
                    width: "180px",
                    height: "49px",
                    marginTop: "8px",
                    borderRadius: "24px",
                  },
                }}
                disableHighlightToday
              />
            </LocalizationProvider>
          </CalendarContainer>
        </PeriodContainer>
        <GrayMB3Text>
          *แคมเปญที่เผยแพร่แล้ว
          สามารถแก้ไขวันปิดรับสมัครอินฟลูเอนเซอร์ได้เท่านั้น
        </GrayMB3Text>
        <PeriodContainer style={{ marginBottom: "24px" }}>
          <CalendarContainer>
            <B3Text>
              วันที่เริ่มโพสต์งาน <WarningB3Text>*</WarningB3Text>
            </B3Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openCalendar === 3}
                onOpen={() => setOpenCalendar(3)}
                onClose={() => setOpenCalendar(0)}
                value={newDates.postingDate}
                onChange={(newValue) => {
                  setNewDates({
                    ...newDates,
                    postingDate: new Date(newValue || ""),
                  });
                }}
                minDate={new Date()}
                maxDate={newDates.submissionDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    onClick={() => setOpenCalendar(3)}
                  />
                )}
                inputFormat="DD/MM/YYYY"
                InputProps={{
                  sx: {
                    width: "180px",
                    height: "49px",
                    marginTop: "8px",
                    borderRadius: "24px",
                  },
                }}
                disableHighlightToday
              />
            </LocalizationProvider>
          </CalendarContainer>
          <ArrowContainer>
            <RXIcon name="ArrowRight" />
          </ArrowContainer>
          <CalendarContainer style={{ marginLeft: "16px" }}>
            <B3Text>
              วันที่โพสต์งานวันสุดท้าย <WarningB3Text>*</WarningB3Text>
            </B3Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openCalendar === 4}
                onOpen={() => setOpenCalendar(4)}
                onClose={() => setOpenCalendar(0)}
                value={newDates.submissionDate}
                onChange={(newValue) => {
                  setNewDates({
                    ...newDates,
                    submissionDate: new Date(newValue || ""),
                  });
                }}
                minDate={
                  isPastToday(postingDate) ? new Date() : deal.postingDate
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      onClick={() => setOpenCalendar(4)}
                    />
                  );
                }}
                inputFormat="DD/MM/YYYY"
                InputProps={{
                  sx: {
                    width: "180px",
                    height: "49px",
                    marginTop: "8px",
                    borderRadius: "24px",
                  },
                }}
                disableHighlightToday
              />
            </LocalizationProvider>
          </CalendarContainer>
        </PeriodContainer>
        {isPastToday(deal.startDate) && (
          <WarningContainer>
            <WarningBoldB3Text>หมายเหตุ : </WarningBoldB3Text>
            <WarningB3Text>
              แคมเปญนี้กำลังดำเนินการอยู่
              การแก้ไขข้อมูลอาจก่อให้เกิดความสับสนระหว่างแบรนด์กับอินฟลูเอนเซอร์
            </WarningB3Text>
          </WarningContainer>
        )}
      </EditCampaignContainer>
      <ButtonsContainer>
        <RButton
          title="กลับ"
          onPress={back}
          backgroundColor={COLOR.WHITE}
          textColor={COLOR.Blue_700}
          hoverColor={COLOR.Blue_50}
        />
        <RButton
          title="บันทึก"
          onPress={() => setModalVisible(true)}
          active={newPhotos.length > 0 && !!newName}
        />
      </ButtonsContainer>
      <EditDealModal
        active={modalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={() => {
          setModalVisible(false);
          editDeal({
            dealId,
            photos: newPhotos,
            edittedName: newName,
            edittedLimit: limit,
            startDate: newDates.startDate,
            dueDate: newDates.dueDate,
            postingDate: newDates.postingDate,
            submissionDate: newDates.submissionDate,
          });
          back();
        }}
      />
    </Container>
  );
}

export default EditDealDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 745px;
`;

const EditCampaignContainer = styled.div`
  padding: 32px 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const LimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const PeriodContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 16px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ArrowContainer = styled.div`
  margin-top: 10px;
`;

const WarningContainer = styled.div`
  margin-top: 8px;
  padding-top: 24px;
  border-top: 1px solid ${COLOR.Gray_200};
`;

const ButtonsContainer = styled.div`
  display: flex;
  height: 120px;
  padding: 36px 72px;
  margin: 32px 0px 32px 0px;
  justify-content: space-between;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const WarningBoldH6Text = styled(BoldH6Text)`
  color: ${COLOR.Warning};
`;

const B3Text = styled(TEXT.B3_Reg)``;

const WarningB2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Warning};
`;

const WarningBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Warning};
`;
const WarningB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Warning};
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;
