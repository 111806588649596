import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import RModal from "../../atoms/RModal";
import ForgetPasswordComponent from "./LoginModalComponent/ForgetPasswordComponent";
import LoginComponent from "./LoginModalComponent/LoginComponent";
import RegisterComponent from "./LoginModalComponent/RegisterComponent";

interface Props {
  modalVisible: number;
  setPage: Dispatch<SetStateAction<number>>;
}

function LoginModal(props: Props) {
  const { modalVisible, setPage } = props;
  const navigate = useNavigate();

  const renderPage = () => {
    switch (modalVisible) {
      case 1:
        return <LoginComponent navigate={navigate} setPage={setPage} />;
      case 2:
        return <RegisterComponent setPage={setPage} />;
      case 3:
        return <ForgetPasswordComponent setPage={setPage} />;
    }
  };

  return (
    <RModal active={modalVisible !== 0} setActive={() => setPage(0)}>
      <Container>{renderPage()}</Container>
    </RModal>
  );
}

export default LoginModal;

const Container = styled.div`
  display: flex;
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
`;
