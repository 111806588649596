import { scopeOfWorkType } from "../../../class/ScopeOfWork";
import { tierType } from "../../../types/createDeal";
import { DealPerformanceTableData, DealsChartData } from "../../../types/deal";
import { TierWorkData, Work } from "../../../types/work";
import EngagementSummary from "./EngagementSummary";
import InfluencerPerformance from "./InfluencerPerformance";
import SummaryOverview from "./SummaryOverview";
import TierSummary from "./TierSummary";

export const MockedOverviewPerformanceChart = () => {
  return (
    <SummaryOverview.PerformanceChart
      chartData={mockedDealsChartData}
      platform={"INSTAGRAM"}
    />
  );
};

export const MockedEngagementSummary = () => {
  return (
    <EngagementSummary
      workEachTier={mockedWorkEachTier}
      dealPerformance={mockedDealPerformance}
      platform={"INSTAGRAM"}
      dealPostTypes={["IG_individual_post"]}
    />
  );
};

export const MockedTierSummary = () => {
  return (
    <TierSummary
      tierList={mockTierList}
      tierInsight={mockedWorkEachTier}
      platform={"INSTAGRAM"}
    />
  );
};

export const MockedInfluencerPerformance = () => {
  return (
    <InfluencerPerformance
      campaignWorks={mockedWorks}
      dealPostType={mockedScopeOfWorkType}
      dealTierType={mockedDealTierType}
      tableData={mockedDealPerformanceTableData}
      postTypes={mockedPostTypes}
      tierTypes={mockedTierTypes}
      platform={"INSTAGRAM"}
    />
  );
};

const mockedDealsChartData: DealsChartData = {
  daily: {
    labels: [
      new Date("2023-08-01"),
      new Date("2023-08-02"),
      new Date("2023-08-03"),
      new Date("2023-08-04"),
      new Date("2023-08-05"),
      new Date("2023-08-06"),
      new Date("2023-08-07"),
    ],
    engagement: [120, 180, 90, 210, 160, 140, 220],
    reach: [300, 340, 290, 370, 320, 280, 390],
    impression: [600, 670, 580, 720, 630, 610, 740],
    view: [200, 250, 190, 260, 230, 210, 280],
  },
  weekly: {
    labels: [
      new Date("2023-07-01"),
      new Date("2023-07-08"),
      new Date("2023-07-15"),
      new Date("2023-07-22"),
      new Date("2023-07-29"),
    ],
    engagement: [700, 850, 620, 870, 780],
    reach: [1500, 1650, 1400, 1700, 1580],
    impression: [3000, 3250, 2900, 3350, 3080],
    view: [1200, 1350, 1100, 1400, 1280],
  },
  monthly: {
    labels: [
      new Date("2023-01-01"),
      new Date("2023-02-01"),
      new Date("2023-03-01"),
      new Date("2023-04-01"),
      new Date("2023-05-01"),
      new Date("2023-06-01"),
      new Date("2023-07-01"),
    ],
    engagement: [2500, 2700, 2300, 2900, 2650, 2750, 3000],
    reach: [5000, 5300, 4700, 5500, 5250, 5400, 5600],
    impression: [10000, 10600, 9500, 11000, 10350, 10500, 11200],
    view: [4000, 4300, 3800, 4500, 4200, 4400, 4600],
  },
};

const mockedWorkEachTier: {
  nano: TierWorkData;
  micro: TierWorkData;
  mid: TierWorkData;
  macro: TierWorkData;
} = {
  nano: {
    works: [],
    totalLike: 1000,
    totalEngagement: 2000,
    totalImpressions: 50000,
    totalReach: 30000,
    totalReplies: 50,
    totalSaved: 200,
    totalComment: 100,
    totalShare: 70,
    cpe: "1.2",
    cpr: "0.9",
    cpm: "20.5",
    uniqueInfluencer: new Set(["influencer1", "influencer2"]),
  },
  micro: {
    works: [],
    totalLike: 5000,
    totalEngagement: 7000,
    totalImpressions: 100000,
    totalReach: 80000,
    totalReplies: 150,
    totalSaved: 400,
    totalComment: 300,
    totalShare: 150,
    cpe: "1.0",
    cpr: "0.7",
    cpm: "18.3",
    uniqueInfluencer: new Set(["influencer3", "influencer4"]),
  },
  mid: {
    works: [],
    totalLike: 10000,
    totalEngagement: 15000,
    totalImpressions: 200000,
    totalReach: 160000,
    totalReplies: 300,
    totalSaved: 600,
    totalComment: 500,
    totalShare: 300,
    cpe: "0.9",
    cpr: "0.6",
    cpm: "16.0",
    uniqueInfluencer: new Set(["influencer5", "influencer6"]),
  },
  macro: {
    works: [],
    totalLike: 25000,
    totalEngagement: 30000,
    totalImpressions: 500000,
    totalReach: 400000,
    totalReplies: 600,
    totalSaved: 1200,
    totalComment: 1000,
    totalShare: 700,
    cpe: "0.7",
    cpr: "0.5",
    cpm: "14.5",
    uniqueInfluencer: new Set(["influencer7", "influencer8"]),
  },
};

const mockedDealPerformance = {
  like: 45000,
  comment: 1900,
  replies: 1150,
  saved: 2400,
  impressions: 850000,
  engagement: 72000,
  reach: 670000,
  share: 1220,
  cpe: 1.2,
  cpr: 0.8,
  cpm: 15.5,
};

const mockTierList = {
  nano: {
    value: 1000,
    cash: 5000,
  },
  micro: {
    value: 3000,
    cash: 15000,
  },
  mid: {
    value: 5000,
    cash: 25000,
  },
  macro: {
    value: 10000,
    cash: 50000,
  },
};

const mockedWorks: Work[] = [
  {
    video_views: 5000,
    workId: "work123",
    replies: 100,
    shares: 50,
    dealContactId: "dealContact123",
    dealId: "deal456",
    postId: "post789",
    postType: "IG_individual_post",
    isDeleted: false,
    deletedAt: new Date(),
    username: "anotherdot.co",
    followers_count: 10000,
    isExist: true,
    createdAt: new Date("2023-07-01"),
    updatedAt: new Date("2023-08-01"),
    nextUpdateAt: new Date("2023-09-01"),
    accountId: "account789",
    influencerid: "influencer123",
    previousWork: [],
    taps_forward: 120,
    taps_back: 60,
    exits: 30,
    version: 2,
    influencerId: "",
    platform: "Facebook",
    id: "",
    postCreatedAt: new Date("2023-07-01"),
    like_count: 0,
    comments_count: 0,
    engagement: 0,
    reach: 0,
    impressions: 0,
    share_count: 0,
    title: "",
    video_description: "",
    cover_image_url: "",
    view_count: 0,
    height: 0,
    width: 0,
    share_url: "",
    duration: 0,
    embed_link: "",
    embed_html: "",
    create_time: 0,
    thumbnail_url:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    caption: "",
    media_type: "IMAGE",
    media_url:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post1.jpg",
    owner: {
      id: "",
    },
    shortcode: "",
    permalink: "https://www.instagram.com/p/C7nqLdHNrmA/",
    is_comment_enabled: false,
    saved: 0,
    timestamp: "",
    created_time: "",
    full_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post1.jpg",
    message: "",
    permalink_url:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post1.jpg",
    status_type: "",
    from: {
      id: "",
      name: "",
    },
  },
];

const mockedScopeOfWorkType: scopeOfWorkType[] = [
  "IG_individual_post",
  "IG_album_post",
];

const mockedDealTierType: tierType[] = ["nano", "micro", "mid", "macro"];

const mockedDealPerformanceTableData: DealPerformanceTableData[] = [
  {
    username: "influencer_1",
    profileImage: "https://example.com/profile1.jpg",
    posts: [
      {
        image: "https://example.com/image1.jpg",
        username: "influencer_1",
        caption: "Check out my latest post! #ad",
        permalink: "https://instagram.com/post_1",
        createdAt: new Date("2023-08-01"),
        like: 1500,
        comment: 120,
        share: 60,
        type: "IG_individual_post",
        engagement: 1680,
        cpe: 1.2,
        reach: 5000,
        cpr: 0.8,
        impression: 7000,
        cpm: 15.0,
      },
      {
        image: "https://example.com/image2.jpg",
        username: "influencer_2",
        caption: "Loving this new product! #sponsored",
        permalink: "https://instagram.com/post_2",
        createdAt: new Date("2023-07-15"),
        like: 3200,
        comment: 200,
        share: 90,
        type: "IG_individual_post",
        engagement: 3490,
        cpe: 1.1,
        reach: 11000,
        cpr: 0.9,
        impression: 14000,
        cpm: 14.5,
      },
      {
        image: "https://example.com/image3.jpg",
        username: "influencer_3",
        caption: "Amazing experience with this product! #collaboration",
        permalink: "https://instagram.com/post_3",
        createdAt: new Date("2023-06-10"),
        like: 2500,
        comment: 150,
        share: 80,
        type: "IG_individual_post",
        engagement: 2730,
        cpe: 1.3,
        reach: 9000,
        cpr: 0.7,
        impression: 12000,
        cpm: 16.0,
      },
    ],
    tier: "nano",
    status: "ongoing",
    budget: 500,
    totalEngagement: 1650,
    totalReach: 5000,
    totalImpression: 7000,
    totalCPE: 1.1,
    totalCPR: 0.8,
    totalCPM: 15.0,
    totalPosts: 1,
  },
  {
    username: "influencer_2",
    profileImage: "https://example.com/profile2.jpg",
    posts: [
      {
        image: "https://example.com/image1.jpg",
        username: "influencer_1",
        caption: "Check out my latest post! #ad",
        permalink: "https://instagram.com/post_1",
        createdAt: new Date("2023-08-01"),
        like: 1500,
        comment: 120,
        share: 60,
        type: "IG_individual_post",
        engagement: 1680,
        cpe: 1.2,
        reach: 5000,
        cpr: 0.8,
        impression: 7000,
        cpm: 15.0,
      },
      {
        image: "https://example.com/image2.jpg",
        username: "influencer_2",
        caption: "Loving this new product! #sponsored",
        permalink: "https://instagram.com/post_2",
        createdAt: new Date("2023-07-15"),
        like: 3200,
        comment: 200,
        share: 90,
        type: "IG_individual_post",
        engagement: 3490,
        cpe: 1.1,
        reach: 11000,
        cpr: 0.9,
        impression: 14000,
        cpm: 14.5,
      },
    ],
    tier: "micro",
    status: "done",
    budget: 1500,
    totalEngagement: 3850,
    totalReach: 12000,
    totalImpression: 15000,
    totalCPE: 1.2,
    totalCPR: 0.7,
    totalCPM: 14.0,
    totalPosts: 1,
  },
];

// Mock for tier types with associated budgets (as an example)
const mockedTierTypes: Record<tierType, number> = {
  nano: 500,
  micro: 1500,
  mid: 5000,
  macro: 10000,
};

// Mock for scope of work types with associated counts (as an example)
const mockedPostTypes: Record<scopeOfWorkType, number> = {
  IG_individual_post: 10,
  IG_album_post: 0,
  IG_story: 0,
  IG_tv: 0,
  IG_reels: 0,
  TK_video: 0,
  LM_article: 0,
  FB_individual_post: 0,
  FB_album_post: 0,
  FB_reels: 0,
};
