import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { FontNotoSansRegular } from "../../fonts/font";
import { TEXT } from "../../fonts/text";
import { ColumnContainer } from "../../utils/styling/general";

export type InputStateProps = "normal" | "error" | "check";

interface StateProps {
  state?: InputStateProps;
}

interface LabelProps {
  haveIcon: boolean;
}

interface InputProps {
  state: InputStateProps;
}

interface Props {
  icon?: IconName;
  iconSize?: number;
  customIcon?: any;
  label?: string;
  type: "text" | "password" | "number";
  containerStyle?: React.CSSProperties;
  password?: boolean;
  required?: boolean;
  state?: InputStateProps;
  message?: string;
  messageArray?: Array<string>;
  inputProp?: InputHTMLAttributes<HTMLInputElement>;
  noBold?: boolean;
  onClear?: () => void;
  clearActive?: boolean;
  inputIcon?: IconName;
}

const RInput = (props: Props) => {
  const {
    icon,
    iconSize = 24,
    customIcon,
    label,
    type,
    containerStyle,
    password = false,
    required = false,
    state = "normal",
    message = " ",
    messageArray,
    inputProp,
    noBold = false,
    onClear,
    clearActive,
    inputIcon,
  } = props;
  const [hidePassword, setHidePassword] = useState(false);

  useEffect(() => {
    if (password) {
      setHidePassword(password);
    }
  }, [password]);

  return (
    <Container style={containerStyle}>
      <LabelContainer>
        {icon && <RXIcon name={icon} size={iconSize} />}
        {customIcon}
        {noBold ? (
          <LabelText haveIcon={!!(icon || customIcon)}>{label}</LabelText>
        ) : (
          <BoldLabelText haveIcon={!!(icon || customIcon)}>
            {label}
          </BoldLabelText>
        )}
        {required && <RequiredText>&nbsp;*</RequiredText>}
      </LabelContainer>
      <InputContainer>
        <Input
          type={type === "password" && hidePassword ? "password" : "text"}
          state={state}
          {...inputProp}
        />
        {password && (
          <InputButton onClick={() => setHidePassword(!hidePassword)}>
            <RXIcon
              name={hidePassword ? "EyeOff" : "EyeOn"}
              size={24}
              color={COLOR.Gray_300}
            />
          </InputButton>
        )}
        {!!onClear && clearActive && (
          <InputButton onClick={onClear}>
            <RXIcon name="Close" color={COLOR.Gray_300} />
          </InputButton>
        )}
        {!!inputIcon && !clearActive && (
          <InputButton onClick={onClear}>
            <RXIcon name={inputIcon} color={COLOR.Gray_300} />
          </InputButton>
        )}
      </InputContainer>

      {messageArray ? (
        messageArray?.map((message) => {
          return (
            <ColumnContainer style={{ alignItems: "flex-start" }}>
              <MessageText state={state}>{message}</MessageText>
            </ColumnContainer>
          );
        })
      ) : (
        <MessageText state={state}>{message}</MessageText>
      )}
    </Container>
  );
};

export default RInput;

const Container = styled.div``;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 10px;
`;

const LabelText = styled(TEXT.H7_Reg)<LabelProps>`
  margin-left: ${(props) => (props.haveIcon ? "8px" : "0")};
`;

const BoldLabelText = styled(TEXT.H7_Bold)<LabelProps>`
  margin-left: ${(props) => (props.haveIcon ? "8px" : "0")};
`;

const RequiredText = styled(TEXT.H7_Bold)`
  color: ${COLOR.Warning};
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

const InputButton = styled.button`
  position: absolute;
  top: 13px;
  right: 22px;
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }
`;

const Input = styled.input<InputProps>`
  font-family: ${FontNotoSansRegular};
  font-weight: 400;
  font-size: 16px;
  line-height: 20.48px;
  display: flex;
  flex: 1;
  height: 48px;
  border: none;
  border-radius: 24px;
  padding: 15px 24px;
  background: ${COLOR.Gray_50};
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 9px;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLOR.Gray_300};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${COLOR.Gray_300};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLOR.Gray_300};
  }

  :not(:placeholder-shown) {
    background-color: ${COLOR.WHITE};
    border: 1px solid;
    border-color: ${(props) =>
      props.state === "normal"
        ? COLOR.Gray_300
        : props.state === "error"
        ? COLOR.Warning
        : COLOR.Blue_700};
  }

  :focus {
    background-color: ${COLOR.White};
    outline: none;
    border: 1px solid ${COLOR.Black};
    box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  }
`;

const MessageText = styled(TEXT.B4_Reg)<StateProps>`
  color: ${(props) =>
    props.state === "error"
      ? COLOR.Warning
      : props.state === "check"
      ? COLOR.Blue_700
      : COLOR.Gray_M};
  padding-left: 24px;
  display: inline-block;
`;
