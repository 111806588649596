import { observer } from "mobx-react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { PostMedia } from "../../../class/ScopeOfWork";
import { ANDCOLOR } from "../../../fonts/andColor";
import { ANDTEXT } from "../../../fonts/andText";
import { COLOR } from "../../../fonts/color";
import {
  ITierType,
  documentType,
  influencerCaption,
  newDealKeys,
} from "../../../types/createDeal";
import { toCapitalize } from "../../../utils";
import Brief from "../../molecules/NewCreateCampaign/ScopeOfWork/Brief";
import Document from "../../molecules/NewCreateCampaign/ScopeOfWork/Document";
import Example from "../../molecules/NewCreateCampaign/ScopeOfWork/Example";
import Hashtag from "../../molecules/NewCreateCampaign/ScopeOfWork/Hashtag";
import InfluencerReward from "../../molecules/NewCreateCampaign/ScopeOfWork/InfluencerReward";
import PostSelector from "../../molecules/NewCreateCampaign/ScopeOfWork/PostSelector";
import { ImageProp } from "../CreateCampaign/ImageSelector";

interface Props {
  deal: newDealKeys;
  setDealData: (deal: newDealKeys) => void;
  triggerHeightChange: boolean;
  setTriggerHeightChange: React.Dispatch<React.SetStateAction<boolean>>;
}

function ScopeOfWork(props: Props) {
  const { deal, setDealData, triggerHeightChange, setTriggerHeightChange } =
    props;
  const {
    platforms,
    influencersTier,
    influencersCaption,
    hashtags,
    examples,
    benefits,
    mustReturnProduct,
    isValue,
    value,
    isCash,
    isNegotiateCash,
    posts,
    documents,
  } = deal;

  const { postMedias } = posts;

  const haveNano = influencersTier.nano.value > 0;
  const haveMicro = influencersTier.micro.value > 0;
  const haveMid = influencersTier.mid.value > 0;
  const haveMacro = influencersTier.macro.value > 0;

  const setDealCaption = (caption: influencerCaption) => {
    setDealData({ ...deal, influencersCaption: caption });
  };

  const setDealHashtags = (hashtags: string[]) => {
    setDealData({ ...deal, hashtags });
  };

  const setDealExamples = (examples: ImageProp[]) => {
    setDealData({ ...deal, examples });
  };

  const setDealBenefits = (benefits: string) => {
    setDealData({ ...deal, benefits });
  };

  const setDealReturnProduct = (mustReturnProduct: boolean) => {
    setDealData({ ...deal, mustReturnProduct });
  };

  const setDealValue = (value: number) => {
    setDealData({ ...deal, value });
  };

  const setDealIsValue = (isValue: boolean) => {
    setDealData({ ...deal, isValue });
  };

  const setDealIsCash = (isCash: boolean) => {
    setDealData({ ...deal, isCash });
  };

  const setDealInfluLevel = (level: ITierType) => {
    setDealData({ ...deal, influencersTier: level });
  };

  const setDealIsNegotiateCash = (isNegotiateCash: boolean) => {
    setDealData({ ...deal, isNegotiateCash });
  };

  const setDealPostMedia = (postMedia: PostMedia) => {
    setDealData({
      ...deal,
      posts: {
        ...deal.posts,
        postMedias: [...deal.posts.postMedias, postMedia],
      },
    });
  };

  const setDealDocuments = (documents: documentType[]) => {
    setDealData({
      ...deal,
      documents,
    });
  };

  const deletePostMedias = (id: string) => {
    deal.posts.postMedias = deal.posts.postMedias.filter(
      (media) => media.id !== id
    );
  };

  const deleteDocuments = (url: string) => {
    deal.documents = deal.documents.filter((document) => document.url !== url);
  };

  const PlatformLogo = () => {
    switch (platforms) {
      case "INSTAGRAM":
        return (
          <Image
            src={require("../../../assets/images/PlatformLogos/Instagram.png")}
          />
        );
      case "TIKTOK":
        return (
          <Image
            src={require("../../../assets/images/PlatformLogos/Tiktok.png")}
          />
        );
      case "FACEBOOK":
        return <RXIcon name="FacebookCircle" color={ANDCOLOR.BLUE} />;
      case "LEMON":
        return (
          <Image
            src={require("../../../assets/images/PlatformLogos/Lemon.png")}
          />
        );
    }
  };

  const PlatformComponent = () => {
    return (
      <PlatformContainer>
        <PlatformLogo />
        <PlatformText>{toCapitalize(platforms || "")}</PlatformText>
      </PlatformContainer>
    );
  };

  return (
    <Container>
      <BoldHeader7Text>Create Campaign</BoldHeader7Text>
      <BoldHeader4Text>Scope Of Work</BoldHeader4Text>
      <Body2Text>ระบุขอบเขตงานที่ Influencer ต้องทำ</Body2Text>
      <PlatformComponent />
      <ContentContainer>
        <PostSelector
          postMedias={postMedias}
          setPostMedias={setDealPostMedia}
          deletePostMedias={deletePostMedias}
          platform={platforms}
        />
        <Brief
          haveMacro={haveMacro}
          haveNano={haveNano}
          haveMicro={haveMicro}
          haveMid={haveMid}
          caption={influencersCaption}
          setCaption={setDealCaption}
        />
        <Hashtag hashtags={hashtags} setHashtags={setDealHashtags} />
        <Example examples={examples} setExamples={setDealExamples} />
        {/* <Document
         //TODO : Re implement 
          documents={documents}
          setDocument={setDealDocuments}
          deleteDocument={deleteDocuments}
        /> */}
        <InfluencerReward
          benefits={benefits}
          setBenefits={setDealBenefits}
          returnProduct={mustReturnProduct}
          setReturnProduct={setDealReturnProduct}
          value={value}
          setValue={setDealValue}
          isCash={isCash}
          isValue={isValue}
          setIsCash={setDealIsCash}
          setIsValue={setDealIsValue}
          influencersTier={influencersTier}
          setInfluLevel={setDealInfluLevel}
          isNegotiateCash={isNegotiateCash}
          setIsNegotiateCash={setDealIsNegotiateCash}
          platform={platforms}
          triggerHeightChange={triggerHeightChange}
          setTriggerHeightChange={setTriggerHeightChange}
        />
      </ContentContainer>
    </Container>
  );
}

export default observer(ScopeOfWork);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 688px;
  padding-bottom: 72px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 688px;
  gap: 64px;
`;

const PlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 72px;
  align-items: center;
  width: 688px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLOR.Gray_300};
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const PlatformText = styled(ANDTEXT.header7BOLD)``;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const BoldHeader4Text = styled(ANDTEXT.header4BOLD)`
  margin-top: 20px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  margin-top: 12px;
`;
