import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { DiscoverPage } from "../../../../pages/DiscoverPage";
import RLoading from "../../../atoms/RLoading";
import ScrollToTop from "../../../atoms/ScrollToTop";
import FilterModal from "../../../molecules/Discover/FilterModal";
import DiscoverExpandWorkDetail from "../../../molecules/Discover/Influencer/DiscoverExpandWorkDetail";
interface Props {
  feed: {
    data: any;
    metadata: {
      count: number;
      offset: number;
      limit: number;
    };
    next: () => void;
  };
  loading: boolean;
  navigate: NavigateFunction;
  page: DiscoverPage;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  setLastPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
}

function DiscoverExpandWork(props: Props) {
  return (
    <Container>
      <ScrollToTop />
      <DiscoverExpandWorkDetail {...props} />
    </Container>
  );
}

export default observer(DiscoverExpandWork);

const Container = styled.div`
  width: 100%;
`;
