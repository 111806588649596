import Lottie from "react-lottie";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function InviteConfirmModal(props: Props) {
  const { active, setActive } = props;

  return (
    <RModal
      active={!!active}
      setActive={() => {
        setActive(false);
      }}
    >
      <Container>
        <div>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: require("../../../assets/images/ModalSent.json"),
            }}
            width={175}
            height={138}
            style={{ marginTop: 24 }}
          />
        </div>
        <BoldH5Text style={{ marginBottom: "8px" }}>ส่งคำเชิญแล้ว</BoldH5Text>
        <B2Text>
          ส่งคำเชิญให้อินฟลูเอนเซอร์มาสมัคร
          <br />
          แคมเปญนี้แล้ว โปรดตรวจสอบอีกครั้งที่
          <br />
        </B2Text>
        <RowContainer>
          <BoldB2Text>"คำขอใหม่"</BoldB2Text>
          <B2Text>หากอินฟลูเอนเซอร์สมัครเข้ามา</B2Text>
        </RowContainer>
        <div style={{ marginTop: "24px", marginBottom: "32px" }}>
          <RButton
            title="เข้าใจแล้ว"
            onPress={() => {
              setActive(false);
            }}
            containerStyle={{ width: "136px" }}
            active={true}
          />
        </div>
      </Container>
    </RModal>
  );
}

export default InviteConfirmModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 389px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 24px;
  background: ${COLOR.WHITE};
`;

const BoldH5Text = styled(TEXT.H5_Bold)`
  margin-top: 16px;
`;

const B2Text = styled(TEXT.B2_Reg)``;

const BoldB2Text = styled(TEXT.B2_Bold)``;
