import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { toDecimalNumber } from "../../../utils";
import { RowContainer } from "../../../utils/styling/general";

interface Props {
  value: number;
  rate: number | string;
}

const PostTableValueRate = (props: Props) => {
  const { value, rate } = props;
  return (
    <GapRowContainer>
      <BoldH8Text>
        {toDecimalNumber({ number: value, isZeroDash: false })}
      </BoldH8Text>
      <GrayDH9Text>{`(${rate} ฿)`}</GrayDH9Text>
    </GapRowContainer>
  );
};

export default PostTableValueRate;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const BoldH8Text = styled(TEXT.H8_Bold)``;

const GrayDH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.Gray_D};
`;
