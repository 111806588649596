import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultDrawer from "./DefaultDrawer";
import PerformanceDrawer from "./PerformanceDrawer";
import CampaignDrawer from "./CampaignDrawer";
import InfluencerManagementDrawer from "./InfluencerManagementDrawer";
import { useQuery } from "../../../utils";

const Drawer = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const query = useQuery();
  const queryStatus =
    (query.get("status") as "pending" | "ongoing" | "check" | "done") ||
    "check";
  const queryDealId = query.get("dealId") as string;
  const queryDealContactId = query.get("dealContactId") as string;

  if (pathname.includes("/performance")) {
    return <PerformanceDrawer navigation={navigation} />;
  }

  if (pathname.includes("/deal")) {
    return <CampaignDrawer navigation={navigation} pathname={pathname} />;
  }

  if (pathname.includes("/influencer")) {
    return (
      <InfluencerManagementDrawer
        navigation={navigation}
        queryDealContactId={queryDealContactId}
        queryDealId={queryDealId}
        queryStatus={queryStatus}
      />
    );
  }

  return <DefaultDrawer navigation={navigation} pathname={pathname} />;
};

export default observer(Drawer);
