import React from "react";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { Work } from "../../types/work";
import {
  ColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../utils/styling/general";
import { TEXT } from "../../fonts/text";
import { IconName, RXIcon } from "rn-rx-icons";
import {
  PLACEHOLDER_ANOTHERDOT,
  hexToRgba,
  toDecimalNumber,
  toThaiDate,
} from "../../utils";
import { InfluencerPost } from "../../types/api/getInfluencer";
import { PlatformType } from "../../types/deal";

interface OnLinkClickProps {
  permalink: string;
}

const onLinkClick = (props: OnLinkClickProps) => {
  const { permalink } = props;
  if (permalink.startsWith("https://")) window.open(permalink);
  else window.open("https://" + permalink);
};

interface OnProfileClickProps {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  username: string;
  permalink: string;
  platformActive: PlatformType;
}

const onProfileClick = (props: OnProfileClickProps) => {
  const { e, username, permalink, platformActive } = props;
  e.stopPropagation();
  e.preventDefault();
  switch (platformActive) {
    case "INSTAGRAM":
      window.open(`https://instagram.com/${username}`);
      break;
    case "TIKTOK":
      window.open(`https://tiktok.com/@${username}`);
      break;
    case "FACEBOOK":
      if (permalink?.startsWith("https://")) window.open(permalink);
      else window.open("https://" + permalink);
      break;
  }
};
interface ValueComponentProps {
  title: string;
  value: number;
}

const ValueComponent = (props: ValueComponentProps) => {
  const { title, value } = props;
  return (
    <ColumnContainer>
      <BoldWhiteH6Text>
        {toDecimalNumber({ number: value, isZeroDash: false })}
      </BoldWhiteH6Text>
      <WhiteH9Text>{title}</WhiteH9Text>
    </ColumnContainer>
  );
};

interface HoverComponentProps {
  platformActive: PlatformType;
  engagement: number;
  reach: number;
  impression: number;
  view: number;
  permalink: string;
}

const HoverComponent = (props: HoverComponentProps) => {
  const { platformActive, engagement, reach, impression, view, permalink } =
    props;
  if (platformActive === "TIKTOK")
    return (
      <HoverDetailContainer onClick={() => onLinkClick({ permalink })}>
        <ValueComponent title="Engagement" value={engagement} />
        <ValueComponent title="Impression" value={view} />
      </HoverDetailContainer>
    );
  return (
    <HoverDetailContainer onClick={() => onLinkClick({ permalink })}>
      <ValueComponent title="Engagement" value={engagement} />
      <ValueComponent title="Reach" value={reach} />
      <ValueComponent title="Impression" value={impression} />
    </HoverDetailContainer>
  );
};
interface Props {
  work: InfluencerPost;
  platformActive: PlatformType;
}

const Post = (props: Props) => {
  const { work, platformActive } = props;

  if (!work) return <></>;
  const {
    image,
    username,
    createdAt,
    caption,
    like,
    comment,
    share,
    permalink,
    engagement,
    reach,
    impression,
    view,
    saved,
    profile_picture_url,
  } = work;

  const shareOrSavedValue = platformActive === "INSTAGRAM" ? saved : share;
  const shareOrSavedIcon: IconName =
    platformActive === "INSTAGRAM" ? "Save" : "Share";

  return (
    <Container>
      <HoverComponent
        engagement={engagement}
        reach={reach}
        impression={impression}
        view={view}
        platformActive={platformActive}
        permalink={permalink}
      />
      <ImageContainer src={image || PLACEHOLDER_ANOTHERDOT} />
      <DetailContainer>
        <FullWidthColumnContainer>
          <FullWidthRowContainer>
            <GapRowContainer
              onClick={(e) =>
                onProfileClick({ e, permalink, username, platformActive })
              }
            >
              <ProfileImageContainer src={profile_picture_url} />
              <BoldB3Text>{username}</BoldB3Text>
            </GapRowContainer>
            <GrayMB5Text>{toThaiDate(createdAt)}</GrayMB5Text>
          </FullWidthRowContainer>
          <B5Text>{caption}</B5Text>
        </FullWidthColumnContainer>
        <GapColumnContainer>
          <Dashline />
          <FullWidthRowContainer>
            <RowContainer style={{ gap: 8 }}>
              <IconRowContainer>
                <RXIcon name="Heart" size={16} color={COLOR.Gray_D} />
                <GrayDB4Text>
                  {toDecimalNumber({ number: like, isZeroDash: false })}
                </GrayDB4Text>
              </IconRowContainer>
              <IconRowContainer>
                <RXIcon name="Chat" size={16} color={COLOR.Gray_D} />
                <GrayDB4Text>
                  {toDecimalNumber({ number: comment, isZeroDash: false })}
                </GrayDB4Text>
              </IconRowContainer>
              <IconRowContainer>
                <RXIcon
                  name={shareOrSavedIcon}
                  size={16}
                  color={COLOR.Gray_D}
                />
                <GrayDB4Text>
                  {toDecimalNumber({
                    number: shareOrSavedValue,
                    isZeroDash: false,
                  })}
                </GrayDB4Text>
              </IconRowContainer>
            </RowContainer>
            <LinkContainer onClick={() => onLinkClick({ permalink })}>
              <RXIcon name="ExternalLink" size={16} color={COLOR.Gray_D} />
              <GrayDB4Text>ดูโพสต์</GrayDB4Text>
            </LinkContainer>
          </FullWidthRowContainer>
        </GapColumnContainer>
      </DetailContainer>
    </Container>
  );
};

export default Post;

const Container = styled(ColumnContainer)`
  position: relative;
  border-radius: 12px;
  border-right: 0.6px solid ${COLOR.Gray_400};
  border-bottom: 0.6px solid ${COLOR.Gray_400};
  border-left: 0.6px solid ${COLOR.Gray_400};
  width: 273px;
  height: 424px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 12px 12px 12px;
  width: 100%;
  height: 132px;
`;

const ImageContainer = styled.img`
  width: 272px;
  height: 282px;
  border-radius: 12px 12px 0px 0px;
  object-fit: cover;
`;

const HoverDetailContainer = styled(ColumnContainer)`
  position: absolute;
  width: 272px;
  height: 282px;
  border-radius: 12px 12px 0px 0px;
  justify-content: center;
  gap: 21px;
  z-index: 999;
  transition: all 0.3s;
  opacity: 0;
  :hover {
    cursor: pointer;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const ProfileImageContainer = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const FullWidthColumnContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 4px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const IconRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const GapColumnContainer = styled(FlexStartColumnContainer)`
  gap: 12px;
  width: 100%;
`;

const LinkContainer = styled(RowContainer)`
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const Dashline = styled.div`
  height: 0.6px;
  width: 100%;
  background-color: ${COLOR.Gray_300};
`;

const BoldB3Text = styled(TEXT.B3_Bold)`
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GrayMB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_M};
`;

const GrayDB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;

const B5Text = styled(TEXT.B5_Reg)`
  width: 241px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const BoldWhiteH6Text = styled(TEXT.H6_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;
