import { observer } from "mobx-react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { clearArray } from "../../../utils";
import RButton from "../../atoms/RButton";
import RInput from "../../atoms/RInput";

interface Props {
  setAuthLogin: ({
    value,
    property,
  }: {
    value: string;
    property: "email" | "password";
  }) => void;
  login: () => Promise<void>;
  authLoginError: string[];
  isLoginActive: boolean;
  loading: boolean;
}

function LoginCardDetail(props: Props) {
  const { setAuthLogin, login, authLoginError, isLoginActive, loading } = props;

  const emailError =
    authLoginError.includes("ไม่พบอีเมลในระบบ") ||
    authLoginError.includes(
      "กรุณายืนยันอีเมลก่อนเข้าสู่ระบบ หากไม่ได้รับอีเมลกรุณาติดต่อ @picklethailand"
    );

  const errorMessage = authLoginError.includes("ไม่พบอีเมลในระบบ")
    ? "ไม่พบอีเมลในระบบ โปรดตรวจสอบอีกครั้ง"
    : authLoginError[0];

  const passwordError = authLoginError.includes("กรอกรหัสผ่านไม่ถูกต้อง");

  useEffect(() => {
    clearArray(authLoginError);
  }, []);

  return (
    <Container>
      <HeaderText>เข้าสู่ระบบสำหรับแบรนด์</HeaderText>
      <BoxContainer>
        <RInput
          icon="Email"
          label="อีเมลสำหรับเข้าใช้งาน"
          type="text"
          state={emailError ? "error" : "normal"}
          inputProp={{
            onChange: (e) =>
              setAuthLogin({ value: e.target.value, property: "email" }),
            placeholder: "youremail@email.com",
          }}
          message={emailError ? errorMessage : " "}
        />
      </BoxContainer>
      <BoxContainer>
        <RInput
          icon="Lock"
          label="รหัสผ่าน"
          type="password"
          state={passwordError ? "error" : "normal"}
          inputProp={{
            onChange: (e) =>
              setAuthLogin({ value: e.target.value, property: "password" }),
            placeholder: "password",
          }}
          password={true}
          message={passwordError ? "รหัสผ่านไม่ถูกต้อง" : " "}
        />
      </BoxContainer>
      <ShadowTextConatiner to={forgotpasswordLink}>
        <LinkText>ลืมรหัสผ่าน?</LinkText>
      </ShadowTextConatiner>
      <ButtonContainer>
        <RButton
          title="เข้าสู่ระบบ"
          onPress={login}
          active={!loading && isLoginActive}
        />
      </ButtonContainer>
    </Container>
  );
}

export default LoginCardDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled(TEXT.H4_Bold)``;

const BoxContainer = styled.div`
  margin-top: 14px;
`;

const ShadowTextConatiner = styled(Link)`
  color: ${COLOR.Gray_300};
  text-decoration: none;
  width: 90px;
  margin-top: 14px;
`;

const LinkText = styled(TEXT.Bu2_Reg)`
  color: ${COLOR.Gray_M};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: row;
  justify-content: flex-end;
  margin-top: 44px;
`;

const forgotpasswordLink = `/forgotpassword`;
