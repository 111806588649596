import { ITierType, tierType } from '../../types/createDeal';
import {
  Deal,
  DealPerformance,
  DealPerformancePost,
  DealPerformanceTableData,
  PlatformType,
} from '../../types/deal';
import { TierWorkData, Work, WorkEachTier } from '../../types/work';
import {
  PLACEHOLDER_ANOTHERDOT,
  calculateAverageData,
  getTierByFollower,
} from '../../utils';

export const getBarChartLabels = (
  platform: PlatformType,
  haveStory: boolean
) => {
  const result: string[] = ['Like', 'Comment', 'Share'];
  if (haveStory) result.push('Reply');
  if (platform === 'INSTAGRAM') result.push('Save');
  return result;
};

export const formatPerformanceBarData = (
  work: TierWorkData,
  platform: PlatformType,
  haveStory: boolean
) => {
  const { totalLike, totalComment, totalShare, totalReplies, totalSaved } =
    work;
  const result: number[] = [];
  result.push(totalLike, totalComment, totalShare);
  if (haveStory) result.push(totalReplies);
  if (platform === 'INSTAGRAM') result.push(totalSaved);
  return result;
};

export const convertInfTierToTierList = (
  influencerTier: ITierType
): tierType[] => {
  const result: tierType[] = [];
  for (const key in influencerTier) {
    const tier = key as tierType;
    if (Object.prototype.hasOwnProperty.call(influencerTier, tier)) {
      const element: { value: number; cash: number } = influencerTier[tier];
      if (element.value > 0) result.push(tier);
    }
  }
  return result;
};

export const emptyWorkEachTier = {
  nano: {
    uniqueInfluencer: new Set(),
    works: [],
    totalLike: 0,
    totalEngagement: 0,
    totalImpressions: 0,
    totalReach: 0,
    totalReplies: 0,
    totalSaved: 0,
    totalComment: 0,
    totalShare: 0,
    cpe: '0',
    cpr: '0',
    cpm: '0',
  },
  micro: {
    uniqueInfluencer: new Set(),
    works: [],
    totalLike: 0,
    totalEngagement: 0,
    totalImpressions: 0,
    totalReach: 0,
    totalReplies: 0,
    totalSaved: 0,
    totalComment: 0,
    totalShare: 0,
    cpe: '0',
    cpr: '0',
    cpm: '0',
  },
  mid: {
    uniqueInfluencer: new Set(),
    works: [],
    totalLike: 0,
    totalEngagement: 0,
    totalImpressions: 0,
    totalReach: 0,
    totalReplies: 0,
    totalSaved: 0,
    totalComment: 0,
    totalShare: 0,
    cpe: '0',
    cpr: '0',
    cpm: '0',
  },
  macro: {
    uniqueInfluencer: new Set(),
    works: [],
    totalLike: 0,
    totalEngagement: 0,
    totalImpressions: 0,
    totalReach: 0,
    totalReplies: 0,
    totalSaved: 0,
    totalComment: 0,
    totalShare: 0,
    cpe: '0',
    cpr: '0',
    cpm: '0',
  },
};

export const calculatePerformanceData = ({
  works,
  deal,
}: {
  works: Work[];
  deal: Deal;
}) => {
  if (!works || !deal) {
    return;
  }

  let workEachTier: WorkEachTier = {
    nano: { ...emptyWorkEachTier.nano, uniqueInfluencer: new Set() },
    micro: { ...emptyWorkEachTier.micro, uniqueInfluencer: new Set() },
    mid: { ...emptyWorkEachTier.mid, uniqueInfluencer: new Set() },
    macro: { ...emptyWorkEachTier.macro, uniqueInfluencer: new Set() },
  };

  let dealPerformance: DealPerformance = {
    like: 0,
    comment: 0,
    replies: 0,
    saved: 0,
    impressions: 0,
    engagement: 0,
    reach: 0,
    share: 0,
    cpe: 0,
    cpr: 0,
    cpm: 0,
  };

  let performanceTableData: DealPerformanceTableData[] = [];

  let campaignTotalLike = 0;
  let campaignTotalComment = 0;
  let campaignTotalEngagement = 0;
  let campaignTotalImpressions = 0;
  let campaignTotalReach = 0;
  let campaignTotalReplies = 0;
  let campaignTotalSaved = 0;
  let campaignTotalShare = 0;

  works.map((campaignWork: Work) => {
    const {
      like_count,
      followers_count,
      comments_count,
      engagement,
      impressions,
      reach,
      replies,
      saved,
      share_count,
      postType,
      view_count,
      username,
    } = campaignWork;

    const isStory = postType === 'IG_story';
    const tier = getTierByFollower(followers_count);

    const replyCount = isStory ? comments_count : replies;
    const impressionView = postType.startsWith('TK') ? view_count : impressions;

    workEachTier[tier].uniqueInfluencer.add(username);
    workEachTier[tier].works.push(campaignWork);
    workEachTier[tier].totalLike += like_count || 0;
    workEachTier[tier].totalComment += comments_count || 0;
    workEachTier[tier].totalEngagement += engagement || 0;
    workEachTier[tier].totalImpressions += impressionView || 0;
    workEachTier[tier].totalReach += reach || 0;
    workEachTier[tier].totalReplies += replyCount || 0;
    workEachTier[tier].totalSaved += saved || 0;
    workEachTier[tier].totalShare += share_count || 0;

    campaignTotalLike += like_count || 0;
    campaignTotalComment += comments_count || 0;
    campaignTotalEngagement += engagement || 0;
    campaignTotalImpressions += impressionView || 0;
    campaignTotalReach += reach || 0;
    campaignTotalReplies += replyCount || 0;
    campaignTotalSaved += saved || 0;
    campaignTotalShare += share_count || 0;
  });

  const tierList: tierType[] = ['nano', 'micro', 'mid', 'macro'];

  tierList.map((tier) => {
    const budgetPerTier = deal.influencersTier[tier].cash + deal.value;
    workEachTier[tier].cpe = calculateAverageData(
      budgetPerTier,
      workEachTier[tier].totalEngagement
    );
    workEachTier[tier].cpr = calculateAverageData(
      budgetPerTier,
      workEachTier[tier].totalReach
    );
    workEachTier[tier].cpm = calculateAverageData(
      budgetPerTier,
      workEachTier[tier].totalImpressions
    );
  });

  dealPerformance = {
    like: campaignTotalLike,
    comment: campaignTotalComment,
    replies: campaignTotalReplies,
    saved: campaignTotalSaved,
    impressions: campaignTotalImpressions,
    engagement: campaignTotalEngagement,
    reach: campaignTotalReach,
    share: campaignTotalShare,
    cpe: 0,
    cpr: 0,
    cpm: 0,
  };
  const tableData: DealPerformanceTableData[] = [];

  const usernameMap: { [key: string]: DealPerformanceTableData } = {};
  let campaignTotalCPE = 0;
  let campaignTotalCPR = 0;
  let campaignTotalCPM = 0;

  works.forEach((work) => {
    const {
      username,
      cover_image_url = PLACEHOLDER_ANOTHERDOT,
      caption = '',
      permalink = '',
      createdAt = new Date(),
      like_count = 0,
      comments_count = 0,
      share_count = 0,
      engagement = 0,
      reach = 0,
      impressions = 0,
      postType,
      followers_count,
      view_count = 0,
      replies = 0,
    } = work;

    const tier = getTierByFollower(followers_count);
    const budget = deal.influencersTier[tier].cash + deal.value;
    const impressionView = postType.startsWith('TK') ? view_count : impressions;
    const commentOrReply = postType === 'IG_story' ? replies : comments_count;

    if (!usernameMap[username]) {
      usernameMap[username] = {
        username,
        posts: [],
        budget,
        profileImage: PLACEHOLDER_ANOTHERDOT,
        tier,
        status: 'done',
        totalCPE: 0,
        totalCPM: 0,
        totalCPR: 0,
        totalEngagement: 0,
        totalImpression: 0,
        totalPosts: 0,
        totalReach: 0,
      };
      tableData.push(usernameMap[username]);
    }

    const post: DealPerformancePost = {
      image: cover_image_url,
      username,
      caption,
      permalink,
      createdAt,
      like: like_count,
      comment: commentOrReply,
      share: share_count,
      type: postType,
      engagement,
      cpe: 0,
      reach,
      cpr: 0,
      impression: impressionView,
      cpm: 0,
    };

    const user = usernameMap[username];
    user.posts.push(post);
    user.totalEngagement += engagement;
    user.totalImpression += impressionView;
    user.totalReach += reach;
    user.totalPosts += 1;
  });

  Object.values(usernameMap).forEach((user) => {
    const budget =
      deal.influencersTier[user.tier as tierType].cash + deal.value;

    user.totalCPE = parseFloat(
      calculateAverageData(budget, user.totalEngagement)
    );
    user.totalCPR = parseFloat(calculateAverageData(budget, user.totalReach));
    user.totalCPM = parseFloat(
      calculateAverageData(budget, user.totalImpression)
    );

    const totalPosts = user.totalPosts;

    user.posts.forEach((post) => {
      const { engagement, reach, impression } = post;
      post.cpe = parseFloat(
        calculateAverageData(budget / totalPosts, engagement)
      );
      post.cpr = parseFloat(calculateAverageData(budget / totalPosts, reach));
      post.cpm = parseFloat(
        calculateAverageData(budget / totalPosts, impression)
      );

      campaignTotalCPE += post.cpe;
      campaignTotalCPR += post.cpr;
      campaignTotalCPM += post.cpm;
    });
  });

  dealPerformance = {
    ...dealPerformance,
    cpe: campaignTotalCPE,
    cpr: campaignTotalCPR,
    cpm: campaignTotalCPM,
  };

  performanceTableData = tableData;

  return { dealPerformance, performanceTableData, workEachTier };
};
