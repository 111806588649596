import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { RowContainer } from "../../../utils/styling/general";
import { TEXT } from "../../../fonts/text";

interface NavigationProps {
  navigation: NavigateFunction;
}

const HomeButton = (props: NavigationProps) => {
  const { navigation } = props;
  return (
    <BackButtonContainer onClick={() => navigation("/")}>
      <IconContainer>
        <RXIcon name="Home" size={20} />
      </IconContainer>
      <Bu3Text>หน้าหลัก</Bu3Text>
    </BackButtonContainer>
  );
};

export default HomeButton;

const IconContainer = styled.div``;

const BackButtonContainer = styled(RowContainer)`
  margin-top: 20px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 12px;
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const Bu3Text = styled(TEXT.Bu3_Reg)``;
