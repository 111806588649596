import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { DiscoverPage } from "../../../pages/DiscoverPage";
import { useQuery } from "../../../utils";
import DiscoveryTabDetail from "../../molecules/Discover/DiscoverTabDetail";

interface Props {
  navigate: NavigateFunction;
  page: DiscoverPage;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  listFeedExpandWork: ({
    limit,
    offset,
    category,
    filter,
  }: {
    limit: number;
    offset: number;
    category: string;
    filter?:
      | {
          sortBy: string;
          platforms: string;
        }
      | undefined;
  }) => Promise<
    | {
        metadata: any;
        data: any;
      }
    | undefined
  >;
  clearFeedExpandWork: () => void;
}

function DiscoveryTab(props: Props) {
  const { page, setPage } = props;
  const query = useQuery();
  const pageQuery = query.get("page") as string;
  const dealId = query.get("dealId") as string;
  if (page !== "Work" && page !== "Default" && !dealId) {
    if (pageQuery === "work") {
      setPage("Work");
    } else if (pageQuery === "campaign") {
      setPage("Campaign");
    }

    return (
      <Container>
        <DiscoveryTabDetail {...props} />
      </Container>
    );
  } else {
    return <></>;
  }
}

export default DiscoveryTab;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 80px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;
