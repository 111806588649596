import { useEffect, useState } from "react";
import styled from "styled-components";
import { RateCard } from "../../../types/api/getInfluencer";
import ImageOverlay from "../../atoms/ImageOverlay";
import FavoriteInfluencerCardBody from "../../atoms/Influencer/FavoriteInfluencerCardBody";
import FavoriteInfluencerCardHeader from "../../atoms/Influencer/FavoriteInfluencerCardHeader";
import { CategoryType } from "../../organisms/CreateCampaign/Options";

interface Props {
  username: string;
  influencerRating: number;
  totalRating: number;
  ratingCount: number;
  profile_picture: string;
  cover_picture: string;
  id: string;
  accountId: string;
  category: string[];
  activeCategory?: CategoryType;
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
  instagramFollowers: number;
  tiktokFollowers: number;
  facebookFollowers: number;
  gender: string;
  age: string;
}

const FavoriteInfluencerCard = (props: Props) => {
  const {
    username,
    ratingCount,
    influencerRating,
    profile_picture,
    cover_picture,
    id,
    rateCard,
    activeCategory,
    category,
    instagramFollowers,
    tiktokFollowers,
    facebookFollowers,
    totalRating,
    gender,
    age,
  } = props;

  const link = `/favorite?influencerId=${id}&platform=INSTAGRAM`;
  const [currentImageUrl, setCurrentImageUrl] = useState(cover_picture);

  const handleImageLoad = () => {
    setCurrentImageUrl(cover_picture);
  };

  const handleImageError = () => {
    setCurrentImageUrl(
      require("../../../assets/images/Placeholder/Placeholder_anotherdot.png")
    );
  };

  useEffect(() => {
    const img = new Image();
    img.src = cover_picture;
    img.onload = handleImageLoad;
    img.onerror = handleImageError;
  }, [cover_picture]);

  return (
    <Container
      href={link}
      target="_blank"
      onClick={(e) => {
        //   e.preventDefault();
        //   setCurrentInfluencerName(username);
        //   navigation(link, { state: { prevPath } });
        window.open(link, "_blank");
      }}
      img={currentImageUrl}
    >
      <ImageOverlay
        zIndex={2}
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 30%)"
        containerStyle={{ borderRadius: 12 }}
      />
      <FavoriteInfluencerCardHeader
        profileImage={profile_picture}
        username={username}
        influencerRating={influencerRating}
        ratingCount={ratingCount}
        totalRating={totalRating}
        gender={gender}
        age={age}
      />
      <FavoriteInfluencerCardBody
        instagramFollowers={instagramFollowers}
        tiktokFollowers={tiktokFollowers}
        facebookFollowers={facebookFollowers}
        rateCard={rateCard}
        activeCategory={activeCategory}
        category={category}
      />
    </Container>
  );
};

export default FavoriteInfluencerCard;

const Container = styled.a<{ img: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 265px;
  min-height: 424.5px;
  aspect-ratio: 0.64310954063;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
  border-radius: 12px;
  z-index: 2;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;
