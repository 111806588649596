import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ScopeOfWork } from "../../../../class/ScopeOfWork";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { ActiveProps } from "../../../../types";
import { PlatformType } from "../../../../types/deal";
import { RowContainer } from "../../../../utils/styling/general";

interface Props {
  platform: PlatformType;
  scopeOfWork: ScopeOfWork;
}

const PostMediaList = (props: Props) => {
  const { scopeOfWork, platform = "INSTAGRAM" } = props;
  const {
    IG_Reels = 0,
    IG_album_posts,
    IG_individual_image_posts = 0,
    IG_individual_video_posts = 0,
    IG_story,
  } = scopeOfWork.toDisplayObject();
  const {
    FB_Reels = 0,
    FB_album_posts,
    FB_individual_image_posts = 0,
    FB_individual_video_posts = 0,
  } = scopeOfWork.toDisplayFacebook();

  const TK_video = scopeOfWork.toDisplayTiktok();

  const LM_article = scopeOfWork.toDisplayLemon();

  const PlatformMedias = () => {
    const medias: JSX.Element[] = [];
    switch (platform) {
      case "INSTAGRAM":
        if (
          [
            IG_Reels,
            IG_album_posts.length,
            IG_individual_image_posts,
            IG_individual_video_posts,
            IG_story.length,
          ].every((val, i, arr) => val === arr[0])
        ) {
          return (
            <TextContainer>
              <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
            </TextContainer>
          );
        }
        if (IG_individual_image_posts > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Image" size={18} />
                <PostText>Single Post (รูป)</PostText>
              </RowContainer>
              <Body1Text>{IG_individual_image_posts} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        if (IG_individual_video_posts > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Image" size={18} />
                <PostText>Single Post (วีดีโอ)</PostText>
              </RowContainer>
              <Body1Text>{IG_individual_video_posts} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        if (IG_album_posts.length > 0) {
          IG_album_posts.map((postMedia, index) => {
            var text = "";
            if (postMedia.image > 0 && postMedia.video === 0) {
              text = `(${postMedia.image} รูป)`;
            } else if (postMedia.video > 0 && postMedia.image === 0) {
              text = `(${postMedia.video} วีดีโอ)`;
            } else {
              text = `(${postMedia.image} รูป และ ${postMedia.video} วีดีโอ)`;
            }
            medias.push(
              <PostContainer key={postMedia.id + index}>
                <RowContainer>
                  <RXIcon name="Multi" size={18} />
                  <PostText>Multi Post {text}</PostText>
                </RowContainer>
                <Body1Text>1 โพสต์</Body1Text>
              </PostContainer>
            );
          });
        }
        if (IG_story.length > 0) {
          IG_story.map((postMedia, index) => {
            const text = postMedia.image > 0 ? "รูป" : "วีดีโอ";
            const posts = postMedia.image + postMedia.video;
            medias.push(
              <PostContainer key={postMedia.id + index}>
                <RowContainer>
                  <RXIcon name="StoryCircle" size={18} />
                  <PostText>Story Post ({text})</PostText>
                </RowContainer>
                <Body1Text>{`${posts}`} โพสต์</Body1Text>
              </PostContainer>
            );
          });
        }
        if (IG_Reels > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Reels" size={18} />
                <PostText>Reels</PostText>
              </RowContainer>
              <Body1Text>{IG_Reels} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        break;
      case "TIKTOK":
        if (TK_video.length > 0) {
          TK_video.map((v, index) => {
            let text = "ไม่กำหนดเวลา";
            if (v.duration !== 0) {
              text = "";
              const minute = Math.floor(v.duration / 60);
              const second = v.duration % 60;
              if (minute > 0) {
                text += `${minute} นาที `;
              }
              if (second > 0) {
                text += `${second} วินาที`;
              }
            }
            medias.push(
              <PostContainer key={`tk-video-${index}`}>
                <RowContainer>
                  <RXIcon name={"Tiktok"} size={18} />
                  <PostText>{`Tiktok Video (${text})`}</PostText>
                </RowContainer>
                <Body1Text>{v.video} โพสต์</Body1Text>
              </PostContainer>
            );
          });
        } else {
          medias.push(
            <TextContainer>
              <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
            </TextContainer>
          );
        }
        break;
      case "LEMON":
        if (LM_article.length > 0) {
          medias.push(
            <PostContainer key={`lm-article-0`}>
              <RowContainer>
                <RXIcon name={"Article"} size={18} />
                <PostText>{`Lemon8 Article`}</PostText>
              </RowContainer>
              <Body1Text>{LM_article.length} บทความ</Body1Text>
            </PostContainer>
          );
        } else {
          medias.push(
            <TextContainer>
              <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
            </TextContainer>
          );
        }
        break;
      case "FACEBOOK":
        if (
          [
            FB_Reels,
            FB_album_posts.length,
            FB_individual_image_posts,
            FB_individual_video_posts,
          ].every((val, i, arr) => val === arr[0])
        ) {
          return (
            <TextContainer>
              <ActivableBody1Text active={false}>ยังไม่ระบุ</ActivableBody1Text>
            </TextContainer>
          );
        }
        if (FB_individual_image_posts > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Image" size={18} />
                <PostText>Single Post (รูป)</PostText>
              </RowContainer>
              <Body1Text>{FB_individual_image_posts} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        if (FB_individual_video_posts > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Image" size={18} />
                <PostText>Single Post (วีดีโอ)</PostText>
              </RowContainer>
              <Body1Text>{FB_individual_video_posts} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        if (FB_album_posts.length > 0) {
          FB_album_posts.map((postMedia, index) => {
            var text = "";
            if (postMedia.image > 0 && postMedia.video === 0) {
              text = `(${postMedia.image} รูป)`;
            } else if (postMedia.video > 0 && postMedia.image === 0) {
              text = `(${postMedia.video} วีดีโอ)`;
            } else {
              text = `(${postMedia.image} รูป และ ${postMedia.video} วีดีโอ)`;
            }
            medias.push(
              <PostContainer key={postMedia.id + index}>
                <RowContainer>
                  <RXIcon name="Multi" size={18} />
                  <PostText>Multi Post {text}</PostText>
                </RowContainer>
                <Body1Text>1 โพสต์</Body1Text>
              </PostContainer>
            );
          });
        }
        if (FB_Reels > 0) {
          medias.push(
            <PostContainer>
              <RowContainer>
                <RXIcon name="Reels" size={18} />
                <PostText>Reels</PostText>
              </RowContainer>
              <Body1Text>{FB_Reels} โพสต์</Body1Text>
            </PostContainer>
          );
        }
        break;
    }
    return <Container>{medias}</Container>;
  };

  return <PlatformMedias />;
};

export default PostMediaList;

const Container = styled.div``;

const PostContainer = styled(RowContainer)`
  justify-content: space-between;
  margin-top: 16px;
`;

const Body1Text = styled(TEXT.body1)``;

const PostText = styled(Body1Text)`
  margin-left: 8px;
`;

const TextContainer = styled.div`
  margin: 16px 0px;
`;

const ActivableBody1Text = styled(TEXT.body1)<ActiveProps>`
  color: ${(props) => (props.active ? COLOR.BLACK : COLOR.PINK)};
`;
