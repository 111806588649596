import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";

interface AgeGenderProps {
  age: string;
  gender: string;
}

const AgeGenderComponent = (props: AgeGenderProps) => {
  const { age, gender } = props;
  const displayAge = !!age ? `อายุ ${age}` : "ไม่ระบุอายุ";
  return (
    <FullWidthRowContainer>
      <WhiteB4Text>{`${gender} | ${displayAge}`}</WhiteB4Text>
    </FullWidthRowContainer>
  );
};

interface Props {
  influencerRating: number;
  ratingCount: number;
  username: string;
  profileImage: string;
  totalRating: number;
  gender: string;
  age: string;
}

const FavoriteInfluencerCardHeader = (props: Props) => {
  const { username, influencerRating, profileImage, totalRating, gender, age } =
    props;
  return (
    <HeaderContainer>
      <Image src={profileImage} />
      <DetailContainer>
        <FullWidthRowContainer>
          <WhiteBoldB3Text>{username}</WhiteBoldB3Text>
          <MiniGapRowContainer>
            <RXIcon name="StarFilled" color={COLOR.Yellow_M} size={12} />
            <WhiteBoldB4Text>
              {(influencerRating || 0).toFixed(1)}
            </WhiteBoldB4Text>
            <WhiteB4Text>({totalRating})</WhiteB4Text>
          </MiniGapRowContainer>
        </FullWidthRowContainer>
        <AgeGenderComponent age={age} gender={gender} />
      </DetailContainer>
    </HeaderContainer>
  );
};

export default FavoriteInfluencerCardHeader;

const HeaderContainer = styled(RowContainer)`
  width: 100%;
  padding: 16px;
  z-index: 3;
  gap: 8px;
`;

const MiniGapRowContainer = styled(RowContainer)`
  gap: 2px;
`;

const DetailContainer = styled(FlexStartColumnContainer)`
  width: 85%;
  gap: 4px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const TierContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(2px);
`;

const Image = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 36px;
  flex-shrink: 0;
`;

const Blue700BoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  width: 174px;
  color: ${COLOR.White};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WhiteBoldB4Text = styled(TEXT.B4_Bold)`
  color: ${COLOR.White};
`;

const WhiteB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.White};
`;
