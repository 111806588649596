import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { Deal } from "../../../types/deal";
import { difTime, toDateDue } from "../../../utils";
import ImageOverlay from "../../atoms/ImageOverlay";

interface Props {
  deal: Deal;
  status: "ongoing" | "pending" | "done";
}

function CampaignImage(props: Props) {
  const { deal, status } = props;
  const { platforms } = deal;

  const PlatformsComponent = () => {
    const icons: JSX.Element[] = [];
    if (platforms?.includes("Instagram")) {
      icons.push(
        <InstagramContainer key={deal.dealId + "instagram"}>
          <RXIcon name="Instagram" color={COLOR.WHITE} />
        </InstagramContainer>
      );
    }
    if (platforms?.includes("Tiktok")) {
      icons.push(
        <TiktokIconContainer key={deal.dealId + "tiktok"}>
          <RXIcon name="Tiktok" color={COLOR.WHITE} />
        </TiktokIconContainer>
      );
    }
    if (platforms?.includes("Facebook")) {
      icons.push(
        <FacebookIconConatiner key={deal.dealId + "facebook"}>
          <ImageContainer
            src={require("../../../assets/images/PlatformLogos/Facebook.png")}
            style={{ width: 50, height: 50 }}
          />
        </FacebookIconConatiner>
      );
    }
    if (platforms?.includes("Lemon")) {
      icons.push(
        <LemonIconContainer key={deal.dealId + "lemon"}>
          <ImageContainer
            src={require("../../../assets/images/PlatformLogos/Lemon.png")}
          />
        </LemonIconContainer>
      );
    }
    return <>{icons}</>;
  };
  return (
    <Container>
      <DealImage src={deal.coverImg} />
      <ImageOverlay zIndex={100} />
      <PlatformContainer>
        <PlatformsComponent />
      </PlatformContainer>
      {status === "done" ? (
        <>
          <DoneTagText>เสร็จสิ้นแล้ว</DoneTagText>
          <NameText>{deal.name}</NameText>
          <DateText>ปิดรับเมื่อ {toDateDue(deal.dueDate)}</DateText>
        </>
      ) : (
        <>
          <TagText>เปิดรับอยู่</TagText>
          <NameText>{deal.name}</NameText>
          <DateText>เปิดรับถึง {toDateDue(deal.dueDate)}</DateText>
        </>
      )}
    </Container>
  );
}

export default CampaignImage;

const Container = styled.div`
  position: relative;
  height: 224px;
  border-radius: 18px 18px 0px 0px;
`;

const PlatformContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 16px;
  right: 21px;
`;

const TiktokIconContainer = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 50px;
  background: ${COLOR.BLACK};
`;

const LemonIconContainer = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 50px;
  background: ${COLOR.LEMON};
`;

const FacebookIconConatiner = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 50px;
  background: ${COLOR.WHITE};
`;

const InstagramContainer = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
`;

const ImageContainer = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
const DealImage = styled.img`
  display: flex;
  width: 320px;
  height: 224px;
  object-fit: cover;
  border-radius: 18px 18px 0px 0px;
`;

const DoneTagText = styled(TEXT.B4_Bold)`
  position: absolute;
  top: 18px;
  left: 20px;
  padding: 2px 6px 2px 6px;
  background: ${COLOR.Gray_300};
  color: ${COLOR.White};
  border-radius: 50px;
  z-index: 1000;
`;

const TagText = styled(TEXT.B4_Bold)`
  position: absolute;
  top: 18px;
  left: 20px;
  padding: 2px 6px 2px 6px;
  background: ${COLOR.Blue_700};
  color: ${COLOR.White};
  border-radius: 50px;
  z-index: 1000;
`;

const NameText = styled(TEXT.H7_Bold)`
  position: absolute;
  top: 169px;
  left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  color: ${COLOR.White};
  z-index: 1000;
`;

const DateText = styled(TEXT.B4_Reg)`
  position: absolute;
  top: 193px;
  left: 20px;
  color: ${COLOR.White};
  z-index: 1000;
`;
