import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import Navigator from "./Navigator";
import GlobalFonts from "./fonts/font";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { accountStore } from "./stores/accountStore";
import { authStore } from "./stores/authStore";
import { chatStore } from "./stores/chatStore";
import { createDealStore } from "./stores/createDealStore";
import { dealStore } from "./stores/dealStore";
import { editDealStore } from "./stores/editDealStore";
import { feedStore } from "./stores/feedStore";
import { influencerStore } from "./stores/influencerStore";
import {
  AccountStoreContext,
  AuthStoreContext,
  ChatStoreContext,
  CreateDealStoreContext,
  DealStoreContext,
  EditDealStoreContext,
  FeatureAccessContext,
  FeedStoreContext,
  InfluencerStoreContext,
  NetworkStoreContext,
  NotificationStoreContext,
  OfferStoreContext,
  ResetPasswordContext,
  WebSocketStoreContext,
} from "./stores/initialize/Context";
import { networkStore } from "./stores/networkStore";
import { notificationStore } from "./stores/notificationStore";
import offerStore from "./stores/offerStore";
import { resetPasswordStore } from "./stores/resetPasswordStore";
import { webSocketStore } from "./stores/webSocketStore";
import { featureAccessStore } from "./stores/featureAccessStore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Root = () => {
  return (
    <HelmetProvider>
      <AuthStoreContext.Provider value={authStore({ accountStore, dealStore })}>
        <AccountStoreContext.Provider value={accountStore}>
          <FeatureAccessContext.Provider value={featureAccessStore}>
            <FeedStoreContext.Provider value={feedStore}>
              <ResetPasswordContext.Provider value={resetPasswordStore}>
                <DealStoreContext.Provider value={dealStore}>
                  <CreateDealStoreContext.Provider value={createDealStore}>
                    <ChatStoreContext.Provider value={chatStore}>
                      <InfluencerStoreContext.Provider
                        value={influencerStore({ accountStore })}
                      >
                        <EditDealStoreContext.Provider value={editDealStore}>
                          <NotificationStoreContext.Provider
                            value={notificationStore}
                          >
                            <WebSocketStoreContext.Provider
                              value={webSocketStore}
                            >
                              <OfferStoreContext.Provider value={offerStore}>
                                <NetworkStoreContext.Provider
                                  value={networkStore}
                                >
                                  <GlobalFonts />
                                  <Navigator />
                                </NetworkStoreContext.Provider>
                              </OfferStoreContext.Provider>
                            </WebSocketStoreContext.Provider>
                          </NotificationStoreContext.Provider>
                        </EditDealStoreContext.Provider>
                      </InfluencerStoreContext.Provider>
                    </ChatStoreContext.Provider>
                  </CreateDealStoreContext.Provider>
                </DealStoreContext.Provider>
              </ResetPasswordContext.Provider>
            </FeedStoreContext.Provider>
          </FeatureAccessContext.Provider>
        </AccountStoreContext.Provider>
      </AuthStoreContext.Provider>
    </HelmetProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
