import { observer } from "mobx-react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { useInfluencerStore } from "../../../stores/initialize/Context";
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ILocation,
  ITiktokInfluencer,
} from "../../../types/api/getInfluencer";
import { PlatformType } from "../../../types/deal";
import {
  ColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import { changwat } from "../../organisms/CreateCampaign/Options";

const POLYGON_STYLE = "polygon(50% 0%, 100% 0%, 100% 100%, -10% 100%)";

interface GetLocationProps {
  instagram: IInstagramInfluencer;
  facebook: IFacebookInfluencer;
  tiktok: ITiktokInfluencer;
  platformActive: PlatformType;
}

const getLocationData = (props: GetLocationProps) => {
  const { instagram, facebook, tiktok, platformActive } = props;
  switch (platformActive) {
    case "INSTAGRAM":
      return instagram?.demographicCities || [];
    case "FACEBOOK":
      return facebook?.demographicCities || [];
    default:
      return [];
  }
};

interface GetProvinceProps {
  location: ILocation[];
}

const getProvinceData = (props: GetProvinceProps) => {
  const { location } = props;

  const sumData = location.reduce((sum, current) => {
    return sum + current[1];
  }, 0);

  const provinceData: Record<string, number> = {};

  location.forEach((locationValue) => {
    const locationSplitValue = locationValue[0].split(", ");
    const count = (locationValue[1] / sumData) * 100;
    if (Object.keys(provinceData).length < 4) {
      const province =
        changwat.find((option) => locationSplitValue[1] === option.value)
          ?.label || locationSplitValue[1];
      if (province in provinceData) {
        provinceData[province] += count;
      } else provinceData[province] = count;
    } else if (Object.keys(provinceData).length === 4) {
      provinceData["อื่นๆ"] = count;
    } else {
      provinceData["อื่นๆ"] += count;
    }
  });

  return provinceData;
};

interface Props {
  platformActive: PlatformType;
}

const ProvinceDemographic = (props: Props) => {
  const { platformActive } = props;
  const { influencer } = useInfluencerStore();

  if (!influencer) return <></>;

  const { instagram, tiktok, facebook } = influencer;

  const location = getLocationData({
    instagram,
    tiktok,
    facebook,
    platformActive,
  });

  if (!location || location.length === 0) return <></>;

  const provinceData = getProvinceData({ location });

  const provinceKey = Object.keys(provinceData);

  return (
    <Container>
      <BoldH6Text>จังหวัด</BoldH6Text>
      <BigGapRowContainer>
        <GraphicContainer>
          <FirstCircle>
            <WhiteBoldH4Text>{`${(provinceData[provinceKey[0]] || 0).toFixed(
              1
            )}%`}</WhiteBoldH4Text>
            <WhiteB1Text>{provinceKey[0]}</WhiteB1Text>
          </FirstCircle>
          <SecondCircle>
            <WhiteBoldH5Text>{`${(provinceData[provinceKey[1]] || 0).toFixed(
              1
            )}%`}</WhiteBoldH5Text>
            <WhiteB2Text>{provinceKey[1]}</WhiteB2Text>
          </SecondCircle>
          <ThirdCircle>
            <WhiteBoldH5Text>{`${(provinceData[provinceKey[2]] || 0).toFixed(
              1
            )}%`}</WhiteBoldH5Text>
            <WhiteB3Text>{provinceKey[2]}</WhiteB3Text>
          </ThirdCircle>
          <FourthCircle>
            <WhiteBoldH6Text>{`${(provinceData[provinceKey[3]] || 0).toFixed(
              1
            )}%`}</WhiteBoldH6Text>
            <WhiteB4Text>{provinceKey[3]}</WhiteB4Text>
          </FourthCircle>
          <FifthCircle>
            <WhiteBoldH8Text>{`${(provinceData[provinceKey[4]] || 0).toFixed(
              1
            )}%`}</WhiteBoldH8Text>
            <WhiteB4Text>{provinceKey[4]}</WhiteB4Text>
          </FifthCircle>
        </GraphicContainer>
        <GapColumnContainer>
          <FullWidthRowContainer>
            <GapRowContainer>
              <BlackCircle />
              <GrayH8Text>{provinceKey[0]}</GrayH8Text>
            </GapRowContainer>
            <BoldH6Text>{`${(provinceData[provinceKey[0]] || 0).toFixed(
              1
            )}%`}</BoldH6Text>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <GapRowContainer>
              <BlueCircle />
              <GrayH8Text>{provinceKey[1]}</GrayH8Text>
            </GapRowContainer>
            <BoldH6Text>{`${(provinceData[provinceKey[1]] || 0).toFixed(
              1
            )}%`}</BoldH6Text>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <GapRowContainer>
              <BlueCircle />
              <GrayH8Text>{provinceKey[2]}</GrayH8Text>
            </GapRowContainer>
            <BoldH6Text>{`${(provinceData[provinceKey[2]] || 0).toFixed(
              1
            )}%`}</BoldH6Text>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <GapRowContainer>
              <BlueCircle />
              <GrayH8Text>{provinceKey[3]}</GrayH8Text>
            </GapRowContainer>
            <BoldH6Text>{`${(provinceData[provinceKey[3]] || 0).toFixed(
              1
            )}%`}</BoldH6Text>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <GapRowContainer>
              <PurpleCircle />
              <GrayH8Text>{provinceKey[4]}</GrayH8Text>
            </GapRowContainer>
            <BoldH6Text>{`${(provinceData[provinceKey[4]] || 0).toFixed(
              1
            )}%`}</BoldH6Text>
          </FullWidthRowContainer>
        </GapColumnContainer>
      </BigGapRowContainer>
    </Container>
  );
};

export default observer(ProvinceDemographic);

const Container = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 360px;
  padding: 0px 24px 0px 24px;
`;

const GraphicContainer = styled.div`
  position: relative;
  width: 340px;
  height: 290px;
`;

const GapColumnContainer = styled(FlexStartColumnContainer)`
  flex: 1;
  gap: 16px;
  height: 224px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const BigGapRowContainer = styled(RowContainer)`
  gap: 24px;
`;

const FirstCircle = styled(ColumnContainer)`
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${COLOR.Black};
  position: absolute;
  top: 40px;
  left: 109px;
  z-index: 2;
  opacity: 0.9;

  &::before {
    content: "";
    position: absolute;
    top: -6.8px;
    left: -6.8px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 2px solid ${COLOR.Black};
    /* clip-path: ${POLYGON_STYLE}; */
  }
`;

const SecondCircle = styled(ColumnContainer)`
  justify-content: center;
  width: 126px;
  height: 126px;
  border-radius: 50%;
  background-color: ${COLOR.Blue_400};
  position: absolute;
  top: 165px;
  left: 153px;
  z-index: 3;
  opacity: 0.9;

  &::before {
    content: "";
    position: absolute;
    top: -6.8px;
    left: -6.8px;
    width: 136px;
    height: 136px;
    border-radius: 50%;
    border: 2px solid ${COLOR.Blue_400};
    /* clip-path: ${POLYGON_STYLE}; */
  }
`;

const ThirdCircle = styled(ColumnContainer)`
  justify-content: center;
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background-color: ${COLOR.Blue_600};
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 3;
  opacity: 0.9;

  &::before {
    content: "";
    position: absolute;
    top: -6.8px;
    left: -6.8px;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    border: 2px solid ${COLOR.Blue_600};
    /* clip-path: ${POLYGON_STYLE}; */
  }
`;

const FourthCircle = styled(ColumnContainer)`
  justify-content: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: ${COLOR.Gray_M};
  position: absolute;
  top: 153px;
  opacity: 0.9;

  &::before {
    content: "";
    position: absolute;
    top: -6.8px;
    left: -6.8px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid ${COLOR.Gray_M};
    /* clip-path: ${POLYGON_STYLE}; */
  }
`;

const FifthCircle = styled(ColumnContainer)`
  justify-content: center;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background-color: ${COLOR.Purple_M};
  position: absolute;
  left: 252px;
  top: 40px;
  z-index: 1;
  opacity: 0.9;

  &::before {
    content: "";
    position: absolute;
    top: -6.8px;
    left: -6.8px;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 2px solid ${COLOR.Purple_M};
    /* clip-path: ${POLYGON_STYLE}; */
  }
`;

const BlueCircle = styled.div`
  background-color: ${COLOR.Blue_500};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BlackCircle = styled.div`
  background-color: ${COLOR.Black};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const PurpleCircle = styled.div`
  background-color: ${COLOR.Purple_M};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BoldH6Text = styled(TEXT.H6_Bold)`
  width: 50px;
`;

const GrayH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_D};
`;

const WhiteBoldH4Text = styled(TEXT.H4_Bold)`
  color: ${COLOR.White};
`;

const WhiteB1Text = styled(TEXT.B1_Reg)`
  color: ${COLOR.White};
  text-align: center;
`;

const WhiteBoldH5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.White};
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
  text-align: center;
`;

const WhiteB2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.White};
  text-align: center;
`;

const WhiteBoldH6Text = styled(TEXT.H6_Bold)`
  color: ${COLOR.White};
`;

const WhiteBoldH8Text = styled(TEXT.H8_Bold)`
  color: ${COLOR.White};
`;

const WhiteB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.White};
  text-align: center;
`;
