import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import LoginCardDetail from "../../molecules/Login/LoginCardDetail";
import LoginCardFooter from "../../molecules/Login/LoginCardFooter";

interface Props {
  setAuthLogin: ({
    value,
    property,
  }: {
    value: string;
    property: "email" | "password";
  }) => void;
  login: () => Promise<void>;
  authLoginError: string[];
  isLoginActive: boolean;
  loading: boolean;
}

const LoginCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 567px;
  height: 664px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  margin-top: 176px;
  padding: 50px 88px;
`;

function LoginCard(props: Props) {
  return (
    <LoginCardContainer>
      <LoginCardDetail {...props} />
      <LoginCardFooter />
    </LoginCardContainer>
  );
}

export default LoginCard;
