import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { Deal } from "../../../../types/deal";
import { difTime, toDateDue } from "../../../../utils";

interface Props {
  deal: Deal;
}

const DealImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
`;

function PendingCampaignImage(props: Props) {
  const { deal } = props;

  return <DealImage src={deal.coverImg} />;
}

export default PendingCampaignImage;
