import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { scopeOfWorkType } from "../../../class/ScopeOfWork";
import InsightBarChart from "../../../components/atoms/Deal/InsightBarChart";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { DealPerformance, PlatformType } from "../../../types/deal";
import { TierWorkData } from "../../../types/work";
import { roundUpNearest } from "../../../utils";
import { SpaceBetweenRowContainer } from "../../../utils/styling/general";
import { formatPerformanceBarData, getBarChartLabels } from "../function";

interface Props {
  workEachTier: {
    nano: TierWorkData;
    micro: TierWorkData;
    mid: TierWorkData;
    macro: TierWorkData;
  };
  dealPerformance: DealPerformance;
  platform: PlatformType;
  dealPostTypes: scopeOfWorkType[];
}

const EngagementSummary = (props: Props) => {
  const { workEachTier, dealPerformance, platform, dealPostTypes } = props;

  const isInstagram = platform === "INSTAGRAM";
  const haveStory = dealPostTypes.includes("IG_story");
  const labels = getBarChartLabels(platform, haveStory);

  const nanoBarData = formatPerformanceBarData(
    workEachTier.nano,
    platform,
    haveStory
  );
  const microBarData = formatPerformanceBarData(
    workEachTier.micro,
    platform,
    haveStory
  );
  const midBarData = formatPerformanceBarData(
    workEachTier.mid,
    platform,
    haveStory
  );
  const macroBarData = formatPerformanceBarData(
    workEachTier.macro,
    platform,
    haveStory
  );

  const maxData = Math.max(
    ...nanoBarData,
    ...microBarData,
    ...midBarData,
    ...macroBarData
  );
  const roundedUpMaxData = roundUpNearest(maxData);

  return (
    <Container>
      <HeaderContainer>
        <BoldH6Text>Engagement Summary</BoldH6Text>
        <RXIcon name="Info" color={COLOR.Gray_M} size={20} />
      </HeaderContainer>
      <ContentContainer>
        <InsightBarChart
          nanoBarData={nanoBarData}
          microBarData={microBarData}
          midBarData={midBarData}
          macroBarData={macroBarData}
          dealPerformance={dealPerformance}
          labels={labels}
          dealPostTypes={dealPostTypes}
          isInstagram={isInstagram}
          haveStory={haveStory}
          suggestedMax={roundedUpMaxData}
        />
      </ContentContainer>
    </Container>
  );
};

export default EngagementSummary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_300};
  width: 100%;
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)`
  padding: 24px;
  border-bottom: 0.6px solid ${COLOR.Gray_300};
`;

const ContentContainer = styled(SpaceBetweenRowContainer)`
  padding: 24px;
  width: 100%;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;
