import React from "react";
import styled from "styled-components";
import { PostMedia, PostTypes } from "../../../../class/ScopeOfWork";
import { dealKeys } from "../../../../types/createDeal";
import { Deal } from "../../../../types/deal";
import { PLACEHOLDER_BRAND, TODAYSTRING } from "../../../../utils";
import DealCardBrandProfile from "../../../molecules/Deal/DealCardBrandProfile";
import DealCardCampaign from "../../../molecules/Deal/DealCardCampaign";
import DealCardInfluencer from "../../../molecules/Deal/DealCardInfluencer";
import DealCardReward from "../../../molecules/Deal/DealCardReward";
import DealCardScopeOfWork from "../../../molecules/Deal/DealCardScopeOfWork";
import DealCardTimeline from "../../../molecules/Deal/DealCardTimeline";
import { ImageProp } from "../../CreateCampaign/ImageSelector";

interface Props {
  dealDetail: dealKeys;
}

function DiscoverCampaignDetail(props: Props) {
  const { dealDetail } = props;
  const {
    posts,
    tiktokCaptionGuide,
    tiktokHashtags,
    caption,
    hashtags,
    platforms,
    detail,
    productDetail,
    age,
    gender,
    location,
    limit,
    criteria,
    benefits,
    value,
    isValue,
    isCash,
    cash,
    brandApprove,
    startDate,
    endDate,
    submissionDate,
    postingDate,
    tags,
    analysis,
    pastInfluencers,
    brandName,
    brandImage,
    total,
    facebookCaptionGuide,
    facebookHashtags,
  } = dealDetail;
  return (
    <Container>
      <CampaignDetailContainer>
        <DealCardBrandProfile
          tags={tags}
          analysis={analysis}
          pastInfluencers={pastInfluencers}
          totalRegister={total || 10}
          brandImg={brandImage || PLACEHOLDER_BRAND}
          brandName={brandName || "Pickle Brand"}
        />
        <DealCardCampaign
          detail={detail}
          productDetail={productDetail}
          age={age}
          gender={gender}
          location={location}
          isNegotiateCash={false}
          isShowUndefined={false}
        />
        <DealCardInfluencer
          limit={limit}
          influencerAge={criteria.ages}
          influencerGender={criteria.gender}
        />
        <DealCardScopeOfWork
          posts={posts}
          platforms={platforms}
          hashtags={hashtags}
          tiktokHashtags={tiktokHashtags}
          caption={caption}
          tiktokCaptionGuide={tiktokCaptionGuide}
          facebookHashtags={facebookHashtags}
          facebookCaptionGuide={facebookCaptionGuide}
        />
        <DealCardReward
          benefitsDetail={benefits}
          isValue={value > 0}
          value={value}
          isCash={cash > 0}
          cash={cash}
        />
        <DealCardTimeline
          startDate={startDate || TODAYSTRING}
          endDate={endDate || TODAYSTRING}
          postingDate={postingDate || TODAYSTRING}
          submissionDate={submissionDate || TODAYSTRING}
          brandApprove={brandApprove}
        />
      </CampaignDetailContainer>
    </Container>
  );
}

export default DiscoverCampaignDetail;

const Container = styled.div`
  width: 822px;
`;

const CampaignDetailContainer = styled.div`
  padding: 0px 40px;
`;
