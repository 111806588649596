import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import { RateCard } from "../../../types/api/getInfluencer";
import { ActiveProps } from "../../../types";

interface TextProps {
  rate: string | number;
  showArticle: boolean;
}

const TextComponent = (props: TextProps) => {
  const { rate, showArticle } = props;

  return (
    <GapColumnContainer>
      <BoldH7Text>{rate}</BoldH7Text>
      {showArticle && <B4Text>บทความละ</B4Text>}
    </GapColumnContainer>
  );
};

interface RateCardProps {
  rateCard: RateCard[];
  haveLemonUrl: boolean;
}

const RateCardComponent = (props: RateCardProps) => {
  const { rateCard, haveLemonUrl } = props;
  if (!!rateCard[0]?.rate && haveLemonUrl) {
    return <TextComponent rate={`${rateCard[0].rate} ฿`} showArticle={true} />;
  }

  return <TextComponent rate={"ไม่มีข้อมูล Ratecard"} showArticle={false} />;
};

const ContentComponent = (props: Props) => {
  const { lemonUrl } = props;

  if (!lemonUrl) return <BoldB3Text>ไม่ได้เชื่อมต่อกับ Lemon8</BoldB3Text>;

  return (
    <>
      <B3Text>{lemonUrl}</B3Text>
      <RXIcon name="ExternalLink" size={16} />
    </>
  );
};

interface OnClickProps {
  lemonUrl: string;
}

const onUrlClick = (props: OnClickProps) => {
  const { lemonUrl } = props;
  if (!lemonUrl) return;
  if (lemonUrl?.startsWith("https://")) window.open(lemonUrl, "_blank");
  else window.open("https://" + lemonUrl, "_blank");
};

interface Props {
  lemonUrl: string;
  rateCard: RateCard[];
}

const LemonAccount = (props: Props) => {
  const { lemonUrl, rateCard } = props;

  const haveLemonUrl = !!lemonUrl;

  return (
    <LemonContainer>
      <AccountContainer
        active={haveLemonUrl}
        onClick={() => onUrlClick({ lemonUrl })}
      >
        <GapRowContainer>
          <LemonIconContainer
            src={require("../../../assets/images/PlatformLogos/Lemon.png")}
          />
          <ContentComponent {...props} />
        </GapRowContainer>
        <RateCardComponent rateCard={rateCard} haveLemonUrl={haveLemonUrl} />
      </AccountContainer>
    </LemonContainer>
  );
};

export default LemonAccount;

const LemonContainer = styled.div`
  border-radius: 16px;
  width: 96.5%;
  height: 76px;
  padding: 16px;
  margin: 0px 20px;
  border: 0.6px solid ${COLOR.Gray_300};
`;

const AccountContainer = styled(SpaceBetweenRowContainer)<ActiveProps>`
  align-items: center;
  height: 100%;
  :hover {
    cursor: ${(props) => (props.active ? "pointer" : "normal")};
  }
`;

const LemonIconContainer = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const GapColumnContainer = styled(ColumnContainer)`
  gap: 8px;
  align-items: flex-end;
`;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const B3Text = styled(TEXT.B3_Reg)`
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const B4Text = styled(TEXT.B4_Reg)``;
