import React, { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { User } from "react-web-gifted-chat";
import { IconName, RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { NullableActiveProps } from "../../../types";
import { Account } from "../../../types/account";
import { Work } from "../../../types/work";
import { RowContainer } from "../../../utils/styling/general";
import CheckWorkList from "../../atoms/CampaignManagement/CheckWorkList";
import RButton from "../../atoms/RButton";

interface Props {
  hasInstagram: boolean;
  igRequirements: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[];
  hasTiktok: boolean;
  tkRequirements: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[];
  hasFacebook: boolean;
  fbRequirements: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[];
  hasLemon: boolean;
  lmRequirements: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[];
  works: Work[] | undefined;
  displayImage: (workList: Work[], index: number) => void;
  sendImages?: (
    dealContactId: string,
    images: string,
    user: User,
    navigate: () => void
  ) => void;
  sendVideos?: (
    dealContactId: string,
    videos: string,
    user: User,
    navigate: () => void
  ) => void;
  openFinishWorkModal: () => void;
  navigate: NavigateFunction;
  dealContactId: string;
  dealId: string;
  status:
    | "pending"
    | "ongoing"
    | "counterPending"
    | "check"
    | "done"
    | undefined;
  account: Account;
  reviewScore: number;
}

function InfluencerWork(props: Props) {
  const {
    hasInstagram,
    igRequirements,
    hasTiktok,
    tkRequirements,
    hasLemon,
    lmRequirements,
    works = [],
    displayImage,
    sendImages,
    sendVideos,
    openFinishWorkModal,
    navigate,
    dealContactId,
    dealId,
    status,
    account,
    reviewScore,
    hasFacebook,
    fbRequirements,
  } = props;

  const [isEdit, setIsEdit] = useState<Boolean>(false);
  const [selectWorkList, setSelectWorkList] = useState<Work[]>([]);

  const selectWork = (selectedWork: Work) => {
    const workIndex = selectWorkList.findIndex(
      (work) => work.workId === selectedWork.workId
    );

    if (workIndex >= 0) {
      setSelectWorkList(
        selectWorkList.filter((work) => work.workId !== selectedWork.workId)
      );
    } else {
      setSelectWorkList([...selectWorkList, selectedWork]);
    }
  };

  const user: User = {
    id: account.brandId,
    avatar: account.img,
    name: account.name,
  };

  const sendChatMedia = async () => {
    if (selectWorkList.length > 0) {
      selectWorkList.map(async (work) => {
        const { media_type, postType } = work;
        const media = work.media_url || work.thumbnail_url || "";
        if (
          (media_type === "IMAGE" ||
            postType === "TK_video" ||
            media_type === "CAROUSEL_ALBUM") &&
          sendImages
        ) {
          sendImages(work.dealContactId, media, user, navigateChat);
        } else if (media_type === "VIDEO" && sendVideos) {
          sendVideos(work.dealContactId, media, user, navigateChat);
        }
      });
    }
  };

  const navigateChat = () =>
    navigate(
      `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&page=chat`
    );

  const renderInstagramWork = () => {
    return (
      <>
        <RowContainer style={{ marginTop: "24px" }}>
          <BoldH7Text>ช่องทาง</BoldH7Text>
          <IGContainer>
            <RXIcon name="Instagram" size={21} color={COLOR.WHITE} />
            <WhiteBoldB3Text>Instagram</WhiteBoldB3Text>
          </IGContainer>
        </RowContainer>
        {igRequirements.map((type, index) => {
          const workData = works.filter(
            (work: { postType: string }) => work.postType === type.value
          );
          const workAmount = workData.length || 0;
          let requirementCount;
          if (typeof type.requirement !== "number") {
            requirementCount = parseInt(type.requirement);
          } else {
            requirementCount = type.requirement;
          }

          if (requirementCount === 0) {
            return <></>;
          }

          return (
            <CheckWorkList
              title={type.title}
              workAmount={workAmount}
              requirementCount={requirementCount}
              workData={workData}
              displayImage={displayImage}
              isEditWork={isEdit}
              selectWorkList={selectWorkList}
              setSelectWork={selectWork}
            />
          );
        })}
      </>
    );
  };

  const renderTiktokWork = () => {
    return (
      <>
        <RowContainer style={{ marginTop: "16px" }}>
          <BoldH7Text>ช่องทาง</BoldH7Text>
          <TKContainer>
            <RXIcon name="Tiktok" size={21} color={COLOR.WHITE} />
            <WhiteBoldB3Text>TikTok</WhiteBoldB3Text>
          </TKContainer>
        </RowContainer>
        {tkRequirements.map((type, index) => {
          const workData = works.filter(
            (work: { postType: string }) => work.postType === type.value
          );
          const workAmount = workData.length || 0;
          let requirementCount;
          if (typeof type.requirement !== "number") {
            requirementCount = parseInt(type.requirement);
          } else {
            requirementCount = type.requirement;
          }
          if (requirementCount === 0) {
            return <></>;
          }

          return (
            <CheckWorkList
              title={type.title}
              workAmount={workAmount}
              requirementCount={requirementCount}
              workData={workData}
              displayImage={() => displayImage(workData, index)}
              isEditWork={isEdit}
              selectWorkList={selectWorkList}
              setSelectWork={selectWork}
            />
          );
        })}
      </>
    );
  };

  const renderFacebookWork = () => {
    return (
      <>
        <RowContainer style={{ marginTop: "24px" }}>
          <BoldH7Text>ช่องทาง</BoldH7Text>
          <FBContainer>
            <RXIcon name="FacebookCircle" size={21} color={COLOR.WHITE} />
            <WhiteBoldB3Text>Facebook</WhiteBoldB3Text>
          </FBContainer>
        </RowContainer>
        {fbRequirements.map((type, index) => {
          const workData = works.filter(
            (work: { postType: string }) => work.postType === type.value
          );
          const workAmount = workData.length || 0;
          let requirementCount;
          if (typeof type.requirement !== "number") {
            requirementCount = parseInt(type.requirement);
          } else {
            requirementCount = type.requirement;
          }

          if (requirementCount === 0) {
            return <></>;
          }

          return (
            <CheckWorkList
              title={type.title}
              workAmount={workAmount}
              requirementCount={requirementCount}
              workData={workData}
              displayImage={displayImage}
              isEditWork={isEdit}
              selectWorkList={selectWorkList}
              setSelectWork={selectWork}
            />
          );
        })}
      </>
    );
  };

  const renderLemonWork = () => {
    return (
      <>
        <RowContainer style={{ marginTop: "16px" }}>
          <BoldH7Text>ช่องทาง</BoldH7Text>
          <LMContainer>
            <ImageContainer
              src={require("../../../assets/images/PlatformLogos/Lemon.png")}
            />
            <BoldB3Text style={{ marginLeft: 4 }}>Lemon8</BoldB3Text>
          </LMContainer>
        </RowContainer>
        {lmRequirements.map((type, index) => {
          const workData = works.filter(
            (work: { postType: string }) => work.postType === type.value
          );
          const workAmount = workData.length || 0;
          let requirementCount;
          if (typeof type.requirement !== "number") {
            requirementCount = parseInt(type.requirement);
          } else {
            requirementCount = type.requirement;
          }
          if (requirementCount === 0) {
            return <></>;
          }

          return (
            <CheckWorkList
              title={type.title}
              workAmount={workAmount}
              requirementCount={requirementCount}
              workData={workData}
              displayImage={() => displayImage(workData, index)}
              isEditWork={isEdit}
              selectWorkList={selectWorkList}
              setSelectWork={selectWork}
            />
          );
        })}
      </>
    );
  };

  const checkFirstWork = (element: React.ReactElement, index: number) => {
    if (index === 0) {
      return (
        <BoxContainer active={hasInstagram && hasTiktok}>
          <BoldH5Text>ผลงาน</BoldH5Text>
          {element}
        </BoxContainer>
      );
    } else {
      return <BoxContainer>{element}</BoxContainer>;
    }
  };

  const WorkComponent = () => {
    const displayWorks: JSX.Element[] = [];
    if (hasInstagram) {
      displayWorks.push(
        checkFirstWork(renderInstagramWork(), displayWorks.length)
      );
    }
    if (hasTiktok) {
      displayWorks.push(
        checkFirstWork(renderTiktokWork(), displayWorks.length)
      );
    }
    if (hasFacebook) {
      displayWorks.push(
        checkFirstWork(renderFacebookWork(), displayWorks.length)
      );
    }
    if (hasLemon) {
      displayWorks.push(checkFirstWork(renderLemonWork(), displayWorks.length));
    }
    return <>{displayWorks}</>;
  };

  const ButtonComponent = () => {
    if (status === "done") {
      return (
        <ButtonContainer style={{ justifyContent: "space-between" }}>
          <ButtonTextContainer>
            <BoldH6Text>คุณให้คะแนนงานนี้</BoldH6Text>
            <GrayMB2Text>ให้คะแนนเมื่อวันที่ : 14 Aug 2023</GrayMB2Text>
          </ButtonTextContainer>
          <StarContainer>
            {new Array(5).fill("0").map((a, index) => {
              if (index < reviewScore) {
                return (
                  <RXIcon
                    key={`star${index}`}
                    name="StarFilled"
                    color={COLOR.YELLOW}
                    size={20}
                  />
                );
              }
              return (
                <RXIcon
                  key={`star${index}`}
                  name="Star"
                  color={COLOR.YELLOW}
                  size={20}
                />
              );
            })}
          </StarContainer>
        </ButtonContainer>
      );
    } else if (isEdit) {
      return (
        <ButtonContainer style={{ height: "120px", marginTop: "32px" }}>
          {/* TODO : Re No hover color */}
          <RButton
            title="ยกเลิก"
            onPress={() => {
              setIsEdit(false);
            }}
            backgroundColor={COLOR.WHITE}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
          />
          <RButton
            title="แนบรูปไปที่แชท"
            onPress={async () => sendChatMedia()}
            icon="Chat"
          />
        </ButtonContainer>
      );
    } else if (status === "check") {
      return (
        <ButtonContainer style={{ height: "120px", marginTop: "32px" }}>
          <RButton
            title="แจ้งแก้ไขงาน"
            onPress={() => {
              setIsEdit(true);
            }}
            backgroundColor={COLOR.WHITE}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
            icon="Edit"
          />
          <RButton title="จบงาน" onPress={openFinishWorkModal} icon="Check" />
        </ButtonContainer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Container>
      <WorkComponent />
      <ButtonComponent />
    </Container>
  );
}

export default InfluencerWork;

const Container = styled.div``;

const BoxContainer = styled.div<NullableActiveProps>`
  padding: 32px 36px;
  margin-bottom: ${(props) => (props.active ? "0px" : "32px")};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${(props) => (props.active ? "0px" : "0px 0px 16px 16px")};
`;

const IGContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
  border-radius: 16px;
`;

const TKContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.BLACK};
  border-radius: 16px;
`;

const FBContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.Blue_700};
  border-radius: 16px;
`;

const LMContainer = styled.div`
  display: flex;
  height: 32px;
  width: 128px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  background: ${COLOR.LEMON};
  border-radius: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 32px 36px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 32px;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StarContainer = styled.div`
  display: flex;
`;

const ImageContainer = styled.img`
  width: 21px;
  height: 21px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;
const BoldH6Text = styled(TEXT.H6_Bold)``;
const BoldH7Text = styled(TEXT.H7_Bold)``;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const WhiteBoldB3Text = styled(BoldB3Text)`
  color: ${COLOR.WHITE};
  margin-left: 4px;
`;

const GrayMB2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Gray_M};
  margin-top: 10px;
`;
