import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { limitText, toDecimalNumber } from "../../../../utils";
import {
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import ValueDetails from "../../../atoms/ValueDetails";
import { PLACEHOLDER_PROFILE } from "../../../../utils";

interface Props {
  name: string;
  value: number;
  cash: number;
  criteria: {
    minFollower: number;
    tiktokMinFollower: number;
  };
  tags: "High Reach" | "Popular" | undefined;
  // TODO: Check reach or impression
  reach: number;
  like: number;
  comment: number;
  userImage: { id: string; profile_picture_url: string; username: string }[];
  totalRegister: number;
}

function DiscoverCampaignCardDetail(props: Props) {
  const {
    name,
    value,
    cash,
    criteria,
    tags,
    reach,
    like,
    comment,
    userImage,
    totalRegister,
  } = props;

  const displayValue = value ? `${value} ฿` : "-";
  const displayCash = cash ? `${cash} ฿` : "-";

  const displayFollower = () => {
    let igFollower = "-";
    let tkFollower = "-";

    if (criteria.minFollower) {
      igFollower = toDecimalNumber({
        number: criteria.minFollower,
        toFixed: 0,
      });
    }
    if (criteria.tiktokMinFollower) {
      tkFollower = toDecimalNumber({
        number: criteria.tiktokMinFollower,
        toFixed: 0,
      });
    }
    return `${igFollower} / ${tkFollower}`;
  };

  const displayRegister = () => {
    if (totalRegister < 4) {
      return "สมัครแคมเปญนี้";
    } else {
      return `และอีก ${totalRegister - userImage.length} คนสมัครแคมเปญนี้`;
    }
  };

  const customColor =
    tags === "Popular"
      ? COLOR.PINK
      : tags === "High Reach"
      ? COLOR.YELLOW
      : COLOR.Gray_M;

  return (
    <Container>
      <NameContainer>
        <BoldH5Text>{limitText(name, 65)}</BoldH5Text>
      </NameContainer>
      <SpaceBetweenRowContainer
        style={{
          paddingBottom: "24px",
          marginBottom: "24px",
          borderBottom: `1px solid ${COLOR.Gray_200}`,
        }}
      >
        <ValueDetails
          icon="MoneyFill"
          value={displayValue}
          description="มูลค่าสินค้า"
          valueColor={COLOR.Blue_700}
          descriptionColor={COLOR.Gray_M}
        />
        <ValueDetails
          icon="BudgetFill"
          value={displayCash}
          description="เงินค่าตอบแทน"
          valueColor={COLOR.Blue_700}
          descriptionColor={COLOR.Gray_M}
        />
        <ValueDetails
          icon="Follower"
          value={displayFollower()}
          description="Followers IG / TikTok"
          valueColor={COLOR.Blue_700}
          descriptionColor={COLOR.Gray_M}
        />
      </SpaceBetweenRowContainer>
      <RowContainer style={{ marginBottom: "48px" }}>
        <InfoContainer color={customColor}>
          <RXIcon name="Signal" color={customColor} size={20} />
          <BoldB4Text color={customColor}>
            {toDecimalNumber({ number: reach, toFixed: 0 })}
          </BoldB4Text>
        </InfoContainer>
        <InfoContainer color={customColor}>
          <RXIcon name="HeartFill" color={customColor} size={20} />

          <BoldB4Text color={customColor}>
            {toDecimalNumber({ number: like, toFixed: 0 })}
          </BoldB4Text>
        </InfoContainer>
        <InfoContainer color={customColor}>
          <RXIcon name="Comment" color={customColor} size={20} />

          <BoldB4Text color={customColor}>
            {toDecimalNumber({ number: comment, toFixed: 0 })}
          </BoldB4Text>
        </InfoContainer>
      </RowContainer>
      <RowContainer>
        {/* TODO: pull info from backend ทำตาม app อาจต้องแก้ prop userImage */}
        <InfluImageContainer>
          {userImage &&
            userImage.map(
              (
                image: {
                  id: string;
                  profile_picture_url: string;
                  username: string;
                },
                index: number
              ) => {
                return (
                  <InfluImage
                    style={{
                      zIndex: "9" + 9 * Math.pow(10, index),
                      marginLeft: index === 0 ? "0px" : "-20px",
                    }}
                    alt={PLACEHOLDER_PROFILE}
                    src={image.profile_picture_url || PLACEHOLDER_PROFILE}
                    onError={(e) => {
                      e.currentTarget.src = PLACEHOLDER_PROFILE;
                    }}
                  />
                );
              }
            )}
        </InfluImageContainer>
        <BoldB2Text>{displayRegister()}</BoldB2Text>
      </RowContainer>
    </Container>
  );
}

export default DiscoverCampaignCardDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 16px 0px 16px 0px;
  /* white-space: pre-wrap; */
`;

const NameContainer = styled.div`
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  height: 56px;
  margin-bottom: 24px;
  overflow-wrap: break-word;
`;

const InfoContainer = styled.div<{ color: string }>`
  display: flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 50px;
`;

const InfluImageContainer = styled.div`
  margin-right: 8px;
`;

const InfluImage = styled.img`
  width: 50px;
  height: 50px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50%;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const BoldB2Text = styled(TEXT.B2_Bold)``;

const BoldB4Text = styled(TEXT.B4_Bold)<{ color: string }>`
  color: ${(props) => props.color};
`;
