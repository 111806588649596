import { useRef } from "react";
import { InfluencerList } from "./RecommendList";
import { InfluencerListResponse } from "../../types/api/getInfluencer";

const mockedData: InfluencerListResponse[] = [
  {
    id: "influencer_1",
    type: "individual",
    accountId: "account_123",
    tier: "nano",
    platform: "INSTAGRAM",
    username: "influencer_one",
    rating: 4.2,
    ratingCount: 100,
    followers: 8000,
    postCount: 200,
    engagement: 2500,
    score: 85,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post1.jpg",
    isSaved: true,
    attributes: ["fitness", "health"],
    lemonUrl: "https://lemon.com/influencer1",
    rateCard: {
      instagram: [{ _id: "ig_rate1", detail: "Post", rate: 150 }],
      tiktok: [],
      lemon: [],
      facebook: [],
    },
    interests: ["fitness", "health"],
  },
  {
    id: "influencer_2",
    type: "brand",
    accountId: "account_456",
    tier: "micro",
    platform: "TIKTOK",
    username: "influencer_two",
    rating: 4.5,
    ratingCount: 150,
    followers: 35000,
    postCount: 500,
    engagement: 8000,
    score: 90,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post6.avif",
    isSaved: false,
    attributes: ["tech", "gadgets"],
    lemonUrl: "https://lemon.com/influencer2",
    rateCard: {
      instagram: [],
      tiktok: [{ _id: "tt_rate2", detail: "Video", rate: 500 }],
      lemon: [],
      facebook: [],
    },
    interests: ["tech", "gadgets"],
  },
  {
    id: "influencer_3",
    type: "individual",
    accountId: "account_789",
    tier: "mid",
    platform: "TIKTOK",
    username: "influencer_three",
    rating: 4.7,
    ratingCount: 250,
    followers: 60000,
    postCount: 800,
    engagement: 15000,
    score: 95,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post7.avif",
    isSaved: true,
    attributes: ["beauty", "fashion"],
    lemonUrl: "https://lemon.com/influencer3",
    rateCard: {
      instagram: [{ _id: "ig_rate3", detail: "Story", rate: 300 }],
      tiktok: [],
      lemon: [],
      facebook: [],
    },
    interests: ["beauty", "fashion"],
  },
  {
    id: "influencer_4",
    type: "individual",
    accountId: "account_321",
    tier: "macro",
    platform: "FACEBOOK",
    username: "influencer_four",
    rating: 4.9,
    ratingCount: 500,
    followers: 120000,
    postCount: 1200,
    engagement: 50000,
    score: 100,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post2.jpg",
    isSaved: false,
    attributes: ["gaming", "technology"],
    lemonUrl: "https://lemon.com/influencer4",
    rateCard: {
      instagram: [],
      tiktok: [],
      lemon: [],
      facebook: [],
    },
    interests: ["gaming", "technology"],
  },
  {
    id: "influencer_5",
    type: "individual",
    accountId: "account_654",
    tier: "micro",
    platform: "FACEBOOK",
    username: "influencer_five",
    rating: 4.1,
    ratingCount: 110,
    followers: 20000,
    postCount: 400,
    engagement: 7000,
    score: 85,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post3.jpg",
    isSaved: true,
    attributes: ["travel", "adventure"],
    lemonUrl: "https://lemon.com/influencer5",
    rateCard: {
      instagram: [],
      tiktok: [],
      lemon: [],
      facebook: [{ _id: "fb_rate5", detail: "Post", rate: 400 }],
    },
    interests: ["travel", "adventure"],
  },
  {
    id: "influencer_6",
    type: "brand",
    accountId: "account_987",
    tier: "mid",
    platform: "INSTAGRAM",
    username: "influencer_six",
    rating: 4.6,
    ratingCount: 160,
    followers: 55000,
    postCount: 600,
    engagement: 16000,
    score: 88,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post4.jpg",
    isSaved: false,
    attributes: ["food", "lifestyle"],
    lemonUrl: "https://lemon.com/influencer6",
    rateCard: {
      instagram: [{ _id: "ig_rate6", detail: "Post", rate: 350 }],
      tiktok: [],
      lemon: [],
      facebook: [],
    },
    interests: ["food", "lifestyle"],
  },
  {
    id: "influencer_7",
    type: "individual",
    accountId: "account_1234",
    tier: "nano",
    platform: "TIKTOK",
    username: "influencer_seven",
    rating: 4.0,
    ratingCount: 95,
    followers: 9000,
    postCount: 250,
    engagement: 3200,
    score: 80,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post8.avif",
    isSaved: true,
    attributes: ["fitness", "motivation"],
    lemonUrl: "https://lemon.com/influencer7",
    rateCard: {
      instagram: [],
      tiktok: [{ _id: "tt_rate7", detail: "Video", rate: 250 }],
      lemon: [],
      facebook: [],
    },
    interests: ["fitness", "motivation"],
  },
  {
    id: "influencer_8",
    type: "individual",
    accountId: "account_4321",
    tier: "macro",
    platform: "TIKTOK",
    username: "influencer_eight",
    rating: 4.8,
    ratingCount: 300,
    followers: 100000,
    postCount: 900,
    engagement: 45000,
    score: 98,
    profile_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
    cover_picture:
      "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post9.avif",
    isSaved: false,
    attributes: ["music", "art"],
    lemonUrl: "https://lemon.com/influencer8",
    rateCard: {
      instagram: [],
      tiktok: [],
      lemon: [],
      facebook: [],
    },
    interests: ["music", "art"],
  },
];
export const MockedInfluencerList = () => {
  const mockedRef = useRef<HTMLDivElement | null>(null);
  return (
    <InfluencerList
      data={mockedData}
      listLoading={false}
      brandSaved={[]}
      addSavedInflu={() => {}}
      removeSavedInflu={() => {}}
      triggerSaveInfluencer={async (arg: {
        accountId: string;
        isSaved: boolean;
      }) => {}}
      finishInitialize={true}
      activeAttributes={[]}
      scrollViewRef={mockedRef}
    />
  );
};
