import Axios from "axios";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { Account } from "../types/account";
import { GET, PATCH, POST } from "../utils/Networking";
import { ValidateEmail, clearArray } from "../utils/index";
import { AccountStore } from "./accountStore";
import { DealStore } from "./dealStore";

const API_URL = process.env.REACT_APP_STAGING_API_URL;

export class AuthStore {
  @observable
  authLogin = {
    email: "",
    password: "",
  };

  logedIn = {
    email: "",
  };

  @observable
  authToken = "";

  @observable
  authLoginError: string[] = [];

  @observable
  authSignUp = {
    teamEmail: "",
    password: "",
    rePassword: "",
    fullName: "",
    position: "",
    workingEmail: "",
    phoneNumber: "",
    agreed: true,
    marketingAgreed: false,
  };

  @observable
  authSignUpError: string[] = [];

  @observable
  authCompanyInfo = {
    brandIMG: "",
    brandName: "",
    contactName: "",
    contactTelephone: "",
    category: "",
    otherCategory: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    website: "",
  };

  @observable
  authLoading: boolean = false;

  @action
  setAuthLoading = (status: boolean) => {
    this.authLoading = status;
  };

  @observable
  authCompanyInfoError = [];

  @observable
  isLoggedIn = false;

  @observable
  accountStore: AccountStore;

  @observable
  dealStore: DealStore;

  constructor(accountStore: AccountStore, dealStore: DealStore) {
    makeAutoObservable(this);
    this.loadIsLoggedIn();
    this.accountStore = accountStore;
    this.dealStore = dealStore;
  }

  @computed
  get isLoginActive(): boolean {
    return !!this.authLogin.email && this.authLogin.password.length > 7;
  }

  @action
  setAuthLogin = ({
    value,
    property,
  }: {
    value: string;
    property: "email" | "password";
  }) => {
    this.authLogin[property] = value;
  };

  @action
  setAuthSignUp = ({
    value,
    property,
  }: {
    value: string;
    property:
      | "teamEmail"
      | "password"
      | "rePassword"
      | "fullName"
      | "position"
      | "workingEmail"
      | "phoneNumber";
  }) => {
    this.authSignUp[property] = value;
  };

  @action
  setAuthCompanyInfo = ({
    value,
    property,
  }: {
    value: string;
    property:
      | "brandIMG"
      | "brandName"
      | "contactName"
      | "contactTelephone"
      | "category"
      | "otherCategory"
      | "facebook"
      | "instagram"
      | "tiktok"
      | "website";
  }) => {
    this.authCompanyInfo[property] = value;
  };

  @action
  setAuthError = ({
    error,
    type,
  }: {
    error: string;
    type: "login" | "signup" | "companyInfo";
  }) => {
    switch (type) {
      case "login":
        this.authLoginError.push(error);
        break;
      case "signup":
        this.authSignUpError.push(error);
        break;
      case "companyInfo":
        // this.authCompanyInfoError.push(error)
        break;
    }
  };

  @action
  login = async () => {
    try {
      clearArray(this.authLoginError);
      const { authToken, isRegistered, account } = await POST(
        "/brands/auth/login",
        {
          email: this.authLogin.email,
          password: this.authLogin.password,
        },
        null,
        () => {},
        (error: any) => {
          this.setAuthError({ error: error.response.data, type: "login" });
          console.log(this.authLoginError);
        }
      );
      this.authToken = authToken;
      this.logedIn.email = this.authLogin.email;
      if (account) await this.accountStore.setAccounts(account, authToken);
      if (isRegistered) {
        await this.saveAuthToken(authToken);
        this.setIsLoggedIn(true);
        await this.accountStore.getMyAccount();
        this.authLogin.email = "";
        this.authLogin.password = "";
      }

      return { isRegistered, loggedIn: true };
    } catch (e) {
      return { isRegistered: false, loggedIn: false };
    }
  };

  @action
  setIsLoggedIn = (isLoggedIn: boolean) => {
    this.isLoggedIn = isLoggedIn;
    localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  };

  @action
  loadIsLoggedIn = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    this.isLoggedIn = isLoggedIn === "true";
    return this.isLoggedIn;
  };

  @action
  logout = async () => {
    this.removeAuthToken();
    this.dealStore.reset();
    this.accountStore.removeAccounts();
    this.setIsLoggedIn(false);
  };

  @action
  logoutAccounts = async (toRemoveAccounts: Account[]) => {
    const { accounts, getMyAccount, removeAccounts } = this.accountStore;
    // remove brand account from local storage
    let remainAccounts = [...accounts];
    for (let toRemoveAccount of toRemoveAccounts) {
      remainAccounts = remainAccounts.filter(
        ({ brandId }) => brandId !== toRemoveAccount.brandId
      );
      removeAccounts(toRemoveAccount.brandId);
    }

    // if logout all account
    if (remainAccounts.length === 0) {
      this.removeAuthToken();
      this.accountStore.removeAccounts();
      this.setIsLoggedIn(false);
      return true;
    }
    await this.saveAuthToken(remainAccounts[0].authToken);
    getMyAccount();
    return false;
  };

  @action
  clearAuthError = () => {
    clearArray(this.authSignUpError);
  };

  @computed
  get validateTeamEmail() {
    return ValidateEmail(this.authSignUp.teamEmail);
  }

  @computed
  get validatePassword() {
    const isIncludeSpecialCharacter = (char: string) => {
      return (
        char.includes(".") ||
        char.includes("^") ||
        char.includes("_") ||
        char.includes("-") ||
        char.includes("/") ||
        char.includes("=") ||
        char.includes("+")
      );
    };

    const strongRegex = new RegExp(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
    );
    const strongRegexEachChar = new RegExp(/^[a-zA-Z0-9!@#$%^&*]$/);

    const checkEachChar = () => {
      if (isIncludeSpecialCharacter(this.authSignUp.password)) {
        const testString = this.authSignUp.password
          .split("")
          .map(
            (char) =>
              (char =
                strongRegexEachChar.test(char) ||
                isIncludeSpecialCharacter(char)
                  ? "accept"
                  : "reject")
          )
          .filter((x) => x === "reject");
        if (testString.length > 0) return false;
        else return true;
      } else {
        return true;
      }
    };

    return isIncludeSpecialCharacter(this.authSignUp.password)
      ? checkEachChar() && this.authSignUp.password.length >= 8
      : strongRegex.test(this.authSignUp.password) &&
          checkEachChar() &&
          this.authSignUp.password.length >= 8;
  }

  @computed
  get validateRePassword() {
    return (
      this.authSignUp.rePassword.length > 0 &&
      this.authSignUp.password === this.authSignUp.rePassword
    );
  }

  @computed
  get validateFullName() {
    // const fullNameRegex = new RegExp('^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$');
    // return this.authSignUp.fullName.match(fullNameRegex);
    return this.authSignUp.fullName.length > 3;
  }

  @computed
  get validatePosition() {
    return this.authSignUp.position.length > 0;
  }

  @computed
  get validateContactEmail() {
    return ValidateEmail(this.authSignUp.workingEmail);
  }

  @computed
  get validateContactTelephone() {
    const phoneNumber = this.authSignUp.phoneNumber;
    if (phoneNumber.length >= 2) {
      if (
        phoneNumber[0] === "0" &&
        (phoneNumber[1] === "0" || phoneNumber[1] === "1")
      ) {
        return false;
      }
      if (
        phoneNumber[0] === "0" &&
        (phoneNumber[1] === "6" ||
          phoneNumber[1] === "8" ||
          phoneNumber[1] === "9")
      )
        return phoneNumber.length >= 10;
      return phoneNumber.length >= 9;
    }
    return false;
  }

  @action
  checkRegister = async () => {
    this.clearAuthError();
    if (!ValidateEmail(this.authSignUp.teamEmail)) {
      //   this.authSignUpError.push("Invalid Email.");
    } else if (!this.validatePassword) {
      //   this.authSignUpError.push("Password must be a Strong Password");
    } else {
      await GET(
        `/brands/auth/checkDuplicateEmail?email=${this.authSignUp.teamEmail}`,
        (error) => {
          //   this.authSignUpError.push(error.response.data + "\n");
          return this.authSignUpError;
        }
      );
      if (this.authSignUp.password !== this.authSignUp.rePassword) {
        // this.authSignUpError.push("Re-password doesn't match.");
        return this.authSignUpError;
      }
      // check Strong password
    }
    return this.authSignUpError;
  };

  @action
  register = async () => {
    this.clearAuthError();
    await POST(
      "/brands/auth/register",
      {
        email: this.authSignUp.teamEmail,
        password: this.authSignUp.password,
        agreed: this.authSignUp.agreed,
        marketingAgreed: this.authSignUp.marketingAgreed,
      },
      null,
      () => {},
      (error: any) => {
        this.authSignUpError.push(error.response.data);
      }
    );
    return this.authSignUpError;
  };

  saveAuthToken = async (authToken: string) => {
    await localStorage.setItem("authToken", authToken);
  };

  removeAuthToken = async () => {
    localStorage.removeItem("authToken");
  };

  @action
  sendCompanyInfo = async () => {
    clearArray(this.authCompanyInfoError);
    const {
      brandIMG,
      brandName,
      contactName,
      contactTelephone,
      category,
      otherCategory,
      facebook,
      instagram,
      tiktok,
      website,
    } = this.authCompanyInfo;
    if (!brandName) {
      //   this.authCompanyInfoError.push("กรุณากรอกชื่อแบรนด์\n");
    }
    if (!contactName) {
      //   this.authCompanyInfoError.push("กรุณากรอกชื่อ-นามสกุล\n");
    }
    if (!contactTelephone) {
      //   this.authCompanyInfoError.push("กรุณากรอกเบอร์โทรศัพท์\n");
    }
    if (
      category === "โปรดระบุ" ||
      (category === "Others" && otherCategory.length === 0)
    ) {
      //   this.authCompanyInfoError.push("กรุณาเลือกหมวดหมู่\n");
    }
    if (
      instagram.length === 0 &&
      website.length === 0 &&
      facebook.length === 0 &&
      tiktok.length === 0
    ) {
      this.authCompanyInfoError
        .push
        // "กรุณากรอกลิ้งค์ social media อย่างน้อย 1 ประเภท\n"
        ();
    }

    if (this.authCompanyInfoError.length > 0) {
      return { error: this.authCompanyInfoError };
    }

    const { authToken, account } = await PATCH(
      "/brands/auth/companyInfo",
      {
        ...this.authCompanyInfo,
        brandIMG:
          brandIMG ||
          "https://storage.googleapis.com/rabbitx/1612256798664Pickle-Default-01.png",
        category: category !== "Others" ? category : otherCategory,
        facebook: facebook === "fb.com/" ? null : facebook,
        instagram: instagram === "instagram.com/" ? null : instagram,
      },
      {},
      () => {},
      (error) => {
        // this.authCompanyInfoError.push(error.response.data);
      },
      this.authToken
    );
    if (account) {
      await this.accountStore.setAccounts(account, authToken);
      this.accountStore.setMyAccount({
        isVerified: false,
        brandId: account.brandId,
        name: brandName,
        img: brandIMG,
        authToken: "",
        email: this.logedIn.email,
        contactName,
        contactPosition: "",
        contactTelephone,
        companyName: "",
        facebook,
        instagram,
        website,
        createdAt: "",
      });
    }
    await this.saveAuthToken(authToken);
    if (this.isLoggedIn) await this.accountStore.getMyAccount();
    return { isLoggedIn: this.isLoggedIn };
  };

  @action
  sendRegisterFrom = async (registerFrom: any) => {
    try {
      await PATCH("/brands/auth/registerFrom", { registerFrom }, {});
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoggedIn(true);
    }
  };

  @action
  resendEmailVerification = async ({ email }: { email: any }) => {
    try {
      await Axios.post(API_URL + "/brands/auth/verification", { email });
    } catch (error: any) {
      clearArray(this.authLoginError);
      // const errorMSG = error.response.data;
      //   this.authLoginError.push(errorMSG);
    }
  };

  @action
  forgetPassword() {}

  @action
  switchAccount = async (authToken: string) => {
    await this.saveAuthToken(authToken);
    this.accountStore.getMyAccount();
  };
}

export const authStore = ({
  accountStore,
  dealStore,
}: {
  accountStore: AccountStore;
  dealStore: DealStore;
}) => new AuthStore(accountStore, dealStore);
