import InfluencerInsight from "./InfluencerInsight";
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer,
  PlatformSocialData,
  SocialData,
} from "../../../types/api/getInfluencer";
import { IAgeGenderDataObject } from "../../../types/api/listInfluencers";

function MockedInfluencerInsight() {
  return (
    <InfluencerInsight
      platformActive="INSTAGRAM"
      setPlatformActive={() => {}}
      dateFormat="weekly"
      setDateFormat={() => {}}
      instagram={mockedInstagramInfluencer}
      facebook={{} as never as IFacebookInfluencer}
      tiktok={{} as never as ITiktokInfluencer}
      statistic={mockedStatistic}
      socialData={mockSocialData}
      loading={mockedLoading}
      ageGenderData={mockedAgeGenderData}
    />
  );
}

const mockedInstagramInfluencer: IInstagramInfluencer = {
  type: "INSTAGRAM_BUSINESS",
  page: "anotherdot.co",
  profile_picture_url:
    "https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg",
  followers_count: 2432,
  username: "anotherdot.co",
  id: "anotherdot.co",
  recentMedia: [],
  isVerified: true,
  activeFollowers: 898,
  locations: [],
  ages: [
    ["25-34", 1194],
    ["18-24", 490],
    ["35-44", 132],
    ["45-54", 18],
    ["65+", 18],
    ["55-64", 4],
  ],
  genders: [
    ["M", 796],
    ["F", 732],
    ["U", 336],
  ],
  recentMediaLikes: 260,
  recentMediaEngagement: 275,
  profileReaches: 274.14,
  profileImpressions: 427.29,
  insights: {
    months: [
      {
        name: "reach",
        period: "days_28",
        values: [
          {
            value: 2316,
            end_time: "2024-08-22T07:00:00+0000",
          },
          {
            value: 2316,
            end_time: "2024-08-23T07:00:00+0000",
          },
        ],
        title: "การเข้าถึง",

        id: "mocked_id/insights/reach/days_28",
      },
      {
        name: "impressions",
        period: "days_28",
        values: [
          {
            value: 13240,
            end_time: "2024-08-22T07:00:00+0000",
          },
          {
            value: 13033,
            end_time: "2024-08-23T07:00:00+0000",
          },
        ],
        title: "อิมเพรสชัน",
        id: "mocked_id/insights/impressions/days_28",
      },
    ],
  },
  storyInsights: {
    avgStoryReach: 139.52631578947367,
    avgStoryImpressions: 143.68421052631578,
  },
  monthData: {
    postCount: 4,
    commentCount: 19,
    likeCount: 362,
    reachCount: 1841,
    impressionCount: 2018,
    engagement: 381,
    createdAt: new Date("2024-08-23T19:01:44.621Z"),
    shareCount: 0,
    viewCount: 0,
    saveCount: 0,
    postType: { videoCount: 0, imageCount: 0 },
  },
  weekData: {
    postCount: 0,
    commentCount: 0,
    likeCount: 0,
    reachCount: 0,
    impressionCount: 0,
    engagement: 0,
    createdAt: new Date("2024-08-23T19:01:44.621Z"),
    shareCount: 0,
    viewCount: 0,
    saveCount: 0,
    postType: { videoCount: 0, imageCount: 0 },
  },
  demographicAges: [
    ["25-34", 1308],
    ["18-24", 401],
    ["35-44", 175],
    ["45-54", 26],
    ["65+", 15],
    ["13-17", 6],
    ["55-64", 6],
  ],
  demographicAgesGenders: [
    {
      age: "25-34",
      gender: "M",
      value: 593,
    },
    {
      age: "25-34",
      gender: "F",
      value: 503,
    },
    {
      age: "25-34",
      gender: "U",
      value: 212,
    },
    {
      age: "18-24",
      gender: "F",
      value: 204,
    },
    {
      age: "18-24",
      gender: "M",
      value: 101,
    },
    {
      age: "18-24",
      gender: "U",
      value: 96,
    },
    {
      age: "35-44",
      gender: "M",
      value: 90,
    },
    {
      age: "35-44",
      gender: "F",
      value: 51,
    },
    {
      age: "35-44",
      gender: "U",
      value: 34,
    },
    {
      age: "45-54",
      gender: "M",
      value: 9,
    },
    {
      age: "65+",
      gender: "M",
      value: 9,
    },
    {
      age: "45-54",
      gender: "U",
      value: 9,
    },
    {
      age: "45-54",
      gender: "F",
      value: 8,
    },
    {
      age: "55-64",
      gender: "M",
      value: 4,
    },
    {
      age: "65+",
      gender: "U",
      value: 4,
    },
    {
      age: "13-17",
      gender: "F",
      value: 2,
    },
    {
      age: "55-64",
      gender: "F",
      value: 2,
    },
    {
      age: "65+",
      gender: "F",
      value: 2,
    },
    {
      age: "13-17",
      gender: "M",
      value: 2,
    },
    {
      age: "13-17",
      gender: "U",
      value: 2,
    },
  ],
  demographicCities: [
    ["Bangkok, Bangkok", 734],
    ["Rangsit, Pathum Thani", 34],
    ["Chon Buri, Chon Buri", 27],
    ["Changwat Samut Prakan, Samut Prakan", 26],
    ["Khlong Sam, Pathum Thani", 23],
    ["Ban Phayom, Ayutthaya", 19],
    ["Rayong, Rayong", 18],
    ["Chiang Mai, Chiang Mai", 17],
  ],
  demographicCountries: [
    ["TH", 1813],
    ["US", 16],
    ["NG", 14],
    ["AU", 8],
  ],
  demographicGenders: [
    ["M", 808],
    ["F", 772],
    ["U", 357],
  ],
};

const mockedStatistic = {
  pieData: [
    { value: 40, label: "Instagram" },
    { value: 30, label: "TikTok" },
    { value: 20, label: "Facebook" },
    { value: 10, label: "YouTube" },
  ],
  likeData: 2500,
  saveData: 1200,
  commentData: 800,
  shareData: 300,
  reachData: 50000,
  impressionsData: 70000,
  engagementData: 4500,
  viewData: 15000,
  postCount: 25,
};

const mockSocialData: SocialData = {
  INSTAGRAM: {
    posts: [
      {
        image: "https://example.com/post1.jpg",
        username: "influencer1",
        caption: "Great day at the beach! #sunset",
        permalink: "https://instagram.com/post1",
        createdAt: new Date("2023-08-01"),
        like: 1500,
        comment: 200,
        share: 50,
        view: 3000,
        engagement: 1800,
        impression: 5000,
        reach: 4000,
        saved: 100,
        profile_picture_url: "https://example.com/profile1.jpg",
      },
    ],
    graphData: {
      weekly: {
        labels: [
          new Date("2023-05-06"),
          new Date("2023-05-13"),
          new Date("2023-05-20"),
          new Date("2023-05-27"),
          new Date("2023-06-03"),
          new Date("2023-06-10"),
          new Date("2023-06-17"),
          new Date("2023-06-24"),
          new Date("2023-07-01"),
          new Date("2023-07-08"),
          new Date("2023-07-15"),
          new Date("2023-07-22"),
          new Date("2023-07-29"),
          new Date("2023-08-05"),
          new Date("2023-08-12"),
          new Date("2023-08-19"),
          new Date("2023-08-26"),
        ],
        engagement: [
          50, 100, 500, 400, 200, 800, 1000, 300, 1500, 100, 900, 2000, 1500, 0,
          500, 1000, 2500,
        ],
        reach: [
          100, 200, 700, 500, 300, 1000, 1200, 600, 1700, 200, 1300, 2200, 1800,
          0, 600, 1200, 2800,
        ],
        impression: [
          150, 300, 1000, 600, 400, 1300, 1500, 900, 2000, 300, 1500, 2500,
          2000, 0, 800, 1400, 3000,
        ],
        followers: [
          1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000,
          2100, 2200, 2300, 2400, 2500, 2600,
        ],
        view: [
          200, 300, 700, 400, 500, 1000, 900, 500, 1300, 200, 1100, 1700, 1200,
          0, 600, 1000, 2200,
        ],
      },
      monthly: {
        labels: [
          new Date("2023-01-01"),
          new Date("2023-02-01"),
          new Date("2023-03-01"),
        ],
        engagement: [2000, 2500, 2700],
        reach: [8000, 8500, 9000],
        impression: [10000, 10500, 11000],
        followers: [12000, 13000, 14000],
        view: [5000, 5500, 6000],
      },
    },
    similarList: [
      {
        id: "influencer_1",
        type: "individual",
        accountId: "account_123",
        tier: "micro",
        platform: "INSTAGRAM",
        username: "influencer_one",
        rating: 4.5,
        ratingCount: 150,
        followers: 12000,
        postCount: 350,
        engagement: 4500,
        score: 90,
        profile_picture: "https://example.com/profile1.jpg",
        cover_picture: "https://example.com/cover1.jpg",
        isSaved: true,
        attributes: ["fashion", "beauty"],
        lemonUrl: "https://lemon.com/influencer1",
        rateCard: {
          instagram: [{ _id: "ig_rate1", detail: "Story", rate: 200 }],
          tiktok: [],
          lemon: [],
          facebook: [],
        },
        interests: ["fashion", "lifestyle"],
      },
    ],
  },
  TIKTOK: {} as PlatformSocialData,
  FACEBOOK: {} as PlatformSocialData,
  LEMON: {} as PlatformSocialData,
};
const mockedLoading = {
  influencers: {
    pending: false,
    ongoing: false,
    check: true,
    done: true,
  },
  influencer: false,
  reviews: false,
  social: false,
  list: false,
  attribute: false,
  posts: false,
  graph: false,
  similarList: false,
};

const mockedAgeGenderData:
  | {
      genders: [string, number][];
      ages: [string, number][];
      ageGender: IAgeGenderDataObject[];
    }
  | {
      genders: never[];
      ages: never[];
      ageGender?: undefined;
    } = {
  genders: [
    ["M", 10],
    ["F", 60],
    ["U", 30],
  ],
  ages: [
    ["18-24", 30],
    ["25-34", 50],
    ["35-44", 20],
  ],
  ageGender: [
    { age: "13-17", gender: "M", value: 100 },
    { age: "13-17", gender: "F", value: 150 },
    { age: "18-24", gender: "M", value: 200 },
    { age: "18-24", gender: "F", value: 250 },
    { age: "25-34", gender: "M", value: 300 },
    { age: "25-34", gender: "F", value: 350 },
    { age: "35-44", gender: "M", value: 180 },
    { age: "35-44", gender: "F", value: 160 },
    { age: "45-54", gender: "M", value: 120 },
    { age: "45-54", gender: "F", value: 110 },
    { age: "55-64", gender: "M", value: 70 },
    { age: "55-64", gender: "F", value: 60 },
    { age: "65+", gender: "M", value: 50 },
    { age: "65+", gender: "F", value: 40 },
  ],
};
export default MockedInfluencerInsight;
