import styled from 'styled-components';
import REmptyState from '../../../components/atoms/REmptyState';
import NewDealDetail from '../../../components/organisms/CampaignManagement/NewDealDetail';
import { IInfluencerReview } from '../../../types/api/getInfluencer';
import {
  Deal,
  DealAnalysis,
  DealPerformance,
  DealPerformanceTableData,
  DealsChartData,
} from '../../../types/deal';
import { Work, TierWorkData } from '../../../types/work';
import useWindowDimensions from '../../../utils/hook/UseWindowDimensions';
import PerformanceDetails from './PerformanceDetails';
import { NavigateFunction } from 'react-router-dom';
import RLoading from '../../../components/atoms/RLoading';

interface Props {
  showDetail: boolean;
  deal: Deal;
  analysis: DealAnalysis | undefined;
  campaignWorks: Work[];
  dealReviews: {
    reviews: IInfluencerReview[];
    reviewOverall: {
      starCount: number[];
      avgRating: number;
      totalReview: number;
      tagCount: number[];
    };
  };
  reviewLoading: boolean;
  dealPerformance: DealPerformance;
  workEachTier: {
    nano: TierWorkData;
    micro: TierWorkData;
    mid: TierWorkData;
    macro: TierWorkData;
  };
  tableData: DealPerformanceTableData[];
  chartData: DealsChartData;
  img: string;
  name: string;
  isNewDeal: boolean;
  navigation: NavigateFunction;
  swapLoading: boolean;
}

const PerformancePageContent = (props: Props) => {
  const {
    showDetail,
    deal,
    campaignWorks,
    dealReviews,
    reviewLoading,
    dealPerformance,
    workEachTier,
    tableData,
    chartData,
    img,
    name,
    swapLoading,
    isNewDeal,
    navigation,
  } = props;

  const { width } = useWindowDimensions();

  if (swapLoading) {
    return <RLoading containerStyle={{ width: '100%' }} />;
  }

  if (showDetail) {
    return <NewDealDetail deal={deal} detailWidth={width - 312} />;
  } else {
    if (!isNewDeal) {
      const emptyStyle = {
        width: '100%',
        alignSelf: 'center',
        marginTop: 24,
        marginBottom: 48,
      };
      return (
        <REmptyState
          graphic={
            <EmptyStateImage
              src={require('../../../assets/images/state/NoHistory.png')}
            />
          }
          header="ไม่รองรับการใช้งาน"
          descriptions={[
            'Campaign performance',
            'จะรองรับเฉพาะแคมเปญที่ถูกสร้างขึ้น',
            'ตั้งแต่วันที่ 19 สิงหาคม 2567 เป็นต้นไป',
          ]}
          containerStyle={emptyStyle}
          buttonText="สร้างแคมเปญใหม่"
          onClick={() => navigation('/createcampaign')}
        />
      );
    }
    return (
      <PerformanceDetails
        deal={deal}
        dealPerformance={dealPerformance}
        campaignWorks={campaignWorks}
        dealReviews={dealReviews}
        reviewLoading={reviewLoading}
        workEachTier={workEachTier}
        tableData={tableData}
        chartData={chartData}
        img={img}
        name={name}
      />
    );
  }
};

export default PerformancePageContent;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
  margin-bottom: 24px;
`;
