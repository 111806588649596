import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import {
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import ANDButton from "../../../atoms/ANDButton";
import RTextarea from "../../../atoms/RTextarea";
import { COLOR } from "../../../../fonts/color";

interface Props {
  name: string;
  setName: (name: string) => void;
}

function CampaignName(props: Props) {
  const { name, setName } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ชื่อแคมเปญ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <Body3Text>
            เลือกชื่อที่น่าสนใจและได้ใจความ<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
        </GapRowContainer>
        <RTextarea
          inputProp={{
            placeholder: "AnotherDotThailand",
            style: {
              display: "flex",
              width: 568,
              height: 48,
              paddingTop: 12,
              paddingBottom: 12,
              paddingRight: 24,
              paddingLeft: 24,
              gap: 10,
              borderRadius: 24,
              border: `1px solid ${COLOR.Gray_400}`,
              background: `${ANDCOLOR.WHITE}`,
              overflow: "hidden",
            },
            defaultValue: name,
            onBlur: (e) => {
              setName(e.target.value);
            },
          }}
        />
      </ContentContainer>
    </Container>
  );
}

export default CampaignName;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
