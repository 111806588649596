import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";

interface Props {
  width: number;
  height: number;
  onClick: () => void;
  borderRadius?: number;
  children?: JSX.Element;
}
interface ContainerProps {
  width: number;
  height: number;
  borderRadius: number;
}

function ViewImageOverlay(props: Props) {
  const { onClick, width, height, borderRadius = 16, children } = props;
  return (
    <OverlayContainer
      onClick={onClick}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      {children ? (
        children
      ) : (
        <ViewButton>
          <RXIcon name="EyeOn" color={COLOR.WHITE} />
          <WhiteBody1Text>View</WhiteBody1Text>
        </ViewButton>
      )}
    </OverlayContainer>
  );
}

export default ViewImageOverlay;

const OverlayContainer = styled.div<ContainerProps>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  opacity: 0;
  background: ${COLOR.BLACK};
  border-radius: ${(props) => props.borderRadius + "px"};
  transition: 0.3s ease;
  top: 0px;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const WhiteBody1Text = styled(TEXT.body1BOLD)`
  margin-left: 5px;
  color: ${COLOR.WHITE};
`;

const ViewButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${COLOR.BLACK};
  border-radius: 24px;
  border: none;
  cursor: pointer;
`;
