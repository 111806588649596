import { IconName, RXIcon } from "rn-rx-icons";
import { COLOR } from "../../fonts/color";
import styled from "styled-components";
import { TEXT } from "../../fonts/text";
import { CSSProperties } from "react";

interface Props {
  value: string | number;
  description: string;
  valueStyle?: CSSProperties;
  descriptionStyle?: CSSProperties;
  containerStyle?: CSSProperties;
}

const RValueDetail = (props: Props) => {
  const { value, description, containerStyle, valueStyle, descriptionStyle } =
    props;
  return (
    <Container style={containerStyle}>
      <Gray100B3Text style={descriptionStyle}>{description}</Gray100B3Text>
      <BoldWhiteH7Text style={valueStyle}>{value}</BoldWhiteH7Text>
    </Container>
  );
};
export default RValueDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Gray100B3Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_100};
`;

const BoldWhiteH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;
