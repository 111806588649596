import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { ActiveProps } from "../../../../types";
import { tierType } from "../../../../types/createDeal";
import { toCapitalize } from "../../../../utils";
import {
  ColumnContainer,
  RowContainer,
} from "../../../../utils/styling/general";
import AToolTip from "../../../../components/atoms/AToolTip";
import { RXIcon } from "rn-rx-icons";
import { useState } from "react";
import { useInfluencerListFilterContext } from "../../InfluencerListContext";

interface TierProps {
  tier: tierType;
}

const TierComponent = (props: TierProps) => {
  const { tier } = props;
  const { filters, updateFilter } = useInfluencerListFilterContext();
  const active = filters.tier.includes(tier);
  return (
    <TagContainer
      active={active}
      onClick={() => {
        let tempTier = [...filters.tier];
        if (active) {
          tempTier.splice(tempTier.indexOf(tier), 1);
        } else {
          tempTier.push(tier);
        }
        updateFilter("tier", tempTier);
      }}
    >
      <Bu3Text active={active}>{toCapitalize(tier)}</Bu3Text>
    </TagContainer>
  );
};

const FilterTier = () => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  return (
    <Container>
      <GapRowContainer>
        <GrayMB3Text>ระดับ Influencer</GrayMB3Text>
        <AddContainer
          onMouseEnter={() => setTooltipActive(true)}
          onMouseLeave={() => setTooltipActive(false)}
        >
          <RXIcon name="Info" />
        </AddContainer>
        <TooltipContainer active={tooltipActive}>
          <AToolTip
            text={[
              `Nano ผู้ติดตาม 3,000 - 10,000`,
              `Micro ผู้ติดตาม 10,000 - 50,000`,
              `Mid ผู้ติดตาม 50,000 - 300,000`,
              `Macro ผู้ติดตามมากกว่า 300,000`,
            ]}
          />
        </TooltipContainer>
      </GapRowContainer>
      <WrapRowContainer>
        <TierComponent tier="nano" />
        <TierComponent tier="micro" />
        <TierComponent tier="mid" />
        <TierComponent tier="macro" />
      </WrapRowContainer>
    </Container>
  );
};

export default FilterTier;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const WrapRowContainer = styled(RowContainer)`
  gap: 8px;
  flex-wrap: wrap;
`;

const TagContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.4px solid
    ${(props) => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
  background-color: ${(props) => (props.active ? COLOR.Blue_50 : COLOR.WHITE)};
  :hover {
    cursor: pointer;
  }
`;

const GapRowContainer = styled(RowContainer)`
  position: relative;
  gap: 4px;
`;

const AddContainer = styled.div`
  position: relative;
`;

const TooltipContainer = styled.div<ActiveProps>`
  position: absolute;
  bottom: 36px;
  left: 0px;
  display: ${(props) => (props.active ? "flex" : "none")};
  width: 307px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  z-index: 999;
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;

const Bu3Text = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${(props) => (props.active ? COLOR.Blue_700 : COLOR.Gray_D)};
`;
