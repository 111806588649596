import React from "react";
import styled from "styled-components";
import { ANDCOLOR } from "../../../../fonts/andColor";
import { ANDTEXT } from "../../../../fonts/andText";
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../../utils/styling/general";
import { RXIcon } from "rn-rx-icons";
import RCounter from "../../../atoms/RCounter";
import ANDCounter from "../../../atoms/ANDCounter";
import { numberWithCommas, toCapitalize } from "../../../../utils";
import { ITierType, tierType } from "../../../../types/createDeal";
import { influLevelDetail } from "../ScopeOfWork/InfluencerReward";
import { COLOR } from "../../../../fonts/color";

interface Props {
  influencersTier: ITierType;
  setinfluencersTier: (level: ITierType) => void;
}

function InfluencerLevel(props: Props) {
  const { influencersTier, setinfluencersTier } = props;

  const LevelSelector = ({ level }: { level: tierType }) => {
    return (
      <SpaceBetweenRowContainer>
        <ContentContainer>
          <BoldHeader8Text>{toCapitalize(level)}</BoldHeader8Text>
          <Body3Text>{influLevelDetail[level]}</Body3Text>
        </ContentContainer>
        <GapRowContainer>
          <ANDCounter
            value={influencersTier[level].value}
            changeValue={(value) => {
              const tempLevel = { ...influencersTier };
              tempLevel[level].value = value;
              setinfluencersTier(tempLevel);
            }}
            limit={999}
          />
          <ComponentContainer>
            <Body2Text>คน</Body2Text>
          </ComponentContainer>
        </GapRowContainer>
      </SpaceBetweenRowContainer>
    );
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ระดับ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <RXIcon name="PlaceholderFill" color={ANDCOLOR.BLUE} size={20} />
          <Body3Text>
            เลือกระดับผู้ติดตามของ{" "}
            <UnderlineBody3Text>Influencer</UnderlineBody3Text>{" "}
            และจำนวนคนในการโปรโมต<RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
        </GapRowContainer>
        <LevelSelector level="nano" />
        <LevelSelector level="micro" />
        <LevelSelector level="mid" />
        <LevelSelector level="macro" />
      </ContentContainer>
    </Container>
  );
}

export default InfluencerLevel;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 688px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const ComponentContainer = styled.div``;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  margin-bottom: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(ANDTEXT.body3BOLD)`
  text-decoration: underline;
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;

const ButtonText = styled(ANDTEXT.button)``;
