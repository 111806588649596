import styled from "styled-components";
import LoginIcon from "../../../assets/images/login/LoginIcon.png";
import { TEXT } from "../../../fonts/text";
import { COLOR } from "../../../fonts/color";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LargeText = styled(TEXT.H2_Bold)``;

const BlueLargeText = styled(LargeText)`
  color: ${COLOR.Blue_700};
`;

const Logo = styled.img`
  width: 300px;
  height: 300px;
  margin-left: 15px;
  vertical-align: text-top;
`;

function LoginLogoDetail() {
  return (
    <Container>
      <LargeText>
        Pick the perfect
        <br />
        Influencer with
      </LargeText>
      <BlueLargeText>
        AnotherDot
        <Logo src={LoginIcon} alt="Login Icon" />
      </BlueLargeText>
    </Container>
  );
}

export default LoginLogoDetail;
