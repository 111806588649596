import styled from "styled-components";
import { TEXT } from "../../../../fonts/text";
import { ColumnContainer } from "../../../../utils/styling/general";
import { regionalProvinces } from "../../../../components/organisms/CreateCampaign/Options";
import { COLOR } from "../../../../fonts/color";
import { useInfluencerListFilterContext } from "../../InfluencerListContext";
import HierarchyDropdown from "../../../../components/atoms/Dropdown/HierarchyDropdown";

function FilterLocation() {
  const { filters, updateFilter } = useInfluencerListFilterContext();

  return (
    <Container>
      <GeayB3Text>จังหวัด</GeayB3Text>
      <HierarchyDropdown
        placeholder="เลือกจังหวัด"
        defaultValue={filters.location}
        options={regionalProvinces}
        onChange={(option: string[]) => {
          let tempLocation = [...filters.location];
          tempLocation = option;
          updateFilter("location", tempLocation);
        }}
        containerStyle={{ width: 304 }}
        nonChildElement={["ทุกจังหวัด"]}
        labelAsValue={true}
        totalText="ทุกจังหวัด"
      />
    </Container>
  );
}

export default FilterLocation;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const GeayB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;
