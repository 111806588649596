import { action, computed, makeAutoObservable, observable } from "mobx";
import { COLOR } from "../../src/fonts/color";
import { dealKeys, newDealKeys } from "../types/createDeal";
import { getDaysArray, minusHours, minusMinutes, toCapitalize } from "../utils";
import { logAnalyticsEvent } from "../utils/Firebase";
import {
  GET,
  POST,
  uploadDocumentAsync,
  uploadFileAsync,
} from "../utils/Networking";
import { AvgMonthlyData } from "../types/averageMonthlyData";

const initialDeal: dealKeys = {
  name: "",
  nameEditted: false,
  photos: [],
  gender: "",
  age: "13-65",
  detail: "",
  detailEditted: false,
  productDetail: "",
  productEditted: false,

  targetInfluencer: "",
  criteria: {
    gender: "",
    ages: "13-65",
    minFollower: "3000",
    selectedIG: false,
    tiktokMinFollower: "3000",
    facebookMinFollower: "3000",
    selectedTK: false,
    selectedLM: false,
    selectedFB: false,
  },
  secret: false,
  isSecret: false,

  location: "",

  category: "",

  isCash: false,
  isValue: false,
  benefits: "",
  benefitsEditted: false,
  value: 0,
  valueEditted: false,
  cash: 0,
  cashEditted: false,
  limit: 0,

  isStrict: false,

  isHashtags: false,
  hashtags: [],

  isTiktokHashtags: false,
  tiktokHashtags: [],

  isFacebookHashtags: false,
  facebookHashtags: [],

  isKeyMessage: false,
  keyMessage: "",

  isCaptionGuide: false,
  caption: "",

  isTiktokCaptionGuide: false,
  tiktokCaptionGuide: "",

  isFacebookCaptionGuide: false,
  facebookCaptionGuide: "",

  posts: {
    IG_individual_post: 0,
    IG_album_post: 0,
    IG_album_images: 0,
    IG_story: 0,
    IG_tv: 0,
    IG_reels: 0,
    TK_video: 0,
    LM_article: 0,
    FB_individual_post: 0,
    FB_album_post: 0,
    FB_album_images: 0,
    FB_reels: 0,

    isExamples: false,
    exampleDetail: "",
    examples: [],

    isTiktokExamples: false,
    tiktokExamples: [],
    tiktokExampleDetail: "",

    isFacebookExamples: false,
    facebookExamples: [],
    facebookExampleDetail: "",

    postMedias: [],
  },

  startDate: null,
  endDate: null,

  postingDate: null,
  submissionDate: null,

  createdDate: null,

  brandApprove: false,
  isNegotiateScopeOfWorks: false,
  isNegotiateCash: false,
  isNegotiateProductValue: false,

  isCheckBefore: false,

  version: "1.0",
};

const initialNewDeal: newDealKeys = {
  name: "",
  nameEditted: false,
  photos: [],
  gender: "W,M,L",
  age: "13-65",
  detail: "",
  detailEditted: false,
  productDetail: "",
  productEditted: false,
  platforms: "INSTAGRAM",

  targetInfluencer: "",
  criteria: {
    gender: "W,M,L",
    ages: "13-65",
    location: "all",
  },
  secret: false,
  isSecret: false,

  location: "all",

  category: "",

  isCash: false,
  isValue: false,
  benefits: "",
  benefitsEditted: false,
  value: 0,
  valueEditted: false,
  cash: 0,
  cashEditted: false,

  isStrict: false,

  hashtags: [],
  examples: [],

  isKeyMessage: false,
  keyMessage: "",

  posts: {
    IG_individual_post: "0",
    IG_album_post: "0",
    IG_album_images: "0",
    IG_story: "0",
    IG_tv: "0",
    IG_reels: "0",
    TK_video: "0",
    LM_article: "0",
    FB_individual_post: "0",
    FB_album_post: "0",
    FB_album_images: "0",
    FB_reels: "0",
    postMedias: [],
  },

  startDate: null,
  endDate: null,

  postingDate: null,
  submissionDate: null,

  createdDate: null,

  isNegotiateCash: false,
  mustReturnProduct: false,

  version: "1.0",

  influencersTier: {
    nano: {
      value: 0,
      cash: 0,
    },
    micro: {
      value: 0,
      cash: 0,
    },
    mid: {
      value: 0,
      cash: 0,
    },
    macro: {
      value: 0,
      cash: 0,
    },
  },

  influencersCaption: {
    nano: `Scope of Work (สิ่งที่ต้องทำ)
- ไม่มี

Key Message (แมสเสจที่อยากให้เน้น)
- ไม่มี
  
ไกด์ไลน์สไตล์ Picture / VDO ที่ต้องการ
- ไม่มี
   
ไกด์ไลน์แคปชั่นที่ต้องการ
- ไม่มี

แฮชแท็กที่ต้องใส่
- ไม่มี

Mood & Tone ที่ต้องการ
- ไม่มี

Do / Don't (ห้ามทำอะไร ควรทำอะไร)
- ไม่มี

แก้งานได้กี่ครั้ง
- ไม่มี
  
นำรูป / คลิปไปใช้ต่อหรือไม่
- ไม่มี`,
    micro: `Scope of Work (สิ่งที่ต้องทำ)
- ไม่มี

Key Message (แมสเสจที่อยากให้เน้น)
- ไม่มี
  
ไกด์ไลน์สไตล์ Picture / VDO ที่ต้องการ
- ไม่มี
   
ไกด์ไลน์แคปชั่นที่ต้องการ
- ไม่มี

แฮชแท็กที่ต้องใส่
- ไม่มี

Mood & Tone ที่ต้องการ
- ไม่มี

Do / Don't (ห้ามทำอะไร ควรทำอะไร)
- ไม่มี

แก้งานได้กี่ครั้ง
- ไม่มี
  
นำรูป / คลิปไปใช้ต่อหรือไม่
- ไม่มี`,
    mid: `Scope of Work (สิ่งที่ต้องทำ)
- ไม่มี

Key Message (แมสเสจที่อยากให้เน้น)
- ไม่มี
  
ไกด์ไลน์สไตล์ Picture / VDO ที่ต้องการ
- ไม่มี
   
ไกด์ไลน์แคปชั่นที่ต้องการ
- ไม่มี

แฮชแท็กที่ต้องใส่
- ไม่มี

Mood & Tone ที่ต้องการ
- ไม่มี

Do / Don't (ห้ามทำอะไร ควรทำอะไร)
- ไม่มี

แก้งานได้กี่ครั้ง
- ไม่มี
  
นำรูป / คลิปไปใช้ต่อหรือไม่
- ไม่มี`,
    macro: `Scope of Work (สิ่งที่ต้องทำ)
- ไม่มี

Key Message (แมสเสจที่อยากให้เน้น)
- ไม่มี
  
ไกด์ไลน์สไตล์ Picture / VDO ที่ต้องการ
- ไม่มี
   
ไกด์ไลน์แคปชั่นที่ต้องการ
- ไม่มี

แฮชแท็กที่ต้องใส่
- ไม่มี

Mood & Tone ที่ต้องการ
- ไม่มี

Do / Don't (ห้ามทำอะไร ควรทำอะไร)
- ไม่มี

แก้งานได้กี่ครั้ง
- ไม่มี
  
นำรูป / คลิปไปใช้ต่อหรือไม่
- ไม่มี`,
  },

  reviewTag: [],
  ads: {
    startDate: null,
    endDate: null,
    value: "none",
  },

  attribute: [],
  documents: [],
};

export class CreateDealStore {
  constructor() {
    makeAutoObservable(this);
    this.loadDraft().then(() => {
      if (this.deal && this.deal.createdDate) {
        if (new Date("2022-06-01") > new Date(this.deal.createdDate)) {
          this.clearDraft();
          return;
        }
      }
    });
    this.loadNewDraft().then(() => {
      if (this.newDeal && this.newDeal.createdDate) {
        if (new Date("2022-06-01") > new Date(this.newDeal.createdDate)) {
          this.clearNewDraft();
          return;
        }
      }
    });
  }

  @observable
  deal: dealKeys = initialDeal;

  @observable
  newDeal: newDealKeys = initialNewDeal;

  @observable
  platformOrder = [];

  @computed
  get markedApplicationDates() {
    const marked: Record<
      string,
      {
        color?: string;
        startingDay?: boolean;
        endingDay?: boolean;
        dotColor?: string;
        marked?: boolean;
        textColor: string;
      }
    > = {};
    const days =
      this.deal.startDate && this.deal.endDate
        ? getDaysArray(
            new Date(this.deal.startDate || this.deal.endDate),
            new Date(this.deal.endDate || this.deal.startDate)
          )
        : [];
    marked[minusHours(new Date().toISOString(), -7).split("T")[0]] = {
      textColor: COLOR.BLACK,
      dotColor: COLOR.GREEN,
      marked: true,
    };
    if (days.length <= 1) {
      const d = days[0].toISOString().split("T")[0];
      marked[d] = {
        startingDay: true,
        endingDay: true,
        color: COLOR.GREEN,
        textColor: COLOR.WHITE,
      };
    } else {
      days.forEach((day, index) => {
        const d = day.toISOString().split("T")[0];
        if (index === 0) {
          marked[d] = {
            startingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else if (index === days.length - 1) {
          marked[d] = {
            endingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else {
          marked[d] = {
            color: COLOR.LIGHTGREEN,
            textColor: COLOR.BLACK,
          };
        }
      });
    }
    return marked;
  }

  @computed
  get markedWorkDates() {
    const marked: Record<
      string,
      {
        color?: string;
        startingDay?: boolean;
        endingDay?: boolean;
        dotColor?: string;
        marked?: boolean;
        textColor: string;
      }
    > = {};
    const days =
      this.deal.postingDate && this.deal.submissionDate
        ? getDaysArray(
            new Date(this.deal.postingDate || this.deal.submissionDate),
            new Date(this.deal.submissionDate || this.deal.postingDate)
          )
        : [];
    marked[minusHours(new Date().toISOString(), -7).split("T")[0]] = {
      textColor: COLOR.BLACK,
      dotColor: COLOR.GREEN,
      marked: true,
    };
    if (days.length <= 1) {
      const d = days[0].toISOString().split("T")[0];
      marked[d] = {
        startingDay: true,
        endingDay: true,
        color: COLOR.GREEN,
        textColor: COLOR.WHITE,
      };
    } else {
      days.forEach((day, index) => {
        const d = day.toISOString().split("T")[0];
        if (index === 0) {
          marked[d] = {
            startingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else if (index === days.length - 1) {
          marked[d] = {
            endingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else {
          marked[d] = {
            color: COLOR.LIGHTGREEN,
            textColor: COLOR.BLACK,
          };
        }
      });
    }
    return marked;
  }

  @observable
  saving = false;

  @observable
  publishing = false;

  @observable
  averageData: AvgMonthlyData[] = [];

  @computed
  get budgetNeeded() {
    var cash = this.deal.isCash ? this.deal.cash || 0 : 0;
    var value = this.deal.isValue ? this.deal.value || 0 : 0;
    var limit = !!this.deal.limit ? this.deal.limit : 1;
    var perInfluencerBudget = cash + value;

    return {
      cash: cash,
      value: value,
      limit: limit,
      perInfluencerBudget: perInfluencerBudget,
      budget: perInfluencerBudget * limit,
    };
  }

  @computed
  get indexSuccess() {
    const _indexSuccess = [];
    const budget = this.budgetNeeded;
    if (
      this.deal.name.trim().length > 0 &&
      this.deal.photos.length > 0 &&
      this.deal.detail.length > 0 &&
      this.deal.productDetail?.length > 0 &&
      this.deal.category &&
      this.deal.gender?.trim().length > 0 &&
      this.deal.location
    ) {
      _indexSuccess.push(0);
    }

    let igSecondIndexPass = true;
    let tkSecondIndexPass = true;
    let fbSecondIndexPass = true;

    if (
      this.deal.criteria.selectedIG &&
      isNaN(+this.deal.criteria.minFollower) &&
      +this.deal.criteria.minFollower < 0
    ) {
      igSecondIndexPass = false;
    }
    if (
      this.deal.criteria.selectedTK &&
      isNaN(+this.deal.criteria.tiktokMinFollower) &&
      +this.deal.criteria.tiktokMinFollower < 0
    ) {
      tkSecondIndexPass = false;
    }
    if (
      this.deal.criteria.selectedFB &&
      isNaN(+this.deal.criteria.facebookMinFollower) &&
      +this.deal.criteria.facebookMinFollower < 0
    ) {
      fbSecondIndexPass = false;
    }

    if (
      this.deal.criteria.gender?.trim().length > 0 &&
      (this.deal.criteria.selectedIG ||
        this.deal.criteria.selectedTK ||
        this.deal.criteria.selectedFB) &&
      igSecondIndexPass &&
      tkSecondIndexPass &&
      fbSecondIndexPass &&
      this.deal.limit > 0
    ) {
      _indexSuccess.push(1);
    }

    let igPass = true;
    let tkPass = true;
    let fbPass = true;
    let lmPass = true;

    if (!!this.deal.posts.postMedias) {
      if (
        this.deal.criteria.selectedIG &&
        this.deal.posts.postMedias.filter((v: { postType: string }) =>
          v.postType.startsWith("IG")
        ).length < 1
      ) {
        igPass = false;
      }

      if (
        this.deal.criteria.selectedTK &&
        this.deal.posts.postMedias.filter(
          (v: { postType: string }) => v.postType === "TK_video"
        ).length < 1
      ) {
        tkPass = false;
      }

      if (
        this.deal.criteria.selectedFB &&
        this.deal.posts.postMedias.filter((v: { postType: string }) =>
          v.postType.startsWith("FB")
        ).length < 1
      ) {
        fbPass = false;
      }

      if (
        this.deal.criteria.selectedLM &&
        this.deal.posts.postMedias.filter(
          (v: { postType: string }) => v.postType === "LM_article"
        ).length < 1
      ) {
        lmPass = false;
      }
    }

    if (
      igPass &&
      tkPass &&
      lmPass &&
      fbPass &&
      (this.deal.criteria.selectedIG ||
        this.deal.criteria.selectedTK ||
        this.deal.criteria.selectedFB ||
        this.deal.criteria.selectedLM)
    ) {
      _indexSuccess.push(2);
    }

    if (
      ((this.deal.isCash && this.deal.cash > 0) ||
        (this.deal.isValue && this.deal.benefits && this.deal.value > 0)) &&
      budget.perInfluencerBudget > 299
    ) {
      if (this.deal.isValue) {
        if (this.deal.benefits && this.deal.value > 0) {
          _indexSuccess.push(3);
        }
      } else {
        _indexSuccess.push(3);
      }
    }

    if (
      this.deal.startDate &&
      this.deal.endDate &&
      this.deal.postingDate &&
      this.deal.submissionDate
    ) {
      _indexSuccess.push(4);
    }
    return _indexSuccess;
  }

  @computed
  get newIndexSucces() {
    const _indexSuccess = [];
    const totalInfluencers =
      this.newDeal.influencersTier.nano.value +
      this.newDeal.influencersTier.micro.value +
      this.newDeal.influencersTier.mid.value +
      this.newDeal.influencersTier.macro.value;

    if (
      (this.newDeal.influencersTier.nano.value > 0 ||
        this.newDeal.influencersTier.micro.value > 0 ||
        this.newDeal.influencersTier.mid.value > 0 ||
        this.newDeal.influencersTier.macro.value > 0) &&
      this.newDeal.gender !== "" &&
      this.newDeal.criteria.gender !== "" &&
      this.newDeal.age !== "" &&
      this.newDeal.criteria.ages !== "" &&
      this.newDeal.location !== "" &&
      this.newDeal.criteria.location !== ""
    )
      _indexSuccess.push(0);

    const nanoCondition =
      this.newDeal.influencersTier.nano.value === 0 ||
      (this.newDeal.influencersTier.nano.value > 0 &&
        this.newDeal.influencersTier.nano.cash > 0);

    const microCondition =
      this.newDeal.influencersTier.micro.value === 0 ||
      (this.newDeal.influencersTier.micro.value > 0 &&
        this.newDeal.influencersTier.micro.cash > 0);

    const midCondition =
      this.newDeal.influencersTier.mid.value === 0 ||
      (this.newDeal.influencersTier.mid.value > 0 &&
        this.newDeal.influencersTier.mid.cash > 0);

    const macroCondition =
      this.newDeal.influencersTier.macro.value === 0 ||
      (this.newDeal.influencersTier.macro.value > 0 &&
        this.newDeal.influencersTier.macro.cash > 0);

    const cashCondition =
      this.newDeal.isCash &&
      nanoCondition &&
      midCondition &&
      macroCondition &&
      microCondition;

    const valueCondition =
      !this.newDeal.isValue ||
      (this.newDeal.isValue &&
        this.newDeal.value > 0 &&
        !!this.newDeal.benefits);

    if (
      this.newDeal.posts.postMedias.length > 0 &&
      cashCondition &&
      valueCondition
    )
      _indexSuccess.push(1);

    if (
      this.newDeal.photos.length > 0 &&
      this.newDeal.name.trim().length > 0 &&
      this.newDeal.detail.trim().length > 0 &&
      this.newDeal.category !== "" &&
      this.newDeal.startDate &&
      this.newDeal.endDate &&
      this.newDeal.postingDate &&
      this.newDeal.submissionDate
    )
      _indexSuccess.push(2);
    return _indexSuccess;
  }

  @action
  loadDraft = async () => {
    const draft =
      localStorage.getItem("draftDeal") || JSON.stringify(initialDeal);
    this.deal = JSON.parse(draft);
  };

  @action
  loadNewDraft = async () => {
    const draft =
      localStorage.getItem("draftNewDeal") || JSON.stringify(initialNewDeal);
    this.newDeal = JSON.parse(draft);
  };

  @action
  saveDraft = async () => {
    this.saving = true;
    localStorage.setItem("draftDeal", JSON.stringify(this.deal));
    this.saving = false;
  };

  @action
  saveNewDraft = async () => {
    this.saving = true;
    localStorage.setItem("draftNewDeal", JSON.stringify(this.newDeal));
    this.saving = false;
  };

  @action
  clearDraft = async () => {
    this.deal = initialDeal;
    this.platformOrder = [];
    this.saveDraft();
  };

  @action
  clearNewDraft = async () => {
    this.newDeal = initialNewDeal;
    this.platformOrder = [];
    this.saveNewDraft();
  };

  @computed
  get isDraftAvailable() {
    return (
      this.newDeal.name.trim().length > 0 ||
      this.newDeal.photos.length > 0 ||
      this.newDeal.detail.length > 0 ||
      this.newDeal.productDetail?.length > 0 ||
      !!this.newDeal.category ||
      this.newDeal.isCash ||
      this.newDeal.isValue ||
      !!this.newDeal.startDate ||
      !!this.newDeal.endDate ||
      !!this.newDeal.postingDate ||
      !!this.newDeal.submissionDate
    );
  }

  @action
  createDeal = async (onProgress: (progress: any) => void) => {
    try {
      this.publishing = true;
      if (!this.deal.posts.isExamples) {
        this.deal.posts.examples = [];
        this.deal.posts.exampleDetail = "";
      }

      // upload images (deal.photos) to server
      const photosProm = this.deal.photos.map((element) => {
        if (typeof element === "object") {
          const { file } = element;
          if (!!file) return uploadFileAsync(file);
        }
        return { imageUrl: null };
      });

      // upload images (deal.posts.example) to server
      const exampleProms = this.deal.posts.examples.map((element) => {
        if (typeof element === "object") {
          const { file } = element;
          if (!!file) return uploadFileAsync(file);
        }
        return { imageUrl: null };
      });

      // upload images (deal.posts.example) to server
      const tiktokExampleProms = this.deal.posts.tiktokExamples.map(
        (element) => {
          if (typeof element === "object") {
            const { file } = element;
            if (!!file) return uploadFileAsync(file);
          }
          return { imageUrl: null };
        }
      );

      const facebookExampleProms = this.deal.posts.facebookExamples.map(
        (element) => {
          if (typeof element === "object") {
            const { file } = element;
            if (!!file) return uploadFileAsync(file);
          }
          return { imageUrl: null };
        }
      );

      const photos = (await Promise.all(photosProm)).map(
        ({ imageUrl }) => imageUrl
      );

      const example = (await Promise.all(exampleProms)).map(
        ({ imageUrl }) => imageUrl
      );

      const tiktokExample = (await Promise.all(tiktokExampleProms)).map(
        ({ imageUrl }) => imageUrl
      );

      const facebookExample = (await Promise.all(facebookExampleProms)).map(
        ({ imageUrl }) => imageUrl
      );

      this.deal.photos = photos;
      this.deal.posts.examples = example;
      this.deal.posts.tiktokExamples = tiktokExample;
      this.deal.posts.facebookExamples = facebookExample;

      const platforms = [];
      if (this.deal.criteria.selectedIG) {
        platforms.push("Instagram");

        this.deal.posts.IG_individual_post = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "IG_individual_post"
        ).length;

        const tempAlbum = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "IG_album_post"
        );

        this.deal.posts.IG_album_post = tempAlbum.length;

        let tempImage = 0;
        tempAlbum.forEach((album: { video: number; image: number }) => {
          tempImage = tempImage + album.video + album.image;
        });

        this.deal.posts.IG_album_images = tempImage;

        const tempStory = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "IG_story"
        );
        let tempStoryImage = 0;
        tempStory.forEach((story: { video: number; image: number }) => {
          tempStoryImage = tempStoryImage + story.video + story.image;
        });

        this.deal.posts.IG_story = tempStoryImage;

        this.deal.posts.IG_tv = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "IG_tv"
        ).length;
        this.deal.posts.IG_reels = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "IG_reels"
        ).length;
      }

      if (this.deal.criteria.selectedTK) {
        platforms.push("Tiktok");
        this.deal.posts.TK_video = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType === "TK_video"
        ).length;
      }

      if (this.deal.criteria.selectedFB) {
        platforms.push("Facebook");

        this.deal.posts.FB_individual_post = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "FB_individual_post"
        ).length;

        const tempAlbum = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "FB_album_post"
        );

        this.deal.posts.FB_album_post = tempAlbum.length;

        let tempImage = 0;
        tempAlbum.forEach((album: { video: number; image: number }) => {
          tempImage = tempImage + album.video + album.image;
        });

        this.deal.posts.FB_album_images = tempImage;

        const tempStory = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "FB_story"
        );
        let tempStoryImage = 0;
        tempStory.forEach((story: { video: number; image: number }) => {
          tempStoryImage = tempStoryImage + story.video + story.image;
        });

        this.deal.posts.FB_reels = this.deal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "FB_reels"
        ).length;
      }

      if (this.deal.criteria.selectedLM) {
        platforms.push("Lemon");
        this.deal.posts.LM_article = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType === "LM_article"
        ).length;
      }

      this.deal.hashtags = this.deal.hashtags.filter(
        (hashtag: string) => hashtag?.trim() !== ""
      );
      this.deal.tiktokHashtags = this.deal.tiktokHashtags.filter(
        (h: string) => h?.trim() !== ""
      );
      this.deal.facebookHashtags = this.deal.facebookHashtags.filter(
        (h: string) => h?.trim() !== ""
      );

      if (!this.deal.criteria.selectedIG) {
        this.deal.posts.IG_album_images = 0;
        this.deal.posts.IG_album_post = 0;
        this.deal.posts.IG_individual_post = 0;
        this.deal.posts.IG_reels = 0;
        this.deal.posts.IG_story = 0;
        this.deal.posts.postMedias = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => !p.postType.startsWith("IG")
        );
      }
      if (!this.deal.criteria.selectedTK) {
        this.deal.posts.TK_video = 0;
        this.deal.posts.postMedias = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType !== "TK_video"
        );
      }
      if (!this.deal.criteria.selectedFB) {
        this.deal.posts.FB_album_images = 0;
        this.deal.posts.FB_album_post = 0;
        this.deal.posts.FB_individual_post = 0;
        this.deal.posts.FB_reels = 0;
        this.deal.posts.postMedias = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => !p.postType.startsWith("FB")
        );
      }
      if (!this.deal.criteria.selectedLM) {
        this.deal.posts.LM_article = 0;
        this.deal.posts.postMedias = this.deal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType !== "LM_article"
        );
      }
      logAnalyticsEvent("create_campaign", {
        dealName: this.deal.name,
      });
      const publishedDeal = await POST(
        "/deals",
        {
          ...this.deal,
          name: this.deal.name.trim(),
          cash: this.deal.isCash ? this.deal.cash : 0,
          value: this.deal.isValue ? this.deal.value : 0,
          benefits: this.deal.isValue ? [this.deal.benefits] : undefined,
          hashtags: this.deal.hashtags,
          tiktokHashtags: this.deal.tiktokHashtags,
          facebookHashtags: this.deal.facebookHashtags,
          caption: this.deal.caption,
          tiktokCaption: this.deal.tiktokCaptionGuide,
          facebookCaption: this.deal.facebookCaptionGuide,
          startDate: this.deal.startDate,
          postingDate: this.deal.postingDate,
          dueDate: minusMinutes(minusHours(this.deal.endDate, -24), 1),
          submissionDate: minusMinutes(
            minusHours(this.deal.submissionDate, -24),
            1
          ),
          platforms,
          targetInfluencers: [this.deal.targetInfluencer],
          secret: this.deal.secret || this.deal.isSecret,
          brandApprove: this.deal.brandApprove,
        },
        {},
        onProgress
      );
      if (publishedDeal) {
        this.clearDraft();
      }
      return publishedDeal;
    } catch (error) {
      console.log(error);
    } finally {
      this.publishing = false;
    }
  };

  @action
  createNewDeal = async (onProgress: (progress: any) => void) => {
    try {
      this.publishing = true;

      // upload images (deal.photos) to server
      const photosProm = this.newDeal.photos.map((element) => {
        if (typeof element === "object") {
          const { file } = element;
          if (!!file) return uploadFileAsync(file);
        }
        return { imageUrl: null };
      });

      // upload images (deal.posts.example) to server
      const exampleProms = this.newDeal.examples.map((element) => {
        if (typeof element === "object") {
          const { file } = element;
          if (!!file) return uploadFileAsync(file);
        }
        return { imageUrl: null };
      });

      // upload images (deal.posts.example) to server
      const documentsProms = this.newDeal.documents.map((element) => {
        if (typeof element === "object") {
          const { file } = element;
          if (!!file) return uploadDocumentAsync(file, element.name);
        }
        return { name: null };
      });

      const photos = (await Promise.all(photosProm)).map(
        ({ imageUrl }) => imageUrl
      );

      const example = (await Promise.all(exampleProms)).map(
        ({ imageUrl }) => imageUrl
      );

      const document = await Promise.all(documentsProms);

      this.newDeal.photos = photos;
      this.newDeal.examples = example;
      this.newDeal.documents = document;

      if (this.newDeal.platforms === "INSTAGRAM") {
        this.newDeal.posts.IG_individual_post = this.newDeal.posts.postMedias
          .filter(
            (postMedia: { postType: string }) =>
              postMedia.postType === "IG_individual_post"
          )
          .length.toString();

        const tempAlbum = this.newDeal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "IG_album_post"
        );

        this.newDeal.posts.IG_album_post = tempAlbum.length.toString();

        let tempImage = 0;
        tempAlbum.forEach((album: { video: number; image: number }) => {
          tempImage = tempImage + album.video + album.image;
        });

        this.newDeal.posts.IG_album_images = tempImage.toString();

        const tempStory = this.newDeal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "IG_story"
        );
        let tempStoryImage = 0;
        tempStory.forEach((story: { video: number; image: number }) => {
          tempStoryImage = tempStoryImage + story.video + story.image;
        });

        this.newDeal.posts.IG_story = tempStoryImage.toString();

        this.newDeal.posts.IG_tv = this.newDeal.posts.postMedias
          .filter(
            (postMedia: { postType: string }) => postMedia.postType === "IG_tv"
          )
          .length.toString();
        this.newDeal.posts.IG_reels = this.newDeal.posts.postMedias
          .filter(
            (postMedia: { postType: string }) =>
              postMedia.postType === "IG_reels"
          )
          .length.toString();
      }

      if (this.newDeal.platforms === "TIKTOK") {
        this.newDeal.posts.TK_video = this.newDeal.posts.postMedias
          .filter((p: { postType: string }) => p.postType === "TK_video")
          .length.toString();
      }

      if (this.newDeal.platforms === "FACEBOOK") {
        this.newDeal.posts.FB_individual_post = this.newDeal.posts.postMedias
          .filter(
            (postMedia: { postType: string }) =>
              postMedia.postType === "FB_individual_post"
          )
          .length.toString();

        const tempAlbum = this.newDeal.posts.postMedias.filter(
          (postMedia: { postType: string }) =>
            postMedia.postType === "FB_album_post"
        );

        this.newDeal.posts.FB_album_post = tempAlbum.length.toString();

        let tempImage = 0;
        tempAlbum.forEach((album: { video: number; image: number }) => {
          tempImage = tempImage + album.video + album.image;
        });

        this.newDeal.posts.FB_album_images = tempImage.toString();

        const tempStory = this.newDeal.posts.postMedias.filter(
          (postMedia: { postType: string }) => postMedia.postType === "FB_story"
        );
        let tempStoryImage = 0;
        tempStory.forEach((story: { video: number; image: number }) => {
          tempStoryImage = tempStoryImage + story.video + story.image;
        });

        this.newDeal.posts.FB_reels = this.newDeal.posts.postMedias
          .filter(
            (postMedia: { postType: string }) =>
              postMedia.postType === "FB_reels"
          )
          .length.toString();
      }

      if (this.newDeal.platforms === "LEMON") {
        this.newDeal.posts.LM_article = this.newDeal.posts.postMedias
          .filter((p: { postType: string }) => p.postType === "LM_article")
          .length.toString();
      }

      this.newDeal.hashtags = this.newDeal.hashtags.filter(
        (hashtag: string) => hashtag?.trim() !== ""
      );

      if (this.newDeal.platforms !== "INSTAGRAM") {
        this.newDeal.posts.IG_album_images = "0";
        this.newDeal.posts.IG_album_post = "0";
        this.newDeal.posts.IG_individual_post = "0";
        this.newDeal.posts.IG_reels = "0";
        this.newDeal.posts.IG_story = "0";
        this.newDeal.posts.postMedias = this.newDeal.posts.postMedias.filter(
          (p: { postType: string }) => !p.postType.startsWith("IG")
        );
      }
      if (this.newDeal.platforms !== "TIKTOK") {
        this.newDeal.posts.TK_video = "0";
        this.newDeal.posts.postMedias = this.newDeal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType !== "TK_video"
        );
      }
      if (this.newDeal.platforms !== "LEMON") {
        this.newDeal.posts.LM_article = "0";
        this.newDeal.posts.postMedias = this.newDeal.posts.postMedias.filter(
          (p: { postType: string }) => p.postType !== "LM_article"
        );
      }
      if (this.newDeal.platforms !== "FACEBOOK") {
        this.newDeal.posts.FB_album_images = "0";
        this.newDeal.posts.FB_album_post = "0";
        this.newDeal.posts.FB_individual_post = "0";
        this.newDeal.posts.FB_reels = "0";
        this.newDeal.posts.postMedias = this.newDeal.posts.postMedias.filter(
          (p: { postType: string }) => !p.postType.startsWith("FB")
        );
      }

      logAnalyticsEvent("create_campaign", {
        dealName: this.newDeal.name,
      });

      const publishedDeal = await POST(
        "/deals/new",
        {
          ...this.newDeal,
          name: this.newDeal.name.trim(),
          cash: this.newDeal.isCash ? this.newDeal.cash : 0,
          value: this.newDeal.isValue ? this.newDeal.value : 0,
          benefits: this.newDeal.isValue ? [this.newDeal.benefits] : undefined,
          hashtags: this.newDeal.hashtags,
          startDate: this.newDeal.startDate,
          postingDate: this.newDeal.postingDate,
          dueDate: minusMinutes(minusHours(this.newDeal.endDate, -24), 1),
          submissionDate: minusMinutes(
            minusHours(this.newDeal.submissionDate, -24),
            1
          ),
          targetInfluencers: [this.newDeal.targetInfluencer],
          secret: this.newDeal.secret || this.newDeal.isSecret,
          platforms: toCapitalize(this.newDeal.platforms),
        },
        {},
        onProgress
      );
      if (publishedDeal) {
        this.clearNewDraft();
      }
      return publishedDeal;
    } catch (error) {
      console.log(error);
    } finally {
      this.publishing = false;
    }
  };

  @action
  setDealData = (newDeal: newDealKeys) => {
    this.newDeal = newDeal;
  };

  @action
  clearDealDocuments = () => {
    this.newDeal.documents = [];
  };

  @action
  getAvgData = async () => {
    try {
      const result = await GET("/influencers/avg/monthly");
      this.averageData = result;
    } catch (error) {
      console.log(error);
    }
  };
}

export const createDealStore = new CreateDealStore();
