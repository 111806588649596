import { NavigateFunction } from "react-router-dom";
import { ChatBanners, Room } from "./../types/chatMessage.d";
import {
  child,
  endAt,
  get,
  getDatabase,
  limitToLast,
  off,
  onChildChanged,
  onChildAdded,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  update,
} from "firebase/database";
import {
  action,
  observable,
  runInAction,
  makeAutoObservable,
  computed,
} from "mobx";
import { User } from "react-web-gifted-chat";
import { ChatMessage } from "../types/chatMessage";
// import { decrementBadges } from "../utils/Badge";
import { initializeApp } from "../utils/Firebase";
import {
  GET,
  uploadFileAsync,
  uploadImageAsync,
  uploadVideoAsync,
  uploadVideoURLAsync,
} from "../utils/Networking";
import { Account } from "../types/account";

const initializeMessages = 20;

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "staging";

export class ChatStore {
  private readonly database;
  constructor() {
    makeAutoObservable(this);
    const app = initializeApp();
    this.database = getDatabase(app);
  }

  @observable
  message: Record<string, ChatMessage[]> = {};

  @observable
  rooms: Record<string, ChatMessage> = {};

  @observable
  allRooms: Array<Room> = [];

  @observable
  lastMessages: Record<string, ChatMessage> = {};

  @observable
  unreadMessages: Record<string, number> = {};

  @observable
  noUnreadMessages = 0;

  @observable
  banners: Record<string, ChatBanners> = {};

  @observable
  influList = [];

  @observable
  influListLoading = false;

  @observable
  subscribeLoading = false;

  @observable
  bannerLoading = true;

  @observable
  chatsLoading = false;

  @observable
  loading = false;

  @observable
  shouldUpdate = false;

  @observable
  canLoadEarlier = false;

  @observable
  uploadingMessages: Record<string, Record<string, ChatMessage>> = {};

  @computed
  get messages(): Record<string, ChatMessage[]> {
    Object.keys(this.message).forEach((key) => {
      this.message[key].forEach((value) => {
        if (Object(value.user).hasOwnProperty("_id")) {
          value.user.id = value.user._id;
        }
      });
    });
    return this.message;
  }

  @action
  send = (dealContactId: string, messages: Array<ChatMessage>) => {
    messages.forEach((_message: ChatMessage) => {
      const message: ChatMessage = {
        ..._message,
        user: {
          ..._message.user,
          type: "brand",
        },
        timestamp: new Date().getTime(),
      };

      this.append(dealContactId, message);
      this.lastMessages[dealContactId] = message;
    });
  };

  @action
  getCurrentUser = (user: Account): User => {
    return {
      id: user.brandId,
      avatar: user.img,
      name: user.name,
    };
  };

  putUploadingMessage = (
    dealContactId: string,
    _id: string,
    message: ChatMessage
  ) => {
    if (!this.uploadingMessages[dealContactId]) {
      this.uploadingMessages[dealContactId] = {};
    }
    this.uploadingMessages[dealContactId][_id] = message;
  };

  removeUploadingMessage = (dealContactId: string, _id: string) => {
    delete this.uploadingMessages[dealContactId][_id];
  };

  @action
  sendVideos = async (dealContactId: string, video: File, user: User) => {
    const _id = `${video}${new Date().getTime()}`;
    const message: ChatMessage = {
      video,
      text: null,
      _id,
      user: {
        ...user,
        type: "brand",
      },
      timestamp: new Date().getTime(),
      createdAt: new Date(),
    };
    this.putUploadingMessage(dealContactId, _id, message);
    const abortController = new AbortController();
    try {
      const result = await uploadVideoAsync(
        video,
        (progress: any) => {
          this.uploadingMessages[dealContactId][_id] = {
            ...message,
            progress,
            abortController,
          };
        },
        abortController
      );
      message.video = result.videoUrl;
      this.append(dealContactId, message);
      this.lastMessages[dealContactId] = message;
      this.shouldUpdate = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.removeUploadingMessage(dealContactId, _id);
    }
  };

  @action
  sendImages = async (dealContactId: string, images: File, user: User) => {
    const _id = `${images}${new Date().getTime()}`;
    const message: ChatMessage = {
      image: images,
      text: null,
      _id,
      user: {
        ...user,
        type: "brand",
      },
      timestamp: new Date().getTime(),
      createdAt: new Date(),
    };

    this.putUploadingMessage(dealContactId, _id, message);
    const abortController = new AbortController();

    try {
      const result = await uploadFileAsync(
        images,
        undefined,
        (progress: any) => {
          this.uploadingMessages[dealContactId][_id] = {
            ...message,
            progress,
            abortController,
          };
        },
        abortController
      );
      message.image = result.imageUrl;
      this.append(dealContactId, message);
      this.lastMessages[dealContactId] = message;
      this.shouldUpdate = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.removeUploadingMessage(dealContactId, _id);
    }
  };

  @action
  forwardVideos = async (
    dealContactId: string,
    video: string,
    user: User,
    navigate: () => void
  ) => {
    const _id = `${video}${new Date().getTime()}`;
    const message: ChatMessage = {
      video,
      text: null,
      _id,
      user: {
        ...user,
        type: "brand",
      },
      timestamp: new Date().getTime(),
      createdAt: new Date(),
    };
    this.putUploadingMessage(dealContactId, _id, message);
    try {
      this.append(dealContactId, message, navigate);
      this.lastMessages[dealContactId] = message;
    } catch (error) {
      console.log(error);
    } finally {
      this.removeUploadingMessage(dealContactId, _id);
    }
  };

  @action
  forwardImages = async (
    dealContactId: string,
    image: string,
    user: User,
    navigate: () => void
  ) => {
    const _id = `${image}${new Date().getTime()}`;
    const message: ChatMessage = {
      image,
      text: null,
      _id,
      user: {
        ...user,
        type: "brand",
      },
      timestamp: new Date().getTime(),
      createdAt: new Date(),
    };
    this.putUploadingMessage(dealContactId, _id, message);
    try {
      this.append(dealContactId, message, navigate);
      this.lastMessages[dealContactId] = message;
    } catch (error) {
      console.log(error);
    } finally {
      this.removeUploadingMessage(dealContactId, _id);
    }
  };

  get ref() {
    return child(ref(this.database), REACT_APP_ENVIRONMENT);
  }

  getMessagesRef = (dealContactId: string) => {
    return child(this.ref, `messages/${dealContactId}/messages`);
  };

  getUserRef = (dealContactId: string) => {
    return child(this.ref, `messages/${dealContactId}/users`);
  };

  getLastMessageRef = (dealContactId: string) => {
    return child(this.ref, `lastmsg/${dealContactId}`);
  };

  append = async (
    dealContactId: string,
    message: ChatMessage,
    navigate?: () => void
  ) => {
    const newMessageKey = push(this.getMessagesRef(dealContactId)).key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates: Record<string, {}> = {};
    updates["/" + newMessageKey] = message;

    if (message.id) {
      message._id = message.id;
      delete message.id;
    }

    if (message.user.id) {
      message.user._id = message.user.id;
      delete message.user.id;
    }

    await update(this.getMessagesRef(dealContactId), updates);
    if (navigate) navigate();
  };

  @action
  appendChatMessage = (dealContactId: string, message: ChatMessage) => {
    this.message[dealContactId].unshift(message);
  };

  @action
  subscribeToChat = (dealContactId: string) => {
    const value = query(
      this.getMessagesRef(dealContactId),
      limitToLast(initializeMessages)
    );

    onChildAdded(value, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const val = snapshot.val();
      const insert = (index: number, item: ChatMessage) => {
        this.message[dealContactId]?.splice(index, 0, item);
      };
      // sync
      if (!this.message[dealContactId]) {
        this.message[dealContactId] = [];
      }
      const existingMessage = this.message[dealContactId].filter(
        ({ _id }) => _id === val._id
      );

      if (existingMessage.length > 0) {
        return;
      }

      insert(0, { ...val, createdAt: new Date(val.timestamp) });

      if (this.message[dealContactId].length >= 20) {
        this.canLoadEarlier = true;
      }
    });

    onChildChanged(this.getMessagesRef(dealContactId), (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const val = snapshot.val();
      const existingMessage = this.message[dealContactId].filter(
        ({ _id }) => _id === val._id
      );

      if (existingMessage.length > 0) {
        const index = this.message[dealContactId].findIndex(
          ({ _id }) => _id === val._id
        );
        runInAction(() => {
          this.message[dealContactId][index] = val;
          this.shouldUpdate = true;
        });
      }
    });
  };

  @action
  toggleShouldUpdate = () => {
    this.shouldUpdate = false;
  };

  @action
  setShouldUpdatetoTrue = () => {
    this.shouldUpdate = true;
  };

  @action
  unsubscribeToChat = async (dealContactId: string) => {
    off(this.getMessagesRef(dealContactId), "child_added");
    off(this.getMessagesRef(dealContactId), "child_changed");
    // save lastest 20 chat message locally
    const latestMessages = this.message[dealContactId].slice(
      0,
      initializeMessages
    );
    await localStorage.setItem(
      `messages-${dealContactId}`,
      JSON.stringify(latestMessages)
    );
  };

  @action
  loadEarlier = (dealContactId: string) => {
    this.loading = true;
    const messages = this.message[dealContactId];
    if (!messages) {
      this.loading = false;
      return;
    }
    const topestMessage = messages[messages.length - 1];
    const value = query(
      this.getMessagesRef(dealContactId),
      orderByChild("timestamp"),
      endAt(topestMessage.timestamp || null),
      limitToLast(initializeMessages + 1)
    );
    get(value).then((snapshot) => {
      if (!snapshot.exists) {
        return;
      }
      const val: Array<ChatMessage> = snapshot.val();

      const values: Array<ChatMessage> = Object.values(val).reverse();
      values.shift();
      values.forEach((value) => {
        this.message[dealContactId].push({
          ...value,
          createdAt: new Date(value.timestamp || ""),
        });
      });
      this.loading = false;
      if (values.length < 20) {
        this.canLoadEarlier = false;
      }
    });
  };

  // initialize chat
  @action
  load = async (dealContactId: string) => {
    const messagesStringObj = await localStorage.getItem(
      `messages-${dealContactId}`
    );
    this.message[dealContactId] = messagesStringObj
      ? JSON.parse(messagesStringObj)
      : [];
    if (this.message[dealContactId].length === initializeMessages) {
      this.canLoadEarlier = true;
    }
  };

  off = () => {
    off(this.ref);
  };

  @action
  getAvailableChat = async () => {
    this.chatsLoading = true;
    try {
      const chats = await GET("/brands/chats");
      if (this.allRooms.length === chats.length) {
        return;
      }
      this.allRooms = chats;
      await this.getRoomsDetail();
    } catch (error) {
      this.allRooms = [];
    } finally {
      this.chatsLoading = false;
    }
  };

  @action
  getRoomsDetail = async () => {
    this.allRooms.forEach((room) => {
      const { dealContactId } = room;
      this.getRoomDetail({ dealContactId });
    });
  };

  @action
  getRoomDetail = ({ dealContactId }: { dealContactId: string }) => {
    const index = this.allRooms.findIndex((room: { dealContactId: string }) => {
      return room.dealContactId === dealContactId;
    });

    const userRef = child(this.ref, `/messages/${dealContactId}/users`);
    onValue(userRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const value = snapshot.val();
      this.allRooms[index] = {
        ...this.allRooms[index],
        users: value,
      };
    });

    // get last message
    const lastMessageRef = child(this.ref, `/lastmsg/${dealContactId}`);
    onValue(lastMessageRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const value = snapshot.val();
      if (
        this.allRooms[index].lastMessage &&
        value.timestamp < this.allRooms[index].lastMessage.timestamp
      ) {
        return;
      }
      this.allRooms[index] = {
        ...this.allRooms[index],
        lastMessage: value.message,
      };
    });
  };

  @action
  getUserDetail = async (dealContactId: string) => {
    get(this.getUserRef(dealContactId)).then((snapshot) => {
      if (!snapshot.exists()) return;
      const { influencer } = snapshot.val();
      this.rooms[dealContactId] = {
        ...influencer,
      };
    });
  };

  initializeLastMessage = async (dealContactId: string) => {
    const savedLastMessage = await localStorage.getItem(
      `lastMessage-${dealContactId}`
    );
    this.lastMessages[dealContactId] = savedLastMessage
      ? JSON.parse(savedLastMessage)
      : {};
  };

  @action
  subscribeToLastMessage = async (
    dealContactId: string,
    popChatInfluencerToTop?: Function
  ) => {
    this.subscribeLoading = true;
    // await this.initializeLastMessage(dealContactId).catch((error) =>
    //   console.log(error)
    // );
    onValue(this.getLastMessageRef(dealContactId), async (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const value = snapshot.val();

      const { message } = value;

      if (
        popChatInfluencerToTop &&
        this.lastMessages?.[dealContactId]?.timestamp !== message.timestamp
      )
        await popChatInfluencerToTop(dealContactId);

      this.lastMessages[dealContactId] = message;
      this.subscribeLoading = false;
    });
  };

  @action
  unsubscribeToLastMessage = (dealContactId: string) => {
    off(this.getLastMessageRef(dealContactId));
    if (this.lastMessages[dealContactId])
      localStorage.setItem(
        `lastMessage-${dealContactId}`,
        JSON.stringify(this.lastMessages[dealContactId])
      );
  };

  @action
  getUnreadChatMessages = ({ brandId }: { brandId: string }) => {
    const ref = child(this.ref, `countmsg/${brandId}`);
    onValue(ref, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const dealContactIds = snapshot.val();
      this.noUnreadMessages = 0;
      Object.keys(dealContactIds).forEach((dealContactId) => {
        const value = dealContactIds[dealContactId];
        this.unreadMessages[dealContactId] = value;
        this.noUnreadMessages += value;
      });
    });
  };

  @action
  readChatMessage = async ({
    brandId,
    dealContactId,
  }: {
    brandId: string;
    dealContactId: string;
  }) => {
    // const numberOfUnreadMessages = this.unreadMessages[dealContactId] || 0;
    // await decrementBadges(numberOfUnreadMessages);
    set(child(this.ref, `countmsg/${brandId}/${dealContactId}`), 0);
  };

  @action
  getChatBannerDetail = async ({
    dealContactId,
  }: {
    dealContactId: string;
  }) => {
    if (!this.banners[dealContactId]) {
      this.bannerLoading = true;
    }
    this.banners[dealContactId] = await GET(`/brands/chat/${dealContactId}`);
    this.bannerLoading = false;
  };

  @action
  getDealInfluList = async (dealId: string) => {
    try {
      this.influListLoading = true;
      this.influList = await GET(
        `/brands/deals/chat/forwards?dealId=${dealId}`
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.influListLoading = false;
    }
  };
}

export const chatStore = new ChatStore();
