import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import {
  IFacebookInfluencer,
  RateCard,
} from '../../../types/api/getInfluencer';
import {
  calculateAverageData,
  calculatePercentage,
  getTierByFollower,
  toCapitalize,
  toDecimalNumber,
} from '../../../utils';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from '../../../utils/styling/general';
import EmptyRateCard from './EmptyRateCard';
import EmptySocialMedia from './EmptySocialMedia';

interface RateCardProps {
  rateCard: RateCard[];
}

const FacebookRateCard = (props: RateCardProps) => {
  const { rateCard } = props;

  if (rateCard.length === 0) return <EmptyRateCard />;

  return (
    <FullWidthRowContainer>
      <GapColumnContainer>
        <Gray400B5Text>ราคาต่อโพสต์</Gray400B5Text>
        <FullWidthRowContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>
              {toDecimalNumber({
                number:
                  rateCard.find((card) => card.detail.includes('fi'))?.rate ||
                  0,
                isZeroDash: false,
              }) + ' ฿'}
            </WhiteBoldH7Text>
            <Gray200B4Text>Single Post</Gray200B4Text>
          </ColumnContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>
              {toDecimalNumber({
                number:
                  rateCard.find((card) => card.detail.includes('fm'))?.rate ||
                  0,
                isZeroDash: false,
              }) + ' ฿'}
            </WhiteBoldH7Text>
            <Gray200B4Text>Multi Post</Gray200B4Text>
          </ColumnContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>
              {toDecimalNumber({
                number:
                  rateCard.find((card) => card.detail.includes('fr'))?.rate ||
                  0,
                isZeroDash: false,
              }) + ' ฿'}
            </WhiteBoldH7Text>
            <Gray200B4Text>Reels</Gray200B4Text>
          </ColumnContainer>
        </FullWidthRowContainer>
      </GapColumnContainer>
    </FullWidthRowContainer>
  );
};
interface Props {
  facebook: IFacebookInfluencer;
  rateCard: RateCard[];
}

const FacebookDetail = (props: Props) => {
  const { facebook, rateCard } = props;

  if (!facebook) return <EmptySocialMedia platform="FACEBOOK" />;

  const {
    followers_count = 0,
    username,
    permalink,
    monthData = {
      engagement: 0,
      reachCount: 0,
      impressionCount: 0,
      postCount: 0,
    },
  } = facebook;
  const { engagement, reachCount, impressionCount, postCount } = monthData;
  const tier = toCapitalize(getTierByFollower(followers_count));
  const avgEngagement = calculateAverageData(engagement, postCount);
  const engagementPercentage = calculatePercentage(
    parseFloat(avgEngagement),
    followers_count
  );
  const avgReach = calculateAverageData(reachCount, postCount);
  const avgImpression = calculateAverageData(impressionCount, postCount);
  const onClick = () => {
    if (permalink?.startsWith('https://')) window.open(permalink);
    else window.open('https://' + permalink);
  };

  return (
    <Container>
      <GapRowContainer>
        <PlatformContainer />
        <PlatformIconContainer onClick={onClick}>
          <RXIcon name="FacebookFill" color={COLOR.Blue_700} size={44} />
        </PlatformIconContainer>
        <FollowersContainer>
          <FullWidthRowContainer>
            <TierContainer>
              <Blue700BoldB5Text>{tier}</Blue700BoldB5Text>
            </TierContainer>
            <IconContainer onClick={onClick}>
              <RXIcon name="ExternalLink" color={COLOR.White} />
            </IconContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <WhiteBoldB3Text onClick={onClick}>{username}</WhiteBoldB3Text>
            <TextContainer>
              <WhiteBoldH7Text>
                {toDecimalNumber({
                  number: followers_count,
                  isZeroDash: false,
                })}
              </WhiteBoldH7Text>
              <WhiteH9Text>Followers</WhiteH9Text>
            </TextContainer>
          </FullWidthRowContainer>
        </FollowersContainer>
      </GapRowContainer>
      <FullWidthRowContainer>
        <ColumnContainer>
          <TextContainer>
            <WhiteBoldH7Text>
              {toDecimalNumber({ number: avgEngagement, isZeroDash: false })}
            </WhiteBoldH7Text>
            <WhiteBoldH9Text>({engagementPercentage}%)</WhiteBoldH9Text>
          </TextContainer>
          <Gray200B4Text>Avg.Engagement</Gray200B4Text>
        </ColumnContainer>
        <ColumnContainer>
          <WhiteBoldH7Text>
            {toDecimalNumber({ number: avgReach, isZeroDash: false })}
          </WhiteBoldH7Text>
          <Gray200B4Text>Avg.Reach</Gray200B4Text>
        </ColumnContainer>
        <ColumnContainer>
          <WhiteBoldH7Text>
            {toDecimalNumber({ number: avgImpression, isZeroDash: false })}
          </WhiteBoldH7Text>
          <Gray200B4Text>Avg.Impression</Gray200B4Text>
        </ColumnContainer>
      </FullWidthRowContainer>
      <Dashline />
      <FacebookRateCard rateCard={rateCard} />
    </Container>
  );
};

export default FacebookDetail;

const Container = styled(ColumnContainer)`
  border-radius: 12px;
  width: 33%;
  height: 260px;
  background: rgba(1, 1, 1, 0.7);
  backdrop-filter: blur(20px);
  padding: 24px 20px;
  align-items: flex-start;
  gap: 16px;
`;

const PlatformContainer = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: ${COLOR.White};
  border-radius: 50px;
  left: 4px;
  bottom: 13px;
`;

const PlatformIconContainer = styled.div`
  z-index: 2;
  :hover {
    cursor: pointer;
  }
`;

const TierContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(2px);
`;

const GapColumnContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
`;

const FollowersContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
  width: 80%;
`;

const GapRowContainer = styled(RowContainer)`
  position: relative;
  width: 100%;
  gap: 8px;
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 0.6px solid ${COLOR.Gray_M};
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const TextContainer = styled(RowContainer)`
  gap: 4px;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const Blue700BoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.White};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
  :hover {
    cursor: pointer;
  }
`;

const WhiteBoldH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const WhiteBoldH9Text = styled(TEXT.H9_Bold)`
  color: ${COLOR.White};
`;

const Gray200B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_200};
`;

const Gray400B5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_400};
`;
