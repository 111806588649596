import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RModal from "../../atoms/RModal";

interface Props {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  progress: number;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 59px 0px 59px;
  background: ${COLOR.WHITE};
  border-radius: 24px;
`;

const CircularContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

const ProgressContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 8px;
  right: 0;
  display: flex;
  justify-content: center;
`;

const ProgressText = styled(TEXT.header4BOLD)``;

const PercentageText = styled(TEXT.body2)`
  margin-top: 3px;
  color: ${COLOR.Gray_300};
`;

const BoldHeader5Text = styled(TEXT.header5BOLD)``;

const Body1Text = styled(TEXT.body1)``;

const RedBoldBody1Text = styled(TEXT.body1BOLD)`
  color: ${COLOR.Warning};
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#11cb5f",
    },
  },
});

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <CircularContainer>
      <CircularProgress variant="determinate" {...props} />
      <CircularProgress
        variant="determinate"
        {...props}
        value={100}
        sx={{ position: "absolute", color: COLOR.Gray_300, zIndex: "999999" }}
      />
      <ProgressContainer>
        <ProgressText>{`${Math.round(props.value)}`}</ProgressText>
        <PercentageText>%</PercentageText>
      </ProgressContainer>
    </CircularContainer>
  );
}

function CreateDealModal(props: Props) {
  const { modalVisible, setModalVisible, progress } = props;

  return (
    <RModal
      active={modalVisible}
      clickable={false}
      setActive={() => setModalVisible(false)}
    >
      <ModalContainer>
        {/* <ThemeProvider theme={theme}> */}
        <CircularProgressWithLabel
          color={"success"}
          size={"100px"}
          thickness={2.5}
          value={progress}
          style={{ marginBottom: "24px" }}
          sx={{ color: COLOR.Blue_700, zIndex: "9999999" }}
        />
        {/* </ThemeProvider> */}
        <BoldHeader5Text>กำลังอัพโหลด</BoldHeader5Text>
        <Body1Text>
          โปรดรอสักครู่{" "}
          <RedBoldBody1Text>และอย่าปิดหน้าต่างนี้</RedBoldBody1Text>
          <br />
          การสร้างแคมเปญจะเสร็จสิ้นในอีกไม่ช้า
        </Body1Text>
      </ModalContainer>
    </RModal>
  );
}

export default CreateDealModal;
