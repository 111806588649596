import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import AnotherDotBrand from "../../../assets/images/AnotherDotBotIcon.png";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { PKNotifications } from "../../../stores/notificationStore";
import { PKNotification } from "../../../types/notification";
import { objectToQuerystring } from "../../../utils";
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import REmptyState from "../../atoms/REmptyState";
import RLoading from "../../atoms/RLoading";

interface OnLoadMoreProps {
  notificationsLoading: boolean;
  notifications: PKNotifications;
  getNotifications: ({
    offset,
    limit,
    refresh,
  }: {
    offset?: number | undefined;
    limit?: number | undefined;
    refresh?: boolean | undefined;
  }) => Promise<void>;
}

const onLoadMore = (props: OnLoadMoreProps) => {
  const { notifications, notificationsLoading, getNotifications } = props;
  // if cannot load more
  if (notificationsLoading) {
    return;
  }

  const count = notifications.metadata[0].count;
  const newOffset =
    notifications.metadata[0].offset + notifications.metadata[0].limit;
  if (newOffset >= count) {
    return;
  }
  getNotifications({
    limit: 10,
    offset: newOffset,
    refresh: false,
  });
};

const changeToWebQuery = ({ key, value }: { key: string; value: string }) => {
  if (key === "tabName") {
    switch (value) {
      case "CheckContact":
        return { status: "check" };
      default:
        return { status: "pending" };
    }
  } else if (key === "dealId" || key === "dealContactId")
    return { [key]: value };
};

interface OnClickProps {
  query: {
    status?: string | undefined;
    dealId?: string | undefined;
    dealContactId?: string | undefined;
    page?: string | undefined;
  };
  _readNotification: ({
    notificationId,
  }: {
    notificationId: string;
  }) => Promise<void>;
  notification: PKNotification;
  onClose: () => void;
  navigate: NavigateFunction;
  haveUrl: boolean;
}

const onClickHandler = (props: OnClickProps) => {
  const { query, _readNotification, notification, onClose, navigate, haveUrl } =
    props;
  const qs = objectToQuerystring(query);
  _readNotification({
    notificationId: notification.notificationId,
  });
  onClose();
  if (haveUrl) {
    if (query.status === "check") {
      const qs = objectToQuerystring(query);
      navigate(`/deal${qs}`, { state: "toWork" });
    } else if (query.status === "cancel") {
      query.status = "done";
      const qs = objectToQuerystring(query);
      navigate(`/deal${qs}`, { state: "toChat" });
    } else {
      navigate(`/deal${qs}`);
    }
  }
};

interface NotificationTextComponentProps {
  notification: PKNotification;
}

const NotificationTextComponent = (props: NotificationTextComponentProps) => {
  const { notification } = props;
  if (notification.unread)
    return (
      <TextContainer>
        <Bu3Text>{notification.title}</Bu3Text>
        <GrayDB5Text>{notification.body}</GrayDB5Text>
      </TextContainer>
    );
  return (
    <TextContainer>
      <GrayMBu3Text>{notification.title}</GrayMBu3Text>
      <Gray400B5Text>{notification.body}</Gray400B5Text>
    </TextContainer>
  );
};
interface ContentComponentProps extends Props {
  notificationRef: React.MutableRefObject<HTMLDivElement | null>;
}

const ContentComponent = (props: ContentComponentProps) => {
  const {
    notifications,
    notificationsLoading,
    navigate,
    _readNotification,
    onClose,
    notificationRef,
  } = props;

  if (!notificationsLoading && notifications.data.length === 0)
    return (
      <EmptyStateContainer>
        <REmptyState
          containerStyle={{ marginTop: 40 }}
          graphic={
            <EmptyStateImage
              src={require("../../../assets/images/state/NoMessage.png")}
            />
          }
          buttonWidth={247}
          header="ไม่พบการแจ้งเตือน"
          descriptions={["สร้างแคมเปญเพื่อหาอินฟลูเอนเซอร์กันเลย "]}
          buttonText="Create Campaign"
          onClick={() => {
            navigate("/createcampaign");
          }}
        />
      </EmptyStateContainer>
    );

  return (
    <OverflowY ref={notificationRef}>
      {notifications.data.length > 0 &&
        notifications.data.map((notification, index) => {
          const query: {
            status?: string;
            dealId?: string;
            dealContactId?: string;
            page?: string;
          } = {};
          let haveUrl: boolean = false;

          if (Object(notification.data).hasOwnProperty("url")) {
            notification.data.url
              .split("MainNav/")[1]
              .split("?")[1]
              .split("&")
              .map((eachQuery) => {
                Object.assign(
                  query,
                  changeToWebQuery({
                    key: eachQuery.split("=")[0],
                    value: eachQuery.split("=")[1],
                  })
                );
              });
            haveUrl = true;
          }
          if (!Object(query).hasOwnProperty("status")) {
            if (notification.body.includes("ยกเลิกการร่วมงาน")) {
              Object.assign(query, { status: "cancel" });
            } else {
              Object.assign(query, { status: "pending" });
            }
          }

          return (
            <>
              <HoverRowContainer
                onClick={() =>
                  onClickHandler({
                    query,
                    haveUrl,
                    notification,
                    _readNotification,
                    onClose,
                    navigate,
                  })
                }
                style={{ marginTop: index === 0 ? 10 : 0 }}
              >
                <Image src={notification.data?.img || AnotherDotBrand} />
                {notification.unread && <Unread />}
                <NotificationTextComponent notification={notification} />
              </HoverRowContainer>
              <Dashline />
            </>
          );
        })}
    </OverflowY>
  );
};

interface LoadingProps {
  isLoading: boolean;
}

const LoadingComponent = (props: LoadingProps) => {
  const { isLoading } = props;
  if (isLoading)
    return (
      <RLoading
        containerStyle={{
          marginTop: "0px",
          marginBottom: "0px",
          alignSelf: "center",
        }}
      />
    );
  return <></>;
};

interface ContainerProps {
  right: number;
}

interface Props {
  active: boolean;
  notifications: PKNotifications;
  navigate: NavigateFunction;
  onClose: () => void;
  _readNotification: ({
    notificationId,
  }: {
    notificationId: string;
  }) => Promise<void>;
  readUnreadNotification: () => Promise<void>;
  notificationsLoading: boolean;
  getNotifications: ({
    offset,
    limit,
    refresh,
  }: {
    offset?: number | undefined;
    limit?: number | undefined;
    refresh?: boolean | undefined;
  }) => Promise<void>;
  offsetRight?: number;
  onBlur: () => void;
}

const NotificationDropdown = (props: Props) => {
  const {
    active,
    notifications,
    notificationsLoading,
    getNotifications,
    offsetRight = -100,
    onBlur,
  } = props;

  const notificationRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const { current } = notificationRef;
    if (current) {
      const handleScroll = () => {
        // Check position when scrolling
        const scrollPosition = current.scrollTop;
        const currentHeight = current.scrollHeight - 619;
        if (scrollPosition >= currentHeight) {
          onLoadMore({ notifications, notificationsLoading, getNotifications });
        }
      };
      current.addEventListener("scroll", handleScroll);
      return () => {
        current.removeEventListener("scroll", handleScroll);
      };
    }
  }, [notificationRef, active]);

  if (!active) {
    return <></>;
  }

  return (
    <Container right={offsetRight} onBlur={onBlur}>
      <HeaderContainer>
        <GrayMBu3Text>Notifications</GrayMBu3Text>
        <GapRowContainer>
          <GrayDBu3Text>อ่างทั้งหมด</GrayDBu3Text>
          <RXIcon name="DoubleCheck" color={COLOR.Gray_D} size={20} />
        </GapRowContainer>
      </HeaderContainer>
      <ContentComponent {...props} notificationRef={notificationRef} />
      <LoadingComponent isLoading={notificationsLoading} />
    </Container>
  );
};

export default observer(NotificationDropdown);

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 20px;
  right: ${(props) => props.right}px;
  display: flex;
  width: 360px;
  height: 592px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${COLOR.White};
  overflow: hidden;
  z-index: 9999;
`;

const HoverRowContainer = styled(RowContainer)`
  position: relative;
  align-items: flex-start;
  width: 92%;
  padding: 8px 12px;
  margin: 0px 16px;
  border-radius: 12px;
  gap: 8px;
  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)`
  margin: 16px 16px;
  padding: 4px 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.Gray_200};
  width: 92%;
  margin-bottom: 0px;
  align-items: center;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const Dashline = styled.div`
  width: 92%;
  height: 1px;
  background-color: ${COLOR.Gray_200};
  margin: 10px 16px;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  object-fit: cover;
  flex-shrink: 0;
`;

const Unread = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: ${COLOR.Warning};
  border-radius: 50%;
  align-self: center;
`;

const OverflowY = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_200};
    border-radius: 18px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.Gray_300};
  }

  :hover {
    overflow-y: overlay;
  }
`;

const TextContainer = styled(FlexStartColumnContainer)`
  width: 100%;
`;

const EmptyStateImage = styled.img`
  width: 161px;
  height: 164px;
`;

const Bu3Text = styled(TEXT.Bu3_Reg)``;

const GrayDB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_M};
`;

const Gray400B5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_400};
`;

const GrayDBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_D};
`;
