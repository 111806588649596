import { action, makeAutoObservable, observable } from "mobx";
import { FilterProps } from "../reusables/InfluencerList/RecommendList";
import {
  IGetInfluencerResponse,
  IInfluencerDetails,
  InfluencerListResponse,
  SocialData,
} from "../types/api/getInfluencer";
import {
  IInfluencerList,
  IListInfluencersParams,
} from "../types/api/listInfluencers";
import { PlatformType } from "../types/deal";
import { SortByType, SortOrderType } from "../types/sort";
import { GET, POST, getErrorMessage } from "../utils/Networking";
import { AccountStore } from "./accountStore";
import {
  getInfluencer,
  getInfluencerById,
  getInfluencerBySocialId,
  getReviews,
  listInfluencers,
} from "./logic/influencers";
import { DirectoryConfigResponse } from "../types/api/directoryconfig";
import { CategoryLifestyle } from "../types/categoryLiftstyle";

const directorySeed: number = Math.random();
export class InfluencerStore {
  @observable
  loading = {
    influencers: {
      pending: false,
      ongoing: false,
      check: false,
      done: false,
    },
    influencer: false,
    reviews: false,
    social: false,
    list: false,
    attribute: false,
    posts: false,
    graph: false,
    similarList: false,
  };

  @observable
  error = {
    influencers: {
      pending: null,
      ongoing: null,
      check: null,
      done: null,
    },
    influencer: null,
    reviews: null,
  };

  @observable
  influencers: Record<
    "pending" | "ongoing" | "check" | "done",
    IInfluencerList
  > = {
    pending: {
      data: new Array<any>(),
      metadata: { count: 0, limit: 10, offset: 0 },
      next: () => {},
    },
    ongoing: {
      data: new Array<any>(),
      metadata: { count: 0, limit: 10, offset: 0 },
      next: () => {},
      dealId: "",
    },
    check: {
      data: new Array<any>(),
      metadata: { count: 0, limit: 10, offset: 0 },
      next: () => {},
    },
    done: {
      data: new Array<any>(),
      metadata: { count: 0, limit: 10, offset: 0 },
      next: () => {},
    },
  };

  @observable
  influencer?: IInfluencerDetails;

  @observable
  influencerSocial?: IGetInfluencerResponse;

  @observable
  influencerList: {
    data: InfluencerListResponse[];
    metadata: { count: number; limit: number; offset: number };
    next: () => Promise<void>;
  } = {
    data: [],
    metadata: { count: 0, limit: 10, offset: 0 },
    next: async () => {},
  };

  @observable
  defaultList: InfluencerListResponse[] = [];

  @observable
  listAbortController = new AbortController();

  @observable
  reviews = {
    data: new Array<any>(),
    metadata: { count: 0 },
    starCount: [0, 0, 0, 0, 0],
    ratingLength: {},
    next: async () => {},
  };

  @observable
  max = {
    engagement: 0,
    activeFollower: 0,
  };

  @observable
  filterDirectory: FilterProps = {
    gender: [],
    tier: [],
    social: "ALL",
    attribute: [],
    engagement: [0, 10000000],
    follower: [0, 10000000],
    rating: [],
    age: [15, 60],
    location: ["all"],
    audience: {
      age: [15, 60],
      gender: [],
      location: ["all"],
    },
    username: "",
    category: undefined,
  };

  @observable
  filterAttributes: string[] = [];

  @observable
  accountStore: AccountStore;

  @observable
  currentInfluencerName: string = "";

  @observable
  socialData: SocialData = {
    INSTAGRAM: {
      posts: [],
      graphData: undefined,
      similarList: [],
    },
    TIKTOK: {
      posts: [],
      graphData: undefined,
      similarList: [],
    },
    FACEBOOK: {
      posts: [],
      graphData: undefined,
      similarList: [],
    },
    LEMON: {
      posts: [],
      graphData: undefined,
      similarList: [],
    },
  };

  @observable
  categoryLifestyle: CategoryLifestyle[] = [];

  constructor(accountStore: AccountStore) {
    makeAutoObservable(this);
    this.initializeDirectory();
    this.accountStore = accountStore;
  }

  @action
  clearInflueners = () => {
    this.influencers = {
      pending: {
        data: new Array<any>(),
        metadata: { count: 0, limit: 10, offset: 0 },
        next: () => {},
      },
      ongoing: {
        data: new Array<any>(),
        metadata: { count: 0, limit: 10, offset: 0 },
        next: () => {},
        dealId: "",
      },
      check: {
        data: new Array<any>(),
        metadata: { count: 0, limit: 10, offset: 0 },
        next: () => {},
      },
      done: {
        data: new Array<any>(),
        metadata: { count: 0, limit: 10, offset: 0 },
        next: () => {},
      },
    };
  };

  @action
  listInfluencers = async ({
    status,
    limit,
    offset,
    dealId,
    sortBy,
    sortOrder,
    filter,
  }: IListInfluencersParams) => {
    try {
      this.loading.influencers[status] = true;
      this.error.influencers[status] = null;
      const result = await listInfluencers({
        dealId,
        status,
        offset,
        limit,
        sortBy,
        sortOrder,
        filter,
      });
      // mean next page
      if (offset >= limit) {
        const prevData = this.influencers[status].data;
        this.influencers[status] = {
          ...this.influencers[status],
          ...result,
          data: [...prevData, ...result.data],
        };
      } else {
        this.influencers[status] = result;
      }
      this.influencers[status].next = () => {
        this.listInfluencers({
          status,
          limit,
          offset: offset + limit,
          dealId,
          sortOrder,
          sortBy,
          filter,
        });
      };
      this.loading.influencers[status] = false;
      return result;
    } catch (error) {
      this.error.influencers[status] = getErrorMessage(error);
      this.loading.influencers[status] = false;
    }
  };

  @action
  popChatInfluencerToTop = (dealContactId: string) => {
    const { data = [] } = this.influencers.ongoing;
    const matchIndex = data.findIndex(
      ({ dealContactId: d }) => dealContactId === d
    );
    if (matchIndex === -1) {
      return;
    }

    function arraymove(arr: Array<any>, fromIndex: number, toIndex: number) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
    arraymove(this.influencers.ongoing.data, matchIndex, 0);
  };

  @action
  getInfluencer = async ({ dealContactId }: { dealContactId: string }) => {
    try {
      this.loading.influencer = true;
      this.error.influencer = null;
      this.influencer = undefined;
      const result = await getInfluencer({ dealContactId });
      this.influencer = result;
      return result;
    } catch (error) {
      console.log(error);
      this.error.influencer = getErrorMessage(error);
      this.loading.influencer = false;
    } finally {
      this.loading.influencer = false;
    }
  };

  @action
  getInfluencerById = async ({
    id,
    isForSocial,
  }: {
    id: string;
    isForSocial: boolean;
  }) => {
    try {
      if (isForSocial) {
        this.loading.social = true;
        this.error.influencer = null;
        this.influencerSocial = undefined;
        const result = await getInfluencerById({ id });
        this.influencerSocial = result;
        this.loading.social = false;
        return result;
      } else {
        this.loading.influencer = true;
        this.error.influencer = null;
        this.influencer = undefined;
        const result = await getInfluencer({ id });
        this.influencer = result;
        this.loading.influencer = false;
      }
    } catch (error) {
      this.error.influencer = getErrorMessage(error);
    } finally {
      this.loading.social = false;
    }
  };

  @action
  getInfluencerBySocial = async ({ id }: { id: string }) => {
    try {
      this.loading.influencer = true;
      this.error.influencer = null;
      this.influencer = undefined;
      const result = await getInfluencerBySocialId({ id });
      this.influencer = result;
      this.loading.influencer = false;
      return result;
    } catch (error) {
      console.log(error);
      this.error.influencer = getErrorMessage(error);
    } finally {
      this.loading.social = false;
    }
  };

  @action
  removePendingInflu = ({ dealContactId }: { dealContactId: string }) => {
    this.influencers.pending.data = this.influencers.pending.data.filter(
      (dealContact) => {
        return dealContact.dealContactId !== dealContactId;
      }
    );
    this.influencers.pending.metadata.count -= 1;
  };

  @action
  removeToReviewInflu = ({ dealContactId }: { dealContactId: string }) => {
    this.influencers.check.data = this.influencers.check.data.filter(
      (dealContact) => {
        return dealContact.dealContactId !== dealContactId;
      }
    );
    this.influencers.check.metadata.count -= 1;
  };

  @action
  changeInfluDeliverStatus = async ({
    dealContactId,
    status = "DELIVERED",
  }: {
    dealContactId: string;
    status: string;
  }) => {
    this.influencers.ongoing.data = this.influencers.ongoing.data.map(
      (dealContact) => {
        if (dealContact.dealContactId === dealContactId) {
          return {
            ...dealContact,
            deliverStatus: status,
          };
        }
        return dealContact;
      }
    );
  };

  @action
  removeNewTag = async ({ dealContactId }: { dealContactId: string }) => {
    this.influencers.pending.data = this.influencers.pending.data.map(
      (dealContact) => {
        if (dealContact.dealContactId === dealContactId) {
          return {
            ...dealContact,
            unread: false,
          };
        }
        return dealContact;
      }
    );
  };

  @action
  triggerSaveInfluencer = async ({
    accountId,
    isSaved,
  }: {
    accountId: string;
    isSaved: boolean;
  }) => {
    if (isSaved) this.accountStore.removeSavedInflu(accountId);
    else this.accountStore.addSavedInflu(accountId);
    await POST("/brands/favourite", {
      accountId,
      isSaved,
    });
  };

  @action
  getInfluencersReviews = async ({
    accountId,
    limit,
    offset,
    sortOrder = "desc",
    filter,
  }: {
    accountId: string;
    limit: number;
    offset: number;
    sortOrder: string;
    filter: number;
  }) => {
    try {
      this.loading.reviews = true;
      this.error.reviews = null;
      const result = await getReviews({
        accountId,
        limit,
        offset,
        sortOrder,
        filter,
      });

      // mean next page
      if (offset >= limit) {
        const prevData = this.reviews.data;
        this.reviews = {
          ...this.reviews,
          ...result,
          data: [...prevData, ...result.data],
        };
      } else {
        this.reviews = {
          ...result,
          next: async () => {
            await this.getInfluencersReviews({
              accountId,
              limit,
              offset: offset + limit,
              sortOrder,
              filter,
            });
          },
        };
      }
      this.loading.reviews = false;
      return result;
    } catch (error) {
      this.error.reviews = getErrorMessage(error);
      this.loading.reviews = false;
    }
  };

  @action
  getIsDefaultFilter = ({ filter }: { filter: FilterProps }) => {
    const {
      gender,
      tier,
      social,
      attribute,
      engagement,
      follower,
      rating,
      audience,
      username,
      category,
      location,
      age,
    } = filter;
    const {
      age: audienceAge,
      gender: audienceGender,
      location: audienceLocation,
    } = audience;
    return (
      gender.length === 0 &&
      tier.length === 0 &&
      social.length === 0 &&
      attribute.length === 0 &&
      engagement[0] === 0 &&
      engagement[1] === 1000000 &&
      follower[0] === 0 &&
      follower[1] === 1000000 &&
      rating.length === 0 &&
      age[0] === 15 &&
      age[1] === 60 &&
      audienceAge[0] === 15 &&
      audienceAge[1] === 60 &&
      audienceGender.length === 0 &&
      location[0] === "all" &&
      audienceLocation[0] === "all" &&
      username === "" &&
      category === undefined
    );
  };

  @action
  getInfluencerList = async ({
    filter,
    withUnfiltered,
    limit,
    offset,
    sortBy = "default",
    sortOrder = "none",
    firstInitialize,
    sortByMatch,
  }: {
    filter: FilterProps;
    withUnfiltered: boolean;
    limit: number;
    offset: number;
    sortBy?: SortByType;
    sortOrder?: SortOrderType;
    firstInitialize: boolean;
    sortByMatch?: boolean;
  }) => {
    try {
      this.listAbortController.abort(); // Abort previous request
    } catch (error) {
      console.log("Previous request already aborted.");
    }

    this.listAbortController = new AbortController();

    try {
      if (this.max.activeFollower !== 0 && this.max.engagement !== 0) {
        this.loading.list = true;
        let result: {
          data: InfluencerListResponse[];
          metadata: { count: number; limit: number; offset: number };
          next: () => void;
        } = {
          data: [],
          metadata: {
            count: 0,
            limit: 10,
            offset: 0,
          },
          next: () => {},
        };
        const isDefaultFilter = this.getIsDefaultFilter({ filter });
        if (
          isDefaultFilter &&
          this.defaultList.length !== 0 &&
          offset === 0 &&
          sortBy === "default" &&
          sortOrder === "none"
        ) {
          this.influencerList.data = [...this.defaultList];
        } else {
          result = await POST(
            "/influencers/directory/list",
            {
              filter,
              withUnfiltered,
              limit,
              offset,
              sortFilter: sortBy,
              sortOrder,
              sortByMatch,
              seed: directorySeed,
            },
            null,
            () => {},
            () => {},
            this.listAbortController.signal
          );
          if (this.defaultList.length === 0) this.defaultList = result.data;
          if (firstInitialize) this.influencerList.data = result.data;
          else
            this.influencerList.data = [
              ...this.influencerList.data,
              ...result.data,
            ];
        }
        this.influencerList.metadata = result.metadata || {
          count: 0,
          limit: 12,
          offset: 0,
        };
        this.influencerList.next = async () =>
          this.getInfluencerList({
            filter,
            withUnfiltered,
            limit,
            offset: offset + 12,
            sortBy,
            sortOrder,
            sortByMatch,
            firstInitialize: false,
          });
        this.loading.list = false;
      }
    } catch (error) {}
  };

  @action
  initializeDirectory = async () => {
    const maxEngagement = await GET(
      `/influencers/directory/max/recentMediaEngagement`
    );
    const maxActiveFollowers = await GET(
      `/influencers/directory/max/activeFollowers`
    );
    this.max.activeFollower = maxActiveFollowers?.activeFollowers;
    this.max.engagement = maxEngagement?.recentMediaEngagement;
    this.getFilterConfig();
    this.getInfluencerList({
      filter: {
        gender: [],
        tier: [],
        social: "ALL",
        attribute: [],
        age: [15, 60],
        engagement: [0, 1000000],
        follower: [0, 1000000],
        rating: [],
        location: ["all"],
        audience: {
          age: [15, 60],
          gender: [],
          location: ["all"],
        },
        username: "",
        category: undefined,
      },
      withUnfiltered: false,
      limit: 12,
      offset: 0,
      sortBy: "default",
      sortOrder: "none",
      firstInitialize: true,
    });
  };

  @action
  clearInfluencerList = () => {
    this.influencerList = {
      data: [],
      metadata: { count: 0, limit: 12, offset: 0 },
      next: async () => {},
    };
  };

  @action
  setFilterDirectory = (filter: FilterProps) => {
    this.filterDirectory = filter;
  };

  @action
  getFilterConfig = async () => {
    try {
      this.loading.attribute = true;
      const result: DirectoryConfigResponse = await GET(
        "/influencers/directory/config"
      );
      const { attribute, categoryLifestyle } = result;
      this.filterAttributes = attribute;
      this.categoryLifestyle = categoryLifestyle;
    } catch (error) {
      this.loading.attribute = false;
    } finally {
      this.loading.attribute = false;
    }
  };

  @action
  setCurrentInfluencerName = (name: string) => {
    this.currentInfluencerName = name;
  };

  @action
  getInfluencerPosts = async (influencerId: string, platform: PlatformType) => {
    try {
      if (this.socialData[platform].posts.length === 0) {
        this.loading.posts = true;
        const result = await GET(
          `/influencers/posts/influencer/${influencerId}/top-engagement`
        );
        this.socialData[platform].posts = result;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.posts = false;
    }
  };

  @action
  getInfluencerGraphData = async (
    influencerId: string,
    platform: PlatformType
  ) => {
    try {
      if (!this.socialData[platform].graphData) {
        this.loading.graph = true;
        const result = await GET(
          `/influencers/summary/influencer/${influencerId}`
        );
        this.socialData[platform].graphData = result;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.graph = false;
    }
  };

  @action
  getInfluencerSimilarList = async (
    influencerId: string,
    platform: PlatformType
  ) => {
    try {
      if (this.socialData[platform].similarList.length === 0) {
        this.loading.similarList = true;
        const result = await GET(
          `/influencers/similar/influencer/${influencerId}`
        );
        this.socialData[platform].similarList = result.slice(-20);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.similarList = false;
    }
  };

  @action
  clearInfluencerGraphPost = () => {
    this.socialData = {
      INSTAGRAM: {
        graphData: undefined,
        posts: [],
        similarList: [],
      },
      TIKTOK: {
        graphData: undefined,
        posts: [],
        similarList: [],
      },
      FACEBOOK: {
        graphData: undefined,
        posts: [],
        similarList: [],
      },
      LEMON: {
        graphData: undefined,
        posts: [],
        similarList: [],
      },
    };
  };
}

export const influencerStore = ({
  accountStore,
}: {
  accountStore: AccountStore;
}) => new InfluencerStore(accountStore);
