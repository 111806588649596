import { platform } from "os";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { toCapitalize } from "../../../utils";
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from "../../../utils/styling/general";
import { TEXT } from "../../../fonts/text";
import { PlatformType } from "../../../types/deal";
import { useState } from "react";
import RPlatformIcon from "../RPlatformIcon";

interface SaveComponentProps {
  isSaved: boolean;
  onSavedClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SaveComponent = (props: SaveComponentProps) => {
  const { isSaved, onSavedClick } = props;

  const [tempIsSaved, setTempIsSaved] = useState<boolean>(isSaved);

  if (tempIsSaved)
    return (
      <IconContainer
        onClick={(e) => {
          onSavedClick(e);
          setTempIsSaved(!tempIsSaved);
        }}
      >
        <RXIcon name="SaveFill" color={COLOR.White} />
      </IconContainer>
    );
  else
    return (
      <IconContainer
        onClick={(e) => {
          onSavedClick(e);
          setTempIsSaved(!tempIsSaved);
        }}
      >
        <RXIcon name="Save" color={COLOR.White} />
      </IconContainer>
    );
};

interface Props {
  platform: PlatformType;
  influencerRating: number;
  ratingCount: number;
  username: string;
  isSaved: boolean;
  tier: string;
  onSavedClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const InfluencerCardHeader = (props: Props) => {
  const {
    isSaved,
    username,
    tier,
    onSavedClick,
    influencerRating,
    ratingCount,
    platform,
  } = props;
  return (
    <HeaderContainer>
      <FullWidthRowContainer>
        <GapRowContainer>
          <RPlatformIcon platform={platform} iconSize={20} dimension={24} />
          <TierContainer>
            <Blue700BoldB5Text>{toCapitalize(tier)}</Blue700BoldB5Text>
          </TierContainer>
        </GapRowContainer>
        <SaveComponent isSaved={isSaved} onSavedClick={onSavedClick} />
      </FullWidthRowContainer>
      <FullWidthRowContainer>
        <WhiteBoldB3Text>{username}</WhiteBoldB3Text>
        <MiniGapRowContainer>
          <RXIcon name="StarFilled" color={COLOR.Yellow_M} size={12} />
          <GapRowContainer>
            <WhiteBoldB4Text>
              {(influencerRating || 0).toFixed(1)}
            </WhiteBoldB4Text>
            <WhiteB4Text>({ratingCount})</WhiteB4Text>
          </GapRowContainer>
        </MiniGapRowContainer>
      </FullWidthRowContainer>
    </HeaderContainer>
  );
};

export default InfluencerCardHeader;

const HeaderContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  padding: 16px;
  z-index: 3;
  gap: 4px;
`;

const MiniGapRowContainer = styled(RowContainer)`
  gap: 2px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  position: relative;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const TierContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(2px);
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const InstagramContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: linear-gradient(
      225deg,
      #4f5bd5 0.62%,
      #962fbf 23.93%,
      #d62976 48.93%,
      #fa7e1e 70.81%,
      #feda75 96.33%
    )
    border-box;
`;

const TiktokContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: ${COLOR.Black};
`;

const FacebookContainer = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: ${COLOR.White};
  border-radius: 50px;
  left: 4px;
  bottom: 6px;
`;

const FacebookIconContainer = styled.div`
  z-index: 2;
`;

const Image = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 24px;
`;

const Blue700BoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  width: 174px;
  color: ${COLOR.White};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WhiteBoldB4Text = styled(TEXT.B4_Bold)`
  color: ${COLOR.White};
`;

const WhiteB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.White};
`;
