import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { Work } from "../../../types/work";
import { limitText, toDateDue } from "../../../utils";
import { ColumnContainer } from "../../../utils/styling/general";

interface Props {
  work: Work;
}

function DiscoverWorkCardDetail(props: Props) {
  const { work } = props;
  const {
    like_count,
    media_url,
    thumbnail_url,
    caption,
    comments_count,
    createdAt,
    postType,
  } = work;

  const getIconName = () => {
    switch (postType) {
      case "IG_album_post":
        return "Album";
      case "IG_story":
        return "StoryCircle";
      case "IG_reels":
        return "Video";
      case "TK_video":
        return "Tiktok";
      default:
        return "Image";
    }
  };

  return (
    <Container>
      <ImageContainer>
        <CardImageContainer src={thumbnail_url || media_url} />
        <PostTypeContainer>
          <RXIcon name={getIconName()} color={COLOR.WHITE} />
        </PostTypeContainer>
      </ImageContainer>
      <TextContainer>
        <RXIcon name="Heart" />
        <BoldB3Text> {like_count || 0} Likes</BoldB3Text>
      </TextContainer>
      <B4Text
        style={{
          width: 420,
          height: 40,
          overflow: "hidden",
          wordWrap: "break-word",
        }}
      >
        {limitText(caption || "", 80)}
      </B4Text>
      <B4Text>{comments_count || 0} Comments</B4Text>
      <TextContainer>
        <RXIcon name="Clock" size={20} color={COLOR.Gray_M} />
        <GrayMB5Text> Posted on : {toDateDue(createdAt)}</GrayMB5Text>
      </TextContainer>
    </Container>
  );
}

export default DiscoverWorkCardDetail;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  width: 427px;
  gap: 8px;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 427px;
  height: 400px;
`;

const CardImageContainer = styled.img`
  display: flex;
  align-items: center;
  object-fit: cover;
  width: 427px;
  height: 400px;
  background: ${COLOR.Gray_300};
  border-radius: 16px;
`;

const PostTypeContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  background: ${COLOR.BLACK};
  opacity: 0.6;
  border-radius: 20px;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 32px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const B4Text = styled(TEXT.B4_Reg)`
  margin-left: 4px;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
`;

const GrayMB5Text = styled(TEXT.B5_Reg)`
  margin-top: 2px;
  color: ${COLOR.Gray_M};
`;
