import React, { useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  children: JSX.Element;
  leftArrowComponent: JSX.Element;
  rightArrowComponent: JSX.Element;
}

// TODO: remove isMouseDown state if possible to prevent rerendering on scroll
const RDraggableWithArrowsComponent = (props: Props) => {
  const { children, leftArrowComponent, rightArrowComponent } = props;
  const draggableRef = useRef<null | HTMLDivElement>(null);
  const intervalRef = useRef<null | NodeJS.Timeout>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!draggableRef.current) return;
    const slider = draggableRef.current.children[0] as HTMLDivElement;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!draggableRef.current) return;
    document.body.style.cursor = "default";
  };

  const handleDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isMouseDown || !draggableRef.current) return;
    const slider = draggableRef.current.children[0] as HTMLDivElement;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  const handleLeftHold = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!draggableRef.current) return;
    const slider = draggableRef.current.children[0] as HTMLDivElement;
    intervalRef.current = setInterval(() => {
      slider.scrollLeft -= 10;
    }, 10);
  };

  const handleLeftEnd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleRightClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!draggableRef.current) return;
    const slider = draggableRef.current.children[0] as HTMLDivElement;
    intervalRef.current = setInterval(() => {
      slider.scrollLeft += 10;
    }, 10);
  };

  const handleParentClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Container>
      <ArrowContainer
        onClick={handleParentClick}
        onMouseDown={handleLeftHold}
        onMouseUp={handleLeftEnd}
        onMouseLeave={handleLeftEnd}
      >
        {leftArrowComponent}
      </ArrowContainer>
      <DraggableContainer
        ref={draggableRef}
        onClick={handleParentClick}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        onMouseLeave={handleDragEnd}
      >
        {children}
      </DraggableContainer>
      <ArrowContainer
        onClick={handleParentClick}
        onMouseDown={handleRightClick}
        onMouseUp={handleLeftEnd}
        onMouseLeave={handleLeftEnd}
      >
        {rightArrowComponent}
      </ArrowContainer>
    </Container>
  );
};

export default RDraggableWithArrowsComponent;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const ArrowContainer = styled.div``;

const DraggableContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
