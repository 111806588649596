import { observer } from "mobx-react";
import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { DiscoverPage } from "../../../../pages/DiscoverPage";
import { Work } from "../../../../types/work";
import { PLACEHOLDER_PROFILE, toDecimalNumber } from "../../../../utils";
import { RowContainer } from "../../../../utils/styling/general";
import ImageOverlay from "../../../atoms/ImageOverlay";
import RLoading from "../../../atoms/RLoading";
interface Props {
  feed: {
    data: any;
    metadata: {
      count: number;
      offset: number;
      limit: number;
    };
    next: () => void;
  };
  navigate: NavigateFunction;
  page: DiscoverPage;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  setLastPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  loading: boolean;
}

function DiscoverExpandWorkDetail(props: Props) {
  const { feed, navigate, setPage, loading, page, setLastPage } = props;

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 674 && !loading) {
      feed.next();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [loading]);

  const renderCard = (work: Work) => {
    if (work) {
      return (
        <CardContainer
          onClick={() => {
            setPage("Work");
            setLastPage(page);
            navigate(`/discover?page=work&workId=${work.workId}`);
          }}
        >
          <Image>
            <ImageOverlay
              zIndex={9}
              gradient={
                "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 15.36%, rgba(0, 0, 0, 0.2) 90.84%)"
              }
            />
            <ImageContainer
              src={work?.thumbnail_url || work?.media_url || ""}
            />
            <PlatformContainer>
              {work?.postType.includes("TK") ? (
                <TiktokIconContainer>
                  <RXIcon name="Tiktok" color={COLOR.WHITE} />
                </TiktokIconContainer>
              ) : (
                <InstagramContainer>
                  <RXIcon name="Instagram" color={COLOR.WHITE} />
                </InstagramContainer>
              )}
            </PlatformContainer>
            <ReachContainer>
              <RXIcon name="Signal" color={COLOR.WHITE} />
              <BoldBody1Text style={{ color: COLOR.WHITE, margin: 0 }}>
                {toDecimalNumber({ number: work?.reach })}
              </BoldBody1Text>
            </ReachContainer>
          </Image>
          <Body1Text>
            {
              //@ts-ignore
              work?.caption || ""
            }
          </Body1Text>
          <RowContainer>
            <ProfileContainer>
              <ProfileImageContainer
                src={
                  //@ts-ignore
                  work.influencers.profile_picture_url
                }
                onError={(e) => (e.currentTarget.src = PLACEHOLDER_PROFILE)}
              />
              <BoldBody1Text>
                {
                  //@ts-ignore
                  work.username
                }
              </BoldBody1Text>
            </ProfileContainer>
          </RowContainer>
        </CardContainer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Container>
        {feed.data.map((work: Work) => {
          return renderCard(work);
        })}
      </Container>
      {loading && <RLoading />}
    </>
  );
}

export default observer(DiscoverExpandWorkDetail);

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 36px;
  width: 100%;
  background: #ffffff;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;

  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  position: relative;
  width: 250px;
  height: 250px;
  background: ${COLOR.Gray_300};
  object-fit: cover;
  border-radius: 16px 16px 0px 0px;
`;

const Image = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
`;

const ProfileContainer = styled(RowContainer)`
  margin: 8px 16px 16px 16px;
  align-items: center;
  justify-content: center;
`;

const ProfileImageContainer = styled.img`
  width: 32px;
  height: 32px;
  background: ${COLOR.Gray_300};
  object-fit: cover;
  border-radius: 16px;
`;

const PlatformContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 16px;
  right: 16px;
  z-index: 99;
`;

const ReachContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 16px;
  left: 16px;
  z-index: 99;
`;

const TiktokIconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${COLOR.BLACK};
`;

const InstagramContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
`;

const Body1Text = styled(TEXT.body1)`
  margin: 8px 16px 0px 16px;
  width: 220px;
  height: 40px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 99;
`;

const BoldBody1Text = styled(TEXT.body1BOLD)`
  margin: 8px 16px;
`;
