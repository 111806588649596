export const ANDCOLOR = {
  BLACK: "#0D0D0D",
  DARKGRAY: "#999999",
  GRAY: "#6D6D6D",
  MEDIUMGRAY: "#9D9D9D",
  LIGHTGRAY: "#EAEAEA",
  GRAY100: "#EEEFEF",
  GRAY300: "#CED0D1",
  GRAY400: "#B9BBBD",

  WHITE: "#FFFFFF",
  OFFWHITE: "#F8F8F8",

  DARKGREEN: "#008F6B",
  DARKGREEN2: "#00AE8A",
  GREEN: "#00D0AA",
  LIGHTGREEN: "#A9F6E3",
  LIGHTGREEN2: "#DDFFF7",

  DARKPINK: "#B23955",
  PINK: "#FF527A",
  LIGHTPINK: "#E5CCFF",

  DARKYELLOW: "#D29101",
  YELLOW: "#FFC648",
  LIGHTYELLOW: "#FFE7B2",
  LIGHTYELLOW2: "#FFEFCC",
  LEMON: "#FFF700",

  BLUE: "#1422ED",
  LIGHTBLUE: "#EAF3FF",
  BLUE400: "#648BFF",
  BLUE500: "#4160FF",
  BLUE600: "#2032FF",

  ORANGE: "#F2994A",
  PURPLE: "#573EC4",
  LIGHTPURPLE: "#D0D3FB",
  MEDIUMPURPLE: "#573EC4",
};
