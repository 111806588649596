import { PieChart } from '@mui/x-charts/PieChart';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { PieValueType } from '@mui/x-charts/models/seriesType';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { PlatformType } from '../../../types/deal';
import {
  calculateAverageData,
  calculatePercentage,
  toDecimalNumber,
} from '../../../utils';
import {
  FlexEndColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from '../../../utils/styling/general';

const pieSizing = {
  width: 220,
  height: 220,
  legend: { hidden: true },
};

interface ShareSavedProps {
  like: number;
  comment: number;
  share: number;
  save: number;
  platformActive: PlatformType;
}

const ShareSavedComponent = (props: ShareSavedProps) => {
  const { platformActive, like, comment, save, share } = props;
  let detailText = '';
  let value = 0;
  let percent = '';
  let totalValue = 0;
  switch (platformActive) {
    case 'INSTAGRAM':
      detailText = 'Saved';
      totalValue = like + comment + save;
      value = save;
      percent = calculatePercentage(save, totalValue);
      break;
    default:
      detailText = 'Share';
      totalValue = like + comment + share;
      value = share;
      percent = calculatePercentage(share, totalValue);
      break;
  }
  return (
    <FullWidthRowContainer>
      <BigGapRowContainer>
        <BlackCircle />
        <GrayH8Text>{detailText}</GrayH8Text>
      </BigGapRowContainer>
      <GapRowContainer>
        <ValueContainer>
          <BoldH6Text>{`${toDecimalNumber({
            number: value,
            isZeroDash: false,
          })}`}</BoldH6Text>
        </ValueContainer>
        <PercentContainer>
          <GrayH9Text>{`(${percent}%)`}</GrayH9Text>
        </PercentContainer>
      </GapRowContainer>
    </FullWidthRowContainer>
  );
};

interface ValueProps {
  title: string;
  value: number;
  // percent: string;
  backgroundColor: string;
  dateAgo: number;
}

const ValueComponent = (props: ValueProps) => {
  const { title, value, backgroundColor, dateAgo } = props;
  return (
    <SmallSubContentContainer>
      {/* <FullWidthRowContainer
        style={{ justifySelf: "flex-end", justifyContent: "flex-end" }}
      > */}
      {/* <ArrowContainer>
          <RXIcon name="ArrowLeft" color={COLOR.Herbal} size={16} />
        </ArrowContainer>
        <GreenB4Text>3.14%</GreenB4Text> */}
      {/* </FullWidthRowContainer> */}
      <GapColumnContainer>
        <IconCotainer style={{ backgroundColor }}>
          <RXIcon name="FileFill" color={COLOR.White} size={32} />
        </IconCotainer>
        <FlexStartColumnContainer>
          <GapRowContainer>
            <BoldH6Text>{`${toDecimalNumber({
              number: value,
              isZeroDash: false,
            })}`}</BoldH6Text>
            {/* <BoldH9Text>{`(${percent}%)`}</BoldH9Text> */}
          </GapRowContainer>
          <FlexStartColumnContainer>
            <GrayH8Text>{title}</GrayH8Text>
            {/* <GrayDB4Text>{`(ย้อนหลัง ${dateAgo} วัน)`}</GrayDB4Text> */}
          </FlexStartColumnContainer>
        </FlexStartColumnContainer>
      </GapColumnContainer>
    </SmallSubContentContainer>
  );
};

interface PlatformValueProps {
  like: number;
  comment: number;
  share: number;
  engagement: number;
  reach: number;
  impressions: number;
  save: number;
  view: number;
  platformActive: PlatformType;
  dateAgo: number;
  postCount: number;
}

const PlatformValueComponent = (props: PlatformValueProps) => {
  const {
    engagement,
    reach,
    impressions,
    view,
    platformActive,
    dateAgo,
    postCount,
  } = props;

  const impression = platformActive === 'TIKTOK' ? view : impressions;
  const impressionOrViewText =
    platformActive === 'TIKTOK' ? 'Avg.View' : 'Avg.Impression';

  const avgEngagement = parseFloat(calculateAverageData(engagement, postCount));
  const avgReach = parseFloat(calculateAverageData(reach, postCount));
  const avgImpression = parseFloat(calculateAverageData(impression, postCount));
  return (
    <>
      <ValueComponent
        title="Avg.Engagement"
        backgroundColor={COLOR.Blue_500}
        value={avgEngagement}
        // percent={engagementPercentage}
        dateAgo={dateAgo}
      />
      {platformActive !== 'TIKTOK' && (
        <ValueComponent
          title="Avg.Reach"
          backgroundColor={COLOR.Yellow_M}
          value={avgReach}
          // percent={reachPercentage}
          dateAgo={dateAgo}
        />
      )}
      <ValueComponent
        title={impressionOrViewText}
        backgroundColor={COLOR.Herbal}
        value={avgImpression}
        // percent={impressionPercentage}
        dateAgo={dateAgo}
      />
    </>
  );
};

interface Props {
  pieData: MakeOptional<PieValueType, 'id'>[];
  like: number;
  comment: number;
  share: number;
  engagement: number;
  reach: number;
  impressions: number;
  save: number;
  view: number;
  platformActive: PlatformType;
  dateAgo: number;
  isPieActive: boolean;
  postCount: number;
}

const InsightStatistics = (props: Props) => {
  const {
    pieData,
    like = 0,
    comment = 0,
    share = 0,
    engagement = 0,
    isPieActive,
  } = props;

  const totalLikeCommentShare = like + comment + share;
  const likePercentage = calculatePercentage(like, totalLikeCommentShare);
  const commentPercentage = calculatePercentage(comment, totalLikeCommentShare);

  return (
    <Container>
      <SubContentContainer>
        <PieChart
          series={[
            {
              data: pieData,
              innerRadius: 50,
              outerRadius: 100,
              cornerRadius: 4,
              valueFormatter: (value) =>
                isPieActive ? value.value.toString() : '0',
              cx: 100,
              paddingAngle: 1,
              ...(isPieActive && {
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -10,
                  color: 'gray',
                },
              }),
            },
          ]}
          {...pieSizing}
        />
        <ContentContainer>
          <FlexStartColumnContainer style={{ width: '100%' }}>
            <SpaceBetweenRowContainer style={{ width: '100%' }}>
              <BoldH6Text>
                {toDecimalNumber({ number: engagement, isZeroDash: false })}
              </BoldH6Text>
              {/* Remove it for now. Waiting for revamp */}
              {/* <GapRowContainer>
                <ArrowContainer>
                  <RXIcon name="ArrowLeft" color={COLOR.Herbal} size={16} />
                </ArrowContainer>
                <GreenB4Text>3.14%</GreenB4Text>
              </GapRowContainer> */}
            </SpaceBetweenRowContainer>
            <RowContainer style={{ gap: 2 }}>
              <GrayH8Text>{`Total Engagement`}</GrayH8Text>
              {/* <GrayDB4Text> (ย้อนหลัง {dateAgo} วัน)</GrayDB4Text> */}
            </RowContainer>
          </FlexStartColumnContainer>
          <BigGapColumnContainer>
            <FullWidthRowContainer>
              <BigGapRowContainer>
                <BlueCircle />
                <GrayH8Text>Like</GrayH8Text>
              </BigGapRowContainer>
              <GapRowContainer>
                <ValueContainer>
                  <BoldH6Text>{`${toDecimalNumber({
                    number: like,
                    isZeroDash: false,
                  })}`}</BoldH6Text>
                </ValueContainer>
                <PercentContainer>
                  <GrayH9Text>{`(${likePercentage}%)`}</GrayH9Text>
                </PercentContainer>
              </GapRowContainer>
            </FullWidthRowContainer>
            <FullWidthRowContainer>
              <BigGapRowContainer>
                <PinkCircle />
                <GrayH8Text>Comment</GrayH8Text>
              </BigGapRowContainer>
              <GapRowContainer>
                <ValueContainer>
                  <BoldH6Text>{`${toDecimalNumber({
                    number: comment,
                    isZeroDash: false,
                  })}`}</BoldH6Text>
                </ValueContainer>
                <PercentContainer>
                  <GrayH9Text>{`(${commentPercentage}%)`}</GrayH9Text>
                </PercentContainer>
              </GapRowContainer>
            </FullWidthRowContainer>
            <ShareSavedComponent {...props} />
          </BigGapColumnContainer>
        </ContentContainer>
      </SubContentContainer>
      <PlatformValueComponent {...props} />
    </Container>
  );
};

export default InsightStatistics;

const Container = styled(RowContainer)`
  width: 100%;
  gap: 12px;
`;

//47% 17%
const SubContentContainer = styled(RowContainer)`
  padding: 20px 24px;
  border-radius: 12px;
  background: ${COLOR.Gray_50};
  gap: 16px;
  height: 210px;
  width: 46%;
`;

const SmallSubContentContainer = styled(FlexStartColumnContainer)`
  padding: 20px 24px;
  border-radius: 12px;
  background: ${COLOR.Gray_50};
  gap: 16px;
  justify-content: space-between;
  height: 210px;
  flex: 1;
`;

const GapRowContainer = styled(RowContainer)`
  width: 40%;
  gap: 4px;
`;

const ValueContainer = styled(FlexEndColumnContainer)`
  width: 50%;
`;

const PercentContainer = styled(FlexStartColumnContainer)`
  width: 50%;
`;

const BigGapRowContainer = styled(RowContainer)`
  width: 60%;
  gap: 8px;
`;

const ContentContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 12px;
`;

const GapColumnContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  flex: 1;
  gap: 8px;
  justify-content: space-between;
`;

const BigGapColumnContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 4px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;
const ArrowContainer = styled.div`
  transform: rotate(90deg);
`;

const BlueCircle = styled.div`
  background-color: ${COLOR.Blue_300};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const PinkCircle = styled.div`
  background-color: ${COLOR.Rubicund};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BlackCircle = styled.div`
  background-color: ${COLOR.Black};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const IconCotainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50px;
`;

const IconDivCotainer = styled.div``;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldH9Text = styled(TEXT.H9_Bold)``;

const GrayH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.Gray_D};
`;

const GreenB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Herbal};
`;

const GrayDB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;
