import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { ActiveProps } from "../../types";

interface Props {
  active: boolean;
  notActiveColor?: string;
}

function Rradiobutton(props: Props) {
  const { active, notActiveColor } = props;
  return (
    <Container active={active}>
      <SelectedContainer
        active={active}
        color={notActiveColor || COLOR.WHITE}
      />
    </Container>
  );
}

export default Rradiobutton;

const Container = styled.div<ActiveProps>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
  border: ${(props) =>
    props.active
      ? `1px solid ${COLOR.Blue_700}`
      : `1px solid ${COLOR.Gray_200}`};
  border-radius: 50px;
`;

const SelectedContainer = styled.div<ActiveProps>`
  display: flex;
  width: 12px;
  height: 12px;
  background: ${(props) => (props.active ? COLOR.Blue_700 : props.color)};
  border-radius: 50px;
`;
