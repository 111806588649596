import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import {
  IInstagramInfluencer,
  RateCard,
} from '../../../types/api/getInfluencer';
import {
  calculateAverageData,
  calculatePercentage,
  getTierByFollower,
  toCapitalize,
  toDecimalNumber,
} from '../../../utils';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer,
} from '../../../utils/styling/general';
import EmptyRateCard from './EmptyRateCard';
import EmptySocialMedia from './EmptySocialMedia';

interface RateCardProps {
  rateCard: RateCard[];
}

const InstagramRateCard = (props: RateCardProps) => {
  const { rateCard } = props;

  if (rateCard.length === 0) return <EmptyRateCard />;

  const singlePostRateCard =
    toDecimalNumber({
      number: rateCard.find((card) => card.detail.includes('i'))?.rate || 0,
      isZeroDash: false,
    }) + ' ฿';
  const multiPostRateCard =
    toDecimalNumber({
      number: rateCard.find((card) => card.detail.includes('m'))?.rate || 0,
      isZeroDash: false,
    }) + ' ฿';
  const reelsRateCard =
    toDecimalNumber({
      number: rateCard.find((card) => card.detail.includes('r'))?.rate || 0,
      isZeroDash: false,
    }) + ' ฿';
  const storyRateCard =
    toDecimalNumber({
      number: rateCard.find((card) => card.detail.includes('s'))?.rate || 0,
      isZeroDash: false,
    }) + ' ฿';

  return (
    <FullWidthRowContainer>
      <GapColumnContainer>
        <GrayB5Text>ราคาต่อโพสต์</GrayB5Text>
        <FullWidthRowContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>{singlePostRateCard}</WhiteBoldH7Text>
            <GrayB4Text>Single Post</GrayB4Text>
          </ColumnContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>{multiPostRateCard}</WhiteBoldH7Text>
            <GrayB4Text>Multi Post</GrayB4Text>
          </ColumnContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>{reelsRateCard}</WhiteBoldH7Text>
            <GrayB4Text>Reels</GrayB4Text>
          </ColumnContainer>
          <ColumnContainer>
            <WhiteBoldH7Text>{storyRateCard}</WhiteBoldH7Text>
            <GrayB4Text>Story</GrayB4Text>
          </ColumnContainer>
        </FullWidthRowContainer>
      </GapColumnContainer>
    </FullWidthRowContainer>
  );
};
interface Props {
  instagram: IInstagramInfluencer;
  rateCard: RateCard[];
}

const InstagramDetail = (props: Props) => {
  const { instagram, rateCard } = props;

  if (!instagram) return <EmptySocialMedia platform="INSTAGRAM" />;

  const {
    followers_count = 0,
    username,
    monthData = {
      engagement: 0,
      reachCount: 0,
      impressionCount: 0,
      postCount: 0,
    },
  } = instagram;
  const { engagement, reachCount, impressionCount, postCount } = monthData;
  const tier = toCapitalize(getTierByFollower(followers_count));
  const avgEngagement = calculateAverageData(engagement, postCount);
  const engagementPercentage = calculatePercentage(
    parseFloat(avgEngagement),
    followers_count
  );
  const avgReach = calculateAverageData(reachCount, postCount);
  const avgImpression = calculateAverageData(impressionCount, postCount);

  const followers = toDecimalNumber({
    number: followers_count,
    isZeroDash: false,
  });
  const engagementValue = toDecimalNumber({
    number: avgEngagement,
    isZeroDash: false,
  });
  const reachValue = toDecimalNumber({ number: avgReach, isZeroDash: false });
  const impressionValue = toDecimalNumber({
    number: avgImpression,
    isZeroDash: false,
  });

  const onClick = () => window.open(`https://instagram.com/${username}`);

  return (
    <Container>
      <GapRowContainer>
        <PlatformContainer onClick={onClick}>
          <RXIcon name="Instagram" color={COLOR.White} size={32} />
        </PlatformContainer>
        <FollowersContainer>
          <FullWidthRowContainer>
            <TierContainer>
              <BlueBoldB5Text>{tier}</BlueBoldB5Text>
            </TierContainer>
            <IconContainer onClick={onClick}>
              <RXIcon name="ExternalLink" color={COLOR.White} />
            </IconContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <WhiteBoldB3Text onClick={onClick}>{username}</WhiteBoldB3Text>
            <TextContainer>
              <WhiteBoldH7Text>{followers}</WhiteBoldH7Text>
              <WhiteH9Text>Followers</WhiteH9Text>
            </TextContainer>
          </FullWidthRowContainer>
        </FollowersContainer>
      </GapRowContainer>
      <FullWidthRowContainer>
        <ColumnContainer>
          <TextContainer>
            <WhiteBoldH7Text>{engagementValue}</WhiteBoldH7Text>
            <WhiteBoldH9Text>({engagementPercentage}%)</WhiteBoldH9Text>
          </TextContainer>
          <GrayB4Text>Avg.Engagement</GrayB4Text>
        </ColumnContainer>
        <ColumnContainer>
          <WhiteBoldH7Text>{reachValue}</WhiteBoldH7Text>
          <GrayB4Text>Avg.Reach</GrayB4Text>
        </ColumnContainer>
        <ColumnContainer>
          <WhiteBoldH7Text>{impressionValue}</WhiteBoldH7Text>
          <GrayB4Text>Avg.Impression</GrayB4Text>
        </ColumnContainer>
      </FullWidthRowContainer>
      <Dashline />
      <InstagramRateCard rateCard={rateCard} />
    </Container>
  );
};

export default InstagramDetail;

const Container = styled(ColumnContainer)`
  border-radius: 12px;
  width: 33%;
  height: 260px;
  background: rgba(1, 1, 1, 0.7);
  backdrop-filter: blur(20px);
  padding: 24px 20px;
  align-items: flex-start;
  gap: 16px;
`;

const PlatformContainer = styled(RowContainer)`
  justify-content: center;
  width: 44px;
  height: 44px;
  background: linear-gradient(
      225deg,
      #4f5bd5 0.62%,
      #962fbf 23.93%,
      #d62976 48.93%,
      #fa7e1e 70.81%,
      #feda75 96.33%
    )
    border-box;
  border-radius: 50px;
  :hover {
    cursor: pointer;
  }
`;

const TierContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(2px);
`;

const GapColumnContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
`;

const FollowersContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
  width: 80%;
`;

const GapRowContainer = styled(RowContainer)`
  width: 100%;
  gap: 8px;
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 0.6px solid ${COLOR.Gray_M};
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const TextContainer = styled(RowContainer)`
  gap: 4px;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const BlueBoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.White};
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;

const WhiteBoldH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const WhiteBoldH9Text = styled(TEXT.H9_Bold)`
  color: ${COLOR.White};
`;

const GrayB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_200};
`;

const GrayB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_400};
`;
