import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { PasswordCodeProp } from "../../../types/resetpassword";
import ForgotPasswordNewDetail from "../../molecules/ForgotPassword/ForgotPasswordNewDetail";

interface Props {
  code: PasswordCodeProp;
  resetPassword: () => Promise<void>;
  isRepasswordSendActive: boolean;
  onDone: () => void;
}

const RegisterCardContainer = styled.div`
  width: 567px;
  height: 664px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  padding: 86px 88px 108px 88px;
`;

function ForgotPasswordNew(props: Props) {
  return (
    <RegisterCardContainer>
      <ForgotPasswordNewDetail {...props} />
    </RegisterCardContainer>
  );
}

export default ForgotPasswordNew;
