import { observer } from "mobx-react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import FavoriteInfluList from "../../molecules/Favorite/FavoriteInfluList";
interface Props {
  influList: {
    data: any;
    metadata: {
      count: number;
      offset: number;
      limit: number;
    };
  };
  loading: boolean;
  navigate: NavigateFunction;
}

function FavoritePageDetail(props: Props) {
  return <FavoriteInfluList {...props} />;
}

export default observer(FavoritePageDetail);
