import { objectToQuerystring } from "../../utils";
import { GET } from "../../utils/Networking";

export const listFeedImages = async ({
  offset = 0,
  url,
}: {
  offset: number;
  url: string;
}) => {
  const queries = { offset };
  const qs = objectToQuerystring(queries);
  const { metadata, data } = await GET(url || `/brands/feeds/explore${qs}`);
  return {
    metadata:
      metadata.length > 0 ? metadata[0] : { offset: 0, next: undefined },
    data,
  };
};

export const listFeedDeals = async ({
  offset = 0,
  limit = 7,
  seed,
}: {
  offset: number;
  limit: number;
  seed?: number;
}) => {
  const queries = { offset, limit, seed };

  const qs = objectToQuerystring(queries);
  const { metadata, data } = await GET(`/brands/feeds/deals${qs}`);

  return {
    metadata:
      metadata.length > 0
        ? metadata[0]
        : { count: 0, limit: 0, offset: 0, seed: Math.random() },
    data,
  };
};

type DescribableDealId = {
  dealId?: string;
};

export const listDiscoveryWork = async () => {
  const result = await GET(`/brands/feeds/explore/discovery`);
  const foodCat = result.filter(
    (data: { category: string }) => data.category === "Food & Beverage"
  );
  const beautyCat = result.filter(
    (data: { category: string }) => data.category === "Beauty & Cosmetics"
  );
  const fashionCat = result.filter(
    (data: { category: string }) => data.category === "Fashion & Accessory"
  );
  return { foodCat, beautyCat, fashionCat };
};

export const listFeedExpandWork = async ({
  offset = 0,
  limit = 10,
  category,
  filter,
}: {
  offset: number;
  limit: number;
  category: string;
  filter: { sortBy: string; platforms: string };
}) => {
  const sortBy = filter.sortBy || "Reach";
  const platforms = filter.platforms || "Default";
  const queries = { offset, limit, category, sortBy, platforms };

  const qs = objectToQuerystring(queries);
  const { metadata, data } = await GET(`/brands/feeds/explore/expand${qs}`);

  return {
    metadata:
      metadata.length > 0
        ? metadata[0]
        : { count: 0, limit: 0, offset: 0, seed: Math.random() },
    data,
  };
};

export const getFeedInfluWork = async ({
  feedId,
  type,
}: {
  feedId: string;
  type: string;
}) => {
  if (!feedId) {
    throw new Error("feedId is undefined");
  }
  return GET(`/brands/feeds/explore/${feedId}?type=${type}`);
};

export const getFeedDealDetail = async (fn: DescribableDealId) => {
  if (!fn.dealId) {
    throw new Error("dealId is undefined");
  }
  return GET(`/brands/feeds/deals/${fn.dealId}`);
};
