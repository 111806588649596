import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../fonts/color";
import { TEXT } from "../../../../fonts/text";
import { RowContainer } from "../../../../utils/styling/general";
import Dropdown from "../../../atoms/Dropdown/Dropdown";
import { changwat } from "../../../organisms/CreateCampaign/Options";

interface Props {
  influencerLocation: string;
  audienceLocation: string;
  setInfluencerLocation: (location: string) => void;
  setAudienceLocation: (location: string) => void;
}

const LocationSelector = (props: Props) => {
  const {
    influencerLocation,
    audienceLocation,
    setInfluencerLocation,
    setAudienceLocation,
  } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>สถานที่</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <RXIcon name="PlaceholderFill" color={COLOR.Blue_700} size={20} />
          <Body3Text>
            เลือก <UnderlineBody3Text>Influencer</UnderlineBody3Text>{" "}
            ที่อยู่ในจังหวัด
          </Body3Text>
        </GapRowContainer>
        <Dropdown
          defaultValue={
            changwat.find((location) => location.value === influencerLocation)
              ?.label || undefined
          }
          activeText={!!influencerLocation}
          placeholder="เลือกจังหวัด"
          options={changwat.map((object) => {
            return object.label;
          })}
          onChange={(option: string) => {
            const location = changwat.find(
              (object) => object.label === option
            ) || {
              label: "ทุกจังหวัด",
              value: "all",
            };
            setInfluencerLocation(location.value);
          }}
          containerStyle={{ width: 448, zIndex: 101 }}
        />
        <GapRowContainer style={{ marginTop: 36 }}>
          <Body3Text>
            {" "}
            เลือก <UnderlineBody3Text>ผู้ติดตาม</UnderlineBody3Text>{" "}
            ที่อยู่ในจังหวัด
          </Body3Text>
        </GapRowContainer>
        <Dropdown
          defaultValue={
            changwat.find((location) => location.value === audienceLocation)
              ?.label || undefined
          }
          activeText={!!audienceLocation}
          placeholder="เลือกจังหวัด"
          options={changwat.map((object) => {
            return object.label;
          })}
          onChange={(option: string) => {
            const location = changwat.find(
              (object) => object.label === option
            ) || {
              label: "ทุกจังหวัด",
              value: "all",
            };
            setAudienceLocation(location.value);
          }}
          containerStyle={{ width: 448 }}
        />
      </ContentContainer>
    </Container>
  );
};

export default LocationSelector;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const BoldHeader7Text = styled(TEXT.H7_Bold)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_D};
`;
const UnderlineBody3Text = styled(TEXT.B3_Bold)`
  text-decoration: underline;
`;
