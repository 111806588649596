import React, { useEffect } from "react";
import styled from "styled-components";
import useWindowDimensions from "../../utils/hook/UseWindowDimensions";

interface Props {
  active: boolean;
  setActive: () => void;
  clickable?: boolean;
  children: JSX.Element;
  backgroundVisible?: boolean;
  align?: "flex-start" | "flex-end" | "center";
  justify?: "flex-start" | "flex-end" | "center";
  marginTop?: number;
}

interface DimensionProps {
  width: number;
  height: number;
  backgroundVisible: boolean;
  align: "flex-start" | "flex-end" | "center";
  justify: "flex-start" | "flex-end" | "center";
}

interface ModalProps {
  marginTop: number;
}

const RModal = (props: Props) => {
  const {
    active,
    setActive,
    clickable = true,
    children,
    backgroundVisible = false,
    align,
    justify,
    marginTop,
  } = props;
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    active
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [active]);

  const onClose = () => {
    if (clickable) {
      setActive();
    }
  };

  const handleChildElementClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  if (!active) {
    return <></>;
  }

  return (
    <Container
      height={height}
      width={width}
      backgroundVisible={backgroundVisible}
      align={align || "center"}
      justify={justify || "center"}
      onClick={onClose}
    >
      <ModalContainer
        onClick={(e) => handleChildElementClick(e)}
        marginTop={marginTop || 0}
      >
        {children}
      </ModalContainer>
    </Container>
  );
};

export default RModal;

const Container = styled.div<DimensionProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  background: ${(props) =>
    props.backgroundVisible ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.8)"};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  overflow: hidden;
  z-index: 999999;
`;

const ModalContainer = styled.div<ModalProps>`
  margin-top: ${(props) => props.marginTop + "px"};
`;
