import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";
import { ColumnContainer, RowContainer } from "../../../utils/styling/general";
import BrandHeader from "../../molecules/Layout/BrandHeader";
import Drawer from "../../molecules/Layout/Drawer";
import AnotherDotHeader from "../../molecules/Layout/AnotherDotHeader";

interface Props {
  children: JSX.Element;
}

interface WidthProps {
  width: number;
}

function MainLayout(props: Props) {
  const { children } = props;

  const url = useLocation();
  const { width } = useWindowDimensions();
  const [newLayout, setNewLayout] = useState<boolean>(false);

  //TODO: remove use effect after finish menu tab
  useEffect(() => {
    if (
      url.pathname.includes("login") ||
      url.pathname.includes("forgotpassword") ||
      url.pathname.includes("register") ||
      url.pathname.includes("createprofile")
    )
      setNewLayout(false);
    else setNewLayout(true);
  }, [url.pathname]);

  if (newLayout)
    return (
      <Container>
        <Drawer />
        <PageLayoutContainer>
          <AnotherDotHeader />
          <ChildrenContainer width={width - 240}>{children}</ChildrenContainer>
        </PageLayoutContainer>
      </Container>
    );
  else
    return (
      <>
        <BrandHeader />
        {children}
      </>
    );
}

export default MainLayout;

const Container = styled(RowContainer)`
  align-items: flex-start;
`;

const PageLayoutContainer = styled(ColumnContainer)`
  position: relative;
  align-items: flex-start;
`;

const ChildrenContainer = styled.div<WidthProps>`
  width: ${(props) => props.width}px;
  min-width: 1200px;
`;
