import React from "react";
import styled, { CSSProperties } from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";

interface Props {
  name: string;
  icon?: any;
  onPress: () => void;
  containerStyle?: CSSProperties;
  iconStyle?: any;
  textStyle?: CSSProperties;
}

function RTags(props: Props) {
  const { name, icon, onPress, containerStyle, iconStyle, textStyle } = props;

  return (
    <Container style={containerStyle} onClick={onPress}>
      <IconText style={iconStyle}>{icon} </IconText>
      <B4Text style={textStyle}>{name}</B4Text>
    </Container>
  );
}

export default RTags;

const Container = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border: 1px solid ${COLOR.Gray_200};
  border-radius: 50px;
  gap: 4px;
`;

const B4Text = styled(TEXT.B4_Reg)``;

const IconText = styled(TEXT.B4_Reg)``;
