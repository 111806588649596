import styled from "styled-components";
import LoginLogoDetail from "../../molecules/Login/LoginLogoDetail";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function LoginLogo() {
  return (
    <Container>
      <LoginLogoDetail />
    </Container>
  );
}

export default LoginLogo;
