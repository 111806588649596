import React from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RButton from "../../atoms/RButton";
import RModal from "../../atoms/RModal";

interface Props {
  modalVisible: string;
  setModalVisible: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  clearDraft: () => Promise<void>;
}

function UseDraftModal(props: Props) {
  const { modalVisible, setModalVisible, navigate, clearDraft } = props;

  return (
    <RModal active={!!modalVisible} setActive={() => setModalVisible("")}>
      <Container>
        <CloseContainer onClick={() => setModalVisible("")}>
          <RXIcon name="Close" />
        </CloseContainer>
        <Image src={require("../../../assets/images/Campaign/Clipboard.png")} />
        <BoldH5Text>คุณมีแคมเปญที่สร้างค้างไว้</BoldH5Text>
        <B2Text style={{ marginTop: "8px" }}>
          คุณมีแคมเปญที่สร้างค้างไว้ก่อนหน้านี้
          <br />
          ต้องการลบทิ้ง หรือสร้างต่อ
        </B2Text>
        <RButton
          title="ลบทิ้งและสร้างใหม่"
          onPress={() => {
            clearDraft();
            navigate("/createcampaign?page=1", { state: modalVisible });
          }}
          containerStyle={{ width: "307px", marginTop: "24px" }}
        />
        <RButton
          title="สร้างต่อจากที่ค้างไว้"
          onPress={() => navigate("/createcampaign?page=1")}
          textColor={COLOR.Blue_700}
          backgroundColor={COLOR.WHITE}
          hoverColor={COLOR.Gray_50}
          containerStyle={{
            width: "307px",
            marginTop: "16px",
            marginBottom: "32px",
          }}
        />
      </Container>
    </RModal>
  );
}

export default UseDraftModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 394px;
  align-items: center;
  text-align: center;
  background: ${COLOR.WHITE};
  border-radius: 24px;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 116px;
  height: 144px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;
