import { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import RModal from "../../atoms/RModal";

interface Props {
  dealId: string;
  status: "ongoing" | "pending" | "done";
  activateShareModal: () => void;
  navigate: NavigateFunction;
}

function CampaignPerformance(props: Props) {
  const { activateShareModal, dealId, status, navigate } = props;
  return (
    <Container>
      <DropdownButton onClick={() => navigate(`/performance?dealId=${dealId}`)}>
        <DropdownText>Performance</DropdownText>
      </DropdownButton>
      {/* TODO : RE Reimplement when shared is fixed */}
      {/* {status === "done" ? (
        // <OpenCampaign>
        //   <ShareText>เปิดแคมเปญอีกครั้ง</ShareText>
        // </OpenCampaign>
        <></>
      ) : (
        <ShareButton onClick={activateShareModal}>
          <RXIcon name="ShareFill" size={18} color={COLOR.Blue_700} />
          <ShareText>แชร์</ShareText>
        </ShareButton>
      )} */}
    </Container>
  );
}

export default CampaignPerformance;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${COLOR.Gray_200};
  border-radius: 0px 0px 18px 18px;
`;

const DropdownButton = styled.div`
  display: flex;
  margin-left: 20px;
`;

const DropdownText = styled(TEXT.B4_Bold)`
  display: flex;
  align-items: center;
  color: ${COLOR.Blue_700};
`;

const ShareButton = styled.button`
  display: flex;
  width: 60px;
  height: 24px;
  margin-right: 16px;
  border: none;
  border-radius: 12px;
  background: ${COLOR.Blue_50};
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const OpenCampaign = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  background: ${COLOR.White};
  /* Green/Primary */

  border: 1px solid ${COLOR.Blue_700};
  border-radius: 50px;
`;

const ShareText = styled(TEXT.B4_Bold)`
  color: ${COLOR.Blue_700};
`;
