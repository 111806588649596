import styled from "styled-components";
import { Deal } from "../../../types/deal";
import { TODAYSTRING } from "../../../utils";
import DealCardCampaign from "../../molecules/Deal/DealCardCampaign";
import DealCardInfluencer from "../../molecules/Deal/DealCardInfluencer";
import DealCardReward from "../../molecules/Deal/DealCardReward";
import DealCardScopeOfWork from "../../molecules/Deal/DealCardScopeOfWork";
import DealCardTimeline from "../../molecules/Deal/DealCardTimeline";

interface Props {
  deal: Deal;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 745px;
`;

const CampaignDetailContainer = styled.div`
  padding: 0px 40px;
  margin-bottom: 100px;
`;

function DealDetails(props: Props) {
  const { deal } = props;

  const {
    detail,
    productDetail,
    limit,
    age,
    gender,
    criteria,
    benefits,
    value,
    cash,
    startDate,
    dueDate,
    postingDate,
    submissionDate,
    location,
    isNegotiateCash,
    brandApprove,
    posts,
    platforms,
    hashtags,
    tiktokHashtags,
    caption,
    tiktokCaptionGuide,
    facebookCaptionGuide,
    facebookHashtags,
  } = deal;

  return (
    <Container>
      <CampaignDetailContainer>
        <DealCardCampaign
          detail={detail}
          productDetail={productDetail}
          age={age}
          gender={gender}
          location={location}
          isNegotiateCash={isNegotiateCash}
        />
        <DealCardInfluencer
          limit={limit}
          influencerAge={criteria.ages}
          influencerGender={criteria.gender}
        />
        <DealCardScopeOfWork
          platforms={platforms}
          // @ts-ignore
          posts={posts}
          hashtags={hashtags}
          tiktokHashtags={tiktokHashtags}
          caption={caption}
          tiktokCaptionGuide={tiktokCaptionGuide}
          facebookHashtags={facebookHashtags}
          facebookCaptionGuide={facebookCaptionGuide}
        />
        <DealCardReward
          benefitsDetail={benefits[0]}
          isValue={!!value}
          value={value}
          isCash={!!cash}
          cash={cash}
        />
        <DealCardTimeline
          startDate={startDate || TODAYSTRING}
          endDate={dueDate || TODAYSTRING}
          postingDate={postingDate || TODAYSTRING}
          submissionDate={submissionDate || TODAYSTRING}
          brandApprove={brandApprove}
        />
      </CampaignDetailContainer>
    </Container>
  );
}

export default DealDetails;
