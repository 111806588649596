import { Slider } from "@mui/material";
import { styled as mstyled } from "@mui/material/styles";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";
import { ANDCOLOR } from "../../fonts/andColor";
import { useState } from "react";

interface Props {
  range: number[];
  setRange: (range: number[]) => void;
  color?: string;
  min: number;
  max: number;
}

interface ColorProps {
  customColor: string;
}

function ANDSilder(props: Props) {
  const { range, setRange, color = ANDCOLOR.BLUE, min, max } = props;

  return (
    <RangeContainer>
      <RangeSlider
        value={range}
        min={min}
        max={max}
        onChange={(event: Event, value: number | number[]) => {
          setRange(value as number[]);
        }}
        onChangeCommitted={(
          event: React.SyntheticEvent | Event,
          value: number | number[]
        ) => {
          setRange(value as number[]);
        }}
        valueLabelDisplay="auto"
        customColor={color}
      />
    </RangeContainer>
  );
}

export default ANDSilder;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  border-radius: 16px;
`;

const RangeSlider = mstyled(Slider)<ColorProps>`
  width: 288px;
  color: ${(props) => props.customColor};

  & .MuiSlider-thumb {
    width: 16px;
    height: 16px;
  }

  & .MuiSlider-mark {
    background-color: ${COLOR.Gray_200};
    height: 24px;
    width: 3px;
    border-radius: 12px;
    &.MuiSlider-markActive {
      opacity: 1;
      background-color: ${(props) => props.customColor};
    },
  },

  & .MuiSlider-rail {
    opacity: 0.5;
    background-color: ${COLOR.Gray_200};
  }
`;

const RangeText = styled(TEXT.body1BOLD)`
  margin-top: 8px;
  color: ${ANDCOLOR.BLUE};
`;
