import styled, { StyledComponent } from "styled-components";
import { TEXT } from "../../fonts/text";
import { COLOR } from "../../fonts/color";

interface DimensionProps {
  width: number;
  height: number;
}

interface Props {
  text: string;
  width?: number;
  height?: number;
  TextStyle?: StyledComponent<"span", any, {}, never>;
}

const ATag = (props: Props) => {
  const { text, width = 49, height = 32, TextStyle = BoldB2Text } = props;
  return (
    <Container width={width} height={height}>
      <TextStyle>{text}</TextStyle>
    </Container>
  );
};

export default ATag;

const Container = styled.div<DimensionProps>`
  display: flex;
  background: linear-gradient(256deg, #bad4ff 0%, #eaf3ff 74%);
  padding: 4px 12px 4px 12px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const BoldB2Text = styled(TEXT.B2_Bold)`
  color: ${COLOR.Blue_700};
`;
